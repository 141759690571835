import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";

// Get Images Start
import why_hire_us from '../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../assets/images/AboutusImages/tick_done.png';
import aboutus_two_col_img from "../assets/images/AboutusImages/aboutus_two_col_img.jpg";
import about_banner from "../assets/images/AboutusImages/about_banner.jpg";
// Get Images End

// Custom Stylesheets Start
import '../assets/css/AboutusStyle.css';
import '../assets/css/commonStyle.css';
// Custom Stylesheets End

// Get Common Components Start
import NumberCount from '../components/common/NumberCount';
import InnerPagesBanner from '../components/common/InnerPagesBanner';
import InnerPagesBelowBannerTwoColumnLayout3 from '../components/common/InnerPagesBelowBannerTwoColumnLayout3';
import OurClients from "./common/OurClients";
import OurTeamPromise from './common/OurTeamPromise';
import CompanyPhilosophy from './common/CompanyPhilosophy';
// Get Common Components End

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "About Visions",
            bannerImg: about_banner,
            content: [
                {
                    title:"More than 3 decades of experience in Information Technology",

                    description: "<strong>VISIONS</strong> has built a significant array of customers over time. An expanding base of clients has benefited from VISIONS expertise and repertoire of solutions. A people's and process company, Visions invests extensively in quality, recruiting, nurturing and retaining talent. Our range of services includes <strong>Customized Solution Development, Application Support and Maintenance, Application/ Product Testing, Design (UI, Website/portals), Project Management & Implementation.</strong>",

                    listingLoop: [
                    "Our products immediately and positively affect the way you interact with your customers. We have made these tools easy to access, so you don't need to spend hours learning and configuring them in order to reap the benefits, nor will they interfere with your work.",
                    "Using our products, you can centrally manage all of your customer support channels. Whether it is email, website, self-service, telephone or live chat, it's all in one place. No clutter and no distractions.",
                    "Once you have great employees on board, how do you keep them from jumping ship?  Visions treat our employees fairly and are dedicated to the success of employees.",
                    "We have pool of employees employed in visions from last 5 years.  Our management will go above and beyond to equip employees with what they need to succeed.",
                    "<strong>Our ethos is quality over quantity & we always aim to deliver every new project with higher quality standards.</strong>"
                    ],

                    image: aboutus_two_col_img,

                    blueBoxText: "Our range of services includes Customized Solution Development Application Support and Maintenance.",

                    orangeBoxText: this.getExperienceyear() + "+ Years of Experience",

                    leftAlignText: "extensively in quality"
                }
            ]
        };
    }
    componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
    }

    getExperienceyear() {
        var today = new Date();
        var start_date = new Date("june 02, 1993");
        var years_now = today.getFullYear() - start_date.getFullYear();
        var m = today.getMonth() - start_date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) 
        {
          years_now--;
        }
        return years_now;
      }

    render() {
        document.title = "About Us";
        const {
            pageTitle,
            bannerImg,
            content
        } = this.state;

        return (
            <div className="aboutus_main">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - InnerPagesBelowBannerTwoColumnLayout3 Import From Common Components */}
                    <InnerPagesBelowBannerTwoColumnLayout3 data={content} />
                    {/* End - InnerPagesBelowBannerTwoColumnLayout3 Import From Common Components */}

                    {/* Start - OurTeamPromise Import From Common Components */}
                    <OurTeamPromise />
                    {/* End - OurTeamPromise Import From Common Components */}

                    {/* Start - CompanyPhilosophy Import From Common Components */}
                    <CompanyPhilosophy />
                    {/* End - CompanyPhilosophy Import From Common Components */}

                    {/* Start - OurClients Import From Common Components */}
                    <OurClients />
                    {/* End - OurClients Import From Common Components */}

                    {/* Start - Section Design "Why Hire Us" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="why_hire_us sec_padding">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="400">Why Hire Us?</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="800">We create outstanding UI/UX, branding web design & development solutions for startups & companies.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="1000">
                                        <ul className="left_list lists_hire_us">
                                            <li>
                                                We specialize in web development outsourcing services. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                We have a long history of satisfied customers across the globe. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                We have top-notch dedicated, enthusiastic and talented professionals. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                Flexible and simple hiring process. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                        </ul>
                                    </Grid>
                                    <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                                        <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                                    </Grid>
                                    <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="1000">
                                        <ul className="right_list lists_hire_us">
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We communicate effectively.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We Offer seamless customer & technical support.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We follow the industry standards.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We’ll always be there to help when you need us.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We only charge you when we do work for you.
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>   
                                    <div className="btn-wrap">                         
                                        <Link to="/hire-developers/hire-android-app-developers" className="dark-btn-effect all-category-mobile" title="Hire Developers">
                                            Hire Developers
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Why Hire Us" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <NumberCount />
                    {/* End - NumberCount Import From Common Components */}

                </Grid>
            </div>
        )
    }
}


export default AboutUs;