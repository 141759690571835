import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import APIimprovement from '../../assets/images/HireDevelopers/HireNodejs/APIimprovement.svg';
import customimprovement from '../../assets/images/HireDevelopers/HireNodejs/customimprovement.svg';
import moduleadvancement from '../../assets/images/HireDevelopers/HireNodejs/moduleadvancement.svg';
import NodeAPIDevelopment from '../../assets/images/HireDevelopers/HireNodejs/NodeAPIDevelopment.svg';
import Nodjsplugin from '../../assets/images/HireDevelopers/HireNodejs/Nodjsplugin.svg';
import WebApplicationDevelopment from '../../assets/images/HireDevelopers/HireNodejs/WebApplicationDevelopment.svg';
import WebDevelopment from '../../assets/images/HireDevelopers/HireNodejs/WebDevelopment.svg';
import webimprovement from '../../assets/images/HireDevelopers/HireNodejs/webimprovement.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HireNodejs/HIRE-NODEJS-DEVELOPERS1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireNodeJsDev extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Hire Node.Js Developers",
            bannerImg: Hire_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: "What administrations zones are characterized under Node.Js improvement? ",
                    TypographyDescription: "We encourage full-scale administrations for Node.Js customization, huge web application, Node.Js module improvement, Node.Js continuous insights, Node.Js for UX/UI advancement, Node.Js mix arrangement, back-end dashboards advancement, &Node.Js portable application."
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: "How would you guarantee quality in venture? ",
                    TypographyDescription: "Visions's engineers are resolved to create quality code. We have formal procedures to hold quality under tight control, including occasional code audits and configuration stroll through. We center after being first-time right, and accept the creation-step itself ought to be solid."
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: "Will You Be accessible to update and support applications in future?",
                    TypographyDescription: "Indeed, Each undertaking we create, has least free help hours/days post dispatch of venture. After free help, we have stunning reasonable help intends to overhaul and bolster applications as long as you need. "
                },
                {
                    expanded: 'panel4',
                    ariaControls: 'panel4d-content',
                    panelId: 'panel4d-header',
                    typographyText: "Would you be able to characterize your valuing model for Node.Js application improvement? ",
                    TypographyDescription: "Our evaluating model is fixed for each portable and web application improvement venture. The evaluating opening may fluctuate on the customer's requests whether to enlist us on an hourly or month to month premise or a devoted engineer to chip away at a venture. "
                },
                {
                    expanded: 'panel5',
                    ariaControls: 'panel5d-content',
                    panelId: 'panel5d-header',
                    typographyText: "What kinds of arrangements are made utilizing Node.Js?",
                    TypographyDescription: "Our Node.Js engineers make ongoing applications dependent on talk, interpersonal interaction, online interfaces, back-end dashboards, field administration, picture handling, information stockpiling, games, large information control, and more administrations."
                }
            ]
        };
    }
    componentWillMount() {
        let isAuthenticated = !localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) {
            this.props.history.push("/login");
            window.location.reload();
        }
    }
    getExperienceyear() {
        var today = new Date();
        var start_date = new Date("june 02, 1993");
        var years_now = today.getFullYear() - start_date.getFullYear();
        var m = today.getMonth() - start_date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) {
            years_now--;
        }
        return years_now;
    }
    render() {
        document.title = "Hire Node.Js Web Developers | Node js Programmers India";
        const {
            pageTitle, bannerImg
        } = this.state;
        return (
            <div className="main_hireEcommerce main_hireDevelopers">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - HireUsForm Import From Root Of Components Folder */}
                    <HireUsForm technology="Nodejs Developers" />
                    {/* End - HireUsForm Import From Root Of Components Folder */}

                    {/* Start - Section Design "Advantages of Hiring Us" */}
                    <Grid item xs={12} className="main_application_dev">
                        <Grid
                            component="div"
                            className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
                            id="ios-app-development"
                        >
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <div className="text_wrap title_row">
                                        <aside>
                                            <Typography
                                                variant="h2"
                                                className="head_title"
                                                data-aos="fade-up"
                                                data-aos-duration="800"
                                            >
                                                Advantages of Hiring Us</Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1200"
                                            >
                                                Being one of the main <strong>Node.js</strong> application advancement organizations, we endeavor to incorporate all business objectives and qualities into stunning arrangements utilizing our broad industry information and imaginative methodologies. We control you through all the procedure to assemble eye-getting, very good quality, and powerful web applications. On the off chance that you need a responsive web application with all the imaginative and inventive functionalities, contract <strong>Node.js</strong> engineer from us who will assist you with building one.</Typography>

                                        </aside>
                                    </div>
                                    <Grid item container xs={12} className="major_networks_wrap">
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={WebDevelopment} alt="Node.js Web Development" title="Node.js Web Development" />
                                                <Typography variant="h5" className="mb-1">
                                                    Node.js Web Development</Typography>
                                                <Typography className="head_paragraph">
                                                    On the off chance that you are anticipating building up your business site that looks astonishing, and has every one of the highlights that your endeavor offers, at that point Visions is the <strong>Node.js</strong> improvement organization you should pick.</Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={WebApplicationDevelopment} alt="Node.js Web Application Development" title="Node.js Web Application Development" />
                                                <Typography variant="h5" className="mb-1">
                                                    Node.js Web Application Development</Typography>
                                                <Typography className="head_paragraph">
                                                    Our <strong>Node.js</strong> advancement administrations assist you with building your next continuous application that works with most extreme proficiency and flawlessness.</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                                                <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={NodeAPIDevelopment} alt="Node.js API Development" title="Node.js API Development" />
                                                <Typography variant="h5" className="mb-1">
                                                    Node.js API Development</Typography>
                                                <Typography className="head_paragraph">
                                                    With our specialization into different advances, we can utilize <strong>Node.JS</strong> alongside MongoDB to create and coordinate an API for your web applications.</Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={Nodjsplugin} alt="Node.js Plugin Development" title="Node.js Plugin Development" />
                                                <Typography variant="h5" className="mb-1">
                                                    Node.js Plugin Development</Typography>
                                                <Typography className="head_paragraph">
                                                    Push the points of confinement of your current site situated in <strong>Node.js</strong>. Visions is a one stop <strong>Node.js</strong> advancement organization that can likewise create <strong>Node.js</strong> modules for your site.</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Advantages of Hiring Us" */}

                    {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Hire Node.Js Developers</Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            <strong>Visions</strong>, a predominant Node JS advancement organization with experienced experts to build up an amazing site that sudden spikes in demand for different stages. Customers fulfillment is our significant resource and our proverb is to satisfy our moved toward customers need with their fundamental necessities. Through our all-encompassing help, we have effectively traversed <strong>{this.getExperienceyear()} years </strong>  of involvement with the IT business and a significant number of upbeat customers.</Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                                    <div className="list_key_boxes">
                                        <img alt="Hub JS Web Improvement" title="Hub JS Web Improvement" src={webimprovement} />
                                        <Typography variant="h4" className="head_title">
                                            Hub JS web improvement</Typography>
                                        <Typography className="head_paragraph">
                                            Contract committed <strong>Node.Js</strong> engineers and experience flexible and adaptable application advancement benefits that improve your business esteem.</Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                                    <div className="list_key_boxes">
                                        <img alt="Node JS Module Advancement" title="Node JS Module Advancement" src={moduleadvancement} />
                                        <Typography variant="h4" className="head_title">
                                            Node JS module advancement </Typography>
                                        <Typography className="head_paragraph">
                                            Master <strong>Node.Js</strong> Remote Team gives module advancement to improving your site's functionalities and abilities. Be it an information substantial application, an ongoing application, a chat application, we have done everything.</Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="list_key_boxes">
                                        <img alt="Node JS API Improvement " title="Node JS API Improvement " src={APIimprovement} />
                                        <Typography variant="h4" className="head_title">
                                            Node JS API improvement</Typography>
                                        <Typography className="head_paragraph">
                                            Alongside <strong>Node.js</strong> outsourcing administrations, we offer top API improvement support for strong administration and high usefulness. An unpredictable application normally has different stage conditions, which could be overseen by having APIs.</Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="list_key_boxes">
                                        <img alt="Node JS Custom Improvement " title="Node JS Custom Improvement " src={customimprovement} />
                                        <Typography variant="h4" className="head_title">
                                            Node JS custom improvement</Typography>
                                        <Typography className="head_paragraph">
                                            We tweak web applications to fit each business and its one of a kind needs. This structure has developed as GoTo Technology to enable constant applications. Contract Node.Js Developers who are proficient at building complex continuous arrangements.</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Hire Our Developers KeyBenefits" */}

                    {/* Start - Section Design "Why Hire Us" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="why_hire_us sec_padding">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We are a group of master technocrats having expertise in <strong>Node.js</strong> advancement administrations. With long periods of involvement with <strong>Node.js</strong> advancement, we have fueled many sites with this innovation and have umpteen glad customers. Our engineers have firm hold over all the mainstream <strong>Node.js</strong> structures and DBs. It enables us to guarantee you progressively excellent and scalably gainful portable and web application with <strong>Node.js</strong>.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                                        <ul className="left_list lists_hire_us">
                                            <li>Cost and time effective Node JS development. <img alt="tick" className="tick_done" src={tick_done} /></li>
                                            <li>Node JS Consultation. <img alt="tick" className="tick_done" src={tick_done} /></li>
                                            <li>Node JS Application Development.<img alt="tick" className="tick_done" src={tick_done} /></li>
                                            <li>Node JS Mobile App Development. <img alt="tick" className="tick_done" src={tick_done} /></li>
                                        </ul>
                                    </Grid>
                                    <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                                        <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                                    </Grid>
                                    <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                                        <ul className="right_list lists_hire_us">
                                            <li><img alt="tick" className="tick_done" src={tick_done} />Node JS Web Application Development.</li>
                                            <li><img alt="tick" className="tick_done" src={tick_done} /> Node JS API Development. </li>
                                            <li><img alt="tick" className="tick_done" src={tick_done} /> Node JS Plugin Development.</li>
                                            <li><img alt="tick" className="tick_done" src={tick_done} /> Node JS Migration. </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Why Hire Us" */}

                    {/* Start - Section Design "We Are Here to Help You" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We make <strong>Node.JS </strong>advancement arrangements that esteem appropriate for your undertaking necessities. Likewise, we think about your vision, goals and convey on time independent of the size of your task. Visions is a believed selection of customers with regards to growing further developed <strong>node.js</strong> based application. Visions <strong>Node.js</strong> designers are profoundly gifted, experienced, and confirmed experts, devoted to creating applications which include combination of most recent advances utilizing <strong>Node.js</strong> highlights.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                                        <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                                        <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "We Are Here to Help You" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <NumberCount />
                    {/* End - NumberCount Import From Common Components */}

                </Grid>
            </div>
        );
    }
}

export default HireNodeJsDev;
