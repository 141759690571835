import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Get Images Start
import count_year_exp from "../../assets/images/count_year_exp.png";
import count_project_delvd from "../../assets/images/count_project_delvd.png";
import count_dedicated_team from "../../assets/images/count_dedicated_team.png";
import count_satisfied_client from "../../assets/images/count_satisfied_client.png";
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
// Custom Stylesheets End

class NumberCount extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      yearsCount: 0,
      projectCount: 0,
      dedicatedCount: 0,
      satisfiedCount: 0,
      scrollDone: false
    };
  }

  getExperienceyear() {
    var today = new Date();
    var start_date = new Date("june 02, 1993");
    var years_now = today.getFullYear() - start_date.getFullYear();
    var m = today.getMonth() - start_date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) 
    {
      years_now--;
    }
    return years_now;
  }

  listenScrollEvent = e => {
    var section1 = this.checkElementInViewport(
      document.getElementById("number_count")
    );
    if(section1) {
      this.numberAutoCount();
    }
    // if (document.querySelector(".number_count.aos-animate")) {
    //   this.numberAutoCount();
    // }
  };

  numberAutoCount() {
    if (this.state.scrollDone === false) {
      this.setState({ scrollDone: true });
      let exitCounter = setInterval(() => {
        this.setState({ yearsCount: this.state.yearsCount + 1 });
        if (this.state.yearsCount === this.getExperienceyear()) {
          clearInterval(exitCounter);
        }
      }, 40);
      let exitCounterpro = setInterval(() => {
        this.setState({ projectCount: this.state.projectCount + 10 });
        if (this.state.projectCount === 1500) {
          clearInterval(exitCounterpro);
        }
      }, 8);
      let exitCounterdedi = setInterval(() => {
        this.setState({ dedicatedCount: this.state.dedicatedCount + 20000 });
        if (this.state.dedicatedCount === 500000) {
          clearInterval(exitCounterdedi);
        }
      }, 70);
      let exitCountersat = setInterval(() => {
        this.setState({ satisfiedCount: this.state.satisfiedCount + 10 });
        if (this.state.satisfiedCount === 1000) {
          clearInterval(exitCountersat);
        }
      }, 10);
    }
  }

  checkElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    const vertInView = 
      rect.top <= windowHeight && rect.top + rect.height >= 0;
    const horInView = 
      rect.left <= windowWidth && rect.left + rect.width >= 0;

    return vertInView && horInView;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent, false);
  }

  render() {
    return (
      <Grid item xs={12}>
        <Grid
          component="div"
          className="number_count sec_padding"
          data-aos="fade-up"
          id="number_count"
        >
          <Grid item container className="container_custom">
            <Typography variant="h2" className="head_title">
            Visions Proudly Serving Clients Worldwide
            </Typography>
            <Grid item container xs={12} spacing={24} className="count_list">
              <Grid item xs={12} sm={6} md={3} className="lists_grid">
                <div className="media">
                  <div className="count_text_box">
                    <Typography variant="h3">
                      <img
                        className="mobile-year-image"
                        alt="Years Experience"
                        title="Years Experience"
                        src={count_year_exp}
                      />
                      {this.state.yearsCount.toLocaleString('en-IN')}+
                    </Typography>
                    <Grid className="years-icon">
                      <img alt="Years Experience" title="Years Experience" src={count_year_exp} />
                      <Typography>Years Experience</Typography>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="lists_grid">
                <div className="media">
                  <div className="count_text_box">
                    <Typography variant="h3">
                      <img
                        className="mobile-year-image"
                        alt="Project Delivered"
                        title="Project Delivered"
                        src={count_project_delvd}
                      />
                      {this.state.projectCount.toLocaleString('en-IN')}+
                    </Typography>
                    <Grid className="years-icon">
                      <img alt="Project Delivered" title="Project Delivered" src={count_project_delvd} />
                      <Typography>Project Delivered</Typography>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="lists_grid">
                <div className="media">
                  <div className="count_text_box">
                    <Typography variant="h3">
                      <img
                        className="mobile-year-image"
                        alt="Development Hours"
                        title="Development Hours"
                        src={count_dedicated_team}
                      />
                      {this.state.dedicatedCount.toLocaleString('en-IN')}+
                    </Typography>
                    <Grid className="years-icon">
                      <img alt="Development Hours" title="Development Hours" src={count_dedicated_team} />
                      <Typography>Development Hours</Typography>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="lists_grid">
                <div className="media">
                  <div className="count_text_box">
                    <Typography variant="h3">
                      <img
                        className="mobile-year-image"
                        alt="Satisfied Clients"
                        title="Satisfied Clients"
                        src={count_satisfied_client}
                      />
                      {this.state.satisfiedCount.toLocaleString('en-IN')}+
                    </Typography>
                    <Grid className="years-icon">
                      <img alt="Satisfied Client" title="Satisfied Client" src={count_satisfied_client} />
                      <Typography>Satisfied Clients</Typography>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default NumberCount;
