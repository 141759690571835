import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Get Files for Slider Start
import Slider from "../../slider";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End


import districtmarketing_mobile from "../../assets/images/GraphicDesign/districtmarketing_mobile.jpg";
import districtmarketing from "../../assets/images/GraphicDesign/districtmarketing.jpg";
import ecotileflooring_mobile from "../../assets/images/GraphicDesign/ecotileflooring_mobile.jpg";
import ecotileflooring from "../../assets/images/GraphicDesign/ecotileflooring.jpg";
import inthenumber_mobile from "../../assets/images/GraphicDesign/inthenumber_mobile.jpg";
import inthenumber from "../../assets/images/GraphicDesign/inthenumber.jpg";
import roguefitness_mobile from "../../assets/images/GraphicDesign/roguefitness_mobile.jpg";
import roguefitness from "../../assets/images/GraphicDesign/roguefitness.jpg";
import php_icon from "../../assets/images/Portfolios/Technologies/php_icon.svg";
import magento_icon from "../../assets/images/Portfolios/Technologies/magento_icon.svg";
import wordpress_icon from "../../assets/images/Portfolios/Technologies/wordpress_icon.svg";
import wooCommerce_icon from "../../assets/images/Portfolios/Technologies/wooCommerce_icon.svg";
import bootstrap_icon from "../../assets/images/Portfolios/Technologies/bootstrap_icon.jpg";


class SliderServiceCatPagePortfolio4 extends Component {

    constructor(props) {
        super(props);
        this.state = {
          nav1: null,
          nav2: null
        };
      }

      componentDidMount() {
        this.setState({
          nav1: this.slider1,
          nav2: this.slider2
        });
      }

    render() {

        

        return (
            <React.Fragment>
                <Grid className="serviceCatPortfolioSlider2 sliderNavForDesign sec_padding pt-0 pb-0" data-aos="fade-up" data-aos-duration="800">
                    <Grid item container className="">
                        <Grid item xs={12}> 
                            <Slider 
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            slidesToShow={3}
                            slidesToScroll={1}
                            swipeToSlide={true}
                            focusOnSelect={true}
                            centerMode={true}
                            centerPadding={0}
                            arrows={true}
                            dots={true}
                            autoplay={true}
                            infinite={true}
                            responsive={[
                                {
                                    breakpoint: 767.98,
                                    settings: {
                                        slidesToShow: 1,
                                        centerPadding: '50px'
                                    }
                                }
                            ]}
                            >
                                <div>
                                    <aside>
                                        <div className="slide_item testimonial_list">
                                            <div className="website_img">
                                                <img width="auto" height="auto" src={districtmarketing} alt="District Marketing" title="District Marketing" />
                                            </div>
                                            <div className="content_wrap">
                                                <Typography className="title">District Marketing</Typography>
                                                <div className="tech_icons_wrapper">
                                                    <img width="30" height="auto" src={php_icon} alt="PHP" title="PHP" className="tech_icons"/> 
                                                    <img width="30" height="auto" src={bootstrap_icon} alt="Bootstrap" title="Bootstrap" className="tech_icons"/> 
                                                </div>
                                                <a href="http://www.districtmarketing.ae/" title="http://www.districtmarketing.ae/" target="_blank" rel="noopener noreferrer" className="slide_item testimonial_list">
                                                    <Typography className="link btn">Go To Website</Typography>
                                                </a>
                                            </div> 
                                        </div>
                                    </aside>
                                </div>
                                {/* <div>
                                    <aside>
                                        <div className="slide_item testimonial_list">
                                            <div className="website_img">
                                                <img width="auto" height="auto" src={ecotileflooring} alt="Ecotile Flooring" title="Ecotile Flooring" />
                                            </div>
                                            <div className="content_wrap">
                                                <Typography className="title">Ecotile Flooring</Typography>
                                                <div className="tech_icons_wrapper">
                                                    <img width="30" height="auto" src={php_icon} alt="PHP" title="PHP" className="tech_icons"/> 
                                                    <img width="30" height="auto" src={wordpress_icon} alt="Wordpress" title="Wordpress" className="tech_icons"/>
                                                    <img width="30" height="auto" src={bootstrap_icon} alt="Bootstrap" title="Bootstrap" className="tech_icons"/> 
                                                </div>
                                                <a href="https://www.ecotileflooring.com/" title="https://www.ecotileflooring.com/" target="_blank" rel="noopener noreferrer" className="slide_item testimonial_list">
                                                    <Typography className="link btn">Go To Website</Typography>
                                                </a>
                                            </div> 
                                        </div>
                                    </aside>
                                </div> */}
                                {/* <div>
                                    <aside>
                                        <div className="slide_item testimonial_list">
                                            <div className="website_img">
                                                <img width="auto" height="auto" src={inthenumber} alt="InTheNumber" title="InTheNumber" />
                                            </div>
                                            <div className="content_wrap">
                                                <Typography className="title">InTheNumber</Typography>
                                                <div className="tech_icons_wrapper">
                                                    <img width="30" height="auto" src={php_icon} alt="PHP" title="PHP" className="tech_icons"/> 
                                                    <img width="30" height="auto" src={wordpress_icon} alt="Wordpress" title="Wordpress" className="tech_icons"/> 
                                                    <img width="30" height="auto" src={wooCommerce_icon} alt="WooCommerce" title="WooCommerce" className="tech_icons"/> 
                                                </div>
                                                <a href="http://inthenumber.com/" title="http://inthenumber.com/" target="_blank" rel="noopener noreferrer" className="slide_item testimonial_list">
                                                    <Typography className="link btn">Go To Website</Typography>
                                                </a>
                                            </div> 
                                        </div>
                                    </aside>
                                </div> */}
                                {/* <div>
                                    <aside>
                                        <div  className="slide_item testimonial_list">
                                            <div className="website_img">
                                                <img width="auto" height="auto" src={roguefitness} alt="Rogue Fitness" title="Rogue Fitness" />
                                            </div>
                                            <div className="content_wrap">
                                                <Typography className="title">Rogue Fitness</Typography>
                                                <div className="tech_icons_wrapper">
                                                    <img width="30" height="auto" src={magento_icon} alt="Magento" title="Magento" className="tech_icons"/> 
                                                </div>
                                                <a href="https://www.roguefitness.com/" title="https://www.roguefitness.com/" target="_blank" rel="noopener noreferrer" className="slide_item testimonial_list">
                                                    <Typography className="link btn">Go To Website</Typography>
                                                </a>
                                            </div> 
                                        </div>
                                    </aside>
                                </div> */}
                            </Slider>
                            <div className="mobileSlider">
                            <Slider
                            asNavFor={this.state.nav1}
                            ref={slider => (this.slider2 = slider)}
                            arrows={false}
                            dots={false}
                            >
                                <div>
                                    <aside>
                                        <div className="mobile_view_img">
                                            <img width="auto" height="auto" src={districtmarketing_mobile} alt="District Marketing" title="District Marketing" />
                                        </div>
                                    </aside>
                                </div>
                                <div>
                                    <aside>
                                        <div className="mobile_view_img">
                                            <img width="auto" height="auto" src={ecotileflooring_mobile} alt="Ecotile Flooring" title="Ecotile Flooring" />
                                        </div>
                                    </aside>
                                </div>
                                {/* <div>
                                    <aside>
                                        <div className="mobile_view_img">
                                            <img width="auto" height="auto" src={inthenumber_mobile} alt="InTheNumber" title="InTheNumber" />
                                        </div>
                                    </aside>
                                </div> */}
                                {/* <div>
                                    <aside>
                                        <div className="mobile_view_img">
                                            <img width="auto" height="auto" src={roguefitness_mobile} alt="Rogue Fitness" title="Rogue Fitness" />
                                        </div>
                                    </aside>
                                </div>                             */}
                            </Slider>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>             
            </React.Fragment>
        )
    }
}

export default SliderServiceCatPagePortfolio4;