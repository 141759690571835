import React, { Component, Suspense, lazy } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

// Get Images Start
import magento from "../../assets/images/Header/magento.svg";
import woocommerce from "../../assets/images/Header/woo.svg";
import shopify from "../../assets/images/Header/shopify.svg";
import opencart from "../../assets/images/Header/opencart.svg";
import header_bigcommerce_icon from "../../assets/images/Header/header-bigcommerce-icon.svg";
import magento_White from "../../assets/images/white-icons/magento.svg";
import woocommerce_White from "../../assets/images/white-icons/woo.svg";
import shopify_White from "../../assets/images/white-icons/shopify.svg";
import opencart_White from "../../assets/images/white-icons/opencart.svg";
import header_bigcommerce_icon_White from "../../assets/images/white-icons/header-bigcommerce-icon.svg";
import ahmanndesign from "../../assets/images/E-CommerceDevelopment/ahmanndesign.jpg";
import Artelia from "../../assets/images/E-CommerceDevelopment/Artelia.jpg";
import Flagship from "../../assets/images/E-CommerceDevelopment/Flagship.jpg";
import hijabuyer from "../../assets/images/E-CommerceDevelopment/hijabuyer.jpg";
import papelstore from "../../assets/images/E-CommerceDevelopment/papelstore.jpg";
import Its_Cooler from "../../assets/images/E-CommerceDevelopment/Its-Cooler.jpg";
import m4mattress from "../../assets/images/E-CommerceDevelopment/m4mattress.jpg";
import marbletops from "../../assets/images/E-CommerceDevelopment/marbletops.jpg";
import store_more from "../../assets/images/E-CommerceDevelopment/Store-more.jpg";
import bbi_flags from "../../assets/images/E-CommerceDevelopment/bbi-flags.jpg";
import Obliphica from "../../assets/images/E-CommerceDevelopment/Obliphica.jpg";
import Orchids from "../../assets/images/E-CommerceDevelopment/Orchids.jpg";
import Wild_tea from "../../assets/images/E-CommerceDevelopment/Wild-tea.jpg";
import Js_Tech from "../../assets/images/E-CommerceDevelopment/Js-Tech.jpg";
import lightsounds from "../../assets/images/E-CommerceDevelopment/lightsounds.jpg";
import Singapore_wedding from "../../assets/images/E-CommerceDevelopment/Singapore-wedding.jpg";
import Urben_Thekka from "../../assets/images/E-CommerceDevelopment/Urben-Thekka.jpg";
import CatalogTransferServices from "../../assets/images/E-CommerceDevelopment/CatalogTransferServices.svg";
import COSTEFFECTIVE from "../../assets/images/E-CommerceDevelopment/COSTEFFECTIVE.svg";
import CustomMagentoSolutions from "../../assets/images/E-CommerceDevelopment/CustomMagentoSolutions.svg";
import EducationServices from "../../assets/images/E-CommerceDevelopment/EducationServices.svg";
import ImplementationProject from "../../assets/images/E-CommerceDevelopment/ImplementationProject.svg";
import MagentoModuleDevelopment from "../../assets/images/E-CommerceDevelopment/MagentoModuleDevelopment.svg";
import MagentoShoppingCartDevelopment from "../../assets/images/E-CommerceDevelopment/MagentoShoppinCartDevelopment.svg";
// import MagentoShoppinCartDevelopment from "../../assets/images/E-CommerceDevelopment/MagentoShoppingCartDevelopment.svg";
import MagentoWebsiteDevelopment from "../../assets/images/E-CommerceDevelopment/MagentoWebsiteDevelopment.svg";
import Migration from "../../assets/images/E-CommerceDevelopment/Migration.svg";
import OpenCartCustomization from "../../assets/images/E-CommerceDevelopment/OpenCartCustomization.svg";
import OpenCartExtensionDevelopment from "../../assets/images/E-CommerceDevelopment/OpenCartExtensionDevelopment.svg";
import OpenCartIntegration from "../../assets/images/E-CommerceDevelopment/OpenCartIntegration.svg";
import OpenCartStoreDevelopment from "../../assets/images/E-CommerceDevelopment/OpenCartStoreDevelopment.svg";
import OpenCartThemeDevelopment from "../../assets/images/E-CommerceDevelopment/OpenCartThemeDevelopment.svg";
import OPTIMIZEDSHOPIFYSTORE from "../../assets/images/E-CommerceDevelopment/OPTIMIZEDSHOPIFYSTORE.svg";
import QUICKTOMARKETSHOPIFYSOLUTIONS from "../../assets/images/E-CommerceDevelopment/QUICKTOMARKETSHOPIFYSOLUTIONS.svg";
import SHOPIFYSTORECUSTOMIZATION from "../../assets/images/E-CommerceDevelopment/SHOPIFYSTORECUSTOMIZATION.svg";
import SolutionsArchitecting from "../../assets/images/E-CommerceDevelopment/SolutionsArchitecting.svg";
import STOREMIGRATION from "../../assets/images/E-CommerceDevelopment/STOREMIGRATION.svg";
import UPGRADEANDMAINTENANCE from "../../assets/images/E-CommerceDevelopment/UPGRADEANDMAINTENANCE.svg";
import WocommerceStorefrontCustomization from "../../assets/images/E-CommerceDevelopment/WocommerceStorefrontCustomization.svg";
import WooCommerceStoreDevelopment from "../../assets/images/E-CommerceDevelopment/WooCommerceStoreDevelopment.svg";
import contact_email_extension from "../../assets/images/E-CommerceDevelopment/contact_email_extension.svg";
import home_slider_extension from "../../assets/images/E-CommerceDevelopment/home_slider_extension.svg";
import category_display_extension from "../../assets/images/E-CommerceDevelopment/category_display_extension.svg";
import trending_products_extension from "../../assets/images/E-CommerceDevelopment/trending_products_extension.svg";
import category_display_extension_white from "../../assets/images/E-CommerceDevelopment/category_display_extension_white.svg";
import WooCommerceResponsiveThemeDevelopment from "../../assets/images/E-CommerceDevelopment/WooCommerceResponsiveThemeDevelopment.svg";
import ecommerce from "../../assets/images/E-CommerceDevelopment/ecommerce.jpg";
import bootstrap_icon from "../../assets/images/Portfolios/Technologies/bootstrap_icon.jpg";
import magento_icon from "../../assets/images/Portfolios/Technologies/magento_icon.svg";
import wordpress_icon from "../../assets/images/Portfolios/Technologies/wordpress_icon.svg";
import wooCommerce_icon from "../../assets/images/Portfolios/Technologies/wooCommerce_icon.svg";
import shopify_icon from "../../assets/images/Portfolios/Technologies/shopify_icon.svg";
import opencart_icon from "../../assets/images/Portfolios/Technologies/opencart_icon.svg";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
// import NumberCount from "../common/NumberCount";
import SliderServiceCatPagePortfolio2 from "../common/SliderServiceCatPagePortfolio2";
const NumberCount = lazy(() => import("../common/NumberCount"));
// Get Common Components End

class ECommerceDevelopment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "E-commerce Development Services",
      bannerImg: ecommerce,
      activeTab: "magento-development",
      activeTab1: "tab-1",
      tabToggle: false,
      isClickedEventFired: 0,
      magentoSlider: [],
      woocommerceSlider: [],
      shopifySlider: [],
      openCartSlider: [],
      bigcommerceSlider: []
    };
    smoothscroll.polyfill();
    this.baseState = this.state;
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }

    const magentoData = [
      {
        image: ahmanndesign,
        title: "Ahmann Design",
        link: "https://ahmanndesign.com/",
        tech_images: [{ icon: magento_icon, title: "Magento" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      },
      {
        image: Artelia,
        title: "Artelia",
        link: "https://www.artelia.de/",
        tech_images: [{ icon: magento_icon, title: "Magento" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      },
      {
        image: Flagship,
        title: "Flagship",
        link: "https://www.fs1inc.com/",
        tech_images: [{ icon: magento_icon, title: "Magento" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      },
      {
        image: hijabuyer,
        title: "Hijabuyer",
        link: "https://www.hijabuyer.com/",
        tech_images: [{ icon: magento_icon, title: "Magento" }]
      },
      // {
      //   image: papelstore,
      //   title: "PapelStore",
      //   link: "http://www.papelstore.es/",
      //   tech_images: [{ icon: magento_icon, title: "Magento" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      // }
    ];

    const woocommerceData = [
      // {
      //   image: Its_Cooler,
      //   title: "Its Cooler",
      //   link: "https://www.itscooler.asia/",
      //   tech_images: [{ icon: wooCommerce_icon, title: "WooCommerce" }, { icon: bootstrap_icon, title: "Bootstrap" }, { icon: wordpress_icon, title: "Wordpress" }]
      // },
      // {
      //   image: m4mattress,
      //   title: "M4mattress",
      //   link: "https://www.m4mattress.co.uk/",
      //   tech_images: [{ icon: wooCommerce_icon, title: "WooCommerce" }, { icon: bootstrap_icon, title: "Bootstrap" }, { icon: wordpress_icon, title: "Wordpress" }]
      // },
      {
        image: marbletops,
        title: "MarbleTops",
        link: "https://www.marbletops.co.uk/",
        tech_images: [{ icon: wooCommerce_icon, title: "WooCommerce" }, { icon: wordpress_icon, title: "Wordpress" }]
      },
      // {
      //   image: store_more,
      //   title: "Store More",
      //   link: "https://storemore.ie/",
      //   tech_images: [{ icon: wooCommerce_icon, title: "WooCommerce" }, { icon: bootstrap_icon, title: "Bootstrap" }, { icon: wordpress_icon, title: "Wordpress" }]
      // }
    ];

    const shopifyData = [
      // {
      //   image: bbi_flags,
      //   title: "BBI Flags",
      //   link: "https://bbiflags.com/",
      //   tech_images: [{ icon: shopify_icon, title: "Shopify" }]
      // },
      // {
      //   image: Obliphica,
      //   title: "Obliphica",
      //   link: "https://obliphica.com/",
      //   tech_images: [{ icon: shopify_icon, title: "Shopify" }]
      // },
      {
        image: Orchids,
        title: "Orchids",
        link: "https://www.orchidsluxhome.com/",
        tech_images: [{ icon: shopify_icon, title: "Shopify" }]
      },
      // {
      //   image: Wild_tea,
      //   title: "Wild Tea Qi",
      //   link: "https://wildteaqi.com/",
      //   tech_images: [{ icon: shopify_icon, title: "Shopify" }]
      // }
    ];

    const openCartData = [
      {
        image: Js_Tech,
        title: "JsTech",
        link: "https://jstech.com.au/",
        tech_images: [{ icon: opencart_icon, title: "OpenCart" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      },
      // {
      //   image: lightsounds,
      //   title: "LightSounds",
      //   link: "http://lightsounds.com.au/",
      //   tech_images: [{ icon: opencart_icon, title: "OpenCart" }]
      // },
      // {
      //   image: Singapore_wedding,
      //   title: "SingaporeWeddingHub",
      //   link: "https://singaporeweddinghub.com/",
      //   tech_images: [{ icon: opencart_icon, title: "OpenCart" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      // },
      // {
      //   image: Urben_Thekka,
      //   title: "Urban Theka",
      //   link: "https://www.urbantheka.in/",
      //   tech_images: [{ icon: opencart_icon, title: "OpenCart" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      // }
    ];

    const bigcommerceData = [
      {
        image: store_more,
        title: "Store More",
        link: "https://storemore.ie/",
        tech_images: ""
      }
    ];

    this.setState({ magentoSlider: magentoData, woocommerceSlider: woocommerceData, shopifySlider: shopifyData, openCartSlider: openCartData, bigcommerceSlider: bigcommerceData });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent, false);
  }

  listenScrollEvent = e => {
    if (this.state.isClickedEventFired > 0) {
      var abc = setTimeout(() => {
        this.setState({ isClickedEventFired: 0 });
      }, 5000);
      if (this.state.isClickedEventFired > 1) {
        clearTimeout(abc);
      }
    } else {
      var section1 = this.checkElementInViewport(
        document.getElementById("magento-development")
      );
      var section2 = this.checkElementInViewport(
        document.getElementById("magento-extension-development")
      );
      var section3 = this.checkElementInViewport(
        document.getElementById("woocommerce-development")
      );
      var section4 = this.checkElementInViewport(
        document.getElementById("shopify-development")
      );
      var section5 = this.checkElementInViewport(
        document.getElementById("openCart-development")
      );
      var section6 = this.checkElementInViewport(
        document.getElementById("bigcommerce-development")
      );

      if (section1 && section2) {
        this.setState({
          activeTab: "magento-development",
          activeTab1: "tab-1"
        });
      }
      if ((section2 && !section1) || (section2 && section3)) {
        this.setState({
          activeTab: "magento-extension-development",
          activeTab1: "tab-2"
        });
      }
      if ((section3 && !section2) || (section3 && section4)) {
        this.setState({
          activeTab: "woocommerce-development",
          activeTab1: "tab-3"
        });
      }
      if ((section4 && !section3) || (section4 && section5)) {
        this.setState({
          activeTab: "shopify-development",
          activeTab1: "tab-4"
        });
      }
      if ((section5 && !section4) || (section5 && section6)) {
        this.setState({
          activeTab: "openCart-development",
          activeTab1: "tab-5"
        });
      }
      if (section6 && !section5) {
        this.setState({
          activeTab: "bigcommerce-development",
          activeTab1: "tab-6"
        });
      }
    }
  };

  checkElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    const vertInView =
      rect.top <= windowHeight && rect.top-74 + rect.height >= 0;
    const horInView =
      rect.left <= windowWidth && rect.left-74 + rect.width >= 0;

    return vertInView && horInView;
  }

  handleOnClickScroll = (divId, tab) => {
    this.setState({
      activeTab: divId,
      activeTab1: tab,
      isClickedEventFired: this.state.isClickedEventFired + 1
    });
    var ref = document.getElementById(divId);
    window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
  };

  handleTabToggle = () => {
    this.setState(state => ({ tabToggle: !state.tabToggle }));
  };

  componentWillReceiveProps(nextProps) {
    var backToTop = localStorage.getItem("backToTop");
    if (nextProps.match.params.id) {
      if (nextProps.match.params.id !== this.props.match.params.id) {
        var ref = document.getElementById(nextProps.match.params.id);
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
      }
    }
    if (backToTop && backToTop === "true") {
      this.setState({
        activeTab: this.baseState.activeTab,
        activeTab1: this.baseState.activeTab1
      });
      localStorage.removeItem("backToTop");
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent, false);
    if (this.props.match.params.id) {
      var ref = document.getElementById(this.props.match.params.id);
      if (ref)
      setTimeout(() => {
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
      }, 500);
      else
        this.props.history.push("/services/ecommerce-development-services");
    }
  }

  render() {
    if (this.props.match.params.id) {
      if (this.props.match.params.id === 'magento-development') {
          document.title = "Magento Development Services | Magento Developers";
      } else if (this.props.match.params.id === 'magento-extension-development') {
          document.title = "Magento Development Services | Magento Developers";
      } else if (this.props.match.params.id === 'woocommerce-development') {
          document.title = "Woocommerce Development Service Company India | Expert Woocommerce";
      } else if (this.props.match.params.id === 'shopify-development') {
          document.title = "Shopify Website Development Services Company India";
      } else if (this.props.match.params.id === 'openCart-development') {
          document.title = "Opencart Development Services Company | Opencart Developer";
      } else if (this.props.match.params.id === 'bigcommerce-development') {
          document.title = "Bigcommerce Development Services Company | Web Developers India";
      } else {
          document.title = "E-commerce Web Developers | E-commerce Development Company India";
      }
    } else {
        document.title = "E-commerce Web Developers | E-commerce Development Company India";
    }
    const { 
      pageTitle, 
      bannerImg, 
      magentoSlider, 
      woocommerceSlider, 
      shopifySlider, 
      openCartSlider, 
      //bigcommerceSlider 
    } = this.state;
    return (
      <div className="main_ecommerce_dev service_category main_services">
        <Grid container>          
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}
          <Grid item xs={12}>
            <Grid component="div">
              <Grid className="serivceTabsSide">
                <Grid
                  className={`services-listing ${
                    this.state.tabToggle ? "showTab" : ""
                    }`}
                >
                  <div className="toggle-btn" onClick={this.handleTabToggle}>
                    <i className="material-icons">arrow_forward</i>
                  </div>
                  <Grid item container className="container_custom">
                    <Grid item xs={12}>
                      <div className={`row-flex ${this.state.activeTab1}`}>
                        <div
                          className={`col-flex tab-1 ${
                            this.state.activeTab === "magento-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "magento-development",
                              "tab-1"
                            )
                          }
                          title="Magento Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={magento}
                                className="withoutActive"
                                alt=" Magento Development"
                                title="Magento Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={magento_White}
                                className="withActive"
                                alt="Magento Development"
                                title="Magento Development"
                              />
                            </div>
                            <Typography>
                              Magento <br /> Development{" "}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-2 ${
                            this.state.activeTab === "magento-extension-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "magento-extension-development",
                              "tab-2"
                            )
                          }
                          title="Extensions Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={category_display_extension}
                                className="withoutActive"
                                alt="Extensions Development"
                                title="Extensions Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={category_display_extension_white}
                                className="withActive"
                                alt="Extensions Development"
                                title="Extensions Development"
                              />
                            </div>
                            <Typography>
                             Extensions <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-3 ${
                            this.state.activeTab === "woocommerce-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "woocommerce-development",
                              "tab-3"
                            )
                          }
                          title="WooCommerce Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={woocommerce}
                                className="withoutActive"
                                alt="WooCommerce Development"
                                title="WooCommerce Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={woocommerce_White}
                                className="withActive"
                                alt="WooCommerce Development"
                                title="WooCommerce Development"
                              />
                            </div>
                            <Typography>
                              WooCommerce <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-4 ${
                            this.state.activeTab === "shopify-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "shopify-development",
                              "tab-4"
                            )
                          }
                          title="Shopify Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={shopify}
                                className="withoutActive"
                                alt="Shopify Development"
                                title="Shopify Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={shopify_White}
                                className="withActive"
                                alt="Shopify Development"
                                title="Shopify Development"
                              />
                            </div>
                            <Typography>
                              Shopify <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-5 ${
                            this.state.activeTab === "openCart-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "openCart-development",
                              "tab-5"
                            )
                          }
                          title="Opencart Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={opencart}
                                className="withoutActive"
                                alt="Opencart Development"
                                title="Opencart Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={opencart_White}
                                className="withActive"
                                alt="Opencart Development"
                                title="Opencart Development"
                              />
                            </div>
                            <Typography>
                              Opencart <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-6 ${
                            this.state.activeTab === "bigcommerce-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "bigcommerce-development",
                              "tab-6"
                            )
                          }
                          title="BigCommerce Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={header_bigcommerce_icon}
                                className="withoutActive"
                                alt="BigCommerce Development"
                                title="BigCommerce Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={header_bigcommerce_icon_White}
                                className="withActive"
                                alt="BigCommerce Development"
                                title="BigCommerce Development"
                              />
                            </div>
                            <Typography>
                              BigCommerce <br /> Development
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}

          {/* Start - Section Design "First Section Of Service Category Page" */}
          <Grid item xs={12}>
            <Grid component="div" className="sec_padding sec_one">
              <Grid item container className="container_custom">
                <Grid item xs={12} className="title_row">
                  <aside className="">
                    <Typography
                      variant="h3"
                      className="head_title mb-3"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="1000"
                    >
                      Quick E-commerce web development services that give you a
                      highly interactive storefront and supremely convertible
                      cart and a simple, secure payment gateway.
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="1500"
                    >
                    <strong>E-commerce website development and design solutions</strong>. Known for innovation and excellence, we have proved ourselves capable enough of handling the growing demands of a robust yet likable E-commerce platform. We have helped businesses upscale the growth with a robust solution that gives them a platform to out-reach the globe. Passionate about creating world-class solutions, we work dedicatedly to deliver solutions that are customized and can drive traffic.
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="2000"
                    >
                      <strong>E-commerce website design </strong> and <strong>web application development </strong> both on the computer and mobile phones have led to massive competition in the field. <strong>Visions </strong> has become a leading <strong>E-commerce development</strong> company that offers commerce websites for small-scale as well as large scale enterprises.
                    </Typography>
                  </aside>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "First Section Of Service Category Page" */}

          {/* Start - Section Design "Magento Development Service" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits magentoSec bg-lightgrey section_title_space"
              id="magento-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title "
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Magento Development Service
                    </Typography>
                    <Typography
                      className="head_paragraph "
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <strong>Visions</strong> has a team of expert <strong>Magento developers </strong> that offer
                      result oriented <strong>Magento E-commerce development </strong> and <strong>Magento
                      website customization services</strong>. Whether you want us to
                      build an ecommerce platform or Magento extension, our
                      <strong> Magento development company in India</strong> has a proven track
                      record of delivering the best-in-class <strong>Magento development </strong>
                      services.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="Magento Website Development"
                      title="Magento Website Development"
                      src={MagentoWebsiteDevelopment}
                    />
                    <Typography variant="h4" className="head_title ">
                      Magento Website Development
                    </Typography>
                    <Typography className="head_paragraph ">
                      From design to development, our web designer, developer,
                      and quality team works together to make sure you get a
                      powerful ecommerce website that contains great
                      functionality, content management, speed.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="Custom Magento Solutions"
                      title="Custom Magento Solutions"
                      src={CustomMagentoSolutions}
                    />
                    <Typography variant="h4" className="head_title ">
                      Custom Magento Solutions
                    </Typography>
                    <Typography className="head_paragraph ">
                      We are specialized in < strong>Magento customization </strong> and offers
                      high quality and result oriented Magento websites to help
                      you achieve your business goals easily. Our Magento
                      experts customize your E-commerce store to drive more
                      conversion.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="Magento Module Development"
                      title="Magento Module Development"
                      src={MagentoModuleDevelopment}
                    />
                    <Typography variant="h4" className="head_title ">
                      Magento Module Development
                    </Typography>
                    <Typography className="head_paragraph ">
                      We offer fully functional module development services for 
                      <strong> Magento 1 </strong> & <strong>Magento 2 </strong>to enhance user E-commerce
                      experience. Our dedicated Magento experts develop,
                      customize or integrate the modules as per your custom
                      E-commerce store requirement.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="Magento Shopping Cart Development"
                      title="Magento Shopping Cart Development"
                      src={MagentoShoppingCartDevelopment}
                    />
                    <Typography variant="h4" className="head_title ">
                      Magento Shopping Cart Development
                    </Typography>
                    <Typography className="head_paragraph ">
                      Our developer creates feature rich Magento shopping carts
                      with an excellent functionality, security & scalability,
                      so that you can sell your products with an appealing
                      E-commerce store. We offer end to end Magento shopping cart
                      solutions.
                    </Typography>
                  </div>
                </Grid>

                <SliderServiceCatPagePortfolio2 data={magentoSlider} />
                <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                  <Link to="/hire-developers/hire-magento-developers" className="dark-btn-effect all-category-mobile" title="Hire Magento Developers">
                  Hire Magento Developers
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Magento Development Service" */}

          {/* Start - Section Design "Magento Extension Development & Integration" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits woocommerceSec section_title_space"
              id="magento-extension-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Magento Extension Development & Integration
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      To boost your online store performance and functionality, our magento developers works dedicatedly to provide custom <strong>Magento extension development services</strong> that maximize your ecommerce store overall performance. Being a leading <strong>Magento 2 extension development company in India</strong>, we feel proud to deliver such a wide range of extensions.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="Contact Us CC Email Extension"
                      title="Contact Us CC Email Extension"
                      src={contact_email_extension}
                    />
                    <Typography variant="h4" className="head_title">
                    Contact Us CC Email Extension
                    </Typography>
                    <Typography className="head_paragraph">
                    Add multiple contact form email recipients who can receive CC of the original email sent to store admin. No limit to add sub-recipients and easy and quick installation. 
                    </Typography>
                    <Typography className="head_paragraph">
                  The only <strong>Magento 2 Contact Us CC Email Extension</strong> is available in the market for such functionality.
                    </Typography>
                    <a href="https://marketplace.magento.com/visions-contact.html" className="dark-btn-effect all-category-mobile" target="_blank" rel="noopener noreferrer" title="Download">Download</a>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="Home Banner Slider Extension"
                      title="Home Banner Slider Extension"
                      src={home_slider_extension}
                    />
                    <Typography variant="h4" className="head_title">
                    Home Banner Slider Extension
                    </Typography>
                    <Typography className="head_paragraph">
                    <strong>Magento 2 Home Banner Slider Extension</strong> is a powerful tool to enhance the visual effects of your Magento store homepage. 
                    </Typography>
                    <Typography className="head_paragraph">
                   This <strong>Magento 2 Extension</strong> allows you to add a banner slider with up to three images and multiple options for transitions to be managed from the admin side. Easily manage prev/next or dotes on a banner slider with this <strong>Magento 2</strong> Homepage Banner Slider Extension.
                    </Typography>
                    <a href="https://marketplace.magento.com/visions-slider.html" className="dark-btn-effect all-category-mobile" target="_blank" rel="noopener noreferrer" title="Download">Download</a>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="Category Display Widget Extension"
                      title="Category Display Widget Extension"
                      src={category_display_extension}
                    />
                    <Typography variant="h4" className="head_title">
                    Category Display Widget Extension
                    </Typography>
                    <Typography className="head_paragraph">
                    Widgets are powerful functionalities in the <strong>Magento 2 configuration</strong>. As a store admin, you can take advantage of this Category Display Widget to improve the storefront under the lively interface. 
                    </Typography>
                    <Typography className="head_paragraph">
                 This widget allows store admins to easily display categories and subcategories with name, image, and description on any page of your CMS.
                    </Typography>
                    <a href="https://marketplace.magento.com/visions-category-links.html" className="dark-btn-effect all-category-mobile" target="_blank" rel="noopener noreferrer" title="Download">Download</a>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="Now Trending Products"
                      title="Now Trending Products"
                      src={trending_products_extension}
                    />
                    <Typography variant="h4" className="head_title">
                    Now Trending Products
                    </Typography>
                    <Typography className="head_paragraph">
                    <strong>Visions</strong> created now trending products widget. Admin will have a widget to be added from backend for showing latest trending products anywhere in Magento with options to select if they want to show the top selling products daily, weekly or monthly.
                    </Typography>
                    
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                  <a href="https://marketplace.magento.com/partner/Visions_chd" rel="noopener noreferrer" className="dark-btn-effect all-category-mobile" title="View Our Magento Marketplace Profile" target="_blank">
                  View Our Magento Marketplace Profile
                  </a>
                </Grid>
            </Grid>         
          </Grid>
          {/* End - Section Design "Magento Extension Development & Integration" */}

          {/* Start - Section Design "WooCommerce Development" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits woocommerceSec section_title_space bg-lightgrey"
              id="woocommerce-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      WooCommerce Development
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <strong>WooCommerce </strong> is a <strong>WordPress plugin </strong> that is designed to
                      fulfill the retailer’s expectations towards their online
                      business. Get the secure, scalable and elegant WooCommerce
                      Development Services with the help of <strong>WooCommerce experts</strong>.
                      <strong> Visions </strong>, a renowned <strong>WooCommerce development </strong> company India
                      is capable to transform your existing WordPress website
                      into an efficient
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="WooCommerce Store Development"
                      title="WooCommerce Store Development"
                      src={WooCommerceStoreDevelopment}
                    />
                    <Typography variant="h4" className="head_title">
                      WooCommerce
                      <br /> Store Development
                    </Typography>
                    <Typography className="head_paragraph">
                      Get a robust <strong>WooCommerce E-commerce </strong> store that is developed
                      with advanced features and functionalities like checkout,
                      payment gateways, shipping methods, and more. Our
                      <strong> E-commerce developers </strong> make sure you get an excellent
                      <strong> WooCommerce store</strong> design and development services from us.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="WooCommerce Responsive Theme Development"
                      title="WooCommerce Responsive Theme Development"
                      src={WooCommerceResponsiveThemeDevelopment}
                    />
                    <Typography variant="h4" className="head_title">
                      WooCommerce Responsive Theme
                      <br /> Development
                    </Typography>
                    <Typography className="head_paragraph">
                      When it comes to <strong>WooCommerce responsive theme development</strong>,
                      you should not worry about that. Feel free to hire <strong>Visions </strong>
                      expert <strong>WooCommerce developers </strong> that have years of industry
                      experience to develop the most advanced responsive
                      <strong> WooCommerce themes</strong>.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="Migration to WooCommerce Services"
                      title="Migration to WooCommerce Services"
                      src={Migration}
                    />
                    <Typography variant="h4" className="head_title">
                      Migration to WooCommerce
                      <br /> Services
                    </Typography>
                    <Typography className="head_paragraph">
                      Planning to migrate your online store from another platform to WooCommerce? Our company has expertise in <strong>WooCommerce Migration services </strong> with no impact on <strong>SEO </strong>. We are a team of skilled developers that provide the best <strong>WooCommerce </strong> website development solution.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="WooCommerce Storefront Customization"
                      title="WooCommerce Storefront Customization"
                      src={WocommerceStorefrontCustomization}
                    />
                    <Typography variant="h4" className="head_title">
                      WooCommerce Storefront
                      <br /> Customization
                    </Typography>
                    <Typography className="head_paragraph">
                      We are a team of talented web developers that is capable to make changes to your existing WooCommerce Store. Our <strong>WooCommerce development company in India </strong> can achieve the client's requirements and extend the store functionalities by offering WooCommerce storefront customization services.
                    </Typography>
                  </div>
                </Grid>
                <SliderServiceCatPagePortfolio2 data={woocommerceSlider} />
                <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                  <Link to="/hire-developers/hire-wordpress-developers" className="dark-btn-effect all-category-mobile" title="Hire Wordpress Developers">
                  Hire Wordpress Developers
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "WooCommerce Development" */}

          {/* Start - Section Design "Shopify Development" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="major_social_networks sec_padding shopifyDev section_title_space"
              id="shopify-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title mb-3"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Shopify Development
                    </Typography>
                    <Typography
                      className="head_paragraph "
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      Various elements of online selling range from websites,
                      Apps and now the new age Shopify solutions. Our expertise
                      helps you capitalize on the rise in online buying.
                      <strong> Professional web designers and developers </strong> work under
                      seasoned leadership to promise a perfect Shopify store for
                      your business and its needs.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item container xs={12} className="major_networks_wrap six-services">
                  <Grid item xs={12} lg={4} className="aside">
                    <div
                      className="content_box blue"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="200"
                    >
                      <div className="circle_wrapper icon">
                        <div className="icon_wrap">
                          <img
                            alt="Optimized Shopify Store"
                            title="Optimized Shopify Store"
                            src={OPTIMIZEDSHOPIFYSTORE}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h5" className="mb-1">
                        Optimized Shopify Store
                      </Typography>
                      <Typography className="head_paragraph">
                        We hold extensive knowledge of SEO and Shopify’s
                        inherent features to rank higher in search engines.
                        Implementation of best techniques by our experts.
                      </Typography>
                    </div>
                    <div
                      className="content_box red"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="300"
                    >
                      <div className="circle_wrapper icon">
                        <div className="icon_wrap">
                          <img
                            alt="Store Migration"
                            title="Store Migration"
                            src={STOREMIGRATION}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h5" className="mb-1">
                        Store Migration
                      </Typography>
                      <Typography className="head_paragraph">
                        Our expertise in <strong> Shopify store development</strong> and other
                        platforms makes us very capable of migrating the
                        complete stores. We help you with end-to-end services.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div
                      className="content_box green"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="100"
                    >
                      <div className="circle_wrapper icon">
                        <div className="icon_wrap">
                          <img
                            alt="Shopify Store Customization"
                            title="Shopify Store Customization"
                            src={SHOPIFYSTORECUSTOMIZATION}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h5" className="mb-1">
                        Shopify Store Customization
                      </Typography>
                      <Typography className="head_paragraph">
                        Tailor crafted solutions are designed and developed as
                        per your business needs. We work to transform your
                        business ideas into digital reality. After running
                        extensive usability tests, we deliver a bug-free
                        <strong> E-commerce store </strong> for you.
                      </Typography>
                    </div>
                    <div
                      className="content_box middle_img"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      <div className="middle-text">
                        <Typography variant="h5" className="mb-1">
                          Our Shopify Development
                        </Typography>
                      </div>
                      <ul className="six-services sec_padding">
                        <li className="blue">
                          <div className="circle_wrapper">
                            <div className="icon_wrap">
                              <img
                                alt="Optimized Shopify Store"
                                title="Optimized Shopify Store"
                                src={OPTIMIZEDSHOPIFYSTORE}
                              />
                            </div>
                            <div className="semi-cicle"></div>
                          </div>
                        </li>
                        <li className="red">
                          <div className="circle_wrapper">
                            <div className="icon_wrap">
                              <img
                                alt="Store Migration"
                                title="Store Migration"
                                src={STOREMIGRATION}
                              />
                            </div>
                            <div className="semi-cicle"></div>
                          </div>
                        </li>
                        <li className="green">
                          <div className="circle_wrapper">
                            <div className="icon_wrap">
                              <img
                                alt="Shopify Store Customization"
                                title="Shopify Store Customization"
                                src={SHOPIFYSTORECUSTOMIZATION}
                              />
                            </div>
                            <div className="semi-cicle"></div>
                          </div>
                        </li>
                        <li className="yellow">
                          <div className="circle_wrapper">
                            <div className="icon_wrap">
                              <img
                                alt="Cost Effective"
                                title="Cost Effective"
                                src={COSTEFFECTIVE}
                              />
                            </div>
                            <div className="semi-cicle"></div>
                          </div>
                        </li>
                        <li className="orange">
                          <div className="circle_wrapper">
                            <div className="icon_wrap">
                              <img
                                alt="Quick to Market Shopify Solutions"
                                title="Quick to Market Shopify Solutions"
                                src={QUICKTOMARKETSHOPIFYSOLUTIONS}
                              />
                            </div>
                            <div className="semi-cicle"></div>
                          </div>
                        </li>
                        <li className="lightgreen">
                          <div className="circle_wrapper">
                            <div className="icon_wrap">
                              <img
                                alt="Upgrade and Maintenance"
                                title="Upgrade and Maintenance"
                                src={UPGRADEANDMAINTENANCE}
                              />
                            </div>
                            <div className="semi-cicle"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="content_box yellow"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="400"
                    >
                      <div className="circle_wrapper icon">
                        <div className="icon_wrap">
                          <img
                            alt="Cost Effective"
                            title="Cost Effective"
                            src={COSTEFFECTIVE}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h5" className="mb-1">
                        Cost effective
                      </Typography>
                      <Typography className="head_paragraph">
                        MEAN Stack offers a cost-effective solution to develop
                        web and mobile applications for all types of projects.
                        It's not necessary to hire multiple.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4} className="aside">
                    <div
                      className="content_box orange"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="200"
                    >
                      <div className="circle_wrapper icon">
                        <div className="icon_wrap">
                          <img
                            alt="Quick to Market Shopify Solutions"
                            title="Quick to Market Shopify Solutions"
                            src={QUICKTOMARKETSHOPIFYSOLUTIONS}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h5" className="mb-1">
                        Quick to Market Shopify Solutions
                      </Typography>
                      <Typography className="head_paragraph">
                        Our expert developers are capable of developing elements
                        from scratch as well as integrate third-party plugins to
                        quickly set up the store <strong>payment gateways</strong>.
                      </Typography>
                    </div>
                    <div
                      className="content_box lightgreen"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="300"
                    >
                      <div className="circle_wrapper icon">
                        <div className="icon_wrap">
                          <img
                            alt="Upgrade and Maintenance"
                            title="Upgrade and Maintenance"
                            src={UPGRADEANDMAINTENANCE}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h5" className="mb-1">
                        Upgrade and Maintenance
                      </Typography>
                      <Typography className="head_paragraph">
                        We promise to hold your hands forever. Your <strong>E-commerce
                        store</strong> is our creation and we promise to take care of it
                        always. Any bugs will be fixed.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <SliderServiceCatPagePortfolio2 data={shopifySlider} />
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Shopify Development" */}

          {/* Start - Section Design "Opencart Customization Services" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="multi_services_circle sec_padding openCart section_title_space bg-lightgrey"
              id="openCart-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Opencart Customization Services
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <strong>OpenCart </strong> is the growing platform for the <strong>ecommerce solutions </strong> and is preferred for its simplicity user friendliness and flexibility. For OpenCart we provide services right from building custom extensions, design and implementing <strong>custom designs, integrating payment gateways, SEO optimization, migrations and upgrades </strong> among others.We are having a full-fledged team of <strong>OpenCart experts </strong> to provide you a customized ecommerce platform for your specific needs along with an active <strong>support and maintenance</strong>.
                      </Typography>
                    </aside>
                  </div>
                  <ul
                    className="six-services sec_padding"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <li className="blue">
                      <div className="circle_wrapper">
                        <div className="icon_wrap">
                          <img
                            alt="OpenCart Store Development"
                            title="OpenCart Store Development"
                            src={OpenCartStoreDevelopment}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h4" className="head_title">
                        OpenCart Store Development
                      </Typography>
                      <Typography className="head_paragraph">
                        Create brilliant online ecommerce stores for your target audience using this simplified platform and top-class services offered by our development team.
                      </Typography>
                    </li>
                    <li className="red">
                      <div className="circle_wrapper">
                        <div className="icon_wrap">
                          <img
                            alt="OpenCart Extension Development"
                            title="OpenCart Extension Development"
                            src={OpenCartExtensionDevelopment}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h4" className="head_title">
                        OpenCart Extension Development
                      </Typography>
                      <Typography className="head_paragraph">
                        Develop extensions for complex functionalities to implement in an easy way. We are capable to develop an extension for any of your requirements.
                      </Typography>
                    </li>
                    <li className="green">
                      <div className="circle_wrapper">
                        <div className="icon_wrap">
                          <img
                            alt="OpenCart Customization"
                            title="OpenCart Customization"
                            src={OpenCartCustomization}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h4" className="head_title">
                        OpenCart Customization
                      </Typography>
                      <Typography className="head_paragraph">
                        Customize the look and functionality of your OpenCart store to meet your specific needs, with the help of our excellent team of designers and OpenCart developers.
                      </Typography>
                    </li>
                    <li className="yellow">
                      <div className="circle_wrapper">
                        <div className="icon_wrap">
                          <img
                            alt="OpenCart Integration"
                            title="OpenCart Integration"
                            src={OpenCartIntegration}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h4" className="head_title">
                        OpenCart Integration
                      </Typography>
                      <Typography className="head_paragraph">
                        Integrate CMS, payment gateways, shipping methods etc of your choice with your OpenCart store to make it more robust and user friendly.
                      </Typography>
                    </li>
                    <li className="orange">
                      <div className="circle_wrapper">
                        <div className="icon_wrap">
                          <img
                            alt="OpenCart Theme Development"
                            title="OpenCart Theme Development"
                            src={OpenCartThemeDevelopment}
                          />
                        </div>
                        <div className="semi-cicle"></div>
                      </div>
                      <Typography variant="h4" className="head_title">
                        OpenCart Theme Development
                      </Typography>
                      <Typography className="head_paragraph">
                        Create a UI of your choice and needs by modifying or developing your own OpenCart themes. Our team of designers and OpenCart Developers can deliver highly scalable and modern looking themes.
                      </Typography>
                    </li>
                  </ul>
                </Grid>
                <SliderServiceCatPagePortfolio2 data={openCartSlider} />
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Opencart Customization Services" */}

          {/* Start - Section Design "BigCommerce Development Services" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits section_title_space"
              id="bigcommerce-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title "
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      BigCommerce Development Services
                    </Typography>
                    <Typography
                      className="head_paragraph "
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <strong>BigCommerce </strong> is more than a technology platform — it’s
                      backed by a team of ecommerce experts driven to see your
                      business maximize success, from one stage of growth to the
                      next, providing the strategy, support, and tools to get
                      you there. BigCommerce is a highly-extensible open source
                      platform offering you a world of possibilities with
                      enterprise-class integrations, flexible APIs and webhooks.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item container className="boxes_wrapper">
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_green"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Solutions Architecting"
                          title="Solutions Architecting"
                          src={SolutionsArchitecting}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Solutions Architecting
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                        Designed to support a diverse range of use cases while
                        streamlining operations, BigCommerce enables your teams
                        to focus on innovation.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_pink"
                      data-aos="fade-up"
                      data-aos-duration="1400"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Catalog Transfer Services"
                          title="Catalog Transfer Services"
                          src={CatalogTransferServices}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Catalog Transfer Services
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                        Replatform with confidence using our intuitive interface
                        or expert services to minimize time-to-market – all at a
                        lower total cost.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_yellow"
                      data-aos="fade-up"
                      data-aos-duration="1800"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Implementation Project"
                          title="Implementation Project"
                          src={ImplementationProject}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Implementation Project
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                        Leveraging the power of Google Cloud Platform,
                        BigCommerce provides multiple layers of security,
                        unparalleled bandwidth.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_red"
                      data-aos="fade-up"
                      data-aos-duration="2200"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Education Services"
                          title="Education Services"
                          src={EducationServices}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Education Services
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                        Combined with best-in-class uptime and DDoS protection,
                        you can focus more on generating sales – and less on
                        monitoring.
                      </Typography>
                    </div>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "BigCommerce Development Services" */}

          {/* Start - NumberCount Import From Common Components */}
          <Suspense fallback={<div></div>}>
          <NumberCount />
          </Suspense>
          {/* End - NumberCount Import From Common Components */}
          
        </Grid>
      </div>
    );
  }
}

export default ECommerceDevelopment;
