import React, { Component, Suspense, lazy } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import smoothscroll from "smoothscroll-polyfill";

// Get Images Start
// import EnterpriseCMSDevelopment from "../../assets/images/MeanStackDevelopment/EnterpriseCMSDevelopment.png";
// import AwsCloud from "../../assets/images/ServerAdminSupport/aws-cloud.png";
// import EmailMigration from "../../assets/images/ServerAdminSupport/email-migration.png";
// import increased_visibilty_icon from "../../assets/images/WebsiteDesignDevelopment/increased_visibilty_icon.png";
import header_awsCloud_icon from "../../assets/images/Header/header-awsCloud-icon.svg";
import server_admin_support from "../../assets/images/Header/server_admin_support.svg";
import header_linux_icon from "../../assets/images/Header/header-linux-icon.svg";
import header_emailmigration_icon from "../../assets/images/Header/header_emailmigration_icon.svg";
import header_awsCloud_icon_White from "../../assets/images/white-icons/header-awsCloud-icon.svg";
import server_admin_support_white from "../../assets/images/white-icons/server_admin_support.svg";
import header_linux_icon_White from "../../assets/images/white-icons/header-linux-icon.svg";
import header_emailmigration_icon_White from "../../assets/images/white-icons/header_emailmigration_icon.svg";
import AWSApplicationSupport from "../../assets/images/Server/AWSApplicationSupport.svg";
import AWSConfigurationandMaintenance from "../../assets/images/Server/AWSConfigurationandMaintenance.svg";
// import AWSInfrastructureConsulting from "../../assets/images/Server/AWSInfrastructureConsulting.svg";
// import AWSMigration from "../../assets/images/Server/AWSMigration.svg";
import Backup_Supoort from "../../assets/images/Server/Backup_Supoort.svg";
import LinuxServicesDeployment from "../../assets/images/Server/LinuxServicesDeployment.svg";
import LinuxVisualizationSupport from "../../assets/images/Server/LinuxVisualizationSupport.svg";
import NoDowntime from "../../assets/images/Server/NoDowntime.svg";
import Transparentworking from "../../assets/images/Server/Transparentworking.svg";
import FullyManagedServices from "../../assets/images/Server/FullyManagedServices.svg";
import HandleDatalimits from "../../assets/images/Server/HandleDatalimits.svg";
import Periodic_Maintenance_Reporting from "../../assets/images/Server/Periodic_Maintenance_Reporting.svg";
import Exchange_Server_Maintenance from "../../assets/images/Server/Exchange_Server_Maintenance.svg";
import Cloud_Office_management from "../../assets/images/Server/Cloud_Office_management.svg";
import Domain_File_Security from "../../assets/images/Server/Domain_File_Security.svg";
import Exchange_Hybrid_Deployment  from "../../assets/images/Server/Exchange_Hybrid_Deployment .svg";
import VM_Ware_Support from "../../assets/images/Server/VM_Ware_Support.svg";
import Network_admin_Support from "../../assets/images/ServerAdminSupport/Network_admin_Support.jpg";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
// import NumberCount from "../common/NumberCount";
const NumberCount = lazy(() => import("../common/NumberCount"));
// Get Common Components End

class ServerAdminSupportDev extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Server/Network Admin Support",
      bannerImg: Network_admin_Support,
      activeTab: "server-network-administration",
      activeTab1: "tab-1",
      tabToggle: false,
      isClickedEventFired: 0
    };
    smoothscroll.polyfill();
    this.baseState = this.state;
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }


  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent, false);
  }

  listenScrollEvent = e => {
    if (this.state.isClickedEventFired > 0) {
      var abc = setTimeout(() => {
        this.setState({ isClickedEventFired: 0 });
      }, 5000);
      if (this.state.isClickedEventFired > 1) {
        clearTimeout(abc);
      }
    } else {
      var section1 = this.checkElementInViewport(
        document.getElementById("server-network-administration")
      );
      var section2 = this.checkElementInViewport(
        document.getElementById("aws-cloud-hosting")
      );
      var section3 = this.checkElementInViewport(
        document.getElementById("linux")
      );
      var section4 = this.checkElementInViewport(
        document.getElementById("email-migration")
      );

      if (section1 && section2) {
        this.setState({
          activeTab: "server-network-administration",
          activeTab1: "tab-1"
        });
      }
      if ((section2 && !section1) || (section2 && section3)) {
        this.setState({
          activeTab: "aws-cloud-hosting",
          activeTab1: "tab-2"
        });
      }
      if ((section3 && !section2) || (section3 && section4)) {
        this.setState({
          activeTab: "linux",
          activeTab1: "tab-3"
        });
      }
      if ((section4 && !section3)) {
        this.setState({
          activeTab: "email-migration",
          activeTab1: "tab-4"
        });
      }
    }
  };

  checkElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    const vertInView = 
      rect.top <= windowHeight && rect.top-74 + rect.height >= 0;
    const horInView = 
      rect.left <= windowWidth && rect.left-74 + rect.width >= 0;

    return vertInView && horInView;
  }

  handleOnClickScroll = (divId, tab) => {
    this.setState({
      activeTab: divId,
      activeTab1: tab,
      isClickedEventFired: this.state.isClickedEventFired + 1
    });
    var ref = document.getElementById(divId);
    window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
  };

  handleTabToggle = () => {
    this.setState(state => ({ tabToggle: !state.tabToggle }));
  };

  componentWillReceiveProps(nextProps) {
    var backToTop = localStorage.getItem("backToTop");
    if (nextProps.match.params.id) {
      if (nextProps.match.params.id !== this.props.match.params.id) {
        var ref = document.getElementById(nextProps.match.params.id);
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
      }
    }
    if (backToTop && backToTop === "true") {
      this.setState({
        activeTab: this.baseState.activeTab,
        activeTab1: this.baseState.activeTab1
      });
      localStorage.removeItem("backToTop");
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent, false);
    if (this.props.match.params.id) {
      var ref = document.getElementById(this.props.match.params.id);
      if (ref)
      setTimeout(() => {
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
      }, 500);
      else
        this.props.history.push("/services/server-network-admin-support-services");
    }
  }


  render() {
    if (this.props.match.params.id) {
      if (this.props.match.params.id === 'server-network-administration') {
          document.title = "Server Network Administration Services | Domain & File Server Security | VMWare Support";
      } else if (this.props.match.params.id === 'aws-cloud-hosting') {
          document.title = "Server Network Administration Services | Amazon Web Services India";
      } 
        else if (this.props.match.params.id === 'linux') {
          document.title = "Linux Server Support Services: Debian/Ubuntu, CebtOS, Arch";
      } else if (this.props.match.params.id === 'email-migration') {
          document.title = "Email Migration Services | Email Migration Service Providers";
      } else {
          document.title = "Network Administrator Service | IT Network Management Services";
      }
    } else {
        document.title = "Network Administrator Service | IT Network Management Services";
    }
    const { pageTitle, bannerImg } = this.state;
    return (
      <div className="main_server_admin service_category main_services">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}
          
          {/* Start - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}
          <Grid item xs={12}>
            <Grid component="div">
              <Grid className="serivceTabsSide">
                <Grid
                  className={`services-listing ${
                    this.state.tabToggle ? "showTab" : ""
                  }`}
                >
                  <div className="toggle-btn" onClick={this.handleTabToggle}>
                    <i className="material-icons">arrow_forward</i>
                  </div>
                  <Grid item container className="container_custom">
                    <Grid item xs={12}>
                      <div className={`row-flex ${this.state.activeTab1}`}>
                        <div
                          className={`col-flex tab-1 ${
                            this.state.activeTab === "server-network-administration"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "server-network-administration",
                              "tab-1"
                            )
                          }
                          title="Server Network Administration"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={server_admin_support}
                                className="withoutActive"
                                alt="Server Network Administration"
                                title="Server Network Administration"
                              />
                              <img
                                width="30"
                                height="30"
                                src={server_admin_support_white}
                                className="withActive"
                                alt="Server Network Administration"
                                title="Server Network Administration"
                              />
                            </div>
                            <Typography>
                              Server Network <br /> Administration{" "}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-2 ${
                            this.state.activeTab === "aws-cloud-hosting"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "aws-cloud-hosting",
                              "tab-2"
                            )
                          }
                          title="AWS Cloud Hosting"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={header_awsCloud_icon}
                                className="withoutActive"
                                alt="AWS Cloud Hosting"
                                title="AWS Cloud Hosting"
                              />
                              <img
                                width="30"
                                height="30"
                                src={header_awsCloud_icon_White}
                                className="withActive"
                                alt="AWS Cloud Hosting"
                                title="AWS Cloud Hosting"
                              />
                            </div>
                            <Typography>
                            AWS Cloud <br />Hosting{" "}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-3 ${
                            this.state.activeTab === "linux"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "linux",
                              "tab-3"
                            )
                          }
                          title="Linux Support"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={header_linux_icon}
                                className="withoutActive"
                                alt="Linux Support"
                                title="Linux Support"
                              />
                              <img
                                width="30"
                                height="30"
                                src={header_linux_icon_White}
                                className="withActive"
                                alt="Linux Support"
                                title="Linux Support"
                              />
                            </div>
                            <Typography>
                                Linux <br /> Support
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-4 ${
                            this.state.activeTab === "email-migration"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "email-migration",
                              "tab-4"
                            )
                          }
                          title="Email Migration"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={header_emailmigration_icon}
                                className="withoutActive"
                                alt="Email Migration"
                                title="Email Migration"
                              />
                              <img
                                width="30"
                                height="30"
                                src={header_emailmigration_icon_White}
                                className="withActive"
                                alt="Email Migration"
                                title="Email Migration"
                              />
                            </div>
                            <Typography>
                              Email <br /> Migration
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}

          {/* Start - Section Design "First Section Of Service Category Page" */}
          <Grid item xs={12}>
            <Grid component="div" className="sec_padding sec_one">
              <Grid item container className="container_custom">
                <Grid item xs={12} className="title_row">
                  <aside className="">
                    <Typography
                      variant="h3"
                      className="head_title mb-3"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="1000"
                    >
                      Our Server & Networking Management Services
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="1500"
                    >
                      Network is a crucial part of any online business and is included of many integrated parts like servers, storage, routers, switches, firewalls and other components. <strong>Visions</strong> offers you an integrated services to handle all of your <strong>server and networking management work</strong> with ease and a dedicated support.
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="2000"
                    >
                      Working with clients remotely with over 2700+ machines/Endpoints in deploying and managing the Operating system and Antivirus updates. Auditing and updating machines inventory details like serial numbers and warranty dates along with auditing and fixing the backups of the remote machines from backup software integrated and functional from remote management tools.
                    </Typography>
                  </aside>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "First Section Of Service Category Page" */}

          {/* Start - Section Design "Server and Network Administration" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits section_title_space" 
              id="server-network-administration" 
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Server and Network Administration 
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      Assisting clients in <strong>Server and Network Administration</strong> tasks and activities such as deploying and migrating Domain controllers, running scans and audit, downloading latest Operating Systems and Anti Virus patches.
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1600"
                    >
                      Also, keeping the firewalls updated on the latest firmware and running port and network scans to ensure that the <strong>Network and Domain</strong> sides are Secured.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="Cloud/Office 365 Management"
                      title="Cloud/Office 365 Management"
                      src={Cloud_Office_management}
                    />
                    <Typography variant="h4" className="head_title">
                    Cloud/Office 365 Management
                    </Typography>
                    <Typography className="head_paragraph">
                    Working with over 125 clients on cloud based email and user management systems like <strong>Office 365, Gsuite</strong> in creating the users and assigning licenses, configuring and managing exchange online mailboxes. Implementing archiving and enhanced security to audit the mailboxes and enabling/enforcing Multi factor authentication that can provide a risk free email access to users.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="VMWare Support"
                      title="VMWare Support"
                      src={VM_Ware_Support}
                    />
                    <Typography variant="h4" className="head_title">
                    VMWare Support
                    </Typography>
                    <Typography className="head_paragraph">
                    Working with 20+ clients in auditing and maintaining their <strong>VMWare</strong> environments. Providing assistance with creating new Virtual Machines, removing virtual machines, disk space issues on the virtual machines, ESXi and datastores health and space issues. Also working on deploying alerting on Esxi to have an eye on the virtual environment 24/7.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="Exchange Hybrid Deployment and Decommission"
                      title="Exchange Hybrid Deployment and Decommission"
                      src={Exchange_Hybrid_Deployment}
                    />
                    <Typography variant="h4" className="head_title">
                    Exchange Hybrid Deployment and Decommission
                    </Typography>
                    <Typography className="head_paragraph">
                    Assisted 50+ clients in deploying Exchange hybrid setup between Office365/Exchange online and migrating the users’ mailboxes on cloud. Planning and executing decommissioning of on-premises exchange servers after migrating all the local mailboxes to cloud hosting systems like Office 365 with disturbing or interrupting the users’ functioning.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="Domain & File Server Security Auditing"
                      title="Domain & File Server Security Auditing"
                      src={Domain_File_Security}
                    />
                    <Typography variant="h4" className="head_title">
                    Domain & File Server Security Auditing
                    </Typography>
                    <Typography className="head_paragraph">
                    Working on running security audits on Domain controllers and file servers and reviewing audit reports on regular basis to monitor and ensure that the security and privacy for the users at both document and domain level is protected and to avoid any internal threats.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Server and Network Administration" */}

          {/* Start - Section Design "AWS Cloud Hosting" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="bg-lightgrey key_benfits_and_why_choose sec_padding bottomkeybenefits section_title_space" 
              id="aws-cloud-hosting" 
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      AWS Cloud Hosting
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      Here at <strong>Visions</strong> our AWS experts help you to provide better recommendations to build a reliable and scalable platform. We are pleased to offer you our services for the AWS having expertise with EC2, IAM, S3, CloudWatch, Certificate Manager, RDS, VPC, CloudFront, Route 53.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="AWS Configuration and Maintenance"
                      title="AWS Configuration and Maintenance"
                      src={AWSConfigurationandMaintenance}
                    />
                    <Typography variant="h4" className="head_title">
                    AWS Configuration and Maintenance
                    </Typography>
                    <Typography className="head_paragraph">
                    We assess, audit and evaluate your configurations of the <strong>AWS</strong> instances and continuously monitors and records your usage. We further help to auto-scale it, deploy applications and monitoring their health.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="AWS Application Support"
                      title="AWS Application Support"
                      src={AWSApplicationSupport}
                    />
                    <Typography variant="h4" className="head_title">
                    AWS Application Support
                    </Typography>
                    <Typography className="head_paragraph">
                    We offer dedicated services to our customers to troubleshoot or improve the <strong>AWS services</strong> they are using. Along with that, we provide architectural guidance to say agile with your resources and applications.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="Exchange Server maintenance"
                      title="Exchange Server maintenance"
                      src={Exchange_Server_Maintenance}
                    />
                    <Typography variant="h4" className="head_title">
                    Exchange Server maintenance
                    </Typography>
                    <Typography className="head_paragraph">
                    Assisting clients that are using on premises exchange servers with users’ and mailbox tasks along with running exchange health checks on daily and weekly basis to identify if there are any issues with the functioning and to avoid any emailing issues proactively.
                    </Typography>
                  </div>
                </Grid>
                {/* <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="AWS Migration"
                      title="AWS Migration"
                      src={AWSMigration}
                    />
                    <Typography variant="h4" className="head_title">
                    AWS Migration
                    </Typography>
                    <Typography className="head_paragraph">
                    Our <strong>AWS professionals</strong> can help you to migrate your application from any server to <strong>AWS</strong> and vice-versa by exacting mapping the configurations of your existing server.
                    </Typography>
                  </div>
                </Grid> */}
                
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "AWS Cloud Hosting" */}

          {/* Start - Section Design "Linux Support" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits section_title_space"
              id="linux"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Linux Support
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      We provide services for Linux to better administer web servers, Linux Visualization support, Backup Solutions, Data backups and management, Server modules installation, update & patching, Server Health Monitoring, Periodic Maintenance and Reporting. We have experience working with OS such as <strong>Debian/Ubuntu, CebtOS and Arch</strong>.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="Linux Services Deployment"
                      title="Linux Services Deployment"
                      src={LinuxServicesDeployment}
                    />
                    <Typography variant="h4" className="head_title">
                    Linux Services Deployment
                    </Typography>
                    <Typography className="head_paragraph">
                    Our Linux professionals can help you to deploy <strong> Linux</strong> environments with best practices and provide you complete control of the environments as well.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="Linux Visualization Support"
                      title="Linux Visualization Support"
                      src={LinuxVisualizationSupport}
                    />
                    <Typography variant="h4" className="head_title">
                    Linux Visualization Support
                    </Typography>
                    <Typography className="head_paragraph">
                    We offer great services for the visualization of business data to better help you to store, cleanse, process and display the fast growing, vast and complex data generated.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="Backup Support"
                      title="Backup Support"
                      src={Backup_Supoort}
                    />
                    <Typography variant="h4" className="head_title">
                    Backup Support
                    </Typography>
                    <Typography className="head_paragraph">
                    We can help you implementing, auditing and fixing the backups, replications and repositories issues concerning Acronis, Appassure 5.X.X and Rapid recovery 6.X.X. This ensures that the servers are always backed up safely in case of any software or hardware disaster.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="Periodic Maintenance and Reporting"
                      title="Periodic Maintenance and Reporting"
                      src={Periodic_Maintenance_Reporting}
                    />
                    <Typography variant="h4" className="head_title">
                    Periodic Maintenance and Reporting
                    </Typography>
                    <Typography className="head_paragraph">
                    We offer timely reporting for the Linux hosted services which are generated at scheduled intervals. Custom Maintenance reports are being prepared for businesses to help them smoothly run their environments.
                    </Typography>
                  </div>
                </Grid>
                {/* <Grid item xs={12} className="img-row text-center">
                <img
                    alt="AWS Cloud Hosting"
                    title="AWS Cloud Hosting"
                    src={AwsCloud}
                    data-aos="fade-up" data-aos-duration="800"
                />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Linux Support" */}

          {/* Start - Section Design "Email Migration" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="FourColumnLayout3 sec_padding section_title_space bg-lightgrey"
              id="email-migration"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title "
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Email Migration
                    </Typography>
                    <Typography
                      className="head_paragraph "
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      Email Migrations can be a big headache, but here at <strong>Visions</strong>, we can handle this task very well as we have been Identifying the requirements and assisted 70+ clients to migrate from on-premises Exchange servers to cloud based systems like Office 365, Gsuite. Also working on cloud-to-cloud migrations such as intermediate to O365, Gsuite to O365.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item container className="boxes_wrapper">
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_green"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="No Downtime"
                          title="No Downtime"
                          src={NoDowntime}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        No Downtime
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                      We ensure you don’t face any downtime or interruptions, while your email migration is in process.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_green"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Handle Data limits"
                          title="Handle Data limits"
                          src={HandleDatalimits}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Handle Data limits
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                      We are very well efficient in easily migration email data of any limits and follow the procedures accordingly.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_green"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Transparent working"
                          title="Transparent working"
                          src={Transparentworking}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Transparent working
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                      Our working process, cost and work delivery go through a very transparent process, shared at the start of the work only. So, there are no hidden costs or policies for you.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_green"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Fully Managed Services"
                          title="Fully Managed Services"
                          src={FullyManagedServices}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Fully Managed Services
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                      We provide services right from analysis of your work, migrations and the after support for any issues that may arrive.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} className="img-row text-center">
                    <img
                        alt="Email Migration"
                        title="Email Migration"
                        src={EmailMigration}
                        data-aos="fade-up" data-aos-duration="800"
                    />
                </Grid> */}
            </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Email Migration" */}

          {/* Start - NumberCount Import From Common Components */}
          <Suspense fallback={<div></div>}>
          <NumberCount />
          </Suspense>
          {/* End - NumberCount Import From Common Components */}
          
        </Grid>
      </div>
    );
  }
}

export default ServerAdminSupportDev;
