import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import LazyLoad from "react-lazyload";

// Get Images Start
import discuss_project_plan from "../../assets/images/HomePage/discuss_project_plan.svg";
import discuss_project_design from "../../assets/images/HomePage/discuss_project_design.svg";
import discuss_project_develop from "../../assets/images/HomePage/discuss_project_develop.svg";
import discuss_project_launch from "../../assets/images/HomePage/discuss_project_launch.svg";
// Get Images End


class DiscussYourProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
          your_project: {
            plan: 'active',
            design: '',
            develop: '',
            launch: ''
          }
        };
    }

    handleOnHoverIcons(icon) {
        switch (icon) {
          case "plan":
            this.setState(prevState => ({
              your_project: {
                ...prevState.your_project,
                design: '',
                develop: '',
                launch: '',
                plan: 'active'
              }
            }));
            break;
          case "design":
            this.setState(prevState => ({
              your_project: {
                ...prevState.your_project,
                develop: '',
                launch: '',
                plan: '',
                design: 'active'
              }
            }));
            break;
          case "develop":
            this.setState(prevState => ({
              your_project: {
                ...prevState.your_project,
                design: '',
                launch: '',
                plan: '',
                develop: 'active'
              }
            }));
            break;
          case "launch":
            this.setState(prevState => ({
              your_project: {
                ...prevState.your_project,
                design: '',
                develop: '',
                plan: '',
                launch: 'active'
              }
            }));
            break;
          default:            
        }
    }

  render() {
    return (
        <Grid item xs={12}>
          <Grid component="div" className="discuss_your_project sec_padding" data-aos="fade-up" data-aos-duration="1500">
            <Grid item container className="container_custom">
              <Grid item xs={12}>
                <Typography variant="h2" className="head_title">
                  Let’s Discuss Your Project
                </Typography>
                <Typography className="head_paragraph">
                  Work experience in technology we are focussed on understanding.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className="listing_wrap">
                  <ul>
                    <li className="yellow" data-aos="fade-down-right" data-aos-duration="800">                      
                      <div className="content_wrap">
                        <div className="img-wrapper-mobile" >
                          <div className="image-circle" >
                            <LazyLoad once offset={100}><img  width="30" height="30" src={discuss_project_plan} title="Plan" alt="Plan" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h4" className="head_title">
                          01
                        </Typography>
                        <Typography variant="h3" className="head_title">
                          Plan
                        </Typography>
                        <Typography className="head_paragraph">
                          All the relevant information is collected from the customer to develop a product as per their expectation.
                        </Typography>
                      </div>
                      <div className="hexagonal_shape_wrap">
                        <div className="box_hexagonal">
                          <LazyLoad once offset={100}><img width="80" height="80" src={discuss_project_plan} title="Plan"  alt="Plan" /></LazyLoad>
                        </div>
                      </div>
                    </li>
                    <li className="blue" data-aos="fade-down-left" data-aos-duration="800">                      
                      <div className="content_wrap">
                        <div className="img-wrapper-mobile" >
                          <div className="image-circle" >
                            <LazyLoad once offset={100}><img  width="30" height="30" src={discuss_project_design} title="Plan" alt="Plan" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h4" className="head_title">
                          02
                        </Typography>
                        <Typography variant="h3" className="head_title">
                          Design
                        </Typography>
                        <Typography className="head_paragraph">
                          The system and software design documents are prepared as per the requirement specification document.
                        </Typography>
                      </div>
                      <div className="hexagonal_shape_wrap">
                        <div className="box_hexagonal">
                          <LazyLoad once offset={100}><img width="80" height="80" src={discuss_project_design} title="Design" alt="Design" /></LazyLoad>
                        </div>
                      </div>
                    </li>
                    <li className="green" data-aos="fade-up-right" data-aos-duration="800">                      
                      <div className="content_wrap">
                        <div className="img-wrapper-mobile" >
                          <div className="image-circle" >
                            <LazyLoad once offset={100}><img width="30" height="30" src={discuss_project_develop} title="Plan" alt="Plan" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h4" className="head_title">
                          03
                        </Typography>
                        <Typography variant="h3" className="head_title">
                          Develop
                        </Typography>
                        <Typography className="head_paragraph">
                          Tasks are divided into units or modules and assigned to the various developers. Developer needs to follow certain predefined coding guidelines.
                        </Typography>
                      </div>
                      <div className="hexagonal_shape_wrap">
                        <div className="box_hexagonal">
                          <LazyLoad once offset={100}><img width="80" height="80" src={discuss_project_develop} title="Develop" alt="Develop" /></LazyLoad>
                        </div>
                      </div>
                    </li>
                    <li className="red" data-aos="fade-up-left" data-aos-duration="800">                      
                      <div className="content_wrap">
                        <div className="img-wrapper-mobile" >
                          <div className="image-circle" >
                            <LazyLoad once offset={100}><img width="30" height="30"  src={discuss_project_launch} title="Plan" alt="Plan" /></LazyLoad>
                          </div>
                        </div>
                        <Typography variant="h4" className="head_title">
                          04
                        </Typography>
                        <Typography variant="h3" className="head_title">
                          Launch
                        </Typography>
                        <Typography className="head_paragraph">
                          Once the product is tested, it is deployed in the production environment depending upon the customer expectation.
                        </Typography>
                      </div>
                      <div className="hexagonal_shape_wrap">
                        <div className="box_hexagonal">
                          <LazyLoad once offset={100}><img width="80" height="80" src={discuss_project_launch} title="Launch" alt="Launch" /></LazyLoad>
                        </div>
                      </div>
                    </li>
                    <div className="middle-circle"></div>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
            {/* <Grid component="div" className="new-lets-discuss-our-project" data-aos="fade-up" data-aos-duration="1500">
                <Grid item container className="container_custom">
                  <Grid item xs={12}>
                    <Typography variant="h2" className="head_title">
                    Let’s Discuss Your Project
                    </Typography>
                    <Typography className="head_paragraph">
                    Work experience in technology we are focussed on understanding.
                    </Typography>
                    <Grid component="div" className="lets-disucss-inner-project">
                      <ul>
                        <li
                        className={`project-image ${this.state.your_project.plan}`}
                        onMouseEnter={() => this.handleOnHoverIcons("plan")}
                        onClick={() => this.handleOnHoverIcons("plan")}
                        >
                        <h3>Plan</h3>
                        <Grid className="lets-disucss-inner-content">
                            <img src={plan_image} alt="Plan" title="Plan"></img>
                            <Typography>
                            All the relevant information is collected from the customer to develop a product as per their expectation.
                            </Typography>
                        </Grid>
                        </li>
                        <li
                        className={`project-image ${this.state.your_project.design}`}
                        onMouseEnter={() => this.handleOnHoverIcons("design")}
                        onClick={() => this.handleOnHoverIcons("design")}
                        >
                        <h3>Design</h3>
                        <Grid className="lets-disucss-inner-content">
                            <img src={design_image} alt="Design" title="Design"></img>
                            <Typography>
                            The system and software design documents are prepared as per the requirement specification document.
                            </Typography>
                        </Grid>
                        </li>
                        <li className={`project-image ${this.state.your_project.develop}`}
                        onMouseEnter={() => this.handleOnHoverIcons("develop")}
                        onClick={() => this.handleOnHoverIcons("develop")}
                        >
                        <h3>Develop</h3>
                        <Grid className="lets-disucss-inner-content">
                            <img src={develop_image} alt="Develop"  title="Develop"></img>
                            <Typography>
                            Tasks are divided into units or modules and assigned to the various developers. Developer needs to follow certain predefined coding guidelines.
                            </Typography>
                        </Grid>
                        </li>
                        <li className={`project-image ${this.state.your_project.launch}`}
                        onMouseEnter={() => this.handleOnHoverIcons("launch")}
                        onClick={() => this.handleOnHoverIcons("launch")}
                        >
                        <h3>Launch</h3>
                        <Grid className="lets-disucss-inner-content">
                            <img src={launch_image} alt="Launch" title="Launch"></img>
                            <Typography>
                            Once the product is tested, it is deployed in the production environment depending upon the customer expectation.
                            </Typography>
                        </Grid>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    );
  }
}

export default DiscussYourProject;
