import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
// import Link from "@material-ui/core/Link";
// import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Particles from 'react-particles-js';
// import Icon from "@material-ui/core/Icon";

// Get Images Start
import bannerImage from "../../assets/images/AboutusImages/about_banner.png";
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
// Custom Stylesheets End

class InnerPagesBanner extends Component {
  render() {
    return (
      <Grid item xs={12}>
        <Grid
          component="div"
          className="innerpagesbanner bg_img_wrap"
          style={{ backgroundImage: `url(${this.props.bannerImg ? this.props.bannerImg : bannerImage})` }}
        >
          <Particles
            params={{
              "particles": {
                  "number": {
                      "value": 50
                  },
                  "size": {
                      "value": 5
                  }
              },
              "interactivity": {
                  "events": {
                      "onhover": {
                          "enable": true,
                          "mode": "repulse"
                      }
                  }
              }
          }} />
          <Grid component="div" className="bannerTextWrap">
            <Typography className="white_clr" variant="h1" align="center" data-aos="zoom-in">
              {this.props.pageTitle
              ? this.props.pageTitle
              : "design and development teams"}
            </Typography>
            {/* <Grid component="div" className="btnWrap" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
              <Link className="view_work" to="/get-a-free-quote">
              Request a Free Consultation
                <Icon>chevron_right</Icon>
              </Link>
              <Link className="free_quote" to="/portfolios">
                VIEW PORTFOLIO
                <Icon>chevron_right</Icon>
              </Link>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default InnerPagesBanner;
