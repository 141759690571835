import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";

// Get Images Start
import exp_footer_25 from "../../assets/images/exp_footer_25.png";
import facebook_logo from "../../assets/images/facebook-logo.svg";
// import twitter_logo from "../../assets/images/twitter-logo.svg";
import linkedin_logo from "../../assets/images/linkedin-logo.svg";
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/Footer.css";
import "../../assets/css/commonStyle.css";
// Custom Stylesheets End


import visions_brochure from "../../assets/Brochure/visions-brochure.pdf";
import visions_sitemap from "../../assets/sitemap/sitemap.xml";


class Footer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeItem: ""
    };
  }

  UNSAFE_componentWillMount() {
    this.footerActiveClass();
  }

  UNSAFE_componentWillReceiveProps() {
    this.footerActiveClass();
  }

  footerActiveClass() {
    var path = window.location.pathname;
    switch (path) {
      case "/":
        this.setState({ activeItem: "home" });
        break;
      case "/about-us":
        this.setState({ activeItem: "about" });
        break;
      case "/services":
        this.setState({ activeItem: "service" });
        break;
      case "/portfolios":
        this.setState({ activeItem: "portfolio" });
        break;
      case "/careers":
        this.setState({ activeItem: "career" });
        break;
      case "/get-a-free-quote":
        this.setState({ activeItem: "quote" });
        break;
      case "/contact-us":
        this.setState({ activeItem: "contact" });
        break;

      case "/privacy-policy":
        this.setState({ activeItem: "privacy" });
        break;
      case "/services/web-development-services":
        this.setState({ activeItem: "web_development" });
        break;
      case "/services/web-development-services/php-development":
        this.setState({ activeItem: "php_development" });
        break;
      case "/services/web-development-services/mean-stack-development":
        this.setState({ activeItem: "mean_stack_development" });
        break;
      case "/services/web-development-services/mern-stack-development":
        this.setState({ activeItem: "mern_stack_development" });
        break;
      case "/services/web-development-services/mevn-stack-development":
        this.setState({ activeItem: "mevn_stack_development" });
        break;
      case "/services/web-development-services/asp-net-development":
        this.setState({ activeItem: "asp.net_development" });
        break;
      case "/services/web-development-services/python-development":
        this.setState({ activeItem: "python_development" });
        break;
      case "/services/uiux-design-services":
        this.setState({ activeItem: "ui_ux" });
        break;
      case "/services/uiux-design-services/website-redesign":
        this.setState({ activeItem: "website_redesign" });
        break;
      case "/services/uiux-design-services/responsive-web-design":
        this.setState({ activeItem: "responsive_web_design" });
        break;
      case "/services/uiux-design-services/logo-designing":
        this.setState({ activeItem: "logo_designing" });
        break;
      case "/services/uiux-design-services/banner-designing":
        this.setState({ activeItem: "banner_designing" });
        break;
      case "/services/uiux-design-services/brochure-designing":
        this.setState({ activeItem: "brochure_designing" });
        break;
      // case "/services/uiux-design-services/print-media-design":
      //   this.setState({ activeItem: "print_media_design" });
      //   break;
      case "/services/uiux-design-services/mobile-app-design":
        this.setState({ activeItem: "mobile-app-design" });
        break;
      case "/services/ecommerce-development-services":
        this.setState({ activeItem: "ecommerce_development_services" });
        break;
      case "/services/ecommerce-development-services/magento-development":
        this.setState({ activeItem: "magento_development" });
        break;
      case "/services/ecommerce-development-services/woocommerce-development":
        this.setState({ activeItem: "woocommerce_development" });
        break;
      case "/services/ecommerce-development-services/shopify-development":
        this.setState({ activeItem: "shopify_development" });
        break;
      case "/services/ecommerce-development-services/openCart-development":
        this.setState({ activeItem: "openCart_development" });
        break;
      case "/services/ecommerce-development-services/bigcommerce-development":
        this.setState({ activeItem: "bigcommerce_development" });
        break;
      case "/services/digital-marketing-services":
        this.setState({ activeItem: "digital_marketing_services" });
        break;
      case "/services/digital-marketing-services/seo-services-india":
        this.setState({ activeItem: "seo_services_india" });
        break;
      case "/services/digital-marketing-services/local-seo":
        this.setState({ activeItem: "local_seo" });
        break;
      case "/services/digital-marketing-services/social-media-optimization":
        this.setState({ activeItem: "social_media_optimization" });
        break;
      case "/services/digital-marketing-services/online-reputation-management":
        this.setState({ activeItem: "online_reputation_management" });
        break;
      case "/services/app-development-services":
        this.setState({ activeItem: "app_development_services" });
        break;
      case "/services/app-development-services/android-app-development":
        this.setState({ activeItem: "android_app_development" });
        break;
      case "/services/app-development-services/ios-app-development":
        this.setState({ activeItem: "ios_app_development" });
        break;
      case "/services/app-development-services/react-native-development":
        this.setState({ activeItem: "react_native_development" });
        break;
      case "/services/server-network-admin-support-services":
        this.setState({ activeItem: "server_network_admin_support_services" });
        break;
      case "/services/server-network-admin-support-services/server-network-administration":
        this.setState({ activeItem: "server_network_administration" });
        break;
      case "/services/server-network-admin-support-services/aws-cloud-hosting":
        this.setState({ activeItem: "aws_cloud_hosting" });
        break;
      case "/services/server-network-admin-support-services/linux":
        this.setState({ activeItem: "linux" });
        break;
      case "/services/server-network-admin-support-services/email-migration":
        this.setState({ activeItem: "email_migration" });
        break;
      default:
        this.setState({ activeItem: "" });
        break;
    }
  }

  getExperienceyear() {
    var today = new Date();
    var start_date = new Date("june 02, 1993");
    var years_now = today.getFullYear() - start_date.getFullYear();
    var m = today.getMonth() - start_date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) {
      years_now--;
    }
    return years_now;
  }
  getYear() {
    return new Date().getFullYear();
  }
  handleItemClick = (item) => {
    this.setState({ activeItem: item })
  }
  render() {
    return (
      <div className="footer_main">
        <Grid container>
          <Grid item xs={12} className="footer_top">
            <Grid item container className="container_custom">
              <Grid item container className="top_content_style">
                <div className="content_style_inner">
                  <Typography variant="h2" className="title_main_footer">
                    We are Excited That You Want To Work With Us
                  </Typography>
                </div>
                <div className="button_group_link">
                  <aside className="btn_Discuss_project">
                    <Link to="/get-a-free-quote" title="Discuss Your Project"> Discuss Your Project</Link>
                  </aside>
                </div>
              </Grid>
              <Grid item container spacing={24} className="addresses addresses_new_style">
                <Grid item xs={12} sm={12} md={2} lg={2} className="experianceCount col_style_left_add main_col_style col_padding_left_zero">
                  <div className="wrap_exp_count">
                    <Typography className="yearNumber">
                      <span variant="span" className="count_number">
                        {this.getExperienceyear()}
                      </span>
                      <span className="count_number_img">
                        <img alt="Our Experiance" src={exp_footer_25} />
                      </span>
                      <span className="plus_year">
                        <span variant="span" className="plus">
                          +
                            </span>
                        <span variant="span" className="year">
                          years
                            </span>
                      </span>
                    </Typography>
                    <Typography className="exp_word">Experience</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3} className="col_style_right_add col_padding_left_zero">
                  <Typography variant="h3" className="title_footer">
                    Head Office (India)
                  </Typography>
                  <MenuList className="menu_wrap">
                    <MenuItem>
                      <a target="_blank"rel="noopener noreferrer" href={"https://goo.gl/maps/p3xWZ8eSsmv"} >
                        <Icon>location_on</Icon>
                        <Typography>
                          4th Floor, Netsmartz House Plot No. 10, Rajiv Gandhi
                          Technology Park, Chandigarh - 160101
                        </Typography>
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a href={"tel:01724018666"} title="0172 - 4018666, 4028666">
                        <Icon>call</Icon>
                        <Typography>0172 - 4018666, 4028666</Typography>
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a href={"mailto:sales@visions.net.in"} title="sales@visions.net.in">
                        <Icon>email</Icon>
                        <Typography>sales@visions.net.in</Typography>
                      </a>
                    </MenuItem>
                  </MenuList>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} className="content_inner_side main_link_side_content col_padding_left_zero">
                  <Typography variant="h3" className="title_footer">
                    Vancouver (Canada)
                      </Typography>
                  <MenuList className="menu_wrap">
                    <MenuItem>
                      <Link to="/" className="for_person" title="Jas Mundy">
                        <Icon>person</Icon>
                        <Typography>Angad Singh Aujla</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <a href={"tel:16047046688"} title="+1 (604) 704 6688">
                        <Icon>call</Icon>
                        <Typography>+1 (604) 704 6688</Typography>
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a href={"mailto:angad@visions.net.in"} title="angad@visions.net.in">
                        <Icon>email</Icon>
                        <Typography>angad@visions.net.in</Typography>
                      </a>
                    </MenuItem>
                  </MenuList>
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={2} className="content_inner_side col_padding_left_zero">
                  <Typography variant="h3" className="title_footer">
                    Toronto (Canada)
                      </Typography>
                  <MenuList className="menu_wrap">
                    <MenuItem>
                      <Link to="/" className="for_person" title="Vikas Sharma">
                        <Icon>person</Icon>
                        <Typography>Vikas Sharma</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <a href={"tel:14168755117"} title="+1 (416) 875 5117">
                        <Icon>call</Icon>
                        <Typography>+1 (416) 875 5117</Typography>
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a href={"mailto:vikas@visions.net.in"} title="vikas@visions.net.in">
                        <Icon>email</Icon>
                        <Typography>vikas@visions.net.in</Typography>
                      </a>
                    </MenuItem>
                  </MenuList>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} className="col_style_right_add follow_us">
                  <Typography variant="h3" className="title_footer">
                    Follow Us
                  </Typography>
                  <MenuList className="menu_wrap">
                    <MenuItem className="social_links">
                      <a href={"https://www.facebook.com/visions.net.in"} title="Facebook" target="_blank" rel="noreferrer noopener">
                        <img src={facebook_logo} alt="Facebook" />
                      </a>
                      {/* <a href={"https://twitter.com/visionssoftware"} title="Twitter" target="_blank" rel="noreferrer noopener">
                        <img src={twitter_logo} alt="Twitter" />
                      </a> */}
                      <a href={"https://www.linkedin.com/company/visions-web-design-development-company-india/"} title="LinkedIn" target="_blank" rel="noreferrer noopener">
                        <img src={linkedin_logo} alt="LinkedIn" />
                      </a>
                    </MenuItem>
                  </MenuList>
                </Grid>
              </Grid>
              <div className="main_wrap_style_link">
                <MenuList className="menu_list_wrap_main d_flex">
                  <MenuItem className="list_style_inner_wrap">
                    <MenuList className="menu_list_sub_category">
                      {/* <Typography variant="h4" className="title_menu_main">
                            Web Development 
                        </Typography> */}
                      <MenuItem className={
                        `list_style_inner_category title_menu_main ${this.state.activeItem === "web_development" ? "active" : ""}`
                      }
                      >
                        <Link
                          onClick={() => this.handleItemClick("web_development")}
                          to="/services/web-development-services"
                          title="Web Development"
                        >
                          Web Development
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "php_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("php_development")}
                          to="/services/web-development-services/php-development"
                          title="PHP Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          PHP Development
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "mean_stack_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("mean_stack_development")}
                          to="/services/web-development-services/mean-stack-development"
                          title="Mean Stack Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Mean Stack Development
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "mern_stack_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("mern_stack_development")}
                          to="/services/web-development-services/mern-stack-development"
                          title="Mern Stack Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Mern Stack Development
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "mevn_stack_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("mevn_stack_development")}
                          to="/services/web-development-services/mevn-stack-development"
                          title="Mevn Stack Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Mevn Stack Development
                        </Link>
                      </MenuItem>
                      {/* <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "asp.net_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("asp.net_development")}
                          to="/services/web-development-services/asp-net-development"
                          title="Asp.Net Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Asp.Net Development
                        </Link>
                      </MenuItem> */}
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "python_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("python_development")}
                          to="/services/web-development-services/python-development"
                          title="Python Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Python Development
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItem>
                  <MenuItem className="list_style_inner_wrap">
                    <MenuList className="menu_list_sub_category">
                      <MenuItem className={
                        `list_style_inner_category title_menu_main ${this.state.activeItem === "ui_ux" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("ui_ux")}
                          to="/services/uiux-design-services"
                          title="UI/UX"
                        >
                          UI/UX
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "website_redesign" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("website_redesign")}
                          to="/services/uiux-design-services/website-redesign"
                          title="Website Redesign"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Website Redesign
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "responsive_web_design" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("responsive_web_design")}
                          to="/services/uiux-design-services/responsive-web-design"
                          title="Responsive Web Design"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Responsive Web Design
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "logo_designing" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("logo_designing")}
                          to="/services/uiux-design-services/logo-designing"
                          title="Logo Designing"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Logo Designing
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "banner_designing" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("banner_designing")}
                          to="/services/uiux-design-services/banner-designing"
                          title="Banner Designing"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Banner Designing
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "brochure_designing" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("brochure_designing")}
                          to="/services/uiux-design-services/brochure-designing"
                          title="Brochure Designing"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Brochure Designing
                        </Link>
                      </MenuItem>
                      {/* <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "print_media_design" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("print_media_design")}
                          to="/services/uiux-design-services/print-media-design"
                          title="Print Media Design"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Print Media Design
                        </Link>
                      </MenuItem> */}
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "mobile-app-design" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("mobile-app-design")}
                          to="/services/uiux-design-services/mobile-app-design"
                          title="Mobile App Design"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Mobile App Design
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItem>
                  <MenuItem className="list_style_inner_wrap">
                    <MenuList className="menu_list_sub_category">
                      <MenuItem className={
                        `list_style_inner_category title_menu_main ${this.state.activeItem === "ecommerce_development_services" ? "active" : ""}`
                      } >
                        <Link
                          onClick={() => this.handleItemClick("ecommerce_development_services")}
                          to="/services/ecommerce-development-services"
                          title="E-commerce Solutions"
                        >
                          E-commerce Solutions
                        </Link>
                      </MenuItem>
                      {/* <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "magento_development" ? "active" : ""}`
                      } >
                        <Link
                          onClick={() => this.handleItemClick("magento_development")}
                          to="/services/ecommerce-development-services/magento-development"
                          title="Magento Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Magento Development
                        </Link>
                      </MenuItem> */}
                      <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "woocommerce_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("woocommerce_development")}
                          to="/services/ecommerce-development-services/woocommerce-development"
                          title="WooCommerce Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          WooCommerce Development
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "shopify_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("shopify_development")}
                          to="/services/ecommerce-development-services/shopify-development"
                          title="Shopify Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Shopify Development
                        </Link>
                      </MenuItem>
                      {/* <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "openCart_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("openCart_development")}
                          to="/services/ecommerce-development-services/openCart-development"
                          title="OpenCart Develeopment"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          OpenCart Develeopment
                        </Link>
                      </MenuItem> */}
                      <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "bigcommerce_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("bigcommerce_development")}
                          to="/services/ecommerce-development-services/bigcommerce-development"
                          title="BigCommerce Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          BigCommerce Development
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItem>
                  <MenuItem className="list_style_inner_wrap">
                    <MenuList className="menu_list_sub_category">
                      <MenuItem className={
                        `list_style_inner_category title_menu_main  ${this.state.activeItem === "digital_marketing_services" ? "active" : ""}`
                      } >
                        <Link
                          onClick={() => this.handleItemClick("digital_marketing_services")}
                          to="/services/digital-marketing-services"
                          title="Digital Marketing"
                        >
                          Digital Marketing
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "seo_services_india" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("seo_services_india")}
                          to="/services/digital-marketing-services/seo-services-india"
                          title="Search Engine Optimization"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          SEO
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "local_seo" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("local_seo")}
                          to="/services/digital-marketing-services/local-seo"
                          title="Local SEO"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Local SEO
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "social_media_optimization" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("social_media_optimization")}
                          to="/services/digital-marketing-services/social-media-optimization"
                          title="Social Media Optimization"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Social Media Optimization
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category  ${this.state.activeItem === "online_reputation_management" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("online_reputation_management")}
                          to="/services/digital-marketing-services/online-reputation-management"
                          title="Online Reputation Management"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Online Reputation Management
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItem>
                  <MenuItem className="list_style_inner_wrap">
                    <MenuList className="menu_list_sub_category">
                      <MenuItem className={
                        `list_style_inner_category title_menu_main ${this.state.activeItem === "app_development_services" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("app_development_services")}
                          to="/services/app-development-services"
                          title="Mobile App Development"
                        >
                          Mobile App Development
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "android_app_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("android_app_development")}
                          to="/services/app-development-services/android-app-development"
                          title="Android App Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Android App Development
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "ios_app_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("ios_app_development")}
                          to="/services/app-development-services/ios-app-development"
                          title="iOS App Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          iOS App Development
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "react_native_development" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("react_native_development")}
                          to="/services/app-development-services/react-native-development"
                          title="React Native Development"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          React Native Development
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItem>
                  <MenuItem className="list_style_inner_wrap">
                    <MenuList className="menu_list_sub_category">
                      <MenuItem className={
                        `list_style_inner_category title_menu_main ${this.state.activeItem === "server_network_admin_support_services" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("server_network_admin_support_services")}
                          to="/services/server-network-admin-support-services"
                          title="Server/Network Admin Support"
                        >
                          Server/Network Admin Support
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "server_network_administration" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("server_network_administration")}
                          to="/services/server-network-admin-support-services/server-network-administration"
                          title="Server Network Administration"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Server Network Administration
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "aws_cloud_hosting" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("aws_cloud_hosting")}
                          to="/services/server-network-admin-support-services/aws-cloud-hosting"
                          title="AWS Cloud Hosting"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          AWS Cloud Hosting
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "linux" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("linux")}
                          to="/services/server-network-admin-support-services/linux"
                          title="Linux"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Linux
                        </Link>
                      </MenuItem>
                      <MenuItem className={
                        `list_style_inner_category ${this.state.activeItem === "email_migration" ? "active" : ""}`
                      }>
                        <Link
                          onClick={() => this.handleItemClick("email_migration")}
                          to="/services/server-network-admin-support-services/email-migration"
                          title="Email Migration"
                        >
                          <i className="material-icons"> keyboard_arrow_right </i>
                          Email Migration
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItem>
                </MenuList>
              </div>
              <div className="footer_menu_bottom">
                <aside>
                  <MenuList className="menu_links d_flex">
                    <MenuItem
                      className={
                        this.state.activeItem === "about" ? "active" : ""
                      }
                    >
                      <Link to="/about-us" onClick={() => this.handleItemClick("about")} title="About Us"> About Us</Link>
                    </MenuItem>
                    <MenuItem
                      className={
                        this.state.activeItem === "portfolio" ? "active" : ""
                      }
                    >
                      <Link to="/portfolios" onClick={() => this.handleItemClick("portfolio")} title="Portfolios"> Portfolios</Link>
                    </MenuItem>
                    <MenuItem
                      className={
                        this.state.activeItem === "career" ? "active" : ""
                      }
                    >
                      <Link to="/careers" onClick={() => this.handleItemClick("career")} title="Career"> Career</Link>
                    </MenuItem>

                    <MenuItem
                      className={
                        this.state.activeItem === "contact" ? "active" : ""
                      }
                    >
                      <Link to="/contact-us" onClick={() => this.handleItemClick("contact")} title="Contact"> Contact</Link>
                    </MenuItem>

                    {/* <MenuItem
                      className={
                        this.state.activeItem === "brochure" ? "active" : ""
                      }
                    >
                      <a
                        href={visions_brochure}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={() => this.handleItemClick("brochure")}
                        title="Download Brochure"
                      >
                        {" "}
                        Download Brochure
                      </a>
                    </MenuItem> */}

                    <MenuItem
                      className={
                        this.state.activeItem === "quote" ? "active" : ""
                      }
                    >
                      <Link to="/get-a-free-quote" title="Get a Free Quote"> Get a Free Quote</Link>
                    </MenuItem>

                    <MenuItem
                      className={
                        this.state.activeItem === "sitemap" ? "active" : ""
                      }
                    >
                      <a
                        href={visions_sitemap}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={() => this.handleItemClick("sitemap")}
                        title="Sitemap"
                      >
                        {" "}
                        Sitemap
                        </a>
                    </MenuItem>

                    <MenuItem
                      className={
                        this.state.activeItem === "privacy" ? "active" : ""
                      }
                    >
                      <Link to="/privacy-policy" onClick={() => this.handleItemClick("privacy")} title="Privacy & Policy"> Privacy & Policy</Link>
                    </MenuItem>

                  </MenuList>
                </aside>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} className="footer_bottom_mobile">
            <aside className="btn_Discuss_project">
              <Link to="/get-a-free-quote" title="Get A Free Quote"> Get A Free Quote</Link>
            </aside>
          </Grid>
          <Grid item xs={12} className="footer_bottom">
            <Grid item className="copyright">
              <Typography>© {this.getYear()} Visions. All Rights Reserved.</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Footer;
