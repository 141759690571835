import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Undertakings_Application from '../../assets/images/HireDevelopers/HireCodeigniter/Undertakings_Application.svg';
import Online_interfaces from '../../assets/images/HireDevelopers/HireCodeigniter/Online_interfaces.svg';
import Online_business_Solutions from '../../assets/images/HireDevelopers/HireCodeigniter/Online_business_Solutions.svg';
import Corporate_Applications from '../../assets/images/HireDevelopers/HireCodeigniter/Corporate_Applications.svg';
import Back_End_Support from '../../assets/images/HireDevelopers/HireCodeigniter/Back_End_Support.svg';
import Bother_Free_Coding from '../../assets/images/HireDevelopers/HireCodeigniter/Bother_Free_Coding.svg';
import CMS_Development from '../../assets/images/HireDevelopers/HireCodeigniter/CMS_Development.svg';
import Different_Platform_Compatibility from '../../assets/images/HireDevelopers/HireCodeigniter/Different_Platform_Compatibility.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HireCodeigniter/HIRE-CODEIGNITER-DEVELOPERS1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HirecodeigniterDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
            pageTitle: "Hire CodeIgniter Developers",
            bannerImg: Hire_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: "What is CodeIgniter?",
                    TypographyDescription: "CodeIgniter is an open-source system for web applications. It is utilized to create sites in PHP. It is in exactly founded on the MVC example, and it is anything but difficult to utilize contrast with other PHP structures."
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: "When would it be a good idea for me to pick CodeIgniter over Laravel?",
                    TypographyDescription: "That depends. CodeIgniter is more qualified for little and medium ventures with little groups. In the event that you need to convey a venture as quick as could be expected under the circumstances and would prefer not to invest any energy discovering some new information notwithstanding PHP, at that point CodeIgniter is the best decision for you. On the off chance that you are in a major group and need to stretch out your site to an exceptionally enormous business site, at that point Laravel is your solitary decision."
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: "Which rendition of PHP does CodeIgniter support?",
                    TypographyDescription: "More current adaptations of CodeIgniter are in reverse perfect with most forms of PHP."
                },
                {
                    expanded: 'panel4',
                    ariaControls: 'panel4d-content',
                    panelId: 'panel4d-header',
                    typographyText: "What are the hooks in CodeIgniter?",
                    TypographyDescription: "The Hook is a component in CodeIgniter that gives an approach to change the inward working of the system without hacking the center documents. It encourages you to execute a content with a specific way inside the CodeIgniter. For the most part, it is characterized in the application/config/hooks.php record."
                },
                {
                    expanded: 'panel5',
                    ariaControls: 'panel5d-content',
                    panelId: 'panel5d-header',
                    typographyText: "What are CodeIgniter drivers?",
                    TypographyDescription: "These are a specific kind of library that has a parent class and numerous kid classes. These kid classes approach the parent class, however not to their kin. Drivers are found in framework / libraries envelope."
                }
            ]
      };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire Codeigniter Developers | Codeigniter Programmers India";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="CodeIgniter Developers" />
          {/* End - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <strong>Visions</strong> is a first class <strong>CodeIgniter Development</strong> Company to make promising sites while utilizing it with PHP structure. CodeIgniter encourages us to make viable sites with quick pace. <strong>CodeIgniter Development</strong> helps the engineers by offering the predefined set of libraries, so designers can require a particular errand without composing the code without any preparation. <strong>Visions</strong> is a main versatile and site advancement and planning organization with a group of profoundly gifted designers. We offer excellent <strong>CodeIgniter Development</strong> arrangements at savvy cost.
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Undertakings_Application} alt="Undertakings Application" title="Undertakings Application" />
                        <Typography variant="h5" className="mb-1">
                          Undertakings Application
                            </Typography>
                        <Typography className="head_paragraph">
                          We offer top of the line and complex based functionalities Enterprises Application to go about as an extension among clients and substances.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Online_interfaces} alt="Online Interfaces" title="Online Interfaces" />
                        <Typography variant="h5" className="mb-1">
                          Online Interfaces
                            </Typography>
                        <Typography className="head_paragraph">
                          Our <strong>CodeIgniter</strong> designers give effective online interfaces crosswise over incidental enterprises to satisfy their wants.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Online_business_Solutions} alt="Online Business Solutions" title="Online Business Solutions" />
                        <Typography variant="h5" className="mb-1">
                          Online Business Solutions
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Visions</strong> bring <strong>AJAX</strong> shopping based cart application to manage the touchy information.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Corporate_Applications} alt="Corporate Applications" title="Corporate Applications" />
                        <Typography variant="h5" className="mb-1">
                          Corporate Applications
                            </Typography>
                        <Typography className="head_paragraph">
                          For ideal and hearty execution, offering you with <strong>PHP CodeIgniter and MySQL</strong> based corporate applications.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire CodeIgniter Developers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      We offer you a chance to contract Dedicated CodeIgniter Developer on convenient, hourly or full time premise. Our CodeIgniter advancement specialists utilize the open source structure for utilizing a total PHP based sites and applications, in view of MVC design. This enables the application to be all around coordinated with facilitating records, setup, and different PHP forms.
                              </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="Back End Support" title="Back End Support" src={Back_End_Support} />
                    <Typography variant="h4" className="head_title">
                      Back End Support
                              </Typography>
                    <Typography className="head_paragraph">
                      Our dedicated engineers are capable to manufacture a solid, dependable site back-end for the application we made with <strong>CodeIgniter</strong>.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="Bother Free Coding" title="Bother Free Coding" src={Bother_Free_Coding} />
                    <Typography variant="h4" className="head_title">
                      Bother Free Coding
                              </Typography>
                    <Typography className="head_paragraph">
                      Our administration expands dependent on the most recent forms of PHP and execute bother free usage of utilization for industrial-explicit patterns.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="CMS Development" title="CMS Development" src={CMS_Development} />
                    <Typography variant="h4" className="head_title">
                      CMS Development
                              </Typography>
                    <Typography className="head_paragraph">
                      We fabricate a capable CMS with different usefulness streams, uniquely redid in regards to customer's necessities.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="Different Platform Compatibility" title="Different Platform Compatibility" src={Different_Platform_Compatibility} />
                    <Typography variant="h4" className="head_title">
                      Different Platform Compatibility
                              </Typography>
                    <Typography className="head_paragraph">
                      Our enticing group fabricate <strong>CodeIgniter applications</strong> that sudden spike in demand for <strong>MacOS, Windows and Linux</strong> that floats constantly starting with one server then onto the next.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">In the event that you need to create web application with 100% committed group till you get fulfilled, at that point enlist our <strong>CodeIgniter </strong> system designers. Our engineers construct subjective arrangements that matches to global advancement guidelines. We have encountered able designers to give practical <strong>CodeIgniter </strong>  arrangements at characterized time. Our designers contribute their best in work to fulfill our customers. Contact us to enable your business and flood in front of your rivals.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li>
                        <strong>CodeIgniter </strong>  web applications development services. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        <strong>CodeIgniter </strong>  maintenance and support. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        <strong>CodeIgniter </strong>  website development. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        <strong>CodeIgniter </strong>  integration Services. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> <strong>CodeIgniter </strong>  application migration services.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> <strong>CodeIgniter </strong>  portal development.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> <strong>CodeIgniter </strong>  upgradation services.
                                  </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We have the best of aptitudes and involvement with Codeigniter. They are profoundly talented in using valuable devices and techniques to meet the particular prerequisite of the customers. The devoted advancement of various PHP structures at <strong>Visions</strong> has set up uncommon unwavering quality in the web improvement industry.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HirecodeigniterDev;
