import React, { Component, Suspense, lazy } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import LazyLoad from "react-lazyload";
//import InfiniteScroll from "react-infinite-scroll-component";

// Get Files for Slider Start
//import Slider from "../../slider";
// Get Files for Slider End

// Images Start
import app_store_badge from "../../assets/images/app_store_badge.png";
import google_play_store_badge from "../../assets/images/google_play_store_badge.png";
import sports_ohio_big from "../../assets/images/Portfolios/Web/sports_ohio_big.jpg";
import sports_ohio_laptop from "../../assets/images/Portfolios/Web/sports_ohio_laptop.png";
import sports_ohio_small_logo from "../../assets/images/Portfolios/Web/sports_ohio_small_logo.png";
import jawcon_big from "../../assets/images/Portfolios/Web/jawcon_big.jpg";
import jawcon_laptop from "../../assets/images/Portfolios/Web/jawcon_laptop.png";
import jawcon_mobile from "../../assets/images/Portfolios/Web/jawcon_mobile.png";
import jawcon_small_logo from "../../assets/images/Portfolios/Web/jawcon_small_logo.png";
import Assignq_big from "../../assets/images/Portfolios/Web/Assignq_big.jpg";
import Assignq_laptop from "../../assets/images/Portfolios/Web/Assignq_laptop.png";
import Assignq_small_logo from "../../assets/images/Portfolios/Web/Assignq_small_logo.png";
import God_Connection_big from "../../assets/images/Portfolios/Web/God_Connection_big.jpg";
import God_Connection_laptop from "../../assets/images/Portfolios/Web/God_Connection_laptop.png";
import God_Connection_small_logo from "../../assets/images/Portfolios/Web/God_Connection_small_logo.png";
import Trueworkforce_big from "../../assets/images/Portfolios/Web/Trueworkforce_big.jpg";
import Trueworkforce_laptop from "../../assets/images/Portfolios/Web/Trueworkforce_laptop.png";
import Trueworkforce_small_logo from "../../assets/images/Portfolios/Web/Trueworkforce_small_logo.png";
import Naudje_big from "../../assets/images/Portfolios/Web/Naudje_big.jpg";
import Naudje_laptop from "../../assets/images/Portfolios/Web/Naudje_laptop.png";
import Naudje_small_logo from "../../assets/images/Portfolios/Web/Naudje_small_logo.png";
import craftycanineclub_big from "../../assets/images/Portfolios/Web/craftycanineclub_big.jpg";
import craftycanineclub_laptop from "../../assets/images/Portfolios/Web/craftycanineclub_laptop.png";
import craftycanineclub_small_logo from "../../assets/images/Portfolios/Web/craftycanineclub_small_logo.png";
import deking_decks_big from "../../assets/images/Portfolios/Web/deking-decks_big.jpg";
import deking_decks_laptop from "../../assets/images/Portfolios/Web/deking-decks_laptop.png";
import dekingdecks_small_logo from "../../assets/images/Portfolios/Web/dekingdecks_small_logo.png";
import ego_big from "../../assets/images/Portfolios/Web/ego_big.jpg";
import ego_laptop from "../../assets/images/Portfolios/Web/ego_laptop.png";
import ego_small_logo from "../../assets/images/Portfolios/Web/ego_small_logo.png";
import alias_big from "../../assets/images/Portfolios/Web/alias_big.jpg";
import alias_logo from "../../assets/images/Portfolios/Web/alias_logo.png";
import Gorilla_big from "../../assets/images/Portfolios/Web/Gorilla_big.jpg";
import Gorilla_logo from "../../assets/images/Portfolios/Web/Gorilla_logo.png";
import Trainer_big from "../../assets/images/Portfolios/Web/Trainer_big.jpg";
import Trainer_logo from "../../assets/images/Portfolios/Web/Trainer_logo.png";
import WESTORN_big from "../../assets/images/Portfolios/Web/WESTORN_big.jpg";
import WESTORN_logo from "../../assets/images/Portfolios/Web/WESTORN_logo.png";
import eatmenu_big from "../../assets/images/Portfolios/Web/eatmenu_big.jpg";
import eatmenu_laptop from "../../assets/images/Portfolios/Web/eatmenu_laptop.png";
import Echo_Sanitise_big from "../../assets/images/Portfolios/Web/Echo_Sanitise_big.jpg";
import Echo_Sanitise_laptop from "../../assets/images/Portfolios/Web/Echo_Sanitise_laptop.png";
import Brochure_Design_small_logo from "../../assets/images/Portfolios/Web/Brochure_Design_small_logo.png";
import Specimen_big from "../../assets/images/Portfolios/Web/Specimen_big.jpg";
import Specimen_laptop from "../../assets/images/Portfolios/Web/Specimen_laptop.png";
import Travel_big from "../../assets/images/Portfolios/Web/Travel_big.jpg";
import Travel_laptop from "../../assets/images/Portfolios/Web/Travel_laptop.png";
import fl_counties_big from "../../assets/images/Portfolios/Web/fl_counties_big.jpg";
import fl_counties_laptop from "../../assets/images/Portfolios/Web/fl_counties_laptop.png";
import FAC_small_logo from "../../assets/images/Portfolios/Web/FAC_small_logo.png";
import int_enviroguard_big from "../../assets/images/Portfolios/Web/int_enviroguard_big.jpg";
import int_enviroguard_laptop from "../../assets/images/Portfolios/Web/int_enviroguard_laptop.png";
import int_enviroguard_small_logo from "../../assets/images/Portfolios/Web/int_enviroguard_small_logo.png";
import kitchenbrains_big from "../../assets/images/Portfolios/Web/kitchenbrains_big.jpg";
import kitchenbrains_laptop from "../../assets/images/Portfolios/Web/kitchenbrains_laptop.png";
import kitchenbrains_mobile from "../../assets/images/Portfolios/Web/kitchenbrains_mobile.png";
import kitchen_brains_small_logo from "../../assets/images/Portfolios/Web/kitchen_brains_small_logo.png";
import MyList_big from "../../assets/images/Portfolios/Web/mylist_big.jpg";
import MyList_laptop from "../../assets/images/Portfolios/Web/mylist_laptop.png";
import mylist_mobile from "../../assets/images/Portfolios/Web/mylist_mobile.png";
import mylist_small_logo from "../../assets/images/Portfolios/Web/mylist_small_logo.png";
import ocett_big from "../../assets/images/Portfolios/Web/ocett_big.jpg";
import ocett_laptop from "../../assets/images/Portfolios/Web/ocett_laptop.png";
import ocett_small_logo from "../../assets/images/Portfolios/Web/ocett_small_logo.png";
import premierglow_big from "../../assets/images/Portfolios/Web/premierglow_big.jpg";
import premierglow_laptop from "../../assets/images/Portfolios/Web/premierglow_laptop.png";
import premierglow_small_logo from "../../assets/images/Portfolios/Web/premierglow_small_logo.png";
import lightSounds_big from "../../assets/images/Portfolios/Web/lightSounds_big.jpg";
import lightSounds_laptop from "../../assets/images/Portfolios/Web/lightSounds_laptop.png";
import lightSounds_small_logo from "../../assets/images/Portfolios/Web/lightSounds_small_logo.png";
import urbantheka_big from "../../assets/images/Portfolios/Web/urbantheka_big.jpg";
import urbantheka_laptop from "../../assets/images/Portfolios/Web/urbantheka_laptop.png";
import urbantheka_small_logo from "../../assets/images/Portfolios/Web/urbantheka_small_logo.png";
import gladev_big from "../../assets/images/Portfolios/Web/gladev_big.jpg";
import gladev_laptop from "../../assets/images/Portfolios/Web/gladev_laptop.png";
import gladev_mobile from "../../assets/images/Portfolios/Web/gladev_mobile.png";
import gladev_small_logo from "../../assets/images/Portfolios/Web/gladev_small_logo.png";
import shop2party_big from "../../assets/images/Portfolios/Web/shop2party_big.jpg";
import shop2party_laptop from "../../assets/images/Portfolios/Web/shop2party_laptop.png";
import shop2party_small_logo from "../../assets/images/Portfolios/Web/shop2party_small_logo.png";
import singapore_big from "../../assets/images/Portfolios/Web/singapore_big.jpg";
import singapore_laptop from "../../assets/images/Portfolios/Web/singapore_laptop.png";
import singapore_small_logo from "../../assets/images/Portfolios/Web/singapore_small_logo.png";
import papelStore_big from "../../assets/images/Portfolios/Web/papelStore_big.jpg";
import papelStore_laptop from "../../assets/images/Portfolios/Web/papelStore_laptop.png";
import papelStore_small_logo from "../../assets/images/Portfolios/Web/papelStore_small_logo.png";
import storeMore_big from "../../assets/images/Portfolios/Web/storeMore_big.jpg";
import storeMore_laptop from "../../assets/images/Portfolios/Web/storeMore_laptop.png";
import storeMore_small_logo from "../../assets/images/Portfolios/Web/storeMore_small_logo.png";
import wltg_big from "../../assets/images/Portfolios/App/wltg_big.jpg";
import wltg_mobile from "../../assets/images/Portfolios/App/wltg_mobile.png";
import wltg_small_logo from "../../assets/images/Portfolios/App/wltg_small_logo.png";
import rolela_big from "../../assets/images/Portfolios/App/rolela_big.jpg";
import rolela_mobile from "../../assets/images/Portfolios/App/rolela_mobile.png";
import rolela_small_logo from "../../assets/images/Portfolios/App/rolela_small_logo.png";
import ugame_big from "../../assets/images/Portfolios/App/ugame_big.jpg";
import ugame_mobile from "../../assets/images/Portfolios/App/ugame_mobile.png";
import ugame_small_logo from "../../assets/images/Portfolios/App/ugame_small_logo.png";
import pullme_big from "../../assets/images/Portfolios/App/pullme_big.jpg";
import pullme_mobile from "../../assets/images/Portfolios/App/pullme_mobile.png";
import pullme_small_logo from "../../assets/images/Portfolios/App/pullme_small_logo.png";
import scan_it_big from "../../assets/images/Portfolios/App/scan_it_big.png";
import scan_it_mobile from "../../assets/images/Portfolios/App/scan_it_mobile.png";
import scan_it_small_logo from "../../assets/images/Portfolios/App/scan_it_small_logo.png";
import timeout_big from "../../assets/images/Portfolios/App/timeout_big.png";
import timeout_mobile from "../../assets/images/Portfolios/App/timeout_mobile.png";
import timeout_small_logo from "../../assets/images/Portfolios/App/timeout_small_logo.png";
import Pill_Identification_big from "../../assets/images/Portfolios/App/Pill_Identification_big.jpg";
import Pill_Identification_mobile from "../../assets/images/Portfolios/App/Pill_Identification_mobile.png";
import Pill_Identification_small_logo from "../../assets/images/Portfolios/App/Pill_Identification_small_logo.png";
import map_my_sound_big from "../../assets/images/Portfolios/App/map-my-sound_big.jpg";
import map_my_sound_mobile from "../../assets/images/Portfolios/App/map-my-sound_mobile.png";
import map_my_sound_small_logo from "../../assets/images/Portfolios/App/map-my-sound_small_logo.png";
import prime_big from "../../assets/images/Portfolios/App/prime_big.jpg";
import prime_mobile from "../../assets/images/Portfolios/App/prime_mobile.png";
import prime_small_logo from "../../assets/images/Portfolios/App/prime_small_logo.png";
import epic_big from "../../assets/images/Portfolios/App/epic_big.jpg";
import epic_mobile from "../../assets/images/Portfolios/App/epic_mobile.png";
import epic_small_logo from "../../assets/images/Portfolios/App/epic_small_logo.png";
import Malena_big from "../../assets/images/Portfolios/Web/Malena_big.jpg";
import Malena_laptop from "../../assets/images/Portfolios/Web/Malena_laptop.png";
import Malena_small_logo from "../../assets/images/Portfolios/Web/Malena_small_logo.png";
import ExpressLogistics_big from "../../assets/images/Portfolios/Web/ExpressLogistics_big.png";
import ExpressLogistics_laptop from "../../assets/images/Portfolios/Web/ExpressLogistics_laptop.png";
import ExpressLogistics_small_logo from "../../assets/images/Portfolios/Web/ExpressLogistics_small_logo.png";
import ReachFast_big from "../../assets/images/Portfolios/Web/ReachFast_big.jpg";
import ReachFast_laptop from "../../assets/images/Portfolios/Web/ReachFast_laptop.png";
import ReachFast_small_logo from "../../assets/images/Portfolios/Web/ReachFast_small_logo.png";
import Botonique_big from "../../assets/images/Portfolios/Web/Botonique_big.png";
import Botonique_laptop from "../../assets/images/Portfolios/Web/botonique_laptop.png";
import Botonique_small_logo from "../../assets/images/Portfolios/Web/botonique_small_logo.png";
import Carinfo_big from "../../assets/images/Portfolios/Web/carinfo_big.png";
import Carinfo_laptop from "../../assets/images/Portfolios/Web/carinfo_laptop.png";
import Carinfo_small_logo from "../../assets/images/Portfolios/Web/carinfo_small_logo.png";
import CRM_Web_Application_big from "../../assets/images/Portfolios/Web/CRM_Web_Application_big.jpg";
import CRM_Web_Application_laptop from "../../assets/images/Portfolios/Web/CRM_Web_Application_laptop.png";
import CRM_Web_Application_small_logo from "../../assets/images/Portfolios/Web/CRM_Web_Application_small_logo.png";
import WLI_WebApp_big from "../../assets/images/Portfolios/Web/WLI_WebApp_big.png";
import WLI_WebApp_laptop from "../../assets/images/Portfolios/Web/WLI_WebApp_laptop.png";
import WLI_WebApp_small_logo from "../../assets/images/Portfolios/Web/WLI_WebApp_small_logo.png";
import car_big from "../../assets/images/Portfolios/Web/car_big.jpg";
import car_laptop from "../../assets/images/Portfolios/Web/car_laptop.png";
import car_small_logo from "../../assets/images/Portfolios/Web/car_small_logo.png";
import CAT_Card_big from "../../assets/images/Portfolios/Web/CAT_Card_big.jpg";
import CAT_Card_laptop from "../../assets/images/Portfolios/Web/CAT_Card_laptop.png";
import CAT_Card_small_logo from "../../assets/images/Portfolios/Web/CAT_Card_small_logo.png";
import CoffeeShop_big from "../../assets/images/Portfolios/Web/CoffeeShop_big.jpg";
import CoffeeShop_laptop from "../../assets/images/Portfolios/Web/CoffeeShop_laptop.png";
import CoffeeShop_small_logo from "../../assets/images/Portfolios/Web/CoffeeShop_small_logo.png";
import Mens_Hair_Fitness_big from "../../assets/images/Portfolios/Web/Mens_Hair_Fitness_big.png";
import Mens_Hair_Fitness_laptop from "../../assets/images/Portfolios/Web/Mens_Hair_Fitness_laptop.png";
import Mens_Hair_Fitness_small_logo from "../../assets/images/Portfolios/Web/Mens_Hair_Fitness_small_logo.png";
import admin_panel_lulo_big from "../../assets/images/Portfolios/Web/admin_panel_lulo_big.png";
import admin_panel_lulo_laptop from "../../assets/images/Portfolios/Web/admin_panel_lulo_laptop.png";
import admin_panel_lulo_small_logo from "../../assets/images/Portfolios/Web/admin_panel_lulo_small_logo.png";
import LeanIPD_big from "../../assets/images/Portfolios/Web/LeanIPD_big.png";
import LeanIPD_laptop from "../../assets/images/Portfolios/Web/LeanIPD_laptop.png";
import LeanIPD_small_logo from "../../assets/images/Portfolios/Web/LeanIPD_small_logo.png";
import RogueFitness_big from "../../assets/images/Portfolios/Web/RogueFitness_big.png";
import RogueFitness_mobile from "../../assets/images/Portfolios/Web/RogueFitness_mobile.png";
import RogueFitness_small_logo from "../../assets/images/Portfolios/Web/RogueFitness_small_logo.png";
import District_Marketing_big from "../../assets/images/Portfolios/Web/District_Marketing_big.png";
import District_Marketing_mobile from "../../assets/images/Portfolios/Web/District_Marketing_mobile.png";
import District_Marketing_small_logo from "../../assets/images/Portfolios/Web/District_Marketing_small_logo.png";
import inTheNumber_big from "../../assets/images/Portfolios/Web/inTheNumber_big.png";
import inTheNumber_mobile from "../../assets/images/Portfolios/Web/inTheNumber_mobile.png";
import inTheNumber_small_logo from "../../assets/images/Portfolios/Web/inTheNumber_small_logo.png";
import ECO_Tile_big from "../../assets/images/Portfolios/Web/ECO_Tile_big.png";
import ECO_Tile_mobile from "../../assets/images/Portfolios/Web/ECO_Tile_mobile.png";
import ECO_Tile_small_logo from "../../assets/images/Portfolios/Web/ECO_Tile_small_logo.png";
import Armando_law_big from "../../assets/images/Portfolios/Web/Armando_law_big.jpg";
import Armando_law_laptop from "../../assets/images/Portfolios/Web/Armando_law_laptop.jpg";
import Christmas_big from "../../assets/images/Portfolios/Web/Christmas_big.jpg";
import Christmas_laptop from "../../assets/images/Portfolios/Web/Christmas_laptop.jpg";
import itsCooler_big from "../../assets/images/Portfolios/Web/itsCooler_big.png";
import itsCooler_laptop from "../../assets/images/Portfolios/Web/itsCooler_laptop.png";
import itsCooler_small_logo from "../../assets/images/Portfolios/Web/itsCooler_small_logo.png";
import m4mattress_big from "../../assets/images/Portfolios/Web/m4mattress_big.jpg";
import m4mattress_laptop from "../../assets/images/Portfolios/Web/m4mattress_laptop.png";
import m4mattress_small_logo from "../../assets/images/Portfolios/Web/m4mattress_small_logo.png";
import marbelTops_big from "../../assets/images/Portfolios/Web/marbelTops_big.jpg";
import marbelTops_laptop from "../../assets/images/Portfolios/Web/marbelTops_laptop.png";
import marbelTops_small_logo from "../../assets/images/Portfolios/Web/marbelTops_small_logo.png";
import obliphica_big from "../../assets/images/Portfolios/Web/obliphica_big.jpg";
import obliphica_laptop from "../../assets/images/Portfolios/Web/obliphica_laptop.png";
import obliphica_small_logo from "../../assets/images/Portfolios/Web/obliphica_small_logo.png";
import orchidsluxhome_big from "../../assets/images/Portfolios/Web/orchidsluxhome_big.jpg";
import orchidsluxhome_laptop from "../../assets/images/Portfolios/Web/orchidsluxhome_laptop.png";
import orchidsluxhome_small_logo from "../../assets/images/Portfolios/Web/orchidsluxhome_small_logo.png";
import wildTEA_big from "../../assets/images/Portfolios/Web/wildTEA_big.jpg";
import wildTEA_laptop from "../../assets/images/Portfolios/Web/wildTEA_laptop.png";
import wildTEA_small_logo from "../../assets/images/Portfolios/Web/wildTEA_small_logo.png";
import BBI_Flags_big from "../../assets/images/Portfolios/Web/BBI_Flags_big.jpg";
import BBI_Flags_laptop from "../../assets/images/Portfolios/Web/BBI_Flags_laptop.png";
import BBI_Flags_small_logo from "../../assets/images/Portfolios/Web/BBI_Flags_small_logo.png";
import jsTech_big from "../../assets/images/Portfolios/Web/jsTech_big.jpg";
import jsTech_laptop from "../../assets/images/Portfolios/Web/jsTech_laptop.png";
import jsTech_small_logo from "../../assets/images/Portfolios/Web/jsTech_small_logo.png";
import icebox_big from "../../assets/images/Portfolios/Web/icebox_big.jpg";
import icebox_laptop from "../../assets/images/Portfolios/Web/icebox_laptop.png";
import icebox_small_logo from "../../assets/images/Portfolios/Web/icebox_small_logo.png";
import LuxuryHolidays_big from "../../assets/images/Portfolios/Web/LuxuryHolidays_big.jpg";
import LuxuryHolidays_laptop from "../../assets/images/Portfolios/Web/LuxuryHolidays_laptop.png";
import LuxuryHolidays_small_logo from "../../assets/images/Portfolios/Web/LuxuryHolidays_small_logo.png";
// import Smart_EHealth_big from "../../assets/images/Portfolios/Web/Smart_EHealth_big.jpg";
// import Smart_EHealth_laptop from "../../assets/images/Portfolios/Web/Smart_EHealth_laptop.png";
// import Smart_EHealth_small_logo from "../../assets/images/Portfolios/Web/Smart_EHealth_small_logo.png";
import employee_mange_big from "../../assets/images/Portfolios/Web/employee_mange_big.jpg";
import employee_mange_laptop from "../../assets/images/Portfolios/Web/employee_mange_laptop.png";
import employee_mange_small_logo from "../../assets/images/Portfolios/Web/employee_mange_small_logo.png";
import itrulyheal_big from "../../assets/images/Portfolios/Web/itrulyheal_big.jpg";
import itrulyheal_laptop from "../../assets/images/Portfolios/Web/itrulyheal_laptop.png";
import itrulyheal_small_logo from "../../assets/images/Portfolios/Web/itrulyheal_small_logo.png";
import madd_big from "../../assets/images/Portfolios/App/madd_big.jpg";
import madd_mobile from "../../assets/images/Portfolios/App/madd_mobile.png";
import madd_small_logo from "../../assets/images/Portfolios/App/madd_small_logo.png";
import gorilla_big from "../../assets/images/Portfolios/App/gorilla_big.jpg";
import gorilla_mobile from "../../assets/images/Portfolios/App/gorilla_mobile.png";
import gorilla_small_logo from "../../assets/images/Portfolios/App/gorilla_small_logo.png";
import loopleap_big from "../../assets/images/Portfolios/App/loopleap_big.jpg";
import loopleap_mobile from "../../assets/images/Portfolios/App/loopleap_mobile.png";
import loopleap_small_logo from "../../assets/images/Portfolios/App/loopleap_small_logo.png";
import luveCyprus_big from "../../assets/images/Portfolios/App/luveCyprus_big.jpg";
import luveCyprus_mobile from "../../assets/images/Portfolios/App/luveCyprus_mobile.png";
import luveCyprus_small_logo from "../../assets/images/Portfolios/App/luveCyprus_small_logo.png";
import richNess_big from "../../assets/images/Portfolios/App/richNess_big.jpg";
import richNess_mobile from "../../assets/images/Portfolios/App/richNess_mobile.png";
import richNess_small_logo from "../../assets/images/Portfolios/App/richNess_small_logo.png";
import TK_Inspection_big from "../../assets/images/Portfolios/App/TK_Inspection_big.jpg";
import TK_Inspection_mobile from "../../assets/images/Portfolios/App/TK_Inspection_mobile.png";
import TK_Inspection_small_logo from "../../assets/images/Portfolios/App/TK_Inspection_small_logo.png";
import warp_big from "../../assets/images/Portfolios/App/warp_big.jpg";
import warp_mobile from "../../assets/images/Portfolios/App/warp_mobile.png";
import warp_small_logo from "../../assets/images/Portfolios/App/warp_small_logo.png";
import php_icon from "../../assets/images/Portfolios/Technologies/php_icon.svg";
import laravel_icon from "../../assets/images/Portfolios/Technologies/laravel_icon.svg";
import cakePHP_icon from "../../assets/images/Portfolios/Technologies/cakePHP_icon.svg";
import yii_icon from "../../assets/images/Portfolios/Technologies/yii_icon.svg";
import wordpress_icon from "../../assets/images/Portfolios/Technologies/wordpress_icon.svg";
import shopify_icon from "../../assets/images/Portfolios/Technologies/shopify_icon.svg";
import illustrator_icon from "../../assets/images/Portfolios/Technologies/illustrator_icon.svg";
import bootstrap_icon from "../../assets/images/Portfolios/Technologies/bootstrap_icon.jpg";
import magento_icon from "../../assets/images/Portfolios/Technologies/magento_icon.svg";
import angular_icon from "../../assets/images/Portfolios/Technologies/angular_icon.svg";
import opencart_icon from "../../assets/images/Portfolios/Technologies/opencart_icon.svg";
import wooCommerce_icon from "../../assets/images/Portfolios/Technologies/wooCommerce_icon.svg";
import dotNetCode_icon from "../../assets/images/Portfolios/Technologies/dotNetCode_icon.png";
import mean_icon from "../../assets/images/Portfolios/Technologies/mean_icon.svg";
import mevn_icon from "../../assets/images/Portfolios/Technologies/mevn_icon.svg";
import aspDotNet_icon from "../../assets/images/Portfolios/Technologies/aspDotNet_icon.svg";
import python_icon from "../../assets/images/Portfolios/Technologies/python_icon.svg";
import drupal_icon from "../../assets/images/Portfolios/Technologies/drupal_icon.svg";
import react_icon from "../../assets/images/Portfolios/Technologies/react_icon.svg";
import redux_icon from "../../assets/images/Portfolios/Technologies/redux_icon.png";
import react_native_icon from "../../assets/images/Portfolios/Technologies/react_native_icon.png";
import java_icon from "../../assets/images/Portfolios/Technologies/java_icon.png";
import objective_c_icon from "../../assets/images/Portfolios/Technologies/objective_c_icon.png";
import swift_icon from "../../assets/images/Portfolios/Technologies/swift_icon.png";
// import Web_DevelopmentPortfolio from "../../assets/images/Portfolios/SliderImages/Web_Development.svg";
// import UI_UX_DesignPortfolio from "../../assets/images/Portfolios/SliderImages/UI_UX_Design.svg";
// import E_Commerce_SolutionsPortfolio from "../../assets/images/Portfolios/SliderImages/E_Commerce_Solutions.svg";
// import Application_DevelopmentPortfolio from "../../assets/images/Portfolios/SliderImages/Application_Development.svg";
// import All_Portfolios from "../../assets/images/Portfolios/SliderImages/All_Portfolios.svg";
// import Web_DevelopmentPortfolioColored from "../../assets/images/Portfolios/SliderImages/colored/Web_Development.svg";
// import UI_UX_DesignPortfolioColored from "../../assets/images/Portfolios/SliderImages/colored/UI_UX_Design.svg";
// import E_Commerce_SolutionsPortfolioColored from "../../assets/images/Portfolios/SliderImages/colored/E_Commerce_Solutions.svg";
// import Application_DevelopmentPortfolioColored from "../../assets/images/Portfolios/SliderImages/colored/Application_Development.svg";
import BannerDesign_small_logo from "../../assets/images/Portfolios/Web/BannerDesign_small_logo.png";
import LogoDesign_small_logo from "../../assets/images/Portfolios/Web/LogoDesign_small_logo.png";
import book_design_small_logo from "../../assets/images/Portfolios/Web/book_design_small_logo.png";
// import All_PortfoliosColored from "../../assets/images/Portfolios/SliderImages/colored/All_Portfolios.svg";
//import Loader from "../../assets/images/loader.gif";
import services_banner from "../../assets/images/services/SERVICE-BANNER.jpg";

import photoshop_icon from "../../assets/images/Portfolios/Technologies/photoshop.svg";

import selongselo_big from "../../assets/images/Portfolios/Web/selongselo.jpg";
import selongselo_laptop from "../../assets/images/Portfolios/Web/shop.selongselo.png";
import selongselo_small_logo from "../../assets/images/Portfolios/Web/selong_small.png";

import selongselo2_big from "../../assets/images/Portfolios/Web/shop.selongselo2.png";
import selongselo2_laptop from "../../assets/images/Portfolios/Web/selongselo.png";
import selongselo2_small_logo from "../../assets/images/Portfolios/Web/selong_small.png";

import digiturnal_big from "../../assets/images/Portfolios/Web/Digiturnal_big.jpg";
import digiturnal_laptop from "../../assets/images/Portfolios/Web/Digiturnal_laptop.png";
import digiturnal_small_logo from "../../assets/images/Portfolios/Web/digiturnal_small.png";

import wall_ready_big from "../../assets/images/Portfolios/Web/wall_ready_big.jpg";
import wall_ready_laptop from "../../assets/images/Portfolios/Web/wall_ready_laptop.jpg";

import family_big from "../../assets/images/Portfolios/Web/family_big.png";
import family_logo from "../../assets/images/Portfolios/Web/family_logo.png";

import reed_big from "../../assets/images/Portfolios/Web/reed_big.jpg";
import reed_logo from "../../assets/images/Portfolios/Web/reed_logo.png";

// Images End

// Custom Stylesheets Start
import "../../assets/css/portfolio_main.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
// import NumberCount from "../common/NumberCount";
const NumberCount = lazy(() => import("../common/NumberCount"));
// Get Common Components End

class portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Portfolios",
      bannerImg: services_banner,
      datalength: "",
      //loaddata: [],
      counter:1,
      hasMore: true,
      data: [
        {
          big_img: itrulyheal_big,
          small_img: itrulyheal_laptop,
          logo: itrulyheal_small_logo,
          title: "True Health App",
          heading: "CakePHP Development",
          content: "At TRULY HEAL, we seek to build a bridge between holistic and conventional medicine, based on trust, compassion, accountability, comprehensive knowledge and understanding. Our dream is to inspire, educate and provide you with the knowledge, tools and motivation to TRULY HEAL. ",
          technologies: [{ icon: php_icon, title: "PHP" }, { icon: cakePHP_icon, title: "Cake PHP" }],
          link: "https://trulyheal.com/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "php-development"
        },        
        // {
        //   big_img: LuxuryHolidays_big,
        //   small_img: LuxuryHolidays_laptop,
        //   logo: LuxuryHolidays_small_logo,
        //   title: "Luxury Holidays",
        //   heading: "Laravel Development",
        //   content: "All of us at Luxury Holidays are passionate about finding luxury holiday properties that are stylish and modern, whilst still being accessible to everyone's budget. We aim to please and will always do our best to accommodate our guests' individual needs.",
        //   technologies: [{ icon: php_icon, title: "PHP" }, { icon: laravel_icon, title: "Laravel" }],
        //   link: "https://www.luxuryholidays.com.au/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "php-development"
        // },
        // {
        //   big_img: icebox_big,
        //   small_img: icebox_laptop,
        //   logo: icebox_small_logo,
        //   title: "ANTZ UK | ICEBOX",
        //   heading: "Laravel Development",
        //   content: "In 2011, we set out to change the way businesses see social projects. Instead of viewing them as one-offs and an overhead, we wanted businesses to appreciate the real value of transforming lives. Over the years, we have achieved just that: developing a proven model that drives business growth while changing people's lives forever. Just as importantly, the strategies we create deliver results that are both targeted and measurable through this system",
        //   technologies: [{ icon: php_icon, title: "PHP" }, { icon: laravel_icon, title: "Laravel" }],
        //   link: "https://system.iceboxserver.co.uk/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "php-development"
        // },
        // {
        //   big_img: Smart_EHealth_big,
        //   small_img: Smart_EHealth_laptop,
        //   logo: Smart_EHealth_small_logo,
        //   title: "Smart eHealth",
        //   heading: "Wordpress Theme Development",
        //   content: "Smart-ehealth combines appointment scheduling, Electronic Health Records, billing, questionnaires, outcomes assessments, lab results, nutrient prescribing and ordering and many more features in a single, integrated suite.",
        //   technologies: [{ icon: wordpress_icon, title: "Wordpress" }],
        //   link: "https://www.smart-ehealth.com.au/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "php-development"
        // },
        // {
        //   big_img: sports_ohio_big,
        //   small_img: sports_ohio_laptop,
        //   logo: sports_ohio_small_logo,
        //   title: "Sports Ohio",
        //   heading: "Wordpress Theme Development",
        //   content: "It’s a 100-acre sports park that’s home to Soccer First (indoor & outdoor Soccer); Field Sports(indoor & outdoor Football, Baseball, Lacrosse, Field Hockey, Birthday Parties, Inflatables, Volleyball, Sand Volleyball and more); The Golf Center(year - round driving range, 9 - hole Par 3 course, and FootGolf) and PowerPlay (Go Karts, Mini Golf, Batting Cages and Birthday Parties).",
        //   technologies: [
        //     { icon: wordpress_icon, title: "Wordpress" },
        //     { icon: bootstrap_icon, title: "Bootstrap" }
        //   ],
        //   link: "http://sportsohio.org/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "php-development"
        // },
        {
          big_img: gladev_big,
          small_img: gladev_laptop,
          logo: gladev_small_logo,
          title: "GLADEV",
          heading: "Wordpress Theme Development",
          content:"GLADEV is a global network of professional firms providing management consulting and business strategy in partnership with clients and experts from the private, public, and not-for-profit sectors around the world to identify highest - value opportunities and unique solutions.",
          technologies: [
            { icon: wordpress_icon, title: "Wordpress" },
            { icon: bootstrap_icon, title: "Bootstrap" }
          ],
          link: "https://www.gladev.com/onepage",
          project_type: "laptop",
          parentId: "web-development",
          childId: "php-development"
        },
        {
          big_img: jawcon_big,
          small_img: jawcon_laptop,
          logo: jawcon_small_logo,
          title: "Jawcon",
          heading: "Wordpress Theme Development",
          content:"JAWCON Builders began as a residential construction company over 20 years ago.Today, we are a diversified commercial and residential construction company, with a track record of exceeding customer expectations in the delivery of high quality, cost- effective building and maintenance services.",
          technologies: [{ icon: wordpress_icon, title: "Wordpress" }],
          link: "https://www.jawcon.com.au/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "php-development"
        },
        {
          big_img: craftycanineclub_big,
          small_img: craftycanineclub_laptop,
          logo: craftycanineclub_small_logo,
          title: "Crafty Canine Club",
          heading: "Wordpress Theme Development",
          content:"We are a full-service training and boarding facility, specializing in strengthening the relationship between you and your dog. We offer an all- inclusive Club Boarding Program, a “no dog left behind” training policy, and a load of different training classes!",
          technologies: [{ icon: wordpress_icon, title: "Wordpress" }],
          link: "https://craftycanineclub.com",
          project_type: "laptop",
          parentId: "web-development",
          childId: "php-development"
        },
        {
          big_img: fl_counties_big,
          small_img: fl_counties_laptop,
          logo: FAC_small_logo,
          title: "FLORIDA'S COUNTIES",
          heading: "Drupal Theme Development",
          content:"The Florida Association of Counties helps counties effectively serve and represent Floridians by strengthening and preserving county home rule through advocacy, education and collaboration.",
          technologies: [
            { icon: drupal_icon, title: "Drupal" },
            { icon: bootstrap_icon, title: "Bootstrap" }
          ],
          link: "http://fl-counties.com/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "php-development"
        },
        {
          big_img: employee_mange_big,
          small_img: employee_mange_laptop,
          logo: employee_mange_small_logo,
          title: "Employee Management",
          heading: "Yii Development",
          content: "Visions designed friendly and easy-to-use task management system that anyone can start using right away.",
          technologies: [{ icon: php_icon, title: "PHP" }, { icon: yii_icon, title: "YII" }],
          link: "",
          project_type: "laptop",
          parentId: "web-development",
          childId: "php-development"
        },
        // {
        //   big_img: ego_big,
        //   small_img: ego_laptop,
        //   logo: ego_small_logo,
        //   title: "EGO",
        //   heading: "Magento Theme Development",
        //   content: "EGO is an online, trend-led sassy footwear brand representing a movement of stylish shoeaholics from around the globe.We provide bold, fierce styles for badass gals who like to stand out from the crowd, set trends and be one step ahead of the game.",
        //   technologies: [{ icon: magento_icon, title: "Magento" }],
        //   link: "https://egoshoes.com/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "php-development"
        // },
        {
          big_img: kitchenbrains_big,
          small_img: kitchenbrains_laptop,
          logo: kitchen_brains_small_logo,
          title: "Kitchen Brains",
          heading: "Drupal Theme Development",
          content:"Boost the performance of everyone, every day. Thousands of restaurants rely on our proven technology to prepare quality food and run their business. Our innovative controllers, timers, sensors and integrated software solutions work directly with your existing equipment and business operations to: Ensure food safety, Satisfy customers, Minimize waste, Deliver consistent product, Increase productivity and Maximize profits.",
          technologies: [
            { icon: drupal_icon, title: "Drupal" },
            { icon: bootstrap_icon, title: "Bootstrap" }
          ],
          link: "https://www.kitchenbrains.com/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "php-development"
        },
        {
          big_img: ocett_big,
          small_img: ocett_laptop,
          logo: ocett_small_logo,
          title: "OCETT",
          heading: "Drupal Theme Development",
          content:'Over the years, the Orange County Electrical Training Center has continually trained the best Electricians, Sound Installers and Intelligent Transportation System Installers in the industry without cost to the taxpayers.Students in our training programs "Earn While They Learn". Not only does this allow students to earn an income while in school, it also creates new tax revenue for the economy.',
          technologies: [
            { icon: drupal_icon, title: "Drupal" },
            { icon: bootstrap_icon, title: "Bootstrap" }
          ],
          link: "https://www.ocett.org/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "php-development"
        },
        // {
        //   big_img: Malena_big,
        //   small_img: Malena_laptop,
        //   logo: Malena_small_logo,
        //   title: "Malena",
        //   heading: "Mevn",
        //   content: "Admin portal design and developed from scratch. We integrated multiple MAPs and Chart. Multiple Reports was shown in desktop.",
        //   technologies: [{ icon: mevn_icon, title: "Mevn" }],
        //   link: "http://malena.az08.dev.exoip.com.ar/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "mevn-stack-development"
        // },
        // {
        //   big_img: Assignq_big,
        //   small_img: Assignq_laptop,
        //   logo: Assignq_small_logo,
        //   title: "AssignQ",
        //   heading: "Angular Development",
        //   content:"AssignQ’s simple philosophy is providing a valued service that allows companies to maximize recruiting efforts by leveraging one database for coverage expansion, price negotiation and advertising.",
        //   technologies: [
        //     { icon: angular_icon, title: "Angular" },
        //     { icon: dotNetCode_icon, title: ".NetCore" }
        //   ],
        //   link: "http://assignq.com/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "mean-stack-development"
        // },
        // {
        //   big_img: CRM_Web_Application_big,
        //   small_img: CRM_Web_Application_laptop,
        //   logo: CRM_Web_Application_small_logo,
        //   title: "CRM Web Apps",
        //   heading: "Python Development",
        //   content: "We created this project in Django rest framework and react. Application requires users to sign up. One signed up they can view the companies and project details added from Administration area.",
        //   technologies: [{ icon: python_icon, title: "Python" }],
        //   //link: "#",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "mern-stack-development"
        // },
        // {
        //   big_img: God_Connection_big,
        //   small_img: God_Connection_laptop,
        //   logo: God_Connection_small_logo,
        //   title: "God Connection",
        //   heading: "Mern",
        //   content:"A place where people can discover connecting with God is possible and real. A space where you can discover people on the same quest: to connect with him.",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: "http://www.godconnection.org/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "mern-stack-development"
        // },
        {
          big_img: deking_decks_big,
          small_img: deking_decks_laptop,
          logo: dekingdecks_small_logo,
          title: "Deking Decks",
          heading: "React Theme Development",
          content: "The Deking Decks company was established in 2003 by director David Rider.Servicing Brisbane, Gold Coast and the Sunshine Coast, Deking Decks has grown from strength to strength and prides itself on building strong and ongoing client relationships.",
          technologies: [
            { icon: react_icon, title: "React" },
            { icon: redux_icon, title: "Redux" }
          ],
          link: "https://www.dekingdecks.com.au/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "mern-stack-development"
        },
        // {
        //   big_img: Mens_Hair_Fitness_big,
        //   small_img: Mens_Hair_Fitness_laptop,
        //   logo: Mens_Hair_Fitness_small_logo,
        //   title: "Men's Hair Fitness",
        //   heading: "Mern",
        //   content: "Men’s Hair Fitness offer treatments to have healthy hairs, where you can read about their services and can buy the products online from the website. This website is custom designed for the client’s business and developed using MERN Stack technologies.",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: "https://www.menshairfitness.co.nz/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "mern-stack-development"
        // },
        {
          big_img: admin_panel_lulo_big,
          small_img: admin_panel_lulo_laptop,
          logo: admin_panel_lulo_small_logo,
          title: "Admin Panel",
          heading: "Mern",
          content: "We have been working on the Admin panel of multi-tenant services providing business where the Admin panel is developed for owners to manage their multi-tenants themselves. The platform is being developed using the MERN Stack.",
          technologies: [{ icon: react_icon, title: "React" }],
          //link: "https://www.dekingdecks.com.au/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "mern-stack-development"
        },
        // {
        //   big_img: Trueworkforce_big,
        //   small_img: Trueworkforce_laptop,
        //   logo: Trueworkforce_small_logo,
        //   title: "TruWorkForce",
        //   heading: "Angular Development",
        //   content: "TruWorkForce is a complete end to end Human Capital Management (HCM) SaaS solution developed for the global HR and staffing industries.",
        //   technologies: [
        //     { icon: angular_icon, title: "Angular" },
        //     { icon: java_icon, title: "Java" }
        //   ],
        //   link:
        //     "http://truworkforce-dev.c8gpaj8jge.us-west-1.elasticbeanstalk.com/#/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "mean-stack-development"
        // },
        // {
        //   big_img: Naudje_big,
        //   small_img: Naudje_laptop,
        //   logo: Naudje_small_logo,
        //   title: "Naudje",
        //   heading: "Mean",
        //   content: "NaudJe is a platform for On-Demand service booking, where users can look for various household or professional help and can book a person for the same online. Naudje is developed using MEAN Stack development.",
        //   technologies: [{ icon: mean_icon, title: "Mean" }],
        //   link: "https://www.naudje.nl/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "mean-stack-development"
        // },
        // {
        //   big_img: ExpressLogistics_big,
        //   small_img: ExpressLogistics_laptop,
        //   logo: ExpressLogistics_small_logo,
        //   title: "Express Logistics",
        //   heading: "ASP.Net Development",
        //   content: "Express logistics is a logistics business website that provides information about their business, services they offer and their team. The application is custom designed by us and is further developed in ASP.Net",
        //   technologies: [{ icon: aspDotNet_icon, title: "ASP.Net" }],
        //   //link: "#",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "asp-net-development"
        // },
        // {
        //   big_img: ReachFast_big,
        //   small_img: ReachFast_laptop,
        //   logo: ReachFast_small_logo,
        //   title: "Reach Fast",
        //   heading: "ASP.Net Development",
        //   content: "Reach Fast is a Hospital Contact Management System where users can buy a subscription to use this web platform and avail services to better manage their hospitals. The Application is developed using ASP.Net technology.",
        //   technologies: [{ icon: aspDotNet_icon, title: "ASP.Net" }],
        //   link: "http://www.reachfastmd.com/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "asp-net-development"
        // },        
        {
          big_img: CRM_Web_Application_big,
          small_img: CRM_Web_Application_laptop,
          logo: CRM_Web_Application_small_logo,
          title: "CRM Web Apps",
          heading: "Python Development",
          content: "We created this project in Django rest framework and react. Application requires users to sign up. One signed up they can view the companies and project details added from Administration area.",
          technologies: [{ icon: python_icon, title: "Python" }],
          link: "http://54.88.53.204",
          project_type: "laptop",
          parentId: "web-development",
          childId: "python-development"
        },
        {
          big_img: WLI_WebApp_big,
          small_img: WLI_WebApp_laptop,
          logo: WLI_WebApp_small_logo,
          title: "WLI Web App",
          heading: "Python Development",
          content: "Website is build in Django Framework. We have used Pandas library to apply operations on CSV File. Website provides feature to upload data in CSV Format.",
          technologies: [{ icon: python_icon, title: "Python" }],
          //link: "#",
          project_type: "laptop",
          parentId: "web-development",
          childId: "python-development"
        },
        // {
        //   big_img: Botonique_big,
        //   small_img: Botonique_laptop,
        //   logo: Botonique_small_logo,
        //   title: "Botonique",
        //   heading: "Python Development",
        //   content: "Botonique is a cosmetic brand website. Customers can buy products online from the website. The website is custom designed as per the product outlook and is developed using Python.",
        //   technologies: [{ icon: python_icon, title: "Python" }],
        //   link: "http://botonique.visionsdemo.com/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "python-development"
        // },
        {
          big_img: Carinfo_big,
          small_img: Carinfo_laptop,
          logo: Carinfo_small_logo,
          title: "Car Info",
          heading: "Python Development",
          content: "Get all of the information you need when buying a car by using the UK's largest database of car service history and information to help you out.",
          technologies: [{ icon: python_icon, title: "Python" }],
          link: "http://carinfo.uk/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "python-development"
        },
        // {
        //   big_img: digiturnal_big,
        //   small_img: digiturnal_laptop,
        //   logo: digiturnal_small_logo,
        //   title: "Digiturnal",
        //   heading: "Website Design and Development",
        //   content: "Being a #1 web design and development company in Qatar,  we provide cutting-edge custom software, app development, website design, and development service combined with new and innovative solution. Our proficient team is well acquainted to create a bespoke web design, custom software, mobile application, and develop a website that echoes your central idea and cater your every specification.",
        //   technologies: [
        //     { icon: wordpress_icon, title: "Wordpress" },
        //     { icon: photoshop_icon, title: "Photoshop" }
        //   ],
        //   link: "https://digiturnal.com/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "website-redesign"
        // },
        // {
        //   big_img: selongselo_big,
        //   small_img: selongselo_laptop,
        //   logo: selongselo_small_logo,
        //   title: "Selongselo",
        //   heading: "Website Redesign",
        //   content: "Your one stop shop for pre-booking all kinds of activities and groceries for a hassle-free stay with us. Organize your pantry and fully stock your fridge with an extensive variety of organic and sustainably sourced, local produce before you arrive!",
        //   technologies: [
        //     { icon: wordpress_icon, title: "Wordpress" }
        //   ],
        //   link: "https://shop.selongselo.com/",
        //   project_type: "laptop",
        //   parentId: "web-development",
        //   childId: "website-redesign"
        // },
        {
          big_img: selongselo2_big,
          small_img: selongselo2_laptop,
          logo: selongselo2_small_logo,
          title: "Selongselo",
          heading: "Website Redesign",
          content: "Perched atop the picturesque hills overlooking stunning Selong Belanak Beach in southern Lombok, Selong Selo Resort and Residences is paradise untouched. With 27 stunning pool villas, all enjoying panoramic views of rice fields and the bay beyond, this is the only go-to destination resort one needs to consider.",
          technologies: [
            { icon: wordpress_icon, title: "Wordpress" }
          ],
          link: "https://www.selongselo.com/",
          project_type: "laptop",
          parentId: "web-development",
          childId: "website-redesign"
        },
        {
          big_img: gladev_big,
          small_img: gladev_mobile,
          logo: gladev_small_logo,
          title: "GLADEV",
          heading: "Responsive Theme Development",
          content: "GLADEV is a global network of professional firms providing management consulting and business strategy in partnership with clients and experts from the private, public, and not-for-profit sectors around the world to identify highest- value opportunities and unique solutions.",
          technologies: [
            { icon: wordpress_icon, title: "Wordpress" },
            { icon: bootstrap_icon, title: "Bootstrap" }
          ],
          link: "https://www.gladev.com/onepage",
          project_type: "mobile",
          parentId: "uiux-design",
          childId: "responsive-web-design"
        },
        {
          big_img: jawcon_big,
          small_img: jawcon_mobile,
          logo: jawcon_small_logo,
          title: "Jawcon",
          heading: "Responsive Theme Development",
          content: "JAWCON Builders began as a residential construction company over 20 years ago.Today, we are a diversified commercial and residential construction company, with a track record of exceeding customer expectations in the delivery of high quality, cost- effective building and maintenance services.",
          technologies: [{ icon: wordpress_icon, title: "Wordpress" }],
          link: "https://www.jawcon.com.au/",
          project_type: "mobile",
          parentId: "uiux-design",
          childId: "responsive-web-design"
        },
        {
          big_img: LeanIPD_big,
          small_img: LeanIPD_laptop,
          logo: LeanIPD_small_logo,
          title: "Lean IPD",
          heading: "Website Redesign",
          content: "Lean Integrated Project Delivery combines lean management principles with a collaborative, aligned team and contract structure to improve the outcomes of construction projects. When Lean Integrated Project Delivery fails, it is usually because of incorrect assumptions, poor planning, and the owner not recognizing their own crucial role in leading the project.",
          technologies: [{ icon: wordpress_icon, title: "Wordpress" }],
          link: "https://leanipd.com/",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "website-redesign"
        },
        // {
        //   big_img: RogueFitness_big,
        //   small_img: RogueFitness_mobile,
        //   logo: RogueFitness_small_logo,
        //   title: "Rogue Fitness",
        //   heading: "Responsive Theme Development",
        //   content:"The Industrial Revolution 2.0 is something we take a tremendous amount of pride in at Rogue. Our mission early on was as much philosophical as it was anything else. Since day one, we have worked to source locally whenever possible. Over the past few decades, many of our local communities have lost their factories and places where men and women took pride in what they made every day. Many of these communities have been left with empty Main streets and crisis of various sorts. ",
        //   technologies: [{ icon: magento_icon, title: "Magento" }],
        //   link: "https://www.roguefitness.com/",
        //   project_type: "mobile",
        //   parentId: "uiux-design",
        //   childId: "responsive-web-design"
        // },
        {
          big_img: District_Marketing_big,
          small_img: District_Marketing_mobile,
          logo: District_Marketing_small_logo,
          title: "District Marketing",
          heading: "Responsive Theme Development",
          content:"In District Marketing, not only we target adding value to your company and create an overarching image that represents your firm in a positive light, but we have our own strategy which makes us stand out than others in our field. We ensure about donating a percentage of our profit with every deal we make to charities locally and globally. As Charities change the lives of people in need every day with even the smallest of donations making a large impact in a community.",
          technologies: [{ icon: bootstrap_icon, title: "Bootstrap" }],
          link: "http://www.districtmarketing.ae/",
          project_type: "mobile",
          parentId: "uiux-design",
          childId: "responsive-web-design"
        },
        // {
        //   big_img: inTheNumber_big,
        //   small_img: inTheNumber_mobile,
        //   logo: inTheNumber_small_logo,
        //   title: "InTheNumber",
        //   heading: "Responsive Theme Development",
        //   content:"ITN’s commitment to supporting meaningful change begins with Promise51 – our pledge to invest 51% of net profits from the sale of branded apparel in people and organizations working within the areas of education, disaster relief, health and human rights.",
        //   technologies: [
        //     { icon: wordpress_icon, title: "Wordpress" },
        //     { icon: wooCommerce_icon, title: "WooCommerce" }
        //   ],
        //   link: "https://inthenumber.com/",
        //   project_type: "mobile",
        //   parentId: "uiux-design",
        //   childId: "responsive-web-design"
        // },
        // {
        //   big_img: ECO_Tile_big,
        //   small_img: ECO_Tile_mobile,
        //   logo: ECO_Tile_small_logo,
        //   title: "ecotile Flooring",
        //   heading: "Responsive Theme Development",
        //   content: "Ecotile is a UK made industrial flooring solution that can be fitted without causing disruption to your business; a cost effective and low maintenance interlocking floor tile that can solve several business challenges and technical issues.",
        //   technologies: [
        //     { icon: wordpress_icon, title: "Wordpress" },
        //     { icon: bootstrap_icon, title: "Bootstrap" }
        //   ],
        //   link: "https://www.ecotileflooring.com/",
        //   project_type: "mobile",
        //   parentId: "uiux-design",
        //   childId: "responsive-web-design"
        // },
        {
          big_img: kitchenbrains_big,
          small_img: kitchenbrains_mobile,
          logo: kitchen_brains_small_logo,
          title: "Kitchen Brains",
          heading: "Responsive Theme Development",
          content: "Boost the performance of everyone, every day. Thousands of restaurants rely on our proven technology to prepare quality food and run their business. Our innovative controllers, timers, sensors and integrated software solutions work directly with your existing equipment and business operations to: Ensure food safety, Satisfy customers, Minimize waste, Deliver consistent product, Increase productivity and Maximize profits.",
          technologies: [
            { icon: drupal_icon, title: "Drupal" },
            { icon: bootstrap_icon, title: "Bootstrap" }
          ],
          link: "https://www.kitchenbrains.com/",
          project_type: "mobile",
          parentId: "uiux-design",
          childId: "responsive-web-design"
        },
        // {
        //   big_img: MyList_big,
        //   small_img: mylist_mobile,
        //   logo: mylist_small_logo,
        //   title: "MyList",
        //   heading: "Responsive Theme Development",
        //   content: "When we launched in 2012 there were around only few stores on board, MyList has now partnered with over 100 retailers and service providers in the UAE and Egypt becoming the GCC's leading online gifting platform, designed for customers who want the greatest choice, flexibility and personalized service.",
        //   technologies: [
        //     { icon: magento_icon, title: "Magento" },
        //     { icon: bootstrap_icon, title: "Bootstrap" }
        //   ],
        //   link: "https://mylist.ae/",
        //   project_type: "mobile",
        //   parentId: "uiux-design",
        //   childId: "responsive-web-design"
        // },
        {
          big_img: alias_big,
          small_img: alias_logo,
          logo: LogoDesign_small_logo,
          title: "Alias Bakery & Catering",
          heading: "Logo Design",
          content: "Alia's is a bakery business based in United States offering catering services as well. Logo was custom designed for client using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop logo",
          parentId: "uiux-design",
          childId: "logo-designing"
        },
        // {
        //   big_img: Gorilla_big,
        //   small_img: Gorilla_logo,
        //   logo: LogoDesign_small_logo,
        //   title: "Gorilla VPN",
        //   heading: "Logo Design",
        //   content:"GorillaVPN based in San Francisco, offers safe and secure VPN services. The logo was designed to better relate its name and branding guidelines of its website https://www.gorillavpn.net/. The logo is custom designed using Adobe illustrator.",
        //   technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
        //   link: "",
        //   project_type: "laptop logo",
        //   parentId: "uiux-design",
        //   childId: "logo-designing"
        // },
        {
          big_img: Trainer_big,
          small_img: Trainer_logo,
          logo: LogoDesign_small_logo,
          title: "TrainersForYou",
          heading: "Logo Design",
          content: "TrainersForYou offer services to book personal fitness trainer as per your location. The logo was designed from scratch after understanding client's requirements and accordingly the website was designed. The logo is designed using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop logo",
          parentId: "uiux-design",
          childId: "logo-designing"
        },
        {
          big_img: WESTORN_big,
          small_img: WESTORN_logo,
          logo: LogoDesign_small_logo,
          title: "Western Store",
          heading: "Logo Design",
          content: "Western Store was a standard logo designed for a client who further wanted to sell it with customizing options. The logo was designed as per client's specifications using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop logo",
          parentId: "uiux-design",
          childId: "logo-designing"
        },     
        {
          big_img: family_big,
          small_img: family_logo,
          logo: LogoDesign_small_logo,
          title: "Family",
          heading: "Logo Design",
          content: "",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop logo",
          parentId: "uiux-design",
          childId: "logo-designing"
        },
        {
          big_img: reed_big,
          small_img: reed_logo,
          logo: LogoDesign_small_logo,
          title: "Reed",
          heading: "Logo Design",
          content: "",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop logo",
          parentId: "uiux-design",
          childId: "logo-designing"
        },   
        {
          big_img: Echo_Sanitise_big,
          small_img: Echo_Sanitise_laptop,
          logo: Brochure_Design_small_logo,
          title: "Echo Sanitise",
          heading: "Brochure Design",
          content: "Echo Sanitise Ltd is based in England and offer specialised cleaning services. The brochure was designed to better list out their services in effective manner. Brochure was designed from scratch based on client's business nature using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "brochure-designing"
        },
        {
          big_img: wall_ready_big,
          small_img: wall_ready_laptop,
          logo: BannerDesign_small_logo,
          title: "Wall Ready Canvas",
          heading: "Banner Design",
          content: "We have developed banners for the client’s various social media platforms as per his website design and colors. Banners are designed using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "banner-designing"
        },
        {
          big_img: Armando_law_big,
          small_img: Armando_law_laptop,
          logo: BannerDesign_small_logo,
          title: "Armando Personal Injury Law",
          heading: "Banner Design",
          content: "We have developed banners for the client’s various social media platforms as per his website design and colors. Banners are designed using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "banner-designing"
        },
        {
          big_img: Christmas_big,
          small_img: Christmas_laptop,
          logo: BannerDesign_small_logo,
          title: "Christmas Banner",
          heading: "Banner Design",
          content: "We have developed these Christmas and New Year banners to great our client on the occasion. Banners are designed using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "banner-designing"
        },
        {
          big_img: Specimen_big,
          small_img: Specimen_laptop,
          logo: book_design_small_logo,
          title: "Specimen",
          heading: "Book Cover Design",
          content: "Specimen is the book for which we designed book cover as per the book content and client's specifications. The book cover was designed in Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "print-media-design"
        },
        {
          big_img: car_big,
          small_img: car_laptop,
          logo: car_small_logo,
          title: "TryCall Car",
          heading: "Car Wrap Design",
          content: "Here we designed the car wrap design for client’s Cab business ‘. The design was done using a car model provides by the client and his business details. It is designed using Adobe photoshop tool.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          // link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "print-media-design"
        },
        {
          big_img: CAT_Card_big,
          small_img: CAT_Card_laptop,
          logo: CAT_Card_small_logo,
          title: "CAT Building Green Living",
          heading: "Visiting Card Design",
          content: "We have designed a business card and logo for our client’s property business. The card and logo are customs designed from scratch using Adobe Photoshop.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          // link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "print-media-design"
        },
        {
          big_img: CoffeeShop_big,
          small_img: CoffeeShop_laptop,
          logo: CoffeeShop_small_logo,
          title: "Coffee Shop",
          heading: "Card Design",
          content: "We designed a rate card for a Coffee Shop restaurant specialized for coffee only. It is designed using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          // link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "print-media-design"
        },
        {
          big_img: Travel_big,
          small_img: Travel_laptop,
          logo: Brochure_Design_small_logo,
          title: "Travel Destination",
          heading: "Brochure Design",
          content:"Travel Destination is a travel company based in Dominian Republic for which we designed a brochure to better list out their services and packages. The brochure was designed from scratch using Adobe illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "brochure-designing"
        },
        {
          big_img: eatmenu_big,
          small_img: eatmenu_laptop,
          logo: Brochure_Design_small_logo,
          title: "EatMenu",
          heading: "Brochure Design",
          content: "EatMenu is a restaurant based in India for which we have designed the Food and drinks menu, totally customized as per client's specification and restaurant vibes. The Menu was designed using Adobe Illustrator.",
          technologies: [{ icon: illustrator_icon, title: "Illustrator" }],
          link: "",
          project_type: "laptop",
          parentId: "uiux-design",
          childId: "brochure-designing"
        },
        // {
        //   big_img: ego_big,
        //   small_img: ego_laptop,
        //   logo: ego_small_logo,
        //   title: "EGO",
        //   heading: "Magento Theme Development",
        //   content: "EGO is an online, trend-led sassy footwear brand representing a movement of stylish shoeaholics from around the globe.We provide bold, fierce styles for badass gals who like to stand out from the crowd, set trends and be one step ahead of the game.",
        //   technologies: [{ icon: magento_icon, title: "Magento" }],
        //   link: "https://egoshoes.com/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "magento-development"
        // },
        // {
        //   big_img: int_enviroguard_big,
        //   small_img: int_enviroguard_laptop,
        //   logo: int_enviroguard_small_logo,
        //   title: "International Enviroguard",
        //   heading: "Magento Theme Development",
        //   content:"We innovate, design and manufacture disposable protective coveralls garments, floor protection and other disposable PPE coveralls that safeguard your people, productivity and operations.",
        //   technologies: [
        //     { icon: magento_icon, title: "Magento" },
        //     { icon: bootstrap_icon, title: "Bootstrap" }
        //   ],
        //   link: "https://int-enviroguard.com/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "magento-development"
        // },
        // {
        //   big_img: MyList_big,
        //   small_img: MyList_laptop,
        //   logo: mylist_small_logo,
        //   title: "MyList",
        //   heading: "Magento Theme Development",
        //   content: "When we launched in 2012 there were around only few stores on board, MyList has now partnered with over 100 retailers and service providers in the UAE and Egypt becoming the GCC's leading online gifting platform, designed for customers who want the greatest choice, flexibility and personalized service.",
        //   technologies: [
        //     { icon: magento_icon, title: "Magento" },
        //     { icon: bootstrap_icon, title: "Bootstrap" }
        //   ],
        //   link: "https://mylist.ae/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "magento-development"
        // },
        // {
        //   big_img: premierglow_big,
        //   small_img: premierglow_laptop,
        //   logo: premierglow_small_logo,
        //   title: "Premier Glow",
        //   heading: "Magento Theme Development",
        //   content:"Premier Glow was founded in September of 2001, by Jeremy Thompson & Jessica Thompson two college students attending the University of Memphis. The goal for Premier Glow is to revolutionize the way novelty companies operate by providing friendly customer service and fast shipping with no strings attached.",
        //   technologies: [
        //     { icon: magento_icon, title: "Magento" },
        //     { icon: bootstrap_icon, title: "Bootstrap" }
        //   ],
        //   link: "https://www.premierglow.com/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "magento-development"
        // },
        // {
        //   big_img: shop2party_big,
        //   small_img: shop2party_laptop,
        //   logo: shop2party_small_logo,
        //   title: "Shop2Party",
        //   heading: "Magento Theme Development",
        //   content:"Shop2party is a portal that helps you have an easy and enjoyable experience when making your party.To this end, it relies on its expertise in digital technology to indicate and create buying interactions with the best suppliers in the market.",
        //   technologies: [
        //     { icon: magento_icon, title: "Magento" },
        //     { icon: bootstrap_icon, title: "Bootstrap" }
        //   ],
        //   link: "http://shop2party.com/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "magento-development"
        // },
        // {
        //   big_img: singapore_big,
        //   small_img: singapore_laptop,
        //   logo: singapore_small_logo,
        //   title: "SingaporeWeddingHub",
        //   heading: "Opencart Theme Development",
        //   content:"We aspire to help every grooms and brides to-be ease and save time for their pre- wedding works through our all -in -one wedding preparation platform.",
        //   technologies: [
        //     { icon: opencart_icon, title: "Opencart" },
        //     { icon: bootstrap_icon, title: "Bootstrap" }
        //   ],
        //   link: "https://singaporeweddinghub.com/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "open-cart-development"
        // },
        // {
        //   big_img: papelStore_big,
        //   small_img: papelStore_laptop,
        //   logo: papelStore_small_logo,
        //   title: "PapelStore",
        //   heading: "Magento Theme Development",
        //   content:"PAPELSTORE was born from the idea of ​​creating a unique shopping experience, offering the best quality in our products and personalized attention. We work on what we like and take care of every detail because… your satisfaction is our reward.",
        //   technologies: [{ icon: magento_icon, title: "Magento" }],
        //   link: "https://www.papelstore.es/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "magento-development"
        // },
        // {
        //   big_img: storeMore_big,
        //   small_img: storeMore_laptop,
        //   logo: storeMore_small_logo,
        //   title: "Store More",
        //   heading: "WooCommerce Theme Development",
        //   content: "Please browse our wide range of Shelving, Pallet Racking, Longspan, Bin Racks, Parts Bins, Slatwall, Tilt Bins and much more. These products have proved to be convenient for both DIY and business owners.",
        //   technologies: [{ icon: wordpress_icon, title: "Wordpress" }, { icon: wooCommerce_icon, title: "WooCommerce" }],
        //   link: "https://storemore.ie/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "woocommerce-development"
        // },
        // {
        //   big_img: itsCooler_big,
        //   small_img: itsCooler_laptop,
        //   logo: itsCooler_small_logo,
        //   title: "ITS COOLER",
        //   heading: "WooCommerce Theme Development",
        //   content: "iTS COOLER is a luxury online department store bringing the Thai and Expat communities only quality luxury brands from Thailand, Asia, the UK, France, Spain, Germany, Italy and the USA. We offer consumers – via our Android and IOS App – the widest selection available, without the hassles of cross border payments, language barriers or delivery issues. iTS COOLER removes all the stress and worry of buying online and from abroad.",
        //   technologies: [{ icon: wordpress_icon, title: "Wordpress" }, { icon: wooCommerce_icon, title: "WooCommerce" }],
        //   link: "https://www.itscooler.asia/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "woocommerce-development"
        // },
        // {
        //   big_img: m4mattress_big,
        //   small_img: m4mattress_laptop,
        //   logo: m4mattress_small_logo,
        //   title: "m4mattress",
        //   heading: "WooCommerce Theme Development",
        //   content: "We manufacture and sell luxury Memory foam, Latex and Hybrid mattresses. Browse the range to choose a mattress that suits you. Pure luxury at affordable price.",
        //   technologies: [{ icon: wordpress_icon, title: "Wordpress" }, { icon: wooCommerce_icon, title: "WooCommerce" }],
        //   link: "https://www.m4mattress.co.uk/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "woocommerce-development"
        // },
        {
          big_img: marbelTops_big,
          small_img: marbelTops_laptop,
          logo: marbelTops_small_logo,
          title: "Marbel Tops",
          heading: "WooCommerce Theme Development",
          content: "Based in Chertsey, in a delightful part of Surrey, The Marble Tops Workshop is recognised as one of the leading fabricators of solid stone surfaces in the UK. We offer an inclusive service in the supply, fabrication and installation of all natural and engineered stones to both the consumer and contract markets. ",
          technologies: [{ icon: wordpress_icon, title: "Wordpress" }, { icon: wooCommerce_icon, title: "WooCommerce" }],
          link: "https://www.marbletops.co.uk/",
          project_type: "laptop",
          parentId: "ecommerce-solutions",
          childId: "woocommerce-development"
        },
        // {
        //   big_img: obliphica_big,
        //   small_img: obliphica_laptop,
        //   logo: obliphica_small_logo,
        //   title: "Obliphica",
        //   heading: "Shopify Theme Development",
        //   content: "Distributed via selected salons, spas and prestige beauty retailers, Obliphica Professional® maximizes the therapeutic properties of Seaberry Oil to instantly rejuvenate and transform the hair and scalp. Obliphica Professional® is a pioneer in transformative Seaberry hair care, which maximizes nature’s most coveted health and beauty ingredient through modern extraction techniques and crafted formulas.",
        //   technologies: [{ icon: shopify_icon, title: "Shopify" }],
        //   link: "https://obliphica.com/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "shopify-development"
        // },
        {
          big_img: orchidsluxhome_big,
          small_img: orchidsluxhome_laptop,
          logo: orchidsluxhome_small_logo,
          title: "Orchids Luxhome",
          heading: "Shopify Theme Development",
          content: "At Orchids Lux Home we have gathered talented designers and lifestyle experts to create a timeless collection of designs that add luxury, comfort and style to the home. Our linens are Contemporary and Modern in design and are made using Hand Embroidery, Appliqué and Quilting.",
          technologies: [{ icon: shopify_icon, title: "Shopify" }],
          link: "https://www.orchidsluxhome.com/",
          project_type: "laptop",
          parentId: "ecommerce-solutions",
          childId: "shopify-development"
        },
        // {
        //   big_img: wildTEA_big,
        //   small_img: wildTEA_laptop,
        //   logo: wildTEA_small_logo,
        //   title: "Wild Tea",
        //   heading: "Shopify Theme Development",
        //   content: "Shana Zhang is the founder of the International Tea Academy and in-house tea artisan of Wild Tea Qi. She has a deep understanding of the tea industry; her tea books are currently published on Amazon, and she is working on new tea books so she can continue to share her unique perspective of the tea industry from the Chinese side of things.",
        //   technologies: [{ icon: shopify_icon, title: "Shopify" }],
        //   link: "https://wildteaqi.com/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "shopify-development"
        // },
        // {
        //   big_img: BBI_Flags_big,
        //   small_img: BBI_Flags_laptop,
        //   logo: BBI_Flags_small_logo,
        //   title: "BBI Flags",
        //   heading: "Shopify Theme Development",
        //   content: "At bbi Flags we are committed to bringing you the highest quality flags that are made in the United States. We can proudly say all the materials and labor used in the construction of these flags are made in the U.S.A.",
        //   technologies: [{ icon: shopify_icon, title: "Shopify" }],
        //   link: "https://bbiflags.com/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "shopify-development"
        // },
        // {
        //   big_img: lightSounds_big,
        //   small_img: lightSounds_laptop,
        //   logo: lightSounds_small_logo,
        //   title: "LightSounds",
        //   heading: "Opencart Theme Development",
        //   content: "Lightsounds has been servicing the Entertainment industry for over 20 years offering reliable and affordable lighting and audio solutions for a large range of event types.",
        //   technologies: [{ icon: opencart_icon, title: "Opencart" }],
        //   link: "https://lightsounds.com.au/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "open-cart-development"
        // },
        // {
        //   big_img: urbantheka_big,
        //   small_img: urbantheka_laptop,
        //   logo: urbantheka_small_logo,
        //   title: "UrbanTheka",
        //   heading: "Opencart Theme Development",
        //   content:"One Quirky place to get Punjabi feel in our Products like Punjabi Tshirts, Bar Accessories, quirky gift packaging etc.",
        //   technologies: [{ icon: opencart_icon, title: "Opencart" }],
        //   link: "https://www.urbantheka.in/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "open-cart-development"
        // },
        // {
        //   big_img: jsTech_big,
        //   small_img: jsTech_laptop,
        //   logo: jsTech_small_logo,
        //   title: "JS Tech",
        //   heading: "Opencart Theme Development",
        //   content: "JS Tech, is a Privately owned company in Australia under JS Online Pty ltd (ABN 8815977770). Established in year 2009, as a wholesale supplier on Replacement parts & Accessories in Mobile Phones,Tablet, Game Console & Laptops. Our main goal is to provide quality replacement parts and accessories at affordable prices. For the last 10 years we have guaranteed secure shopping, super fast delivery, easy returns and best after sales service with in locally and internationally.",
        //   technologies: [{ icon: opencart_icon, title: "Opencart" }],
        //   link: "https://jstech.com.au/",
        //   project_type: "laptop",
        //   parentId: "ecommerce-solutions",
        //   childId: "open-cart-development"
        // },
        // {
        //   big_img: ugame_big,
        //   small_img: ugame_mobile,
        //   logo: ugame_small_logo,
        //   title: "UGAME",
        //   heading: "Objective C App",
        //   content:"UGame is the only app uniquely focused on facilitating connections and communication among event- goers before and en route to events, helping people make real connections in real life.",
        //   technologies: [{ icon: objective_c_icon, title: "Objective C" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/st/app/ugame/id1186722645",
        //     androidBtnLink:"https://play.google.com/store/apps/details?id=com.grappetite.ugame&hl=en"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "common-app-development"
        // },
        //  {
        //   big_img: wltg_big,
        //   small_img: wltg_mobile,
        //   logo: wltg_small_logo,
        //   title: "Would Love To Go",
        //   heading: "Objective C App",
        //   content: "Make new friends doing the things you love; whether that's catching a movie, going to the theatre, bowling, visiting an art gallery, or simply meeting up for a coffee and a chat.",
        //   technologies: [{ icon: objective_c_icon, title: "Objective C" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/us/app/would-love-to-go/id1100217014?ls=1",
        //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.wltg.app&hl=en"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "common-app-development"
        // },
        // {
        //   big_img: rolela_big,
        //   small_img: rolela_mobile,
        //   logo: rolela_small_logo,
        //   title: "Rolela",
        //   heading: "Swift App",
        //   content: "Are you a supermarket, restaurant or shop and you want to sell your expiring food items? Download, register and sell your expiring food items on Rolela.",
        //   technologies: [{ icon: swift_icon, title: "Swift" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/us/app/rolela/id1439787970?ls=1",
        //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.rolela&hl=en"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "common-app-development"
        // }, {
        //   big_img: Pill_Identification_big,
        //   small_img: Pill_Identification_mobile,
        //   logo: Pill_Identification_small_logo,
        //   title: "Pill Identification",
        //   heading: "Objective C App",
        //   content: "Pill Identifier App for Android and tablets provides access to the PILLID.com database in an easy to use Android App.",
        //   technologies: [{ icon: objective_c_icon, title: "Objective C" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/us/app/pill-identification/id1046763249",
        //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.pillidapp"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "common-app-development"
        // },
        {
          big_img: epic_big,
          small_img: epic_mobile,
          logo: epic_small_logo,
          title: "EpicPlace",
          heading: "Objective C App",
          content: "EpicPlace is a new fast way to connect with family and friends to share your most recent photos and videos and life experiences. Have fun with memes, Gifs, and viral featured videos...Browse and rate the best videos, photos, and internet content.",
          technologies: [
            { icon: objective_c_icon, title: "Objective C" },
            { icon: java_icon, title: "Java" }
          ],
          link: {
            iOSBtnLink: "https://apps.apple.com/us/app/epicplace/id1028804394",
            // androidBtnLink: "https://play.google.com/store/apps/details?id=com.leif.style.yourtalentnew&hl=en_IN"
          },
          project_type: "mobile app",
          parentId: "application-development",
          childId: "common-app-development"
        },
        {
          big_img: ugame_big,
          small_img: ugame_mobile,
          logo: ugame_small_logo,
          title: "UGAME",
          heading: "Objective C App",
          content:"UGame is the only app uniquely focused on facilitating connections and communication among event- goers before and en route to events, helping people make real connections in real life.",
          technologies: [{ icon: objective_c_icon, title: "Objective C" }],
          link: {
            iOSBtnLink: "",
            androidBtnLink:"https://play.google.com/store/apps/details?id=com.grappetite.ugame&hl=en"
          },
          project_type: "mobile app",
          parentId: "application-development",
          childId: "android-app-development",
          exclude: "yes"
        },        
        {
          big_img: wltg_big,
          small_img: wltg_mobile,
          logo: wltg_small_logo,
          title: "Would Love To Go",
          heading: "Objective C App",
          content:"Make new friends doing the things you love; whether that's catching a movie, going to the theatre, bowling, visiting an art gallery, or simply meeting up for a coffee and a chat.",
          technologies: [{ icon: objective_c_icon, title: "Objective C" }],
          link: {
            iOSBtnLink: "",
            androidBtnLink: "https://play.google.com/store/apps/details?id=com.wltg.app&hl=en"
          },
          project_type: "mobile app",
          parentId: "application-development",
          childId: "android-app-development",
          exclude: "yes"
        },
        // {
        //   big_img: rolela_big,
        //   small_img: rolela_mobile,
        //   logo: rolela_small_logo,
        //   title: "Rolela",
        //   heading: "Swift App",
        //   content:"Are you a supermarket, restaurant or shop and you want to sell your expiring food items? Download, register and sell your expiring food items on Rolela.",
        //   technologies: [{ icon: swift_icon, title: "Swift" }],
        //   link: {
        //     iOSBtnLink: "",
        //     androidBtnLink:"https://play.google.com/store/apps/details?id=com.app.rolela&hl=en"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "android-app-development",
        //   exclude: "yes"
        // },
        {
          big_img: prime_big,
          small_img: prime_mobile,
          logo: prime_small_logo,
          title: "PriMe",
          heading: "Swift App",
          content:"PriMe App provides a set of simple research-proven tools to reduce anxiety and stress and build psychological resilience and well- being.As a part of your daily use of PriMe, you will be asked to fill out questionnaires assessing your stress and insecurity levels, and play various mini - games that would help relax and increase your sense of relational security.The app will also offer you different real - world tasks, which will help you reconnect with your authentic self and support network.",
          technologies: [{ icon: swift_icon, title: "Swift" }],
          link: {
            iOSBtnLink: "https://apps.apple.com/us/app/prime-psychological-security/id1477446447?ls=1",
            androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.priMe"
          },
          project_type: "mobile app",
          parentId: "application-development",
          childId: "android-app-development"
        },
        {
          big_img: Pill_Identification_big,
          small_img: Pill_Identification_mobile,
          logo: Pill_Identification_small_logo,
          title: "Pill Identification",
          heading: "Objective C App",
          content:"Pill Identifier App for Android and tablets provides access to the PILLID.com database in an easy to use Android App.",
          technologies: [{ icon: objective_c_icon, title: "Objective C" }],
          link: {
            iOSBtnLink: "",
            androidBtnLink:"https://play.google.com/store/apps/details?id=com.pillidapp"
          },
          project_type: "mobile app",
          parentId: "application-development",
          childId: "android-app-development",
          exclude: "yes"
        },
        {
          big_img: epic_big,
          small_img: epic_mobile,
          logo: epic_small_logo,
          title: "EpicPlace",
          heading: "Objective C App",
          content: "EpicPlace is a new fast way to connect with family and friends to share your most recent photos and videos and life experiences. Have fun with memes, Gifs, and viral featured videos...Browse and rate the best videos, photos, and internet content.",
          technologies: [
            { icon: objective_c_icon, title: "Objective C" },
            { icon: java_icon, title: "Java" }
          ],
          link: {
            iOSBtnLink: "",
            androidBtnLink:"https://play.google.com/store/apps/details?id=com.leif.style.yourtalentnew&hl=en_IN"
          },
          project_type: "mobile app",
          parentId: "application-development",
          childId: "android-app-development",
          exclude: "yes"
        },
        // {
        //   big_img: ugame_big,
        //   small_img: ugame_mobile,
        //   logo: ugame_small_logo,
        //   title: "UGAME",
        //   heading: "Objective C App",
        //   content: "UGame is the only app uniquely focused on facilitating connections and communication among event- goers before and en route to events, helping people make real connections in real life.",
        //   technologies: [{ icon: objective_c_icon, title: "Objective C" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/st/app/ugame/id1186722645",
        //     androidBtnLink: ""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "ios-app-development",
        //   exclude: "yes"
        // },
        // {
        //   big_img: wltg_big,
        //   small_img: wltg_mobile,
        //   logo: wltg_small_logo,
        //   title: "Would Love To Go",
        //   heading: "Objective C App",
        //   content:"Make new friends doing the things you love; whether that's catching a movie, going to the theatre, bowling, visiting an art gallery, or simply meeting up for a coffee and a chat.",
        //   technologies: [{ icon: objective_c_icon, title: "Objective C" }],
        //   link: {
        //     iOSBtnLink:"https://apps.apple.com/us/app/would-love-to-go/id1100217014?ls=1",
        //     androidBtnLink: ""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "ios-app-development",
        //   exclude: "yes"
        // },
        // {
        //   big_img: rolela_big,
        //   small_img: rolela_mobile,
        //   logo: rolela_small_logo,
        //   title: "Rolela",
        //   heading: "Swift App",
        //   content: "Are you a supermarket, restaurant or shop and you want to sell your expiring food items? Download, register and sell your expiring food items on Rolela.",
        //   technologies: [{ icon: swift_icon, title: "Swift" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/us/app/rolela/id1439787970?ls=1",
        //     androidBtnLink: ""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "ios-app-development",
        //   exclude: "yes"
        // },
        // {
        //   big_img: Pill_Identification_big,
        //   small_img: Pill_Identification_mobile,
        //   logo: Pill_Identification_small_logo,
        //   title: "Pill Identification",
        //   heading: "Objective C App",
        //   content:"Pill Identifier App for Android and tablets provides access to the PILLID.com database in an easy to use Android App.",
        //   technologies: [{ icon: objective_c_icon, title: "Objective C" }],
        //   link: {
        //     iOSBtnLink:"https://apps.apple.com/us/app/pill-identification/id1046763249",
        //     androidBtnLink: ""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "ios-app-development",
        //   exclude: "yes"
        // },
        {
          big_img: map_my_sound_big,
          small_img: map_my_sound_mobile,
          logo: map_my_sound_small_logo,
          title: "MapMySound",
          heading: "Swift App",
          content:"MapMySound is a free sound recorder app that allows you to record or edit audio using a mobile phone.You can view the sound mapping details of audio recorded by you and its representation on spectrogram to find out the actual quality of audio files. Download the MapMySound audio recorder app now to make audio recording simple!",
          technologies: [{ icon: swift_icon, title: "Swift" }],
          link: {
            iOSBtnLink: "https://apps.apple.com/us/app/mapmysound/id1370044887?ls=1",
            androidBtnLink: ""
          },
          project_type: "mobile app",
          parentId: "application-development",
          childId: "ios-app-development"
        },
        {
          big_img: epic_big,
          small_img: epic_mobile,
          logo: epic_small_logo,
          title: "EpicPlace",
          heading: "Objective C App",
          content: "EpicPlace is a new fast way to connect with family and friends to share your most recent photos and videos and life experiences. Have fun with memes, Gifs, and viral featured videos...Browse and rate the best videos, photos, and internet content.",
          technologies: [
            { icon: objective_c_icon, title: "Objective C" },
            { icon: java_icon, title: "Java" }
          ],
          link: {
            iOSBtnLink: "https://apps.apple.com/us/app/epicplace/id1028804394",
            androidBtnLink: ""
          },
          project_type: "mobile app",
          parentId: "application-development",
          childId: "ios-app-development",
          exclude: "yes"
        },
        // {
        //   big_img: pullme_big,
        //   small_img: pullme_mobile,
        //   logo: pullme_small_logo,
        //   title: "Pullme",
        //   heading: "React Native App",
        //   content: "Combining Vehicle Rescue, Tow Truck and 24/7 Assistance sector, our company's existence is to provide high quality service to our people and employees and to establish lasting relationships with them.As Pullme.net, our corporate thinking structure is to continue our investments in favor of them without forgetting the fact that our customers and employees are primarily human.",
        //   technologies: [{ icon: react_native_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/in/app/pullme/id1473117105",
        //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.pullme.customer"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: scan_it_big,
        //   small_img: scan_it_mobile,
        //   logo: scan_it_small_logo,
        //   title: "Scan It",
        //   heading: "React Native App",
        //   content: "Scan It is a React Native Attendance app, which will facilitate teachers to mark attendance, calculate absent/present rate of each student through app in a quick way.",
        //   technologies: [{ icon: react_native_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "",
        //     androidBtnLink: ""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: timeout_big,
        //   small_img: timeout_mobile,
        //   logo: timeout_small_logo,
        //   title: "TimeOut",
        //   heading: "React Native App",
        //   content: "Timeout is a React Native fitness training app which provide users with set of exercises to perform for each muscle group and also guide users about the right posture and way to execute the exercise through video clip and text material.",
        //   technologies: [{ icon: react_native_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/us/app/timeout-palestra/id1495794207?ls=1",
        //     androidBtnLink: "https://play.google.com/store/apps/details?id=com.timeoutfitnessapp&hl=en"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: madd_big,
        //   small_img: madd_mobile,
        //   logo: madd_small_logo,
        //   title: "Madd Loans",
        //   heading: "Native App",
        //   content:"At Madd Loans, we offer more than the average mortgage broker. We genuinely want our customer to have an enjoyable experience from start to finish. Our aim is to make the process of getting a home loan, or refinancing an existing loan as easy and stress free as possible, while also working to find the best deal.",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/us/app/madd-loans/id1342559577",
        //     androidBtnLink:"https://play.google.com/store/apps/details?id=com.app.maddloans"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: gorilla_big,
        //   small_img: gorilla_mobile,
        //   logo: gorilla_small_logo,
        //   title: "Gorilla",
        //   heading: "Native App",
        //   content:"Gorilla VPN app offers safe and secure VPN services. App offers many amazing features to its users and have a subscription plan to use the app.",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "",
        //     androidBtnLink:""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: loopleap_big,
        //   small_img: loopleap_mobile,
        //   logo: loopleap_small_logo,
        //   title: "Loopleap",
        //   heading: "Native App",
        //   content:"LoopLeap is a system where you can move forward in time, sharing your life with your descendants or others at a time of your choosing. Your descendants (or others) can travel back in time to your life and go through your timeline, see you, listen to you and communicate with you (utilizing Artificial Intelligence and Augmented Reality).",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "https://apps.apple.com/us/app/loopleap/id1451157270",
        //     androidBtnLink:"https://play.google.com/store/apps/details?id=com.app.loopleap"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: luveCyprus_big,
        //   small_img: luveCyprus_mobile,
        //   logo: luveCyprus_small_logo,
        //   title: "Luv Cyprus",
        //   heading: "Native App",
        //   content:"Luv Cyprus is an app for blogging website www.luvcyprus.com which provide all the details of Cyprus Country for travellers and tourists.",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "",
        //     androidBtnLink:""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: richNess_big,
        //   small_img: richNess_mobile,
        //   logo: richNess_small_logo,
        //   title: "RichNess",
        //   heading: "Native App",
        //   content:"Richness it's a new social app for richness peoples. Today on every kind of social networks there is an excessive ostentation of richness and beauty. If beauty is a visible thing than richness can be created and shown in a artificially way.",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "",
        //     androidBtnLink:"https://play.google.com/store/apps/details?id=richness.com.richness"
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: TK_Inspection_big,
        //   small_img: TK_Inspection_mobile,
        //   logo: TK_Inspection_small_logo,
        //   title: "TK Inspection",
        //   heading: "Native App",
        //   content:"It is a survey data collection app developed exclusively for TK Inspections as per their need to easily collect survey data and email the survey report to their clients.",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "",
        //     androidBtnLink:""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
        // {
        //   big_img: warp_big,
        //   small_img: warp_mobile,
        //   logo: warp_small_logo,
        //   title: "Warp",
        //   heading: "Native App",
        //   content:"Our mission at WARP Local is to get every possible event in one organized place while creating a virtual social experience like no other. WARP Local features: Map view - organized with emojis for each category.List view - scroll down the list according to proximity.Event Details - accessible from the map or list.Search and Filter - find the events YOU want and care about. Search or use multiple filter options like Keywords, Names, Favorite Categories, Free or Paid, Start Date, and more.",
        //   technologies: [{ icon: react_icon, title: "React" }],
        //   link: {
        //     iOSBtnLink: "",
        //     androidBtnLink:""
        //   },
        //   project_type: "mobile app",
        //   parentId: "application-development",
        //   childId: "react-native-development"
        // },
      ],
      filterData: [],
      // activeTabParent: "",
      // activeTabChild: ""
    };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
    this.setState({
      filterData: this.state.data.filter(project => !project.exclude), 
      datalength: this.state.data.length, 
      loaddata: this.state.data.slice(0, 5) 
    });
    if (
      window.location.pathname !== "/portfolios/web-development" &&
      window.location.pathname !== "/portfolios/web-development/php-development" &&
      window.location.pathname !== "/portfolios/web-development/mean-stack-development" &&
      window.location.pathname !== "/portfolios/web-development/mern-stack-development" &&
      window.location.pathname !== "/portfolios/web-development/mevn-stack-development" &&
      window.location.pathname !== "/portfolios/web-development/asp-net-development" &&
      window.location.pathname !== "/portfolios/web-development/python-development" &&
      window.location.pathname !== "/portfolios/uiux-design" &&
      window.location.pathname !== "/portfolios/uiux-design/website-redesign" &&
      window.location.pathname !== "/portfolios/uiux-design/responsive-web-design" &&
      window.location.pathname !== "/portfolios/uiux-design/logo-designing" &&
      window.location.pathname !== "/portfolios/uiux-design/banner-designing" &&
      window.location.pathname !== "/portfolios/uiux-design/brochure-designing" &&
      window.location.pathname !== "/portfolios/uiux-design/print-media-design" &&
      window.location.pathname !== "/portfolios/ecommerce-solutions" &&
      window.location.pathname !== "/portfolios/ecommerce-solutions/magento-development" &&
      window.location.pathname !== "/portfolios/ecommerce-solutions/woocommerce-development" &&
      window.location.pathname !== "/portfolios/ecommerce-solutions/shopify-development" &&
      window.location.pathname !== "/portfolios/ecommerce-solutions/open-cart-development" &&
      window.location.pathname !== "/portfolios/ecommerce-solutions/bigcommerce-development" &&
      window.location.pathname !== "/portfolios/application-development" &&
      window.location.pathname !== "/portfolios/application-development/android-app-development" &&
      window.location.pathname !== "/portfolios/application-development/ios-app-development" &&
      window.location.pathname !== "/portfolios/application-development/react-native-development"
      ) {
        this.props.history.push("/portfolios");
      }
  }

  // fetchMoreData = () => {
  //   if (this.state.loaddata.length >= this.state.datalength) {
  //     this.setState({ hasMore: false });
  //     return;
  //   }
  //   this.timerID = setTimeout(() => {
  //     this.setState({
  //       loaddata: this.state.loaddata.concat(this.state.data.splice(5, 5))
  //     });
  //   }, 2000);
  // };

  componentWillUnmount() {
    //clearTimeout(this.timerID);
  }

  filterData = (parentTab,childTab) => {
    switch (parentTab) {
      case "web-development":
        this.setState({ pageTitle: 'Web Development Portfolios'})
        break;
      case "uiux-design":
        this.setState({ pageTitle: 'UI/UX Design Portfolios' })
        break;
      case "ecommerce-solutions":
        this.setState({ pageTitle: 'E-Commerce Solution Portfolios' })
        break;
      case "application-development":
        this.setState({ pageTitle: 'App Development Portfolios' })
        break;
      default:
        this.setState({ pageTitle: "Portfolios"})
        break;
    }
    if(childTab) {
      let filteredData = this.state.data.filter(project => project.parentId === parentTab && project.childId === childTab);
      this.setState({
        filterData: filteredData,
        // activeTabParent: parentTab,
        // activeTabChild: childTab
      })
    } else {
      this.props.history.push("/portfolios/" + parentTab);
      if (parentTab === 'application-development') {
        let filteredData = this.state.data.filter(project => project.parentId === parentTab && !project.exclude );
        this.setState({
          filterData: filteredData,
          // activeTabParent: parentTab,
          // activeTabChild: ''
        })
      } else {
        let filteredData = this.state.data.filter(project => project.parentId === parentTab);
        this.setState({
          filterData: filteredData,
          // activeTabParent: parentTab,
          // activeTabChild: ''
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.pid) {
      if (nextProps.match.params.cid) {
        if ((nextProps.match.params.pid !== this.props.match.params.pid) || (nextProps.match.params.cid !== this.props.match.params.cid)) {
          this.filterData(nextProps.match.params.pid, nextProps.match.params.cid);
          this.setState({counter: 1})
          // this.setState({activeTabChild : nextProps.match.params.cid})
        }
      } else {
        if (nextProps.match.params.pid !== this.props.match.params.pid) {
          this.filterData(nextProps.match.params.pid, '');
          this.setState({counter: 1})
          // this.setState({ activeTabChild: '' })
        } else {
          if(this.state.counter === 1) {
            this.filterData(nextProps.match.params.pid, "");
            this.setState({counter: 2})
          }
        }
      }
    }
    if(window.location.pathname === "/portfolios") {
      this.setState({
        filterData: this.state.data.filter(project => !project.exclude),
        pageTitle: "Portfolios"
      });
    }
  }
  
  componentDidMount() {
      if (this.props.match.params.pid) {
        if(this.props.match.params.cid) {
          this.filterData(this.props.match.params.pid, this.props.match.params.cid);
        } else {
          this.filterData(this.props.match.params.pid, '');
        }
      }
    }
    render() {
    if (this.props.match.params.id) {
      if (this.props.match.params.id === 'web-development') {
          document.title = "Web Development Portfolio | Web Development Company India";
      } else if (this.props.match.params.id === 'uiux-design') {
          document.title = "UI/UX Design & Development Portfolio | UI/UX Design Services";
      } else if (this.props.match.params.id === 'ecommerce-solutions') {
          document.title = "Ecommerce Website Design & Development Portfolio";
      } else if (this.props.match.params.id === 'application-development') {
          document.title = "Mobile Application Development Portfolio | App Developers";
      } else {
          document.title = "Web, UI/UX Design, Ecommerce & App Development Portfolio";
      }
    } else {
        document.title = "Web, UI/UX Design, Ecommerce & App Development Portfolio";
    }
    const { pageTitle, bannerImg } = this.state;

    // const settingsHorizontal = {
    //   dots: false,
    //   arrows: true,
    //   infinite: true,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   adaptiveHeight: true,
    //   responsive: [
    //     {
    //       breakpoint: 1279,
    //       settings: {
    //         slidesToShow: 4
    //       }
    //     },
    //     {
    //       breakpoint: 992,
    //       settings: {
    //         slidesToShow: 3
    //       }
    //     },
    //     {
    //       breakpoint: 767,
    //       settings: {
    //         slidesToShow: 2
    //       }
    //     },
    //     {
    //       breakpoint: 575,
    //       settings: {
    //         slidesToShow: 1
    //       }
    //     }
    //   ]
    // };
    return (
      <div className="main_portfolio">
        <Grid container>

          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - Section Design "Portfolios Listing HTML Code" */}
          <Grid item xs={12}>
            {/* <Grid component="div" className={`portfolio_work_header sec_padding`}>
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Link className="all_link_btn" to="/portfolios">
                    <Typography>All</Typography>
                    <small>Projects</small>
                  </Link>
                  <Slider {...settingsHorizontal}>
                    <div>
                      <div
                        className={this.state.activeTabParent === 'web-development' ? "slide_item portfolio_list active" : "slide_item portfolio_list"}
                        onClick={() => this.filterData('web-development','')}
                        title="Web Development"
                      >
                        <img src={Web_DevelopmentPortfolio} alt="Web Development" title="Web Development" />
                        <img src={Web_DevelopmentPortfolioColored} className="d-none" alt="Web Development" title="Web Development" />
                        <Typography>Web <br />Development</Typography>
                      </div>
                    </div>
                    <div>
                      <div
                        className={this.state.activeTabParent === 'uiux-design' ? "slide_item portfolio_list active" : "slide_item portfolio_list"}
                        onClick={() => this.filterData("uiux-design","")}
                        title="UI/UX Design"
                      >
                        <img src={UI_UX_DesignPortfolio} alt="UI/UX Design" title="UI/UX Design" />
                        <img src={UI_UX_DesignPortfolioColored} className="d-none" alt="UI/UX Design" title="UI/UX Design" />
                        <Typography>UI/UX <br />Design</Typography>
                      </div>
                    </div>
                    <div>
                      <div
                        className={this.state.activeTabParent === 'ecommerce-solutions' ? "slide_item portfolio_list active" : "slide_item portfolio_list"}
                        onClick={() => this.filterData('ecommerce-solutions','')}
                        title="E-Commerce Solutions"
                      >
                        <img src={E_Commerce_SolutionsPortfolio} alt="E-Commerce Solutions" title="E-Commerce Solutions" />
                        <img src={E_Commerce_SolutionsPortfolioColored} className="d-none" alt="E-Commerce Solutions" title="E-Commerce Solutions" />
                        <Typography>E-Commerce <br />Solutions</Typography>
                      </div>
                    </div>
                    <div>
                      <div
                        className={this.state.activeTabParent === 'application-development' ? "slide_item portfolio_list active" : "slide_item portfolio_list"}
                        onClick={() => this.filterData('application-development','')}
                        title="Application Development"
                      >
                        <img src={Application_DevelopmentPortfolio} alt="Application Development" title="Application Development" />
                        <img src={Application_DevelopmentPortfolioColored} className="d-none" alt="Application Development" title="Application Development" />
                        <Typography>App <br />Development</Typography>
                      </div>
                    </div>
                  </Slider>
                  <div className="sub_cat_wrap" hidden={this.state.activeTabParent === 'web-development' ? false : true}>
                    <ul>
                      <li className={this.state.activeTabChild === 'php-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('web-development', 'php-development')} title="PHP Development"><span>PHP Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'mean-stack-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('web-development', 'mean-stack-development')} title="Mean Stack Development"><span>Mean Stack Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'mern-stack-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('web-development', 'mern-stack-development')} title="Mern Stack Development"><span>Mern Stack Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'mevn-stack-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('web-development', 'mevn-stack-development')} title="Mevn Stack Development"><span>Mevn Stack Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'asp-net-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('web-development', 'asp-net-development')} title="ASP.Net Development"><span>ASP.Net Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'python-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('web-development', 'python-development')} title="Python Development"><span>Python Development</span></div>
                      </li>
                    </ul>
                  </div>
                  <div className="sub_cat_wrap" hidden={this.state.activeTabParent === 'uiux-design' ? false : true}>
                    <ul>
                      <li className={this.state.activeTabChild === 'website-redesign' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData("uiux-design", "website-redesign")} title="Website Redesign"><span>Website Redesign</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'responsive-web-design' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData("uiux-design", "responsive-web-design")} title="Responsive Web Design"><span>Responsive Web Design</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'logo-designing' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData("uiux-design", "logo-designing")} title="Logo Designing"><span>Logo Designing</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'banner-designing' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData("uiux-design", "banner-designing")} title="Banner Designing"><span>Banner Designing</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'brochure-designing' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData("uiux-design", "brochure-designing")} title="Brochure Designing"><span>Brochure Designing</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'print-media-design' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData("uiux-design", "print-media-design")} title="Print Media Design"><span>Print Media Design</span></div>
                      </li>
                    </ul>
                  </div>
                  <div className="sub_cat_wrap" hidden={this.state.activeTabParent === 'ecommerce-solutions' ? false : true}>
                    <ul>
                      <li className={this.state.activeTabChild === 'magento-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('ecommerce-solutions', 'magento-development')} title="Magento Development"><span>Magento Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'woocommerce-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('ecommerce-solutions', 'woocommerce-development')} title="WooCommerce Development"><span>WooCommerce Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'shopify-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('ecommerce-solutions', 'shopify-development')} title="Shopify Development"><span>Shopify Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'open-cart-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('ecommerce-solutions', 'open-cart-development')} title="Open Cart Development"><span>Open Cart Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'bigcommerce-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('ecommerce-solutions', 'bigcommerce-development')} title="BigCommerce Development"><span>BigCommerce Development</span></div>
                      </li>
                    </ul>
                  </div>
                  <div className="sub_cat_wrap application_development" hidden={this.state.activeTabParent === 'application-development' ? false : true}>
                    <ul>
                      <li className={this.state.activeTabChild === 'android-app-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('application-development', 'android-app-development')} title="Android App Development"><span>Android App Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'ios-app-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('application-development', 'ios-app-development')} title="iOS App Development"><span>iOS App Development</span></div>
                      </li>
                      <li className={this.state.activeTabChild === 'react-native-development' ? "fillActive" : ""}>
                        <div className="tab_click" onClick={() => this.filterData('application-development', 'react-native-development')} title="React Native Development"><span>React Native Development</span></div>
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid
              container
              component="div"
              className="portfolio_work_content sec_padding"
            >
              {/* <InfiniteScroll
                dataLength={this.state.loaddata.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={<Grid item xs={12}><img className="portfolio_loader" src={Loader} alt="Loader" title="Loader" /></Grid>}
              > */}
                <div className="infinite-scroll-component"> 
                {this.state.filterData.length > 0 && this.state.filterData.map((project, index) => (
                  <Grid key={index} item xs={12} sm={6} lg={12} container>
                    <Grid item xs={12} container className="portfolio_list">
                      <Grid item xs={12} lg={6}>
                        <div className="website_img_box">
                          <div className="website_big_img" data-aos="fade-right">
                            <LazyLoad once offset={300}>
                            <img
                              src={project.big_img}
                              alt={project.title}
                              title={project.title}
                              className="big_img"
                            />
                            </LazyLoad>
                          </div>
                            <div
                            className={(project.project_type === 'mobile' || project.project_type === 'mobile app') ? "website_laptop_img website_mobile_img" : (project.project_type === 'laptop logo') ? "website_logo_img website_laptop_img" : "website_laptop_img"}
                            data-aos="fade-up"
                            data-aos-duration="3000"
                            >
                            <LazyLoad once offset={300}>
                            <img
                              src={project.small_img}
                              alt={project.title}
                              title={project.title}
                              className="laptop_img shake"
                            />
                            </LazyLoad>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                          <div
                          className="website_portfolio_info"
                          data-aos="fade-up"
                          data-aos-duration="2000"
                          >
                          <aside>
                            {project.logo && 
                            <div className="website_logo_small">
                            <LazyLoad once offset={300}>
                              <img
                                src={project.logo}
                                alt={project.title}
                                title={project.title}
                                className=""
                              />
                            </LazyLoad>
                            </div>
                            }
                            <div className="title_text">
                              <Typography variant="h2" className="head_title text-left">
                                {project.title}
                              </Typography>
                              <Typography className="head_paragraph text-left small">
                                {project.heading}
                              </Typography>
                            </div>
                          </aside>
                          <Typography className="head_paragraph text-left">
                            {/* {((`${project.content}`).length > 200) ?
                            (((`${project.content}`).substring(0, 200 - 3)) + '...') : project.content} */}
                            {project.content}
                          </Typography>
                          <div className="add_technologies">
                            <Typography className="head_paragraph text-left">
                              Technologies<span class="font-arial">:-</span>
                            </Typography>
                            <div className="icons">
                              {project.technologies.map((technology, index) => (
                              <LazyLoad once offset={300} key={index}><img 
                                key={index}
                                src={technology.icon}
                                alt={technology.title}
                                title={technology.title}
                                className="tech_icons"
                              /></LazyLoad>
                              ))}
                            </div>
                          </div>
                          {project.link && project.project_type !== 'mobile app' && <Grid component="div" className="btnWrap">
                            <a
                              className="view_site hover-btn-animation-in"
                              href={project.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Visit Website"
                            >
                              Visit Website
                            <Icon>chevron_right</Icon>
                            </a>
                          </Grid> 
                          }

                          { project.project_type === 'mobile app' && <Grid component="div" className="btnDownloadWrap">
                            {project.link.iOSBtnLink &&
                              <a
                                className=""
                                href={project.link.iOSBtnLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <LazyLoad once offset={300}><img src={app_store_badge} alt="App Store" title="App Store" /></LazyLoad>
                              </a>
                            }
                            { project.link.androidBtnLink &&
                              <a
                                className=""
                                href={project.link.androidBtnLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <LazyLoad once offset={300}><img src={google_play_store_badge} alt="Google Play Store" title="Google Play Store" /></LazyLoad>
                              </a>
                            }
                            </Grid>
                          }
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                {this.state.filterData.length === 0 && <div className="no_more_portfolios"><h6 className="head_title">No more Portfolios</h6></div> }
                </div>
              {/* </InfiniteScroll> */}
            </Grid>
          </Grid>
          {/* End - Section Design "Portfolios Listing HTML Code" */}

          {/* Start - NumberCount Import From Common Components */}
          <Suspense fallback={<div></div>}>
          <NumberCount />
          </Suspense>
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default portfolio;
