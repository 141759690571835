import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

class HomeBannerSlider extends React.Component {
    constructor(props) {
      super(props);
      
      this.IMAGE_PARTS = 4;
      
      this.changeTO = null;
      this.AUTOCHANGE_TIME = 15000;
      
      this.state = { 
          activeSlide: -1, 
          prevSlide: -1, 
          sliderReady: false 
        };
    }
    
    componentWillUnmount() {
      window.clearTimeout(this.changeTO);
    }
    
    componentDidMount() {
      this.runAutochangeTO();
      setTimeout(() => {
        this.setState({ activeSlide: 0, sliderReady: true });
      }, 0);
    }
    
    runAutochangeTO() {
      this.changeTO = setTimeout(() => {
        this.changeSlides(1);
        this.runAutochangeTO();
      }, this.AUTOCHANGE_TIME);
    }
    
    changeSlides(change) {
      window.clearTimeout(this.changeTO);
      const { length } = this.props.slides;
      const prevSlide = this.state.activeSlide;
      let activeSlide = prevSlide + change;
      if (activeSlide < 0) activeSlide = length - 1;
      if (activeSlide >= length) activeSlide = 0;
      this.setState({ activeSlide, prevSlide });
    }
    
    render() {
      const { activeSlide, prevSlide, sliderReady } = this.state;
      return (
        <Grid item xs={12}>
          <div className={sliderReady ? "slider s--ready":"slider"}>
            {/* <p className="slider__top-heading">Travelers</p> */}
            <div className="slider__slides">
              {this.props.slides.map((slide, index) => (
                <div
                  className={`slider__slide ${activeSlide === index ? "s--active":""} ${prevSlide === index ? "s--prev":""}`}
                  key={slide.title}
                  >
                  <div className="slider__slide-content">
                    <h3 className="slider__slide-subheading">{slide.subTitle}</h3>
                    <div className="title-wrapper">
                    <h1 className="slider__slide-heading">
                      {slide.title.split('').map((l,key) => <span key={key}>{l}</span>)}
                    </h1>
                    
                    {slide.titleWhite ? (
                      <i>{slide.titleWhite}</i>
                    ) : (
                      <span></span>
                    )}
                    </div>
                    {slide.description &&
                      <p className="slider__slide-description">{slide.description}</p>
                    }
                    <p className="slider__slide-readmore"><Link to="/get-a-free-quote" className="dark-btn-effect all-category-mobile" title="Get Started Now">Get Started Now</Link></p>
                  </div>
                  <div className="slider__slide-parts">
                    {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                      <div className="slider__slide-part" key={i}>
                        <LazyLoad once><div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.img})` }} /></LazyLoad>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="slider__control slider__control--left" onClick={() => this.changeSlides(-1)} title="Previous">
              <i className="material-icons">
                  keyboard_arrow_left
              </i>
            </div>
            <div className="slider__control slider__control--right" onClick={() => this.changeSlides(1)} title="Next" >
              <i className="material-icons">
                  keyboard_arrow_right
              </i>
            </div>
          </div>
        </Grid>
      );
    }
  }
  
  export default HomeBannerSlider; 
  