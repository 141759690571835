import React, { Component, Suspense, lazy } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import smoothscroll from "smoothscroll-polyfill";
import { Link } from "react-router-dom";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import its_coller from "../../assets/images/ApplicationDevelopment/its-coller.png";
import Ugame from "../../assets/images/ApplicationDevelopment/Ugame.png";
import madd from "../../assets/images/ApplicationDevelopment/madd.png";
import rolela from "../../assets/images/ApplicationDevelopment/rolela.png";
import pullme from "../../assets/images/ApplicationDevelopment/pullme.png";
import gorilla_vpn from "../../assets/images/ApplicationDevelopment/gorilla_vpn.png";
import loopleap from "../../assets/images/ApplicationDevelopment/loopleap.png";
import luveCyprus from "../../assets/images/ApplicationDevelopment/luveCyprus.png";
import richness from "../../assets/images/ApplicationDevelopment/richness.png";
import tk_inspection from "../../assets/images/ApplicationDevelopment/tk_inspection.png";
import warp from "../../assets/images/ApplicationDevelopment/warp.png";
import Would_Love_To_Go from "../../assets/images/ApplicationDevelopment/Would-Love-To-Go.png";
import EpicPlace from "../../assets/images/ApplicationDevelopment/EpicPlace.png";
import scan_it from "../../assets/images/ApplicationDevelopment/scan_it.png";
import timeout from "../../assets/images/ApplicationDevelopment/timeout.png";
import android from "../../assets/images/Header/android.svg";
import apple from "../../assets/images/Header/apple.svg";
import reactNative from "../../assets/images/Header/reactnative.svg";
import android_White from "../../assets/images/white-icons/android.svg";
import apple_White from "../../assets/images/white-icons/apple.svg";
import reactNative_White from "../../assets/images/white-icons/reactnative.svg";
import AppStoreDeployment from "../../assets/images/ApplicationDevelopment/AppStoreDeployment.svg";
import CustomAndroidAppDevelopment from "../../assets/images/ApplicationDevelopment/CustomAndroidAppDevelopment.svg";
import EndToEndService from "../../assets/images/ApplicationDevelopment/EndToEndService.svg";
import EnterpriseAndroidAppDevelopment from "../../assets/images/ApplicationDevelopment/EnterpriseAndroidAppDevelopment.svg";
import HybridAppDevelopment from "../../assets/images/ApplicationDevelopment/HybridAppDevelopment.svg";
import MaintenanceSupport from "../../assets/images/ApplicationDevelopment/MaintenanceSupport.svg";
import NativeAndroidAppDevelopment from "../../assets/images/ApplicationDevelopment/NativeAndroidAppDevelopment.svg";
import ReactNativeTeamAugmentation from "../../assets/images/ApplicationDevelopment/ReactNativeTeamAugmentation.svg";
import ReactNativeConsulting from "../../assets/images/ApplicationDevelopment/ReactNativeConsulting.svg";
import ReactNativeDevelopment from "../../assets/images/ApplicationDevelopment/ReactNativeDevelopment.svg";
import ReactNativeMigration from "../../assets/images/ApplicationDevelopment/ReactNativeMigration.svg";
import SpecializedIOSDevelopers from "../../assets/images/ApplicationDevelopment/SpecializedIOSDevelopers.svg";
import mapmysound from "../../assets/images/ApplicationDevelopment/mapmysound.png";
import WorkoutMemo from "../../assets/images/ApplicationDevelopment/WorkoutMemo.png";
import pill_identification from "../../assets/images/ApplicationDevelopment/pill-identification.png";
import Prime from "../../assets/images/ApplicationDevelopment/Prime.png";
// import iOSAppStore from "../../assets/images/ApplicationDevelopment/app-store.svg";
// import googlePlayStore from "../../assets/images/ApplicationDevelopment/play-store.svg";
import Application_Banner from "../../assets/images/ApplicationDevelopment/Application-Banner.jpg";
// import objective_c_icon from "../../assets/images/Portfolios/Technologies/objective_c_icon.png";
// import react_icon from "../../assets/images/Portfolios/Technologies/react_icon.png";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
// import NumberCount from "../common/NumberCount";
import SliderServiceCatPagePortfolio3 from "../common/SliderServiceCatPagePortfolio3";
const NumberCount = lazy(() => import("../common/NumberCount"));
// Get Common Components End

class ApplicationDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Mobile App Development",
      bannerImg: Application_Banner,
      activeTab: "android-app-development",
      activeTab1: "tab-1",
      tabToggle: false,
      isClickedEventFired: 0,
      androidAppSlider: [],
      iOSAppSlider: [],
      reactNativeSlider: []
    };
    smoothscroll.polyfill();
    this.baseState = this.state;
  }

  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }

    const androidAppData = [
      // {
      //   image: Ugame,
      //   title: "Ugame",
      //   iOSBtnLink: "https://apps.apple.com/st/app/ugame/id1186722645",
      //   androidBtnLink: "https://play.google.com/store/apps/details?id=com.grappetite.ugame&hl=en",
      //   tech_images: []
      // },
      // {
      //   image: madd,
      //   title: "Madd Loans",
      //   iOSBtnLink: "",
      //   androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.maddloans",
      //   tech_images: []
      // },
      // {
      //   image: its_coller,
      //   title: "iTs Cooler",
      //   iOSBtnLink: "",
      //   androidBtnLink: "",
      //   tech_images: []
      // },
      // {
      //   image: rolela,
      //   title: "Rolela",
      //   iOSBtnLink: "https://apps.apple.com/us/app/rolela/id1439787970?ls=1",
      //   androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.rolela&hl=en",
      //   tech_images: []
      // },
      // {
      //   image: Would_Love_To_Go,
      //   title: "Would Love To Go",
      //   iOSBtnLink: "",
      //   androidBtnLink: "https://play.google.com/store/apps/details?id=com.wltg.app&hl=en",
      //   tech_images: []
      // }
    ];

    const iOSAppData = [
      {
        image: mapmysound,
        title: "MapMySound",
        iOSBtnLink: "https://apps.apple.com/us/app/mapmysound/id1370044887?ls=1",
        androidBtnLink: "",
        tech_images: []
      },
      {
        image: WorkoutMemo,
        title: "WorkoutMemo",
        iOSBtnLink: "",
        androidBtnLink: "",
        tech_images: []
      },
      // {
      //   image: pill_identification,
      //   title: "Pill Identification",
      //   iOSBtnLink: "https://apps.apple.com/us/app/pill-identification/id1046763249",
      //   androidBtnLink: "https://play.google.com/store/apps/details?id=com.pillidapp",
      //   tech_images: []
      // },
      {
        image: Prime,
        title: "Prime",
        iOSBtnLink: "",
        androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.priMe",
        tech_images: []
      },
      {
        image: EpicPlace,
        title: "EpicPlace",
        iOSBtnLink: "https://apps.apple.com/us/app/epicplace/id1028804394",
        androidBtnLink: "",
        tech_images: []
      }
    ];

    const reactNativeData = [
      // {
      //   image: pullme,
      //   title: "PullMe",
      //   iOSBtnLink: "https://apps.apple.com/in/app/pullme/id1473117105",
      //   androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.pullme.customer",
      //   tech_images: []
      // },
      // {
      //   image: gorilla_vpn,
      //   title: "Gorilla VPN",
      //   iOSBtnLink: "",
      //   androidBtnLink: "",
      //   tech_images: []
      // },
      // {
      //   image: loopleap,
      //   title: "Loopleap",
      //   iOSBtnLink: "https://apps.apple.com/us/app/loopleap/id1451157270",
      //   androidBtnLink: "https://play.google.com/store/apps/details?id=com.app.loopleap",
      //   tech_images: []
      // },
      // {
      //   image: luveCyprus,
      //   title: "Luv Cyprus",
      //   iOSBtnLink: "",
      //   androidBtnLink: "",
      //   tech_images: []
      // },
      // {
      //   image: richness,
      //   title: "RichNess",
      //   iOSBtnLink: "",
      //   androidBtnLink: "https://play.google.com/store/apps/details?id=richness.com.richness",
      //   tech_images: []
      // },
      // {
      //   image: tk_inspection,
      //   title: "TK Inspection",
      //   iOSBtnLink: "",
      //   androidBtnLink: "",
      //   tech_images: []
      // },
      // {
      //   image: warp,
      //   title: "Warp",
      //   iOSBtnLink: "",
      //   androidBtnLink: "",
      //   tech_images: []
      // },
      // {
      //   image: scan_it,
      //   title: "Scan It",
      //   iOSBtnLink: "",
      //   androidBtnLink: "",
      //   tech_images: []
      // },
      {
        image: timeout,
        title: "TimeOut",
        iOSBtnLink: "",
        androidBtnLink: "",
        tech_images: []
      }
    ];

    this.setState({ androidAppSlider: androidAppData, iOSAppSlider: iOSAppData, reactNativeSlider: reactNativeData });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent, false);
  }

  listenScrollEvent = e => {
    if (this.state.isClickedEventFired > 0) {
      var abc = setTimeout(() => {
        this.setState({ isClickedEventFired: 0 });
      }, 5000);
      if (this.state.isClickedEventFired > 1) {
        clearTimeout(abc);
      }
    } else {
      var section1 = this.checkElementInViewport(
        document.getElementById("android-app-development")
      );
      var section2 = this.checkElementInViewport(
        document.getElementById("ios-app-development")
      );
      var section3 = this.checkElementInViewport(
        document.getElementById("react-native-development")
      );

      if (section1 && section2) {
        this.setState({
          activeTab: "android-app-development",
          activeTab1: "tab-1"
        });
      }
      if ((section2 && !section1) || (section2 && section3)) {
        this.setState({
          activeTab: "ios-app-development",
          activeTab1: "tab-2"
        });
      }
      if (section3 && !section2) {
        this.setState({
          activeTab: "react-native-development",
          activeTab1: "tab-3"
        });
      }
    }
  };

  checkElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    const vertInView =
      rect.top <= windowHeight && rect.top-74 + rect.height >= 0;
    const horInView =
      rect.left <= windowWidth && rect.left-74 + rect.width >= 0;

    return vertInView && horInView;
  }

  handleOnClickScroll = (divId, tab) => {
    this.setState({
      activeTab: divId,
      activeTab1: tab,
      isClickedEventFired: this.state.isClickedEventFired + 1
    });
    var ref = document.getElementById(divId);
    window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
  };

  handleTabToggle = () => {
    this.setState(state => ({ tabToggle: !state.tabToggle }));
  };

  componentWillReceiveProps(nextProps) {
    var backToTop = localStorage.getItem('backToTop');
    if (nextProps.match.params.id) {
      if (nextProps.match.params.id !== this.props.match.params.id) {
        var ref = document.getElementById(nextProps.match.params.id);
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
      }
    }
    if(backToTop && backToTop === 'true') {
      this.setState({
        activeTab: this.baseState.activeTab,
        activeTab1: this.baseState.activeTab1
      });
      localStorage.removeItem("backToTop");
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent, false);
    if (this.props.match.params.id) {
      var ref = document.getElementById(this.props.match.params.id);
      if (ref)
      setTimeout(() => {
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
      }, 500);
      else
        this.props.history.push("/services/app-development-services");
    }
  }

  render() {
    if (this.props.match.params.id) {
      if (this.props.match.params.id === 'android-app-development') {
          document.title = "Android App Development Company India | App Developers";
      } else if (this.props.match.params.id === 'ios-app-development') {
          document.title = "iOS, iPhone App Development Company India | App Developers";
      } else if (this.props.match.params.id === 'react-native-development') {
          document.title = "React Native Development Company India | App Developers";
      } else {
          document.title = "Mobile App Development Company India | App Developers";
      }
    } else {
        document.title = "Mobile App Development Company India | App Developers";
    }
    const {
      pageTitle,
      bannerImg,
      androidAppSlider,
      iOSAppSlider,
      reactNativeSlider 
    } = this.state;
    return (
      <div className="main_application_dev service_category main_services">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}
          <Grid item xs={12}>
            <Grid component="div">
              <Grid className="serivceTabsSide">
                <Grid
                  className={`services-listing ${
                    this.state.tabToggle ? "showTab" : ""
                    }`}
                >
                  <div className="toggle-btn" onClick={this.handleTabToggle}>
                    <i className="material-icons">arrow_forward</i>
                  </div>
                  <Grid item container className="container_custom">
                    <Grid item xs={12}>
                      <div className={`row-flex ${this.state.activeTab1}`}>
                        <div
                          className={`col-flex tab-1 ${
                            this.state.activeTab === "android-app-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "android-app-development",
                              "tab-1"
                            )
                          }
                          title="Android App Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={android}
                                className="withoutActive"
                                alt="Android App Development"
                                title="Android App Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={android_White}
                                className="withActive"
                                alt="Android App Development"
                                title="Android App Development"
                              />
                            </div>
                            <Typography>
                              Android App
                              <br /> Development{" "}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-2 ${
                            this.state.activeTab === "ios-app-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "ios-app-development",
                              "tab-2"
                            )
                          }
                          title="IOS App Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={apple}
                                className="withoutActive"
                                alt="IOS App Development"
                                title="IOS App Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={apple_White}
                                className="withActive"
                                alt="IOS App Development"
                                title="IOS App Development"
                              />
                            </div>
                            <Typography>
                              IOS App
                              <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-3 ${
                            this.state.activeTab === "react-native-development"
                              ? "active"
                              : ""
                            }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "react-native-development",
                              "tab-3"
                            )
                          }
                          title="React Native Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={reactNative}
                                className="withoutActive"
                                alt="React Native Development"
                                title="React Native Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={reactNative_White}
                                className="withActive"
                                alt="React Native Development"
                                title="React Native Development"
                              />
                            </div>
                            <Typography>
                              React Native
                              <br /> Development
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}

          {/* Start - Section Design "First Section Of Service Category Page" */}
          <Grid item xs={12}>
            <Grid component="div" className="sec_padding sec_one">
              <Grid item container className="container_custom">
                <Grid item xs={12} className="title_row">
                  <aside className="">
                    <Typography
                      variant="h3"
                      className="head_title mb-3"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="1000"
                    >
                      Our End-to-End Mobile App Development Services
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="1500"
                    >
                      Being a <strong>leading mobile app development company</strong>, Visions Software has extensive experience in creating enterprise-grade, native & hybrid mobile applications that power mission-critical processes and support your digital transformation journey. We offer mobile enablement and re-engineering services across all the <strong> major mobile platforms including iOS, Android, React, and Windows Mobile</strong>.
                    </Typography>
                    {/* <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="2000"
                    >
                      Every business is turning to digital world and if you are
                      not on the best website, you are losing business and
                      fueling the growth of your competitor.
                    </Typography> */}
                  </aside>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "First Section Of Service Category Page" */}

          {/* Start - Section Design "Android App Development" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits androidAppSec bg-lightgrey section_title_space"
              id="android-app-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title "
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Android App Development
                    </Typography>
                    <Typography
                      className="head_paragraph "
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      With the increasing demand for Android devices, creating an app that can be run seamlessly on any android device available in the market is very important, if you want to connect with your potential customers. Visions is a <strong>leading Android app development company in India</strong> specializing in offering innovative, robust, scalable, intuitive and user-friendly mobile apps for Android devices. If you're planning to invest in an Android application to bring your company to new heights of success, <strong> Visions </strong> would be happy to help. Our Android app developers can design, develop and launch Android apps for almost all the categories that deliver consistent value throughout the Android app development lifecycle.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="Hybrid App Development"
                      title="Hybrid App Development"
                      src={HybridAppDevelopment}
                    />
                    <Typography variant="h4" className="head_title ">
                      Hybrid App Development
                    </Typography>
                    <Typography className="head_paragraph ">
                      Our mobile app developers are capable to make the finest <strong>Hybrid mobile apps</strong> that can run across multiple platforms. We provide cost-effective hybrid mobile application development services.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="Custom Android App Development"
                      title="Custom Android App Development"
                      src={CustomAndroidAppDevelopment}
                    />
                    <Typography variant="h4" className="head_title ">
                      Custom Android App Development
                    </Typography>
                    <Typography className="head_paragraph ">
                      Join our mobile app development company to get the custom <strong>Android app development services</strong>. Our professional Android application developers create robust, user-centric, and innovative apps with great interfaces.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="Native Android App Development"
                      title="Native Android App Development"
                      src={NativeAndroidAppDevelopment}
                    />
                    <Typography variant="h4" className="head_title ">
                      Native Android App Development
                    </Typography>
                    <Typography className="head_paragraph ">
                      Get the best performing, fast loading, secure, interactive, and intuitive Native apps for your next project. We have a skilled team of mobile app developers that deliver the business centric <strong>Native app development services</strong>.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="Enterprise Android App Development"
                      title="Enterprise Android App Development"
                      src={EnterpriseAndroidAppDevelopment}
                    />
                    <Typography variant="h4" className="head_title ">
                      Enterprise Android App Development
                    </Typography>
                    <Typography className="head_paragraph ">
                      We are a team of experienced Android App developers that create the finest quality enterprise <strong>android applications</strong> to meet the client's business needs and provides a flawless user interface with exceptional features.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid className="container_custom">
                <SliderServiceCatPagePortfolio3 data={androidAppSlider} />
              </Grid>
              <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                <Link to="/hire-developers/hire-android-app-developers" className="dark-btn-effect all-category-mobile" title="Hire Android App Developers">
                  Hire Android App Developers
                  </Link>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Android App Development" */}

          {/* Start - Section Design "iOS App Development" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        iOS App Development
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        With iOS commanding a massive growth in terms of monetization, businesses need a <strong>professional iPhone app development company</strong> as their partner to execute a mobile strategy, which keeps them ahead of curve. At <strong> Visions  Software</strong>, we deliver winning <strong> iPhone apps</strong>.
                      </Typography>
                      {/* <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <strong>
                          We Build a Brilliant Solution for iPhone App
                          Development
                        </strong>
                      </Typography> */}
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={SpecializedIOSDevelopers} alt="Specialized IOS Developers" title="Specialized IOS Developers" />
                        <Typography variant="h5" className="mb-1">
                          Specialized IOS Developers
                            </Typography>
                        <Typography className="head_paragraph">
                          Experienced iPad and iPhone app developers that specialize in <strong>iOS development</strong> designated to your project.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={EndToEndService} alt="End-To-End Service" title="End-To-End Service" />
                        <Typography variant="h5" className="mb-1">
                          End-To-End Service
                            </Typography>
                        <Typography className="head_paragraph">
                          Our <strong> iOS development </strong> services include consulting and product strategy, <strong> UX/UI design</strong>, development, testing and technical delivery.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="IOS App Development" title="IOS App Development" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={AppStoreDeployment} alt="App Store Deployment" title="App Store Deployment" />
                        <Typography variant="h5" className="mb-1">
                          App Store Deployment
                            </Typography>
                        <Typography className="head_paragraph">
                          We handle the entire App Store submission process including the listing, descriptions, assets and more.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={MaintenanceSupport} alt="Maintenance & Support" title="Maintenance & Support" />
                        <Typography variant="h5" className="mb-1">
                          Maintenance & Support
                            </Typography>
                        <Typography className="head_paragraph">
                          We offer attractive maintenance and support packages on top of our iOS development services that help you keep on top of platform updates and app upgrades.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <SliderServiceCatPagePortfolio3 data={iOSAppSlider} />
                <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                  <Link to="/hire-developers/hire-ios-app-developers" className="dark-btn-effect all-category-mobile" title="Hire IOS App Developers">
                    Hire IOS App Developers
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "iOS App Development" */}

          {/* Start - Section Design "React Native App Development" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits androidAppSec bg-lightgrey section_title_space"
              id="react-native-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title "
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <strong>React Native App Development</strong>
                    </Typography>
                    <Typography
                      className="head_paragraph "
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <strong>React native</strong> is an awesome platform for developing mobile applications using <strong> JavaScript</strong>. We not only build HTML5 or hybrid apps but are also experienced enough to develop real-time mobile applications using objective C or java. With this new technology, the entire mobile app development industry has been transformed and has become more efficient and speedier in their development processes.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="React Native Consulting"
                      title="React Native Consulting"
                      src={ReactNativeConsulting}
                    />
                    <Typography variant="h4" className="head_title ">
                    <strong>React Native Consulting</strong>
                    </Typography>
                    <Typography className="head_paragraph ">
                      Our experts get in touch with you to understand your mobile app development goals. Once we understand the product goals, we provide consulting and roadmap for the complete project and layout a plan about leveraging React Native.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="React Native Development"
                      title="React Native Development"
                      src={ReactNativeDevelopment}
                    />
                    <Typography variant="h4" className="head_title ">
                    <strong>React Native Development</strong>
                    </Typography>
                    <Typography className="head_paragraph ">
                      Right from designing an intuitive UI, developing cross-platform mobile apps for <strong> Android, iOS </strong> and other platforms to customizing the mobile application, our React Native services cover the entire gamut of development.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="React Native Migration"
                      title="React Native Migration"
                      src={ReactNativeMigration}
                    />
                    <Typography variant="h4" className="head_title ">
                    <strong>React Native Migration</strong>
                    </Typography>
                    <Typography className="head_paragraph ">
                      Whether <strong>reengineering existing legacy mobile app</strong> or migrating it to React Native with improved <strong>UI and UX </strong>, our experts have the capabilities to modernize your mobile application in all aspects.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="React Native Team Augmentation"
                      title="React Native Team Augmentation"
                      src={ReactNativeTeamAugmentation}
                    />
                    <Typography variant="h4" className="head_title ">
                      React Native Team Augmentation
                    </Typography>
                    <Typography className="head_paragraph ">
                      We become an extended arm for the clients by augmenting a team of React Native experts and following outsourced development model. You can easily scale the teams up/down by hiring.
                    </Typography>
                  </div>
                </Grid>
                <SliderServiceCatPagePortfolio3 data={reactNativeSlider} />
                {/* <React.Fragment>
                  <Grid item xs={12}>
                      <Grid className="serviceCatPortfolioSlider2 serviceCatPortfolioSlider3 sec_padding pt-0 pb-0" data-aos="fade-up" data-aos-duration="800">
                          <Grid item container className="">
                              <Grid item xs={12}> 
                                <div className="custom_slider_wrap">
                                {reactNativeSlider.map((item, key) =>
                                <div key={key}>
                                  <aside>
                                      <div className="slide_item testimonial_list">
                                          <div className="website_img">
                                              <img src={item.image} alt={item.title} title={item.title} />
                                          </div>
                                          <div className="content_wrap">
                                              <Typography className="title">{item.title}</Typography>
                                              <div className="btnDownloadWrap">
                                                  {item.iOSBtnLink && 
                                                  <a href={item.iOSBtnLink} className="iOS-btn" target="_blank" rel="noopener noreferrer">
                                                      <img src={iOSAppStore} title="Download From AppStore" alt="Download From AppStore" />
                                                  </a>
                                                  }
                                                  {item.androidBtnLink && 
                                                  <a href={item.androidBtnLink} className="android-btn" target="_blank" rel="noopener noreferrer">
                                                      <img src={googlePlayStore} title="Download From GooglePlayStore" alt="Download From GooglePlayStore" />
                                                  </a>
                                                  }
                                              </div>
                                          </div> 
                                        </div>
                                  </aside>
                                  </div>
                                )}
                                </div>
                              </Grid>
                          </Grid>
                      </Grid> 
                  </Grid>            
                </React.Fragment> */}
                
                <Grid item xs={12} className="btn-wrap" data-aos="fade-up" data-aos-duration="1000">
                  <Link to="/hire-developers/hire-react-native-developers" className="dark-btn-effect all-category-mobile" title="Hire React Native App Developers">
                    Hire React Native App Developers
                    </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "React Native App Development" */}

          {/* Start - NumberCount Import From Common Components */}
          <Suspense fallback={<div></div>}>
          <NumberCount />
          </Suspense>
          {/* End - NumberCount Import From Common Components */}
          
        </Grid>
      </div>
    );
  }
}

export default ApplicationDev;
