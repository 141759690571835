import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Advancecodingskills from '../../assets/images/HireDevelopers/HirePHP/Advancecodingskills.svg';
import Committedwork from '../../assets/images/HireDevelopers/HirePHP/Committedwork.svg';
import DedicatedSupportMaintenance from '../../assets/images/HireDevelopers/HirePHP/DedicatedSupportMaintenance.svg';
import Easyfunctioning from '../../assets/images/HireDevelopers/HirePHP/Easyfunctioning.svg';
import Fastprocessing from '../../assets/images/HireDevelopers/HirePHP/Fastprocessing.svg';
import RichExperience from '../../assets/images/HireDevelopers/HirePHP/RichExperience.svg';
import SecureStable from '../../assets/images/HireDevelopers/HirePHP/SecureStable.svg';
import VariousFrameworks from '../../assets/images/HireDevelopers/HirePHP/VariousFrameworks.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HirePHP/Php-Banner1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HirePHPDev extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Hire PHP Developers",
            bannerImg: Hire_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: "Why to choose PHP for Web development?",
                    TypographyDescription: "If you are looking for speed and high processing in your application, PHP is the answer to your requirements. With its fast page loading and data processing, it boost the speed of your application."
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: "Can I build my whole application using PHP?",
                    TypographyDescription: "PHP is the server-side scripting language and is used as backend technology in combination with front end technologies like HTML/CSS, JavaScript, jQuery, React, Angular among others."
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: "What is the latest version of PHP?",
                    TypographyDescription: "PHP is a free and open source language with latest version of 7.4.1 updated in Dec, 2019."
                },
                {
                    expanded: 'panel4',
                    ariaControls: 'panel4d-content',
                    panelId: 'panel4d-header',
                    typographyText: "Is PHP secure?",
                    TypographyDescription: "PHP language is highly secure, stable and fast."
                }
            ]
        };
    }
    componentWillMount() {
        let isAuthenticated = !localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) {
            this.props.history.push("/login");
            window.location.reload();
        }
    }
    render() {
        document.title = "Hire Dedicated PHP Web Developers | Hire PHP Programmer";
        const {
            pageTitle, bannerImg
        } = this.state;
        return (
            <div className="main_hireEcommerce main_hireDevelopers">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - HireUsForm Import From Root Of Components Folder */}
                    <HireUsForm technology="PHP Developers" />
                    {/* End - HireUsForm Import From Root Of Components Folder */}

                    {/* Start - Section Design "Advantages of Hiring Us" */}
                    <Grid item xs={12} className="main_application_dev">
                        <Grid
                            component="div"
                            className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
                            id="ios-app-development"
                        >
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <div className="text_wrap title_row">
                                        <aside>
                                            <Typography
                                                variant="h2"
                                                className="head_title"
                                                data-aos="fade-up"
                                                data-aos-duration="800"
                                            >
                                                Advantages of Hiring Us </Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1200"
                                            >
                                                We are having a team of expert <strong>PHP developers</strong> to deliver you result oriented <strong>PHP applications</strong>. We offer advanced and custom coded solutions for your diverse business requirements. We are capable of handling <strong>PHP applications</strong> form simple websites to advance domain specific solutions.</Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1400"
                                            >
                                                <strong>PHP is the free and open source technology in nature and can render dynamic pages and applications.</strong>
                                            </Typography>
                                        </aside>
                                    </div>
                                    <Grid item container xs={12} className="major_networks_wrap">
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={VariousFrameworks} alt="Various Frameworks" title="Various Frameworks" />
                                                <Typography variant="h5" className="mb-1">
                                                    Various Frameworks</Typography>
                                                <Typography className="head_paragraph">
                                                    <strong>PHP</strong> offers various frameworks to choose from, to fulfil a unique requirement of a business.</Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={SecureStable} alt="Secure and Stable" title="Secure and Stable" />
                                                <Typography variant="h5" className="mb-1">
                                                    Secure and Stable</Typography>
                                                <Typography className="head_paragraph">
                                                    <strong>PHP</strong> is a feature rich language, having utmost security, stability and compatibility with all platforms and operating systems. </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                                                <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={Fastprocessing} alt="Fast Processing" title="Fast Processing" />
                                                <Typography variant="h5" className="mb-1">
                                                    Fast Processing</Typography>
                                                <Typography className="head_paragraph">
                                                    The processing speed of <strong>PHP</strong> is quite fast and hence is an ideal solution to build E-commerce, ERPs, CRMs etc.</Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={Easyfunctioning} alt="Easy Functioning" title="Easy Functioning" />
                                                <Typography variant="h5" className="mb-1">
                                                    Easy Functioning</Typography>
                                                <Typography className="head_paragraph">
                                                    <strong>PHP</strong> is quite easy to integrate with other web platforms and servers. It works smoothly with all web browsers.</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Advantages of Hiring Us" */}

                    {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Hire our PHP Developers</Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            As a prominent and trusted <strong>PHP</strong> development company, we offer <strong>PHP</strong> based platforms which are packed with best features of Advanced <strong>PHP</strong> frameworks.
                              </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                                    <div className="list_key_boxes">
                                        <img alt="Rich Experience" title="Rich Experience" src={RichExperience} />
                                        <Typography variant="h4" className="head_title">
                                            Rich Experience</Typography>
                                        <Typography className="head_paragraph">
                                            Our developers have vast and rich experience in delivering highly secure, stable and complex applications as per client’s specific needs.</Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                                    <div className="list_key_boxes">
                                        <img alt="Advance Coding Skills" title="Advance Coding Skills" src={Advancecodingskills} />
                                        <Typography variant="h4" className="head_title">
                                            Advance Coding Skills</Typography>
                                        <Typography className="head_paragraph">
                                            We extensively make use of latest development tools and technologies to build the applications, following highest code standards.</Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="list_key_boxes">
                                        <img alt="Committed to Work" title="Committed to Work" src={Committedwork} />
                                        <Typography variant="h4" className="head_title">
                                            Committed to Work</Typography>
                                        <Typography className="head_paragraph">
                                            We will make sure to complete your project within the provided time and deliver it with utmost satisfaction.</Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="list_key_boxes">
                                        <img alt="Dedicated Support and Maintenance" title="Dedicated Support and Maintenance" src={DedicatedSupportMaintenance} />
                                        <Typography variant="h4" className="head_title">
                                            Dedicated Support and Maintenance</Typography>
                                        <Typography className="head_paragraph">
                                            Our developers are just a message away to cater to any of your needs or any bug or issues you may be facing during the real time execution of your application.</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Hire Our Developers KeyBenefits" */}

                    {/* Start - Section Design "Why Hire Us" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="why_hire_us sec_padding">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">A team with right skill set and expertise is all you need to get your plan rolling for your next <strong>PHP</strong> project.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                                        <ul className="left_list lists_hire_us">
                                            <li>Dedicated team to work and ensure quality. <img alt="tick" className="tick_done" src={tick_done} /></li>
                                            <li>Expert with all <strong>PHP</strong> frameworks and tech stack.  <img alt="tick" className="tick_done" src={tick_done} /></li>
                                            <li>Regular communication and reporting about the project. <img alt="tick" className="tick_done" src={tick_done} /></li>
                                        </ul>
                                    </Grid>
                                    <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                                        <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                                    </Grid>
                                    <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                                        <ul className="right_list lists_hire_us">
                                            <li><img alt="tick" className="tick_done" src={tick_done} /> Deliver result-oriented <strong>PHP</strong> applications.</li>
                                            <li><img alt="tick" className="tick_done" src={tick_done} /> Ready to deliver customer-centric solutions.</li>
                                            <li><img alt="tick" className="tick_done" src={tick_done} /> Expert <strong>PHP</strong> Developers, available to work for hourly, part-time, full time basis.</li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Why Hire Us" */}

                    {/* Start - Section Design "We Are Here to Help You" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">There might me some questions popping out in your head, so you may find your answer below.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                                        <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                                        <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "We Are Here to Help You" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <NumberCount />
                    {/* End - NumberCount Import From Common Components */}

                </Grid>
            </div>
        );
    }
}

export default HirePHPDev;
