import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LazyLoad from "react-lazyload";

// Get images Start
import client_bayside from "../../assets/images/HomePage/client_bayside.png";
import client_cmoa from "../../assets/images/HomePage/client_cmoa.png";
import client_simple_bath from "../../assets/images/HomePage/simple_bath.png";
import client_jawcon from "../../assets/images/HomePage/client_jawcon.png";
import client_rackzon from "../../assets/images/HomePage/client_rackzon.png";
import client_deking_decks from "../../assets/images/HomePage/client_deking_decks.png";
import client_eqho from "../../assets/images/HomePage/client_eqho.png";
import client_turfgreen from "../../assets/images/HomePage/client_turfgreen.png";
// Get images End

// Get Files for Slider Start
import Slider from "../../slider";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End

class OurClients extends Component {
  render() {

    const settingsClientslogo = {
        infinite: true,
        dots: false,
        arrows: true,
        accessibility: false,
        slidesToShow: 6,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 1279,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2,
            }
          }
        ]
      };

    return (
        <Grid item xs={12}>
        <Grid component="div" className="our_clients sec_padding" data-aos="fade-up" data-aos-duration="1500">
          <Grid item container className="container_custom">
            <Grid item xs={12}>
              <Typography
                variant="h2"
                className="head_title"
                data-aos="fade-up"
                data-aos-easing="ease-in-cubic"
              >
                Our Clients
              </Typography>
              <Typography
                className="head_paragraph"
                data-aos="fade-up"
                data-aos-easing="ease-in-cubic"
              >
                Who Are Our Customers!
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="clients_logo">
            <Slider {...settingsClientslogo}>
            <div> 
              <div className="brand_wrap"> 
                <a href="https://www.hapkidoqld.com.au/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-duration="400"> 
                  <LazyLoad once><img width="auto" height="auto" alt="Bayside Hapkido"title="Bayside Hapkido"src={client_bayside} /></LazyLoad> 
                </a> 
              </div> 
            </div> 
            <div> 
              <div className="brand_wrap"> 
                <a href="https://www.columbusmuseum.org/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-duration="800">
                    <LazyLoad once><img width="auto" height="auto" alt="CMOA" title="CMOA" src={client_cmoa} /></LazyLoad> 
                </a> 
              </div> 
            </div> 
            <div> 
              <div className="brand_wrap">
                <a href="https://www.jawcon.com.au/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-duration="1000"> 
                    <LazyLoad once><img width="auto" height="auto" alt="Jawcon" title="Jawcon" src={client_jawcon} /></LazyLoad> 
                </a>
              </div> 
            </div> 
            <div>
              <div className="brand_wrap"> 
                <a href="https://www.rackzone.ie/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-duration="1200"> 
                    <LazyLoad once><img width="auto" height="auto" alt="Rackzone"title="Rackzone"src={client_rackzon} /></LazyLoad> 
                </a>
              </div> 
            </div> 
            <div> 
              <div className="brand_wrap">
                <a href="https://www.dekingdecks.com.au/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-duration="1400"> 
                    <LazyLoad once><img width="auto" height="auto" alt="Deking Decks"title="Deking Decks"src={client_deking_decks} /></LazyLoad> 
                </a> 
              </div> 
            </div> 
            <div> 
              <div className="brand_wrap">
                <a href="http://eqho.com" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-duration="1800"> 
                    <LazyLoad once><img width="auto" height="auto" alt="EQHO" title="EQHO" src={client_eqho} /></LazyLoad> 
                </a> 
              </div>
            </div> 
            <div>
              <div className="brand_wrap">  
                <a href="https://www.turfgreen.com.au/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-duration="2000"> 
                    <LazyLoad once><img width="auto" height="auto" alt="Turf Green"title="Turf Green"src={client_turfgreen} /></LazyLoad> 
                </a>
              </div> 
            </div> 
            <div> 
              <div className="brand_wrap"> 
                <a href="https://www.simplebathohio.net/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-duration="2200"> 
                    <LazyLoad once><img width="auto" height="auto" alt="SimpleBath"title="SimpleBath"src={client_simple_bath} /></LazyLoad> 
                </a> 
              </div>
            </div>
            </Slider>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default OurClients;
