import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

// Get Images Start
import career_banner from "../../assets/images/careers/career_banner.jpg";
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/career.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from '../common/InnerPagesBanner';
import NumberCount from "../common/NumberCount";
// Get Common Components End

class career extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Career",
            bannerImg: career_banner,
            activeTab: ''
        };
        smoothscroll.polyfill();
    }
    componentWillMount() {
        let isAuthenticated = !localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) {
            this.props.history.push("/login");
            window.location.reload();
        }
    }

    handleFormRedirect = (job) => {
        localStorage.setItem('jobAppliedFor', job);
    }

    getExperienceyear() {
        var today = new Date();
        var start_date = new Date("june 02, 1993");
        var years_now = today.getFullYear() - start_date.getFullYear();
        var m = today.getMonth() - start_date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) {
            years_now--;
        }
        return years_now;
    }

    handleTabSelect = (tab) => {
        this.setState({ activeTab: tab})
        var ref = document.getElementById(tab);
        window.scrollTo({ top: ref.offsetTop+800, behavior: "smooth" });
    }

    render() {
        document.title = "Careers";
        const {
            pageTitle,
            bannerImg,
            activeTab
        } = this.state;

        return (
            <div className="main_career">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - Section Design "Jobs Click To Scroll Tabs" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="two_coloumn_Below_Banner_layout_3 sec_padding">
                            <Grid item container className="">
                                <Grid item xs={12} lg={6} data-aos="fade-right" data-aos-duration="400">
                                    <div className="left_side max-width-655 ml-auto">
                                        <Typography variant="h2" className="head_title text-left">
                                        Are You Looking For            
                                        </Typography>
                                        <Typography className="head_paragraph text-left">
                                        To service our expanding client base, we offer the following opportunities to outstanding professionals wishing to join our agile and cohesive team. We are an equal opportunity employer where mutual respect, teamwork, a sense of fun and camaraderie are all part of our culture. <br />
                                        <strong>
                                        Currently we have the following openings, if you are up for a challenge, are a go-getter then this is for you
                                        </strong>
                                        </Typography>
                                        <ul className="head_paragraph text-left">
                                            <li>Exposure to an international client base? An opportunity to work on the latest technologies?</li>
                                            <li>Personal and career development?</li>
                                            <li>A friendly and co-operative team environment?</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <div className="right_side_tabs" data-aos="fade-left">
                                        <div className="career_tabs">
                                            <ul>
                                                <li onClick={() => this.handleTabSelect('iOS-Developers')} className={activeTab === 'iOS-Developers' ? 'active':''}>
                                                    <Typography className="head_paragraph">iOS Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Senior-Php-Developers')} className={activeTab === 'Senior-Php-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Senior Php Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Senior-Net-Developers')} className={activeTab === 'Senior-Net-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Senior .Net Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Android-Developers')} className={activeTab === 'Android-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Android Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Angular-Developers')} className={activeTab === 'Angular-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Angular Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Laravel-Developers')} className={activeTab === 'Laravel-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Laravel Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Web-Designers')} className={activeTab === 'Web-Designers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Web Designers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Graphic-Designers')} className={activeTab === 'Graphic-Designers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Graphic Designers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Qa-Testers')} className={activeTab === 'Qa-Testers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">QA Testers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Business-Development-Executive')} className={activeTab === 'Business-Development-Executive' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Business Development Executive</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('SEO-Executive')} className={activeTab === 'SEO-Executive' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">SEO Executive</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Business-Analyst')} className={activeTab === 'Business-Analyst' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Business Analyst</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Mean-Stack-Developers')} className={activeTab === 'Mean-Stack-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Mean Stack Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Python-Developers')} className={activeTab === 'Python-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Python Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('React-Native-Developers')} className={activeTab === 'React-Native-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">React Native Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('ReactJS-Developers')} className={activeTab === 'ReactJS-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">ReactJS Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Business-Development-Manager')} className={activeTab === 'Business-Development-Manager' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Business Development Manager</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Linux-System-Administrator')} className={activeTab === 'Linux-System-Administrator' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Linux System Administrator</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Ruby-on-Rails-Developers')} className={activeTab === 'Ruby-on-Rails-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Ruby on Rails Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Wordpress-Developers')} className={activeTab === 'Wordpress-Developers' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Wordpress Developers</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Php-Team-Lead')} className={activeTab === 'Php-Team-Lead' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Php Team Lead</Typography>
                                                </li>
                                                <li onClick={() => this.handleTabSelect('Online-Bidder')} className={activeTab === 'Online-Bidder' ? 'active' : ''}>
                                                    <Typography className="head_paragraph">Online Bidder</Typography>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Jobs Click To Scroll Tabs" */}

                    {/* Start - Section Design "Jobs With Us Listing" */}
                    <Grid item xs={12}>
                        <Grid item container className="sec_padding search_job_withUs">
                            <Grid item container className="container_custom">
                                <Grid item xs={12} className="">
                                    <Grid component="div" className="jobs-with-us">
                                        <Typography variant="h2" align="center" className="head_title" data-aos="fade-up" data-aos-duration="400">
                                            Jobs With Us
                                        </Typography>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="iOS-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                    iOS Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <h4>Job Description:</h4>
                                                        <p>
                                                        We are looking for an iOS developer who possesses a passion for pushing mobile technologies to the limits and will work with our team of talented engineers to design and build the next generation of our mobile applications.
                                                        </p>
                                                    </div>
                                                    <ul>
                                                        <li>Built real world apps that are published on the app store.</li>
                                                        <li>Experience in OO design, design patterns &amp; fluency in Objective-C programming (exposure to Swift or React Native would be a plus).</li>
                                                        <li>Experience working with iOS frameworks such as Core Data, Core Animation, Core Graphics and Core Text.</li>
                                                        <li>Experience with third-party libraries and APIs, consuming REST Web Services.</li>
                                                        <li>Unit/Integration tests.</li>
                                                        <li>Experience with third-party libraries and APIs.</li>
                                                        <li>Working knowledge of the general mobile landscape, architectures, trends, and emerging technologies.</li>
                                                        <li>Solid understanding of the full mobile development life cycle.</li>
                                                        <li>Strong knowledge of data structures, algorithms, and designing for performance.</li>
                                                        <li>UI development with Interface Builder and Code, including Auto Layout.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("iOS Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Senior-Php-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    4+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                    Senior Php Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>At least 3-6 years of experience in PHP and related technologies.</li>
                                                        <li>Knowledge of PHP 5, MySQL, Drupal, YII, WordPress, Java Script, Jquery, Ajax HTML, CSS 3 are required.</li>
                                                        <li>Knowledge of Joomla, CodeIgniter, Cake PHP is a plus.</li>
                                                        <li>Analyze system issues and problems and implement solutions rapidly; formulate/use design patterns wherever applicable.</li>
                                                        <li>Meet aggressive deadlines while working under pressure, dedication to building high-quality deliverables while being thorough in all aspects of the development lifecycle.</li>
                                                        <li>Construct and support integrations that are easy to manage, maintain, customize, and configure.</li>
                                                        <li>Have good communication skills.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Senior PHP Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Senior-Net-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    3+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                    Senior .Net Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>3+ yrs Software Developer &amp; Programmer (Microsoft Technologies).</li>
                                                        <li> Developing web-based/Desktop applications and client-server applications using ASP.NET 3.5, ASP.NET 4.0, C#.NET, ADO.NET, LINQ7, MVC 4, MVC 5 with bootstrap and HTML 5.</li>
                                                        <li>Should have working knowledge of creating web services, WCF services and Web API’s.</li>
                                                        <li>Knowledge of Telerik Controls, KindoUI controls in C# is a plus.</li>
                                                        <li>Design and develop applications based on .NET design framework of the client.</li>
                                                        <li>Follow stringent quality guidelines and execute the development plan, following the complete SDLC.</li>
                                                        <li>Developing custom applications using Dot Net.</li>
                                                        <li>Have rich experience of designing &amp; architecture of Web based applications.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Senior .NET Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Android-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                    Android Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className='descriptionWrap'>
                                                        <h4>Job Description:</h4>
                                                        <ul>
                                                            <li>You will be working with cross-functional teams to create and maintain applications while providing valuable input for your team to guide and refine the development process. Communicate.</li>
                                                            <li>You’ll be creating applications/features by following wire-frames and mock-ups that are created based on your discussions with your team.</li>
                                                            <li>You will also be involved in the maintenance for some existing applications built by your team.</li>
                                                            <li>You’ll be writing well documented/self-documenting code for the purposes of readability and maintainability. This will help you and your team save time in the future.</li>
                                                            <li>You will be participating in brainstorming sessions to help your team grow and find the best approach to solve challenges.</li>
                                                            <li>You will take ownership of your work from start to finish and will make the efforts required to deliver a good product.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>You must have:</h4>
                                                        <ul>
                                                            <li>In-depth knowledge of Application Development Fundamentals and the Android SDK/NDK.</li>
                                                            <li>Expertise in creating Custom User Interfaces, Connectivity, Data Storage, Processes and Threads.</li>
                                                            <li>Expertise in Android components like different Views, Fragments, Intents, Activities, Content Providers and Services.</li>
                                                            <li>Experience in creating a cohesive UI/UX that makes your applications feel responsive and intuitive.</li>
                                                            <li>Experience with databases like SQLite and Firebase.</li>
                                                            <li>Familiarity with push notifications and deep linking.</li>
                                                            <li>Experience in creating complex mobile applications by leveraging System Architecture Design concepts to create an excellent user experience.</li>
                                                            <li>Experience working with remote data via REST and JSON.</li>
                                                            <li>Experience with integrating third-party libraries and APIs along with knowledge of commonly used ones.</li>
                                                            <li>Proficiency in version control systems.</li>
                                                            <li>Good knowledge of data structures and algorithms and how to use them in your work.</li>
                                                            <li>A habit of writing code using best practices and optimizations where required.</li>
                                                            <li>At least 2 apps published on the Google Play Store. (We’d like to see them).</li>
                                                        </ul>
                                                    </div>

                                                    <div className="descriptionWrap">
                                                        <h4>Good to have:</h4>
                                                        <ul>
                                                            <li>Experience in React Native and/or Flutter.</li>
                                                            <li>Knowledge of underlying device hardware and its impact on your code.</li>
                                                            <li>Basic knowledge of networking and services like AWS.</li>
                                                            <li>Experience in creating applications using microservices.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Android Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Angular-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                    Angular Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className='descriptionWrap'>
                                                        <h4>Job Description:</h4>
                                                        <p>We are looking for Angular/NodeJS Developer to join our engineering team and help us develop and maintain various software products.
                                                            <br/><br/>Developer responsibilities include writing effective, scalable and testing code, debugging programs and integrating applications. To be successful in this role, you should have experience using front/server-side logic and work well in a team.
                                                            <br/><br/>Ultimately, you’ll build highly responsive web applications that align with our business needs.</p>
                                                    </div>

                                                    <div className="descriptionWrap">
                                                        <h4>Responsibilities:</h4>
                                                        <ul>
                                                            <li>Write effective, scalable code.</li>
                                                            <li>Integrate user-facing elements into applications.</li>
                                                            <li>Test and debug programs.</li>
                                                            <li>Improve functionality of existing systems.</li>
                                                            <li>Implement security and data protection solutions.</li>
                                                            <li>Assess and prioritize feature requests.</li>
                                                            <li>Understand user requirements and provide technical solutions.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Angular Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Laravel-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                    Laravel Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <h4>Responsibilities and Duties:</h4>
                                                    </div>
                                                    <ul>
                                                        <li>Develop and maintain applications with high performance and quality standards.</li>
                                                        <li>Collaborate with the UI/UX team.</li>
                                                        <li>Ensure the code is valid and consistent across the application.</li>
                                                        <li>Utilize backend data services and contribute to increasing existing data services.</li>
                                                        <li>Convey effectively in response to the tasks given.</li>
                                                        <li>Document the development process and architecture.</li>
                                                        <li>Coordinate with other developers in the team and keep Project Manager informed of the status of development.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Laravel Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Web-Designers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    1+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Web Designers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <p>We need a professional Web Designer with good experience in Web Designing using HTML/CSS/WordPress.
                                                            <br/>Candidate who can create and maintain functional, attractive websites with the latest in web design and development trends.
                                                        </p>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Responsibilities and Duties:</h4>
                                                        <ul>
                                                            <li>Candidates should be well versed in HTML5, CSS3, JQUERY, WORDPRESS, Material design (theme integrations) and responsive website designs.</li>
                                                            <li>Expert in Web Layout Designing.</li>
                                                            <li>Should have knowledge of image editing tools such as Photoshop.</li>
                                                            <li>Should be adaptable and able to pick up new techniques.</li>
                                                            <li>Play a key role in the design lifecycle, from brief through to design and release/implementation.</li>
                                                            <li>Ensure all designs work across all browsers and on all devices.</li>
                                                            <li>Help to design elements of mobile apps.</li>
                                                            <li>Modify WordPress templates to reflect the tone and style of the client/brand.</li>
                                                            <li>Ensure all designs produced deliver great user experience.</li>
                                                            <li>Promotional Emails / Email Template / Mailchimp Promotion: Should have knowledge of how emails are prepared in third party tools.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Web Designer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Graphic-Designers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Graphic Designers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>2-3 yrs Experience, working knowledge of Flash, Photoshop, Macromedia Dreamweaver, HTML, CSS, CorelDraw and other implementation solutions.</li>
                                                        <li>Knowledge of Adobe Illustrator and other creative software would be an added advantage.</li>
                                                        <li>Should be able to visualize, and able to develop concepts and create the design templates.</li>
                                                        <li>Knowledge of Action Scripting would be an added advantage.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Graphic Designer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Qa-Testers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    1+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                QA Testers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>Experienced in preparing and executing test cases.</li>
                                                        <li>Candidate should able to perform Requirement Analysis.</li>
                                                        <li>Should able to understand end to end Test Life cycle.</li>
                                                        <li>Should have knowledge of automation tools e.g Selenium.</li>
                                                        <li>Must have good interpersonal and communication skills.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("QA Tester")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Business-Development-Executive">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    1+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Business Development Executive
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>At least 2 years of experience in a web based company.</li>
                                                        <li>Should possess the knowledge of Web and Mobile Technologies.</li>
                                                        <li>Ability to capture customers' requirements and deliver a project with help from the design and programming departments.</li>
                                                        <li>Should be able to write documents including both details specifications and reports.</li>
                                                        <li>Should have online strategies for new and existing clients to generate new business revenue.</li>
                                                        <li>Capable to gather client requirements and open to work with Project Managers to estimate the projects.</li>
                                                        <li>Able to increase revenue from existing clients and enhancing client relationships.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Business Development Executive")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="SEO-Executive">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    1+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                SEO Executive
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>Plan and execute all digital marketing, including SEO/SEM, marketing database, email, social media and display advertising campaigns.</li>
                                                        <li>Design, build and maintain our social media presence.</li>
                                                        <li>Measure and report performance of all digital marketing campaigns, and assess against goals (ROI and KPIs).</li>
                                                        <li>Identify trends and insights, and optimize spend and performance based on the insights.</li>
                                                        <li>Brainstorm new and creative growth strategies.</li>
                                                        <li>Plan, execute, and measure experiments and conversion tests.</li>
                                                        <li>Collaborate with internal teams to create landing pages and optimize user experience.</li>
                                                        <li>Utilize strong analytical ability to evaluate end-to-end customer experience across multiple channels and customer touch points.</li>
                                                        <li>Instrument conversion points and optimize user funnels.</li>
                                                        <li>Collaborate with agencies and other vendor partners.</li>
                                                        <li>Evaluate emerging technologies. Provide thought leadership and perspective for adoption where appropriate.</li>
                                                    </ul>

                                                    <div className="descriptionWrap">
                                                        <h4>Education, Experience, and Requirements:</h4>
                                                        <ul>
                                                            <li>Bachelors or Masters degree in marketing or a related field.</li>
                                                            <li>Proven working experience in digital marketing, particularly within the industry.</li>
                                                            <li>Demonstrable experience leading and managing SEO/SEM, marketing database, email, social media and/or display advertising campaigns.</li>
                                                            <li>Experience in optimizing landing pages and user funnels.</li>
                                                            <li>Experience with A/B and multivariate experiments.</li>
                                                            <li>Solid knowledge of website and marketing analytics tools (e.g., Google Analytics, NetInsight, Omniture, WebTrends, SEMRush, etc.).</li>
                                                            <li>Working knowledge of ad serving tools.</li>
                                                            <li>Experience in setting up and optimizing PPC campaigns on all major search engines.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("SEO Executive")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Business-Analyst">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    4+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Business Analyst
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>Should be able to plan the BA efforts for Pre-sales and post sales.</li>
                                                        <li>Interact with client to do requirements gathering that will include the usage of various elicitation techniques like Document Analysis, Requirement Workshops, etc.</li>
                                                        <li>Ability to create Product Backlogs, Feature Sets, FRDs, Use Case, Business Case, User Stories etc.</li>
                                                        <li>Ability to demonstrate functionalities before and after development, and BA would be managing both using Workflows, Flowcharts, BPMN, UML Diagrams per requirement.</li>
                                                        <li>Manage Project Scope and Scope creeps. Raise Change Requests, interact with Dev team and help in coming up with Impact analysis and estimates.</li>
                                                        <li>Prioritize Requirements independently or in collaboration with Dev Team/Stakeholders using various prioritization techniques.</li>
                                                        <li>Manage BA Performance for BAs working under this person.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Business Analyst")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Mean-Stack-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    1+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Mean Stack Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>Mongo db, Express JS, Angular 2 Node JS.This is a role for someone who thrives in a dynamic, fast-paced environment and who wants to play a critical role in the development and enhancement of core technology products.</li>
                                                        <li>Best of the best MEAN Stack Developer required for a great, unique opportunity with a global leader in Business Consulting and Web Development.</li>
                                                        <li>Strongly influence the direction of a cutting edge web system that provides immediate real time simulation of data for a variety of technical platforms.</li>
                                                        <li>Work with the latest technologies in JavaScript Stack i.e MEAN (Mongo db, Express JS, Angular JS, Node JS) development in a modern, forward thinking environment.</li>
                                                        <li>Excellent communication skills.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Mean Stack Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Python-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    1+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Python Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>Solid knowledge of Python and Django.</li>
                                                        <li>Solid knowledge of standard web technology (HTTP, HTTPS, HTML5, client/server model).</li>
                                                        <li>Basic knowledge of containers and micro Services.</li>
                                                        <li>Knowledge or Experience of Agile and DevOps development methodologies.</li>
                                                        <li>Proficiency in Git.</li>
                                                        <li>Experience with JavaScript, jQuery and Angular.</li>
                                                        <li>Ability to clearly and professionally interact with clients.</li>
                                                        <li>Unix/Linux experience.</li>
                                                        <li>Participating throughout the project lifecycle, from initiation to final product.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Python Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="React-Native-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    1+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                React Native Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <ul>
                                                        <li>Must have Mobile app development experience using IONIC, Phonegap Develop User interfaces for Modern Rich Internet Applications with the latest Front End Technologies.</li>
                                                        <li>Perform product analysis and development tasks of increasingly complex nature which may require extensive research and analysis.</li>
                                                        <li>Develop application code and unit test in the AngularJS.</li>
                                                        <li>Hand on and implements complex AngularJS applications.</li>
                                                        <li>Familiar with current technology and industry standards, approaches, and innovations.</li>
                                                        <li>Skills : AngularJS/Knockout or any other JavaScript Framework, JavaScript (Object Oriented).</li>
                                                        <li>Must have strong experience in JavaScript, Firebase, Angular JS / Node JS/ Mongodb Primary.</li>
                                                        <li>Other Skills :JQuery, Knockout.JS.</li>
                                                        <li>Unit testing: HTML5.0, CSS3.0, DOM, XML, X-Path and XSLT.</li>
                                                    </ul>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("React Native Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="ReactJS-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                ReactJS Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <p>
                                                        We are looking for a ReactJS Developer to join our engineering team and help us develop and maintain various software products.
                                                        ReactJS Developer responsibilities include writing effective, scalable and testing code, debugging programs and integrating applications. To be successful in this role, you should have experience using front/server-side logic and work well in a team.
                                                        <br/>Ultimately, you'll build highly responsive web applications that align with our business needs.
                                                        </p>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Responsibilities:</h4>
                                                        <ul>
                                                            <li>Write effective, scalable code.</li>
                                                            <li>Integrate user-facing elements into applications.</li>
                                                            <li>Test and debug programs.</li>
                                                            <li>Improve functionality of existing systems.</li>
                                                            <li>Implement security and data protection solutions.</li>
                                                            <li>Assess and prioritize feature requests.</li>
                                                            <li>Understand user requirements and provide technical solutions.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Requirements and skills:</h4>
                                                        <ul>
                                                            <li>Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model.</li>
                                                            <li>Experience with popular React.js workflows (such as Flux or Redux).</li>
                                                            <li>Familiarity with newer specifications of EcmaScript.</li>
                                                            <li>Experience with data structure libraries (e.g., Immutable.js).</li>
                                                            <li>Knowledge of isomorphic React is a plus.</li>
                                                            <li>Knowledge of modern authorization mechanisms, such as JSON Web Token.</li>
                                                            <li>Familiarity with modern front-end build pipelines and tools.</li>
                                                            <li>Experience with common front-end development tools such as Babel, Webpack, NPM, etc.</li>
                                                            <li>Experience building any of these features: Personalization, Complex user mgmt. and security requirements, Secure document mgmt. (upload, download, permissioning), Online collaboration tools, Alerts, notifications, Chatbot / live chat / video chat integration.</li>
                                                            <li>Familiarity with code versioning tools such as Git, SVN.</li>
                                                            <li>Team spirit.</li>
                                                            <li>Good problem-solving skills.</li>
                                                            <li>Client handling.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("ReactJS Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Business-Development-Manager">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    4+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Business Development Manager
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <h4>Roles and Responsibilities:</h4>
                                                        <p>
                                                        We are looking for an ambitious and energetic business development executive to help us expand our clientele through Direct Sales. You will be the front of the company and will have the dedication to create and apply an effective sale. The goal is to drive sustainable financial growth through boosting sales and forging strong relationships with clients.
                                                        </p>
                                                        <ul>
                                                            <li>Source new sales opportunities through inbound lead follow-up and outbound cold calls and emails.</li>
                                                            <li>Understand customer needs and requirements.</li>
                                                            <li>Route qualified opportunities to the appropriate sales for further development and closure.</li>
                                                            <li>Close sales and achieve quarterly quotas.</li>
                                                            <li>Research accounts, identify key players, and generate interest.</li>
                                                            <li>Able to manage marketing campaigns and strategies.</li>
                                                            <li>Promote the company's services addressing or predicting clients objectives.</li>
                                                            <li>Prepare sales contracts ensuring adherence to law-established rules and guidelines.</li>
                                                            <li>Build long-term relationships with new and existing customers.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Desired Candidate Profile:</h4>    
                                                        <ul>
                                                            <li>Proven inside sales experience</li>
                                                            <li>Track record of over-achieving quota</li>
                                                            <li>Strong phone presence and experience dialing dozens of calls per day.</li>
                                                            <li>Proficient with corporate productivity and web presentation tools.</li>
                                                            <li>Experience working with Salesforce.com or similar CRM.</li>
                                                            <li>Excellent verbal and written communications skills.</li>
                                                            <li>Strong listening and presentation skills.</li>
                                                            <li>Ability to multi-task, prioritize, and manage time effectively.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Business Development Manager")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Linux-System-Administrator">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Linux System Administrator
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <p>
                                                        We are looking for a Linux System Administrator who will be responsible for designing, implementing, and monitoring the infrastructure.
                                                        </p>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Duties and Responsibilities:</h4>    
                                                        <ul>
                                                            <li>Proactively maintain and develop all Linux infrastructure technology to maintain a 24x7x365 uptime service.</li>
                                                            <li>Engineering of systems administration-related solutions for various project and operational needs.</li>
                                                            <li>Maintain best practices on managing systems and services across all environments.</li>
                                                            <li>Fault finding, analysis and of logging information for reporting of performance exceptions.</li>
                                                            <li>Proactively monitoring system performance and capacity planning.</li>
                                                            <li>Manage, coordinate, and implement software upgrades, patches, hot fixes on servers, workstations, and network hardware.</li>
                                                            <li>Create and modify scripts or applications to perform tasks.</li>
                                                            <li>Provide input on ways to improve the stability, security, efficiency, and scalability of the environment.</li>
                                                            <li>Collaborate with other teams and team members to develop automation strategies and deployment processes.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Qualifications and Requirements:</h4>    
                                                        <ul>
                                                            <li>Have at least 2 years of experience as a Linux Administrator.</li>
                                                            <li>Bachelor's degree in Computer Science, Information Systems or similar relevant degree.</li>
                                                            <li>IN depth knowledge of Linux: RedHat, CentOS, Debian, etc.</li>
                                                            <li>Hands on experience with MySQL and/or MariaDB.</li>
                                                            <li>Familiarity with VMWare and SAN management and concepts.</li>
                                                            <li>Expert in Shell, Perl, and/or Python scripting.</li>
                                                            <li>Solid knowledge of protocols such as DNS, HTTP, LDAP, SMTP and SNMP.</li>
                                                            <li>Additional Linux certifications (RHCT, RHCE and LPIC) will be considered an advantage.</li>
                                                            <li>Strong problem solving and communication skills.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Linux System Administrator")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Ruby-on-Rails-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Ruby on Rails Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <h4>Job Description:</h4>
                                                        <p>
                                                        We are looking for a Ruby on Rails developer who is responsible for managing the interchange of data between the server and the users.
                                                        </p>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Roles and Responsibilities:</h4>
                                                        <ul>
                                                            <li>Design, build and maintain efficient, reusable, and reliable Ruby code.</li>
                                                            <li>Contribute to building an agile environment.</li>
                                                            <li>Test your code.</li>
                                                            <li>Engaged in all aspects of product development and will be working closely with product management, operations, client-engineering and customer success teams.</li>
                                                            <li>Working with an exceptional team to help scale a fast-paced business.</li>
                                                            <li>Ability to work independently on challenging compound problems as well as collaborating with other team members to solve major goals.</li>
                                                            <li>Present complex ideas and approaches in simple communicable terms across engineering and management levels.</li>
                                                            <li>Identify bottlenecks and bugs, and devise solutions to these problems.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Desired Candidate Profile:</h4>    
                                                        <ul>
                                                            <li>Ruby on Rails.</li>
                                                            <li>PostgreSQL or MySQL.</li>
                                                            <li>Git, Continuous Integration, AWS, etc.</li>
                                                            <li>Bonus points for SaaS experience.</li>
                                                            <li>Strong communication, presentation, and technical skills.</li>
                                                            <li>Demonstrated creative problem-solving ability and the ability to learn new technologies/concepts.</li>
                                                            <li>Quick learner who can learn and ramp up quickly.</li>
                                                            <li>Ability to work in a fast-paced changing environment with minimal direction.</li>
                                                            <li>Highly organized with strong time management skills.</li>
                                                            <li>High-energy and persistent.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Ruby on Rails Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Wordpress-Developers">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    2+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                WordPress Developers
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <ul>
                                                            <li>Excellent knowledge of WordPress, Theme customization, Child Themes, Plugin customization, HTML/ CSS.</li>
                                                            <li>Knowledge of PHP, MySQL, HTML, CSS, JavaScript, JQuery, AJAX, J SON, and XML would be an advantage.</li>
                                                            <li>Ability to work per Coding Standards and write Quality Code.</li>
                                                            <li>Effective team coordination with a focus on getting the job done on time and with high-quality standards.</li>
                                                            <li>Must have good troubleshooting skills.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Required Skills:</h4>    
                                                        <ul>
                                                            <li>WordPress</li>
                                                            <li>WP Plugin</li>
                                                            <li>Theme Customization</li>
                                                            <li>HTML 5</li>
                                                            <li>JavaScript</li>
                                                            <li>jQuery</li>
                                                            <li>CSS</li>
                                                            <li>AJAX</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("WordPress Developer")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Php-Team-Lead">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    3+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Php Team Lead
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <p>
                                                        We are looking for the PHP Team Lead, who is excellent at coding, database design, analytical approach. The candidate must have ability to deliver the project and have good communication skills to communicate with the clients and in-house cross-department team members.
                                                        </p>
                                                        <p>
                                                        Candidates who believe in themselves, want to do hard work to achieve heights, have can do attitude and highly optimistic are most welcome.
                                                        </p>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Responsibilities and Duties:</h4>    
                                                        <ul>
                                                            <li>Having excellent communication skills.</li>
                                                            <li>Must be self-driven and highly organized.</li>
                                                            <li>Strong ability to effectively lead a team.</li>
                                                            <li>Design, code, test, debug and document software according to the functional requirements.</li>
                                                            <li>Develop, maintain and support programs/tools for internal and external clients.</li>
                                                            <li>Analyze, diagnose and resolve errors related to their applications.</li>
                                                            <li>Define project planning and execution and on time delivery.</li>
                                                            <li>Provide technical and project management expertise across multiple areas of application development.</li>
                                                            <li>Follow all department standards and methodologies.</li>
                                                            <li>Interaction with cross functional team.</li>
                                                            <li>Familiarity with Agile project Methodologies.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="descriptionWrap">
                                                        <h4>Requirements:</h4>    
                                                        <ul>
                                                            <li>Have at least 5 years PHP programming experience on application level projects.</li>
                                                            <li>Programming skills in PHP / React/ Angular/ YII / Codeigniter / Laravel along MySQL / Postgre / MongoDB database.</li>
                                                            <li>Have sound knowledge in MVC Frameworks, Database Structure, CMS &amp; eCommerce Development.</li>
                                                            <li>Able to write clean code that follow industry standards and scalable.</li>
                                                            <li>Have delivered some projects individually that includes Project Planning, Execution &amp; On-time Delivery.</li>
                                                            <li>Experience in Agile development process &amp; Complete SDLC.</li>
                                                            <li>Have ability to speak in English with clients regularly over phone / messengers and write emails with regular project updates.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Php Team Lead")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid component="div" className="listing-experience-wrapper" data-aos="fade-up" data-aos-duration="400" id="Online-Bidder">
                                            <Grid component="div" className="experience-left">
                                                <Typography variant="h5" align="center">
                                                    3+
                                                </Typography>
                                                <Typography align="center">
                                                    Experience
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="experience-right">
                                                <Typography variant="h4">
                                                Online Bidder
                                                </Typography>
                                            </Grid>
                                            <Grid component="div" className="exp-list-wrapper">
                                                <Grid component="div" className="exp-list-wrapper-inner">
                                                    <div className="descriptionWrap">
                                                        <p>
                                                        We are looking for Business Development Executive with excellent verbal and written communication skills. The ideal candidate should have the ability to call, connect and interact with potential customers.
                                                        </p>
                                                        <ul>
                                                            <li>Expert in sales of IT services like Mobile App Development, Web App development, Digital Marketing to international clients.</li>
                                                            <li>Expert in generating leads from bidding platforms such as Upwork, Guru, AppFutura etc.</li>
                                                            <li>Expert in generating new business through LinkedIn, Facebook &amp; other Social Media Portals.</li>
                                                            <li>Can Meet the Targets within timeline and handle work Pressure.</li>
                                                            <li>Experience in Business Development, project requirement analysis, drafting proposal and negotiation with International clients.</li>
                                                            <li>Excellent PR skills to make long term relationship with clients.</li>
                                                            <li>Should be able to manage sales process independently.</li>
                                                            <li>Regular communication with the management to successfully execute the business strategy.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="btn-wrap text-left">
                                                        <Link to="/apply-form" className="dark-btn-effect all-category-mobile" onClick={() => this.handleFormRedirect("Online Bidder")} title="Apply Now">
                                                        Apply Now
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Start - Section Design "Jobs With Us Listing" */}
                </Grid>

                {/* Start - NumberCount Import From Common Components */}
                <NumberCount />
                {/* End - NumberCount Import From Common Components */}
            </div>
        )
    }
}

export default career;