import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import allow_parallel_development from '../../assets/images/HireDevelopers/HireAngular/allow_parallel_development.svg';
import better_support from '../../assets/images/HireDevelopers/HireAngular/better-support.svg';
import mvc_design from '../../assets/images/HireDevelopers/HireAngular/mvc_design.svg';
import on_time_delivery from '../../assets/images/HireDevelopers/HireAngular/on_time_delivery.svg';
import Our_Expertise_in_Angular from '../../assets/images/HireDevelopers/HireAngular/Our_Expertise_in_Angular.svg';
import Productive_Collaboration from '../../assets/images/HireDevelopers/HireAngular/Productive_Collaboration.svg';
import reuseable_html_components from '../../assets/images/HireDevelopers/HireAngular/reuseable_html_components.svg';
import Smart_Coding_Strategies from '../../assets/images/HireDevelopers/HireAngular/Smart_Coding_Strategies.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HireAngular/Angular-Banner1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireAngularJsDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
            pageTitle: "Hire Angular Developers",
            bannerImg: Hire_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: "Why should I use Angular?",
                    TypographyDescription: "Angular is the trending and open source technology to build interactive real time application and with its reusable component ability, it helps to develop large scale applications in an efficient manner."
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: "What are some main features of Angular?",
                    TypographyDescription: "Angular offers some amazing features like data binding, MVC, routing etc to code applications in a better way."
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: "What is the latest version of Angular?",
                    TypographyDescription: "Launched by Google, the latest in line is Angular 8 being released in May 2019."
                }
            ]
      };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire Angular developer | Hire AngularJS developers";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="Angular Developers" />
          {/* End - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        We have immense experience working with <strong>Angular</strong> as mainstream front-end technology to deliver robust, scalable, interactive designs and application development.
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <strong>
                          Angular JS is the right choice to build real time applications to help scale up your business.
                        </strong>
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={reuseable_html_components} alt="Reusable HTML Components" title="Reusable HTML Components" />
                        <Typography variant="h5" className="mb-1">
                          Reusable HTML Components
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Angular</strong> allows us create reusable HTML components, which are useful for creating a large-scale application.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={allow_parallel_development} alt="Allow Parallel Development" title="Allow Parallel Development" />
                        <Typography variant="h5" className="mb-1">
                          Allow Parallel Development
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Angular</strong> allows parallel development and allows the developers to write and test the code in parallel functions.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={better_support} alt="Better Support" title="Better Support" />
                        <Typography variant="h5" className="mb-1">
                          Better Support
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Angular</strong> is being launched by Google and is quite stable framework. Along with that <strong>Angular</strong> has strong support community ad,hence make constant updates to make it secure and stable.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={mvc_design} alt="MVC Design" title="MVC Design" />
                        <Typography variant="h5" className="mb-1">
                          MVC Design
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Angular</strong> works in <strong>MVC development</strong> pattern and is used widely due to its support community and swift workflow. It ensures faster development, easy maintenance and <strong>SEO optimized</strong> platform.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire Angular Developers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      <strong>Visions</strong> is a skilled and trusted <strong>Angular</strong> Development company. We have successfully delivered satisfactory <strong>Angular</strong> web design and development services all over the world. Hence, you can trust us for any of your requirements for an <strong>Angular</strong> project.
                              </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="Our Expertise in Angular" title="Our Expertise in Angular" src={Our_Expertise_in_Angular} />
                    <Typography variant="h4" className="head_title">
                      Our Expertise in Angular
                              </Typography>
                    <Typography className="head_paragraph">With many of projects, right from simple applications to dynamic web platforms, already in the market, our <strong>Angular</strong> Developers are fully capable to deliver your requirements in the latest <strong>Angular</strong> version.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="Smart Coding Strategies" title="Smart Coding Strategies" src={Smart_Coding_Strategies} />
                    <Typography variant="h4" className="head_title">
                      Smart Coding Strategies
                              </Typography>
                    <Typography className="head_paragraph">
                      We prefer to keep our coding simple and clean with best practices to enable effortless understanding of code.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="Productive Collaboration" title="Productive Collaboration" src={Productive_Collaboration} />
                    <Typography variant="h4" className="head_title">
                      Productive Collaboration
                              </Typography>
                    <Typography className="head_paragraph">
                      We make sure to ensure our development team interacts with you directly to make sure they understood your requirements and expectations and can cater them accordingly.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="On Time Delivery" title="On Time Delivery" src={on_time_delivery} />
                    <Typography variant="h4" className="head_title">
                      On Time Delivery
                              </Typography>
                    <Typography className="head_paragraph">
                      We are committed to deliver your requirements on time, once we have discussed everything and have given you a timeframe for the same.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We have been actively using <strong>Angular</strong> from many years and have developed an expertise to cater to any of your need very well.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li> We use best coding methodologies. <img alt="tick" className="tick_done" src={tick_done} /></li>
                      <li>Expert <strong>Angular</strong> developers team. <img alt="tick" className="tick_done" src={tick_done} /></li>
                      <li>We never compromise with the quality of our work. <img alt="tick" className="tick_done" src={tick_done} /></li>

                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li><img alt="tick" className="tick_done" src={tick_done} /> We offer dedicated support and maintenance to our clients.</li>
                      <li><img alt="tick" className="tick_done" src={tick_done} /> We deliver attractive designs with interactive features.</li>
                      <li><img alt="tick" className="tick_done" src={tick_done} /> Excellent and quick communication.</li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Browse through the most frequently asked questions.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Start - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HireAngularJsDev;
