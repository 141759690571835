import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Client_Focused from '../../assets/images/HireDevelopers/HireWebDesigner/Client_Focused.svg';
import Mobile_Responsive_Design from '../../assets/images/HireDevelopers/HireWebDesigner/Mobile_Responsive_Design.svg';
import Search_Engine_Friendly from '../../assets/images/HireDevelopers/HireWebDesigner/Search_Engine_Friendly.svg';
import Web_Design_Noticed from '../../assets/images/HireDevelopers/HireWebDesigner/Web_Design_Noticed.svg';
import Expert_consultation from '../../assets/images/HireDevelopers/HireWordpress/Expert_consultation.svg';
import Designing_Graphics from '../../assets/images/HireDevelopers/HireWebDesigner/Designing_Graphics.svg';
import Testing_Deployment from '../../assets/images/HireDevelopers/HireWordpress/Testing_Deployment.svg';
import Maintenance_and_Support from '../../assets/images/HireDevelopers/HireWordpress/Maintenance_and_Support.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HireWordpress/HIRE-WORDPRESS-DEVELOPERS1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireWebDesigner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Hire Web Designer",
      bannerImg: Hire_banner,
      HereToHelpYou: [
        {
          expanded: 'panel1',
          ariaControls: 'panel1d-content',
          panelId: 'panel1d-header',
          typographyText: 'Are your plans pre-made templates?',
          TypographyDescription: 'Not a chance! A ton of our rivals buy "subjects" or use pre-made formats to reduce expenses. We hand-code each site to the careful needs of every customer.'
        },
        {
          expanded: 'panel2',
          ariaControls: 'panel2d-content',
          panelId: 'panel2d-header',
          typographyText: 'Do you build landing pages for marketing campaigns?',
          TypographyDescription: "Indeed! Being marketing driven implies that we're promoters of a good landing page."
        },
        {
          expanded: 'panel3',
          ariaControls: 'panel3d-content',
          panelId: 'panel3d-header',
          typographyText: "I continue seeing the expression 'responsive website composition'. I don't get this' meaning?",
          TypographyDescription: "Responsive website architecture is a system used to assemble a site that adjusts its design to fit the size of any gadget. Rather than building an extra site for cell phones, you can bring down beginning expenses by just requiring a solitary site."
        },
        {
          expanded: 'panel4',
          ariaControls: 'panel4d-content',
          panelId: 'panel4d-header',
          typographyText: 'Will I have a state in the graphic design process?',
          TypographyDescription: "Totally. We will probably satisfy you. We can't do that without your information. On the off chance that there are different destinations with a structure style that you might want to copy, we can do that. In the event that you have a logo or brand guarantee that you'd like us to work around, that is no issue. We'll be certain your new site will be a steady and fundamental piece of your general image."
        }
      ]
    };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire Web Designer | Hire Dedicated Website Designers";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="Web Designer" />
          {/* End - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <strong>Visions</strong> is a full-administration Indian Web Design and <strong>Web Development Company</strong>. Our <strong>Web designers</strong> make rousing and brand-centered structure arrangements that intrigue and give a phenomenal client experience. The objective? Changing over site guests to clients, bringing about business development and achievement. Our drawing in and easy to understand website architectures are easy to explore and striking to view.
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Client_Focused} alt="Client Focused Website Design" title="Client Focused Website Design" />
                        <Typography variant="h5" className="mb-1">
                          Client Focused Website Design
                            </Typography>
                        <Typography className="head_paragraph">
                          How client arranged is your site? We are an India website composition organization building premium web encounters for your important site guests.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Search_Engine_Friendly} alt="Search Engine Friendly" title="Search Engine Friendly" />
                        <Typography variant="h5" className="mb-1">
                          Search Engine Friendly
                            </Typography>
                        <Typography className="head_paragraph">
                          If your website is visible, ranked well on search engines and offers quality content, you have hit a home run! Our web design and web development team ensures that your website meets the latest Google .
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Mobile_Responsive_Design} alt="Mobile Responsive Design" title="Mobile Responsive Design" />
                        <Typography variant="h5" className="mb-1">
                          Mobile Responsive Design
                            </Typography>
                        <Typography className="head_paragraph">
                          Mobile web browsing is here to stay and increasing every day. Our websites are mobile-friendly and fully responsive! There is no need for a separate mobile website when you have a well-designed liquid layout.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Web_Design_Noticed} alt="Web Design To Get You Noticed" title="Web Design To Get You Noticed" />
                        <Typography variant="h5" className="mb-1">
                          Web Design To Get You Noticed
                            </Typography>
                        <Typography className="head_paragraph">
                          Make an impression and stand out from your competition. Our web design and web development services define your business in the digital world. We design and build websites that impress, hold attention and lead to online success.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire Our Designers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      Our imaginative group breaks new ground and offers the outstanding Website Designing Services to their customers. Our completely tweaked web compositions produce more prominent brand commitment, higher changes, and quantifiable outcomes. We have the first-string website specialists group in Quite a while that has some expertise in building up the entrancing designs with better ease of use, more grounded substance, enrapturing invitations to take action that expands the customer cooperation.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="Expert Consultation" title="Expert Consultation" src={Expert_consultation} />
                    <Typography variant="h4" className="head_title">
                      Expert Consultation
                              </Typography>
                    <Typography className="head_paragraph">
                      We, as a main UI and UX administrations organization, put in careful endeavors in the User Interface (UI) and User Experience (UX) to give your image a grasping computerized media nearness. Our UX configuration group and UI cooperation in close joint effort to draw out the most excellent and practical sites and applications.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="Designing Graphics" title="Designing Graphics" src={Designing_Graphics} />
                    <Typography variant="h4" className="head_title">
                      Designing Graphics
                              </Typography>
                    <Typography className="head_paragraph">
                      We make instinctive and incredible structure that works amazingly in the front-end and easily in the back-end. Applying our significant market information on understanding your business prerequisite assistance us to convey the most ideal UI and UX structure arrangements.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="Testing & Deployment" title="Testing & Deployment" src={Testing_Deployment} />
                    <Typography variant="h4" className="head_title">
                      Testing & Deployment
                              </Typography>
                    <Typography className="head_paragraph">
                      We will test the website across all devices and screens sizes to make sure, its responsive and ready to deploy. We will also assist you to deploy the website on your domain and hosting.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="Maintenance & Support" title="Maintenance & Support" src={Maintenance_and_Support} />
                    <Typography variant="h4" className="head_title">
                      Maintenance & Support
                              </Typography>
                    <Typography className="head_paragraph">
                      We are masters in front-end design and innovations. Our adherence to severe Agile Scrum standards assist us with conveying the total item in a bound timetable.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Our website design process guarantees that your site makes certain to dazzle potential clients, acquiring more leads for your business.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li>
                        We conceptualize different designs dependent on our underlying gathering. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        The ideas contain fundamental route menus, demo content and important symbolism. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        We investigate web composition from an innovative and sensible viewpoint. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Our planners will assemble a graphical introduction for you. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> We structure SEO well disposed sites to pull in clients to the greeting page.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> We make responsive structures that are perfect with all gadgets.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Your site is always observed to avert brokenness.
                                  </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We figure on that for the achievement of any business, a consistent methodology is a key arrangement. We pursue an exceptionally viable and result-driven way to deal with take your business on the way of progress.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HireWebDesigner;
