import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class InnerPagesBelowBannerTwoColumn_Layout_3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data ? this.props.data[0] : []
    }
  }
  render() {
    const { data } = this.state;
    return (
      <Grid item xs={12}>
        <Grid component="div" className="two_coloumn_Below_Banner_layout_3 sec_padding">
          <Grid item container className="">
            <Grid item xs={12} lg={6} data-aos="fade-right" data-aos-duration="400">
              <div className="left_side max-width-655 ml-auto">
                <Typography variant="h2" className="head_title text-left">
                {data.title}            
                </Typography>
                <Typography className="head_paragraph text-left" dangerouslySetInnerHTML={{ __html: data.description }}></Typography>
                <ul className="head_paragraph text-left">
                  {data.listingLoop && data.listingLoop.length > 0 && data.listingLoop.map((list, key) => (
                    <li key={key} dangerouslySetInnerHTML={{ __html: list }}></li>
                  ))}
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className="right_side" data-aos="fade-left">
                <div className="contentWrap">
                  <Typography className="blue_box" data-aos="fade-down" data-aos-duration="600">{data.blueBoxText}</Typography>
                  <Typography className="red_box" data-aos="fade-left" data-aos-duration="1000">{data.orangeBoxText}</Typography>
                </div>
                <div className="img_wrap">
                  <img src={data.image} alt={data.title}  title={data.title}  />
                  <Typography className="left-aligned-text">{data.leftAlignText}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default InnerPagesBelowBannerTwoColumn_Layout_3;
