import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import sideBar_freeQuote from "./assets/images/sideBar-freeQuote.png";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import HomePage from "./components/HomePage";
import AboutUs from "./components/AboutUs";
import { Link } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import ApplicationDev from "./components/ServicesMenu/ApplicationDev";
import HireReactNativeDev from "./components/HireDevelopers/HireReactNativeDev";
import HireMagentoDev from "./components/HireDevelopers/HireMagentoDev";
import UiUxDesign from "./components/ServicesMenu/UiUxDesign";
import career from "./components/company/career";
import GetFreeQuote from "./components/GetFreeQuote";
import ContactUs from "./components/contact";
import ApplyForm from "./components/apply-form";
import portfolios from "./components/Portfolio/portfolio";
import { createBrowserHistory } from "history";
import WebDevelopment from "./components/ServicesMenu/WebDevelopment";
import ECommerceDevelopment from "./components/ServicesMenu/ECommerceDev";
import DigitalMarketing from "./components/ServicesMenu/DigitalMarketingService";
import Services from "./components/ServicesMenu/Services";
import HirePHPDev from "./components/HireDevelopers/HirePHPDev";
import OurApproach from "./components/company/OurApproach";

import HireASPDev from "./components/HireDevelopers/HireASPDev";
import HireAndroidAppDev from "./components/HireDevelopers/HireAndroidAppDev";
import HireIOSAppDev from "./components/HireDevelopers/HireIOSAppDev";
import HireAngularJsDev from "./components/HireDevelopers/HireAngularJsDev";
import ServerAdminSupportDev from "./components/ServicesMenu/ServerAdminSupportDev";
import PageNotFound from "./components/PageNotFound";
import smoothscroll from "smoothscroll-polyfill";

import HireDevelopers from "./components/HireDevelopers/HireDevelopers";
import HireWordpressDev from "./components/HireDevelopers/HireWordpressDev";
import HireDrupalDev from "./components/HireDevelopers/HireDrupalDev";
import HirePythonDev from "./components/HireDevelopers/HirePytonDev";
import ReactJsDev from "./components/HireDevelopers/HireReactJsDev";
import HireNodeJsDev from "./components/HireDevelopers/HireNodeJsDev";
import HireVueJsDev from "./components/HireDevelopers/HireVueJsDev";
import HirecodeigniterDev from "./components/HireDevelopers/HireCodeIgniterDev";
import HireLaravelDev from "./components/HireDevelopers/HireLaravelDev";
import PrivacyPolicies from "./components/company/PrivacyPolicies";

// This is for Added Animations Start
import AOS from "aos";
import "aos/dist/aos.css";
import HireWebDesigner from "./components/HireDevelopers/HireWebDesigner";
// This is for Added Animations End


const history = createBrowserHistory();

history.listen((location, action) => {
  window.scrollTo(0, 0);
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backToTopButton: "",
      HideToggleFreeQuoteBtn: false
    };
    smoothscroll.polyfill();
    this._timeout = null;
  }

  componentWillMount() {
    AOS.init({
      // Global settings:
      disable: window.innerWidth < 1024, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 150, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom" // defines which position of the element regarding to window should trigger the animation
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent, false);
  }

  listenScrollEvent = e => {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    this._timeout = setTimeout(() => {
      this._timeout = null;
      this.setState({
        HideToggleFreeQuoteBtn: false
      });
    }, 200);

    if (!this.state.HideToggleFreeQuoteBtn) {
      this.setState({
        HideToggleFreeQuoteBtn: true
      });
    }

    if (window.scrollY > 500) {
      this.setState({ backToTopButton: "show" });
    } else {
      this.setState({ backToTopButton: "" });
    }
  };

  handleBackToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
    localStorage.setItem("backToTop", true);
    setTimeout(() => {
      localStorage.removeItem("backToTop", false);
    }, 5000);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent, false);
  }
  
  render() {

    return (
      <Router history={history}>
        {
          !localStorage.getItem("isAuthenticated") && 
          <Header />}
        <div className="App">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/get-a-free-quote" component={GetFreeQuote} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/careers" component={career} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/apply-form" component={ApplyForm} />
            <Route exact path="/our-approach" component={OurApproach} />

            <Route exact path="/services" component={Services} />
            <Route exact path="/services/web-development-services/:id?" component={WebDevelopment} />
            <Route exact path="/services/uiux-design-services/:id?" component={UiUxDesign} />
            <Route exact path="/services/ecommerce-development-services/:id?" component={ECommerceDevelopment} />
            <Route exact path="/services/app-development-services/:id?" component={ApplicationDev} />
            <Route exact path="/services/server-network-admin-support-services/:id?" component={ServerAdminSupportDev} />
            <Route exact path="/services/digital-marketing-services/:id?" component={DigitalMarketing} />

            <Route exact path="/hire-developers" component={HireDevelopers} />
            <Route exact path="/hire-developers/hire-react-native-developers" component={HireReactNativeDev} />
            <Route exact path="/hire-developers/hire-magento-developers" component={HireMagentoDev} />
            <Route exact path="/hire-developers/hire-php-developers" component={HirePHPDev} />
            <Route exact path="/hire-developers/hire-asp-net-developers" component={HireASPDev} />
            <Route exact path="/hire-developers/hire-android-app-developers" component={HireAndroidAppDev} />
            <Route exact path="/hire-developers/hire-ios-app-developers" component={HireIOSAppDev} />
            <Route exact path="/hire-developers/hire-angular-developers" component={HireAngularJsDev} />
            <Route exact path="/hire-developers/hire-wordpress-developers" component={HireWordpressDev} />
            <Route exact path="/hire-developers/hire-drupal-developers" component={HireDrupalDev} />
            <Route exact path="/hire-developers/hire-python-developers" component={HirePythonDev} />
            <Route exact path="/hire-developers/hire-reactjs-developers" component={ReactJsDev} />
            <Route exact path="/hire-developers/hire-nodejs-developers" component={HireNodeJsDev} />
            <Route exact path="/hire-developers/hire-vuejs-developers" component={HireVueJsDev} />
            <Route exact path="/hire-developers/hire-codeigniter-developers" component={HirecodeigniterDev} />
            <Route exact path="/hire-developers/hire-laravel-developers" component={HireLaravelDev} />
            <Route exact path="/hire-developers/hire-web-designer" component={HireWebDesigner} />

            <Route exact path="/portfolios/:pid?/:cid?" component={portfolios} />
            <Route exact path="/privacy-policy" component={PrivacyPolicies} />

            <Route component={PageNotFound} />
          </Switch>
        </div>
        {
          !localStorage.getItem("isAuthenticated") && 
          <Footer />}
        <div className={`back-to-top ${this.state.backToTopButton}`} onClick={this.handleBackToTop} title="Back To Top">
          <i className="material-icons"> arrow_upward </i>
        </div>

        <div className={`buttonStyleFxd ${this.state.HideToggleFreeQuoteBtn ? "hide" : ''}`}>
          <div className="innerContentButton">
            <Link to="/get-a-free-quote" className="buttonLinkGet" title="Get A Free Quote"><img src={sideBar_freeQuote} alt="Get A Free Quote" /></Link>
            <div className="buttonAdjustToggle">
              <span className="upSlide"><i className="material-icons">arrow_forward</i></span>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
