import React, { Component, Suspense, lazy } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import smoothscroll from "smoothscroll-polyfill";

// Get Files for Slider Start
import Slider from "../../slider";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End

// Get Images Start
import seo from "../../assets/images/Header/seo.svg";
import seo2 from "../../assets/images/Header/seo2.svg";
import socialMediaSeo from "../../assets/images/Header/social-media-seo.svg";
import online_reputation_icon from "../../assets/images/Header/online_reputation_icon.svg";
import seo_White from "../../assets/images/white-icons/seo.svg";
import seo2_White from "../../assets/images/white-icons/seo2.svg";
import socialMediaSeo_White from "../../assets/images/white-icons/social-media-seo.svg";
import online_reputation_icon_White from "../../assets/images/white-icons/online_reputation_icon.svg";
import AdvancedSEOAnalysis from "../../assets/images/DigitalMarketing/AdvancedSEOAnalysis.svg";
import analyze from "../../assets/images/DigitalMarketing/analyze.svg";
import Backlinks from "../../assets/images/DigitalMarketing/Backlinks.svg";
import Buildingconversion from "../../assets/images/DigitalMarketing/Buildingconversion.svg";
import CompetitorAnalysis from "../../assets/images/DigitalMarketing/CompetitorAnalysis.svg";
import CustomerReviewsandratings from "../../assets/images/DigitalMarketing/CustomerReviewsandratings.svg";
import digitalmarketing from "../../assets/images/DigitalMarketing/digital-marketing.svg";
import Googlesettings from "../../assets/images/DigitalMarketing/Googlesettings.svg";
import Increasingwebsitetraffic from "../../assets/images/DigitalMarketing/Increasingwebsitetraffic.svg";
import KeywordResearching from "../../assets/images/DigitalMarketing/KeywordResearching.svg";
import LinkBuilding from "../../assets/images/DigitalMarketing/LinkBuilding.svg";
import minimize from "../../assets/images/DigitalMarketing/minimize.svg";
import OnPageElements from "../../assets/images/DigitalMarketing/OnPageElements.svg";
import Raisingbrandawareness from "../../assets/images/DigitalMarketing/Raisingbrandawareness.svg";
import Reachbusinessdirectories from "../../assets/images/DigitalMarketing/Reachbusinessdirectories.svg";
import review from "../../assets/images/DigitalMarketing/review.svg";
import Socialnetwork from "../../assets/images/DigitalMarketing/Socialnetwork.svg";
import WebsiteContent from "../../assets/images/DigitalMarketing/WebsiteContent.svg";
import SetglobalSMOgoals from "../../assets/images/DigitalMarketing/SetglobalSMOgoals.svg";
import Opportunityanalysis from "../../assets/images/DigitalMarketing/Opportunityanalysis.svg";
import DevelopaStrategy from "../../assets/images/DigitalMarketing/DevelopaStrategy.svg";
import Application_Banner from "../../assets/images/DigitalMarketing/Digital_Marekting.jpg";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
// import NumberCount from "../common/NumberCount";
const NumberCount = lazy(() => import("../common/NumberCount"));
// Get Common Components End

class DigitalMarketing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Digital Marketing",
            bannerImg: Application_Banner,
            activeTab: "seo-services-india",
            activeTab1: "tab-1",
            tabToggle: false,
            isClickedEventFired: 0
        };
        smoothscroll.polyfill();
        this.baseState = this.state;
    }
    componentWillMount() {
        let isAuthenticated = !localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) {
            this.props.history.push("/login");
            window.location.reload();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenScrollEvent, false);
    }

    listenScrollEvent = e => {
        if (this.state.isClickedEventFired > 0) {
            var abc = setTimeout(() => {
                this.setState({ isClickedEventFired: 0 });
            }, 5000);
            if (this.state.isClickedEventFired > 1) {
                clearTimeout(abc);
            }
        } else {
            var section1 = this.checkElementInViewport(
                document.getElementById("seo-services-india")
            );
            var section2 = this.checkElementInViewport(
                document.getElementById("local-seo")
            );
            var section3 = this.checkElementInViewport(
                document.getElementById("social-media-optimization")
            );
            var section4 = this.checkElementInViewport(
                document.getElementById("online-reputation-management")
            );

            if (section1 && section2) {
                this.setState({
                    activeTab: "seo-services-india",
                    activeTab1: "tab-1"
                });
            }
            if ((section2 && !section1) || (section2 && section3)) {
                this.setState({
                    activeTab: "local-seo",
                    activeTab1: "tab-2"
                });
            }
            if ((section3 && !section2) || (section3 && section4)) {
                this.setState({
                    activeTab: "social-media-optimization",
                    activeTab1: "tab-3"
                });
            }
            if ((section4 && !section3)) {
                this.setState({
                    activeTab: "online-reputation-management",
                    activeTab1: "tab-4"
                });
            }
        }
    };

    checkElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
            window.innerWidth || document.documentElement.clientWidth;

        const vertInView =
            rect.top <= windowHeight && rect.top-74 + rect.height >= 0;
        const horInView =
            rect.left <= windowWidth && rect.left-74 + rect.width >= 0;

        return vertInView && horInView;
    }

    handleOnClickScroll = (divId, tab) => {
        this.setState({
            activeTab: divId,
            activeTab1: tab,
            isClickedEventFired: this.state.isClickedEventFired + 1
        });
        var ref = document.getElementById(divId);
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
    };

    handleTabToggle = () => {
        this.setState(state => ({ tabToggle: !state.tabToggle }));
    };

    componentWillReceiveProps(nextProps) {
        var backToTop = localStorage.getItem("backToTop");
        if (nextProps.match.params.id) {
            if (nextProps.match.params.id !== this.props.match.params.id) {
                var ref = document.getElementById(nextProps.match.params.id);
                window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
            }
        }
        if (backToTop && backToTop === "true") {
          this.setState({
            activeTab: this.baseState.activeTab,
            activeTab1: this.baseState.activeTab1
          });
          localStorage.removeItem("backToTop");
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.listenScrollEvent, false);
        if (this.props.match.params.id) {
            var ref = document.getElementById(this.props.match.params.id);
            if (ref)
                setTimeout(() => {
                    window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
                }, 500);
            else
                this.props.history.push("/services/digital-marketing-services");
        }
    }

    getExperienceyear() {
        var today = new Date();
        var start_date = new Date("june 02, 1993");
        var years_now = today.getFullYear() - start_date.getFullYear();
        var m = today.getMonth() - start_date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) {
            years_now--;
        }
        return years_now;
    }

    render() {
        if (this.props.match.params.id) {
            if (this.props.match.params.id === 'seo-services-india') {
                document.title = "SEO Services India | Search Engine Optimization Agency";
            } else if (this.props.match.params.id === 'local-seo') {
                document.title = "Local SEO Services Company India | Local SEO Experts";
            } else if (this.props.match.params.id === 'social-media-optimization') {
                document.title = "Social Media Optimization Services Company | SMO Services";
            } else if (this.props.match.params.id === 'online-reputation-management') {
                document.title = "Online Reputation Management Services Company | ORM Services";
            } else {
                document.title = "Digital Marketing Company India | SEO Services Agency";
            }
        } else {
            document.title = "Digital Marketing Company India | SEO Services Agency";
        }
        const { pageTitle, bannerImg } = this.state;

        const settingsPrinciplesListing = {
            dots: true,
            arrows: false,
            infinite: true,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1279.98,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767.98,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };


        return (
            <div className="main_digitalMarketing service_category main_services">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}
                    <Grid item xs={12}>
                        <Grid component="div">
                            <Grid className="serivceTabsSide">
                                <Grid className={`services-listing ${this.state.tabToggle ? "showTab" : ""}`}>
                                    <div className="toggle-btn" onClick={this.handleTabToggle}>
                                        <i className="material-icons">arrow_forward</i>
                                    </div>
                                    <Grid item container className="container_custom">
                                        <Grid item xs={12}>
                                            <div className={`row-flex ${this.state.activeTab1}`}>
                                                <div className={`col-flex tab-1 ${this.state.activeTab === "seo-services-india" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("seo-services-india", "tab-1")} title="Search Engine Optimization">
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={seo} className="withoutActive" alt="Search Engine Optimization" title="Search Engine Optimization" />
                                                            <img width="30" height="30" src={seo_White} className="withActive" alt="Search Engine Optimization" title="Search Engine Optimization" />
                                                        </div>
                                                        <Typography>
                                                            Search Engine
                                                    <br /> Optimization{" "}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className={`col-flex tab-2 ${this.state.activeTab === "local-seo" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("local-seo", "tab-2")} title="Local Seo" >
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={seo2} className="withoutActive" alt="Local Seo" title="Local Seo" />
                                                            <img width="30" height="30" src={seo2_White} className="withActive" alt="Local Seo" title="Local Seo" />
                                                        </div>
                                                        <Typography>
                                                            Local
                                                    <br /> Seo
                                                </Typography>
                                                    </div>
                                                </div>
                                                <div className={`col-flex tab-3 ${this.state.activeTab === "social-media-optimization" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("social-media-optimization", "tab-3")} title="Social Media Optimization">
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={socialMediaSeo} className="withoutActive" alt="Social Media Optimization" title="Social Media Optimization" />
                                                            <img width="30" height="30" src={socialMediaSeo_White} className="withActive" alt="Social Media Optimization" title="Social Media Optimization" />
                                                        </div>
                                                        <Typography>
                                                            Social Media
                                                    <br /> Optimization
                                                </Typography>
                                                    </div>
                                                </div>
                                                <div className={`col-flex tab-4 ${this.state.activeTab === "online-reputation-management" ? "active" : ""}`} onClick={() =>
                                                    this.handleOnClickScroll("online-reputation-management", "tab-4")} title="Online Reputation Management">
                                                    <div className="img-txt-wrap">
                                                        <div className="img-wrap">
                                                            <img width="30" height="30" src={online_reputation_icon} className="withoutActive" alt="Online Reputation Management" title="Online Reputation Management" />
                                                            <img width="30" height="30" src={online_reputation_icon_White} className="withActive" alt="Online Reputation Management" title="Online Reputation Management" />
                                                        </div>
                                                        <Typography>
                                                            Online Reputation
                                                    <br /> Management
                                                </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}

                    {/* Start - Section Design "First Section Of Service Category Page" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="sec_padding sec_one">
                            <Grid item container className="container_custom">
                                <Grid item xs={12} className="title_row">
                                    <aside className="">
                                        <Typography variant="h3" className="head_title mb-3" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                                            Grow your business with Online Marketing Services
                                </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1500">
                                            <strong>  Visions </strong>  as a top-rated <strong>Search Engine Optimization Agency</strong> in India feel proud to deliver successful SEO services in most competitive sectors in different countries of the world. Let us help you to bring your business on the top search results, expand globally and accomplish tremendous growth for your business. In the past <strong> {this.getExperienceyear()} years</strong> , we have helped our clients to have a powerful impact of internet marketing on their business revenue and growing client base.</Typography>
                                    </aside>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "First Section Of Service Category Page" */}

                    {/* Start - Section Design "Search Engine Optimization Services" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits websiteRedesignSec section_title_space bg-lightgrey" id="seo-services-india">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Search Engine Optimization Services
                                </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            At Visions, we have a dedicated team of <strong>professional SEO experts</strong> who can help you in achieving a good amount of traffic from the "organic", "natural" or "unpaid" search results on search engines. Our <strong>Search Engine Optimization company</strong> experts enable every business to rank above the competitors by completing their tailored requirements and help businesses to gain higher visibility on the web.
                                </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1000">
                                        <img alt="Advanced SEO Analysis" title="Advanced SEO Analysis" src={AdvancedSEOAnalysis} />
                                        <Typography variant="h4" className="head_title">
                                            Advanced SEO Analysis
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Our dedicated team of professional <strong> SEO experts</strong>  deeply analyzes the site to find out the weaknesses and the strengths of your website. We provide free advanced SEO audit services and recommendations so you can know where your website needs improvement.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1400">
                                        <img alt="Keyword Researching" title="Keyword Researching" src={KeywordResearching} />
                                        <Typography variant="h4" className="head_title">
                                            Keyword Researching
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Keyword researching helps you target your market within your industry and if you <strong>Hire professional SEO experts</strong> from <strong> Visions </strong>  you can easily achieve your desired results on search results. Hire our Search Engine Optimization Agency to rank your keywords in top searches.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1800">
                                        <img alt="Link Building" title="Link Building" src={LinkBuilding} />
                                        <Typography variant="h4" className="head_title">
                                            Link Building
                                </Typography>
                                        <Typography className="head_paragraph">
                                            <strong> Link Building </strong>  is the most important <strong> SEO</strong>  task that helps you to acquire high authority relevant backlinks for your website. We have a team of dedicated SEO experts that manages the link building in a semantic manner with the goal of increasing the search engine rankings of a web page.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="2200">
                                        <img alt="Competitor Analysis" title="Competitor Analysis" src={CompetitorAnalysis} />
                                        <Typography variant="h4" className="head_title">
                                            Competitor Analysis
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Our search engine optimization company in India has a dedicated team of professional <strong> SEO experts </strong>  that analyze your business competitor’s strengths and weaknesses from every aspect to find new opportunities for your continued online growth.
                              </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Search Engine Optimization Services" */}

                    {/* Start - Section Design "Local SEO Services" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="print-principles printMediaDesignSec section_title_space sec_padding" id="local-seo">
                            <Grid item container className="container_custom">
                                <Grid component="div" className="print-principles-inner">
                                    <Grid item xs={12} className="title_row">
                                        <aside className="">
                                            <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                                Local SEO Services
                                    </Typography>
                                            <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                                Get recognized locally with our local SEO services and drive more users to your website. Being a <strong>leading Search Engine Optimization Agency in India</strong>, we help local businesses and SMEs to increase their visibility among local customers and reach more potential customers. At <strong> Visions </strong> , we help you to take benefit of the local search through our effective local <strong> SEO services </strong> .
                                    </Typography>
                                        </aside>
                                    </Grid>
                                    <Grid className="principles-listing" data-aos="fade-up" data-aos-duration="800">
                                        <div className="graphicsShape2" data-text="Local SEO To Learn">
                                            <div>
                                                <div className="box-wrapper yellow">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <div className="img-wrap-2">
                                                                <div className="img-circle">
                                                                    <img src={OnPageElements} alt="On Page Elements" title="On Page Elements" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">On Page Elements</Typography>
                                                        <Typography className="head_paragraph">Optimize title tags, images, URLs, setup XML Sitemaps and <strong> 301 redirects</strong> .</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper navygreen">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <div className="img-wrap-2">
                                                                <div className="img-circle">
                                                                    <img src={Googlesettings} alt="Google Settings" title="Google Settings" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Google settings</Typography>
                                                        <Typography className="head_paragraph">Create Google my business listing, select business categories, setup <strong> google webmaster</strong>  and google analytics.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper red">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <div className="img-wrap-2">
                                                                <div className="img-circle">
                                                                    <img src={Reachbusinessdirectories} alt="Reach Business Directories" title="Reach Business Directories" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Reach Business Directories</Typography>
                                                        <Typography className="head_paragraph">Create your business listing for Bing, Yelp, social platforms and niche-specific directories.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper pink">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <div className="img-wrap-2">
                                                                <div className="img-circle">
                                                                    <img src={CustomerReviewsandratings} alt="Customer Reviews and ratings" title="Customer Reviews and ratings" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Customer Reviews and Ratings</Typography>
                                                        <Typography className="head_paragraph">Generate genuine reviews, add recognizable testimonials. </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper blue">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <div className="img-wrap-2">
                                                                <div className="img-circle">
                                                                    <img src={WebsiteContent} alt="Website Content" title="Website Content" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Website Content</Typography>
                                                        <Typography className="head_paragraph">Add SSL, add keyword specific content, remove duplicate content, index pages.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="box-wrapper parrotgreen">
                                                    <div className="circleShape">
                                                        <div className="img-wrap">
                                                            <div className="img-wrap-2">
                                                                <div className="img-circle">
                                                                    <img src={Backlinks} alt="Backlinks" title="Backlinks" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                    </div>
                                                    <div className="content">
                                                        <Typography variant="h4" className="head_title">Backlinks</Typography>
                                                        <Typography className="head_paragraph">Build quality backlinks on high domain authority websites, guest posting.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div data-aos="fade-up" data-aos-duration="800">
                                            <Slider className="graphicsShape2" {...settingsPrinciplesListing}>
                                                <div>
                                                    <div className="box-wrapper yellow">
                                                        <div className="circleShape">
                                                            <div className="img-wrap">
                                                                <div className="img-wrap-2">
                                                                    <div className="img-circle">
                                                                        <img src={OnPageElements} alt="On Page Elements" title="On Page Elements" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                        </div>
                                                        <div className="content">
                                                            <Typography variant="h4" className="head_title">On Page Elements</Typography>
                                                            <Typography className="head_paragraph">Optimize title tags, images, URLs, setup XML Sitemaps and 301 redirects.</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="box-wrapper navygreen">
                                                        <div className="circleShape">
                                                            <div className="img-wrap">
                                                                <div className="img-wrap-2">
                                                                    <div className="img-circle">
                                                                        <img src={Googlesettings} alt="Google settings" title="Google settings" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                        </div>
                                                        <div className="content">
                                                            <Typography variant="h4" className="head_title">Google settings</Typography>
                                                            <Typography className="head_paragraph">Create Google my business listing, select business categories, <strong> setup google webmaster and google analytics</strong> .</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="box-wrapper red">
                                                        <div className="circleShape">
                                                            <div className="img-wrap">
                                                                <div className="img-wrap-2">
                                                                    <div className="img-circle">
                                                                        <img src={Reachbusinessdirectories} alt="Reach business directories" title="Reach business directories" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                        </div>
                                                        <div className="content">
                                                            <Typography variant="h4" className="head_title">Reach business directories</Typography>
                                                            <Typography className="head_paragraph">Create your business listing for Bing, Yelp, social platforms and niche-specific directories.</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="box-wrapper pink">
                                                        <div className="circleShape">
                                                            <div className="img-wrap">
                                                                <div className="img-wrap-2">
                                                                    <div className="img-circle">
                                                                        <img src={CustomerReviewsandratings} alt="Customer Reviews and ratings" title="Customer Reviews and ratings" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                        </div>
                                                        <div className="content">
                                                            <Typography variant="h4" className="head_title">Customer Reviews and ratings</Typography>
                                                            <Typography className="head_paragraph">Generate genuine reviews, add recognizable testimonials.</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="box-wrapper blue">
                                                        <div className="circleShape">
                                                            <div className="img-wrap">
                                                                <div className="img-wrap-2">
                                                                    <div className="img-circle">
                                                                        <img src={WebsiteContent} alt="Website Content" title="Website Content" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                        </div>
                                                        <div className="content">
                                                            <Typography variant="h4" className="head_title">Website Content</Typography>
                                                            <Typography className="head_paragraph">Add <strong> SSL</strong> , add keyword specific content, remove duplicate content, index pages.</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="box-wrapper parrotgreen">
                                                        <div className="circleShape">
                                                            <div className="img-wrap">
                                                                <div className="img-wrap-2">
                                                                    <div className="img-circle">
                                                                        <img src={Backlinks} alt="Backlinks" title="Backlinks" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="small-circles-wrapper"><div className="circles-2"></div></div>
                                                        </div>
                                                        <div className="content">
                                                            <Typography variant="h4" className="head_title">Backlinks</Typography>
                                                            <Typography className="head_paragraph">Build quality backlinks on high domain authority websites, guest posting.</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Local SEO Services" */}

                    {/* Start - Section Design "Social Media Optimization" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits broucherDesignSec section_title_space bg-lightgrey" id="social-media-optimization">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title " data-aos="fade-up" data-aos-duration="800">
                                            Social Media Optimization
                                </Typography>
                                        <Typography className="head_paragraph " data-aos="fade-up" data-aos-duration="1200">
                                            Hire dedicated SEO expert from <strong> Visions</strong>  to manage all your social media accounts and reach to your targeted audience across all social media channels. Social media is the best option to grow online businesses so let us help you to promote your business on all social media platforms and fetch genuine customers as per their interests.
                                </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1000">
                                        <img alt="Increasing Website Traffic" title="Increasing Website Traffic" src={Increasingwebsitetraffic} />
                                        <Typography variant="h4" className="head_title">
                                            Increasing website traffic
                                </Typography>
                                        <Typography className="head_paragraph">
                                            <strong>  Social media marketing</strong>  allows you to bring target prospects or customers to your website through different social media such as Facebook, Instagram, Pinterest etc and add-on traffic to the SEO generated organic users.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1400">
                                        <img alt="Building Conversions" title="Building Conversions" src={Buildingconversion} />
                                        <Typography variant="h4" className="head_title">
                                            Building conversions
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Social media is a perfect channel to build loyal following and then further engage your followers by posting your website content and redirecting them to further explore your website. This helps to build conversions as social media will bring the users interested in your product or services.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1800">
                                        <img alt="Raising Brand Awareness" title="Raising Brand Awareness" src={Raisingbrandawareness} />
                                        <Typography variant="h4" className="head_title">
                                            Raising brand awareness
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Social media marketing cam further help your business to build an identity and stand as a brand among its followers. This will further increase your recognition and awareness about the products or services through different social channels with different environments.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="2200">
                                        <img alt="Social Network" title="Social Network" src={Socialnetwork} />
                                        <Typography variant="h4" className="head_title">
                                            Social network
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Word of mouth is the most important tool of networking on social media channels. So creating and posting consistent content and images on all relevant social media channels will help to build a strong social networks for your business.
                              </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item container className="container_custom">
                                <Grid item container className="globel-smo">
                                    <Grid item sm={12} md={6} lg={6} container className="globel-smo-experience">
                                        <div className="smo-wrap">
                                            <div className="smocolumn">
                                                <div className="innerDiv" data-aos="fade-up" data-aos-duration="1000">
                                                    <div className="img-wrapper local-seo">
                                                        <img alt="Set Global SMO Goals" title="Set Global SMO Goals" src={SetglobalSMOgoals} />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <Typography variant="h4">Set Global SMO Goals</Typography>
                                                        <Typography>Develop a goal and decide on the matrices to work on.</Typography>
                                                    </div>
                                                </div>
                                                <div className="innerDiv" data-aos="fade-up" data-aos-duration="1000">
                                                    <div className="img-wrapper local-business">
                                                        <img alt="Opportunity Analysis" title="Opportunity Analysis" src={Opportunityanalysis} />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <Typography variant="h4">Opportunity Analysis</Typography>
                                                        <Typography>Analyse the competitiveness of your business globally.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="smocolumn">
                                                <div className="innerDiv years-exp" data-aos="fade-up" data-aos-duration="1000">
                                                    <div className="exp-text">
                                                        <Typography><span>{this.getExperienceyear()}+</span> years of Experiences</Typography>
                                                    </div>
                                                </div>
                                                <div className="innerDiv" data-aos="fade-up" data-aos-duration="1000">
                                                    <div className="img-wrapper large-enterprice">
                                                        <img alt="Develop a Strategy" title="Develop a Strategy" src={DevelopaStrategy} />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <Typography variant="h4">Develop a Strategy</Typography>
                                                        <Typography>Plan out tactics to get the desirable results.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={6} className="globel-smo-text">
                                        <div className="textWrap text-left" data-aos="fade-left" data-aos-duration="800">
                                            <Typography variant="h4" className="head_title ">Global SMO</Typography>
                                            <Typography className="head_paragraph "><strong> Global SMO</strong>  stands for optimizing your content for a multitude of regions, languages, cultures, local internet regulations & policies and audience worldwide. You’ll need to create a content that can serve multiple regions and languages. Accordingly, the content is optimized for country’s search engine to gain users from all over the world. </Typography>
                                            <Typography className="head_paragraph ">The main part of Global SMO is the globalization of your website by translation your website content for multi-languages, implementing local SEO for content based on region. The process starts with the research of your business niche across countries and work for them accordingly. You can set Global SEO goals and bases on that we can do the opportunity analysis, develop a global SEO strategy.</Typography>
                                            <Typography className="head_paragraph ">For <strong> Global SEO</strong> , we need to optimize  your website for International Search Engines Beyond Google, including Bing, Yahoo, Baidu, Rambler, kvasir among others. We can get huge opportunities for our business through Global SMO, but your strategies can't be similar to the ones you use for traditional or <strong> Local SEO </strong> . So you can hire us for the professional services to globalize your business in an efficient manner.</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Social Media Optimization" */}

                    {/* Start - Section Design "Online Reputation Management" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits websiteRedesignSec section_title_space" id="online-reputation-management">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Online Reputation Management
                                </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            We help businesses to build an online reputation and create a positive connection with their potential customers. Our SEO services agency works effectively to build an impactful online reputation for your business.
                                </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1000">
                                        <img alt="Research" title="Research" src={analyze} />
                                        <Typography variant="h4" className="head_title">
                                            Research
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Research plays a vital role to get started with the online reputation to find out the positive as well as negative results available on the internet.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1400">
                                        <img alt="Suppression" title="Suppression" src={minimize} />
                                        <Typography variant="h4" className="head_title">
                                            Suppression
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Suppressing the negative links, monitoring your business, handling negative content will be the next step for this process.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="1800">
                                        <img alt="Online Reviews" title="Online Reviews" src={review} />
                                        <Typography variant="h4" className="head_title">
                                            Online Reviews
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Replying to online reviews and further sharing your part of stories for the negative comments in another crucial step of this project and allows users to understand both sides of the story.
                                </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                                    <div className="list_key_boxes" data-aos="fade-up" data-aos-duration="2200">
                                        <img alt="Monitor Your Blog" title="Monitor Your Blog" src={digitalmarketing} />
                                        <Typography variant="h4" className="head_title">
                                            Monitor Your Blog
                                </Typography>
                                        <Typography className="head_paragraph">
                                            Updating the blog with positive and progressive content on regular basis allows google to share this material on top of search engine and hence suppressing the negative links.
                              </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Online Reputation Management" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <Suspense fallback={<div></div>}>
                    <NumberCount />
                    </Suspense>
                    {/* End - NumberCount Import From Common Components */}
                    
                </Grid>
            </div>
        );
    }
}

export default DigitalMarketing;
