import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Get Images Start
import team_members_img1 from "../../assets/images/ourTeam/team_members_img1.png";
import team_members_img2 from "../../assets/images/ourTeam/team_members_img2.png";
import team_members_img3 from "../../assets/images/ourTeam/team_members_img3.png";
import team_members_img4 from "../../assets/images/ourTeam/team_members_img4.png";
import team_members_img5 from "../../assets/images/ourTeam/team_members_img5.png";
import team_members_img6 from "../../assets/images/ourTeam/team_members_img6.png";
import team_members_img7 from "../../assets/images/ourTeam/team_members_img7.png";
import team_members_img8 from "../../assets/images/ourTeam/team_members_img8.png";
import office_env from "../../assets/images/ourTeam/office-env-icon.svg";
import staff_icon from "../../assets/images/ourTeam/staff-icon.svg";
import team_process from "../../assets/images/ourTeam/team-process-icon.svg";
// Get Images End

import "../../assets/css/ourteam.css";

// Get Files for Slider Start
import Slider from "../../slider";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End

class OurTeamPromise extends Component {
    getExperienceyear() {
        var today = new Date();
        var start_date = new Date("june 02, 1993");
        var years_now = today.getFullYear() - start_date.getFullYear();
        var m = today.getMonth() - start_date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) 
        {
          years_now--;
        }
        return years_now;
      }
  render() {

    const settingsTeamImages = {
        infinite: true,
        dots: false,
        arrows: true,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2,
            }
          }
        ]
    };

    return (
      <React.Fragment>
        <Grid item xs={12}>
            <Grid component="div" className="quality-services sec_padding">
                <Grid item container className="container_custom">
                    <Grid component="div" className="quality-services-inner">
                    <Typography variant="h2" data-aos="fade-right" data-aos-duration="400">
                        What We Promise High Quality Services
                    </Typography>
                    {/* <Typography>
                        But explain to you how all this mistaken pain this denoun
                        was born give.
                    </Typography> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid component="div" className="process-wrapper sec_padding">
            <Grid item container className="container_custom">
                <Grid component="div" className="process-inner-wrapper">
                <Grid component="div" className="process-flex-wrapper">
                    <Grid component="div" className="process-first-wrapper twenty-five-right-wrap" data-aos="fade-up" data-aos-duration="800">
                    <img
                        alt="Our Team Processes"
                        title="Our Team Processes"
                        src={team_process}
                    />
                    <Typography variant="h3">Our Team Processes</Typography>
                    <ul>
                        <li>
                        Our convergent development process takes the best of both waterfall and agile development procedures.
                        </li>
                        <li>
                        Our procedure permits us the adaptability to modify our undertaking the board way to deal with every customer's one of a kind specialized prerequisites and business needs, while augmenting venture timetables and decreasing advancement costs.
                        </li>
                    </ul>
                    </Grid>
                    <Grid component="div" className="process-first-wrapper twenty-five-right-wrap" data-aos="fade-up" data-aos-duration="900">
                    <img
                        alt="Our Staff"
                        title="Our Staff"
                        src={staff_icon}
                    />
                    <Typography variant="h3">Our Staff</Typography>
                    <ul>
                        <li>
                        Motivation is an immeasurable thing that moves individuals consistently and drives them to a more promising time to come.
                        </li>
                        <li>
                        We accept that by supporting each other with a heart, mind and will you can rouse others and lead people in the future with your vision.
                        </li>
                    </ul>
                    </Grid>
                    <Grid component="div" className="process-first-wrapper twenty-five-right-wrap" data-aos="fade-up" data-aos-duration="1000">
                    <img
                        alt="Office Environment"
                        title="Office Environment"
                        src={office_env}
                    />
                    <Typography variant="h3">Office Environment</Typography>
                    <ul>
                        <li>
                        At Visions, we've constantly realized that the best worth we bring is our capacity to reconsider how to structure and create programming so the organizations we work with can accomplish their objectives.
                        </li>
                        <li>
                        Working one next to the other with our customers, we draw out our aggregate best through genuine correspondence, regard and an enthusiasm for taking care of issues together.
                        </li>
                    </ul>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid component="div" className="twenty-five-team sec_padding">
                <Grid item container className="container_custom">
                    <Grid item xs={12}>
                        <Grid component="div" className="twenty-five-inner">
                            <Grid component="div" className="twenty-five-left-wrap" data-aos="fade-down-right" data-aos-duration="400">                    
                                <Slider {...settingsTeamImages}>
                                    <div> 
                                        <div className="image-wrapper"> 
                                            <img alt="Our Team"title="Our Team"src={team_members_img1} /> 
                                        </div>  
                                    </div>
                                    <div> 
                                        <div className="image-wrapper"> 
                                            <img alt="Our Team"title="Our Team"src={team_members_img2} /> 
                                        </div>  
                                    </div>
                                    <div> 
                                        <div className="image-wrapper"> 
                                            <img alt="Our Team"title="Our Team"src={team_members_img3} /> 
                                        </div>  
                                    </div>
                                    <div> 
                                        <div className="image-wrapper"> 
                                            <img alt="Our Team"title="Our Team"src={team_members_img4} /> 
                                        </div>  
                                    </div>
                                    <div> 
                                        <div className="image-wrapper"> 
                                            <img alt="Our Team"title="Our Team"src={team_members_img5} /> 
                                        </div>  
                                    </div>
                                    <div> 
                                        <div className="image-wrapper"> 
                                            <img alt="Our Team"title="Our Team"src={team_members_img6} /> 
                                        </div>  
                                    </div>
                                    <div> 
                                        <div className="image-wrapper"> 
                                            <img alt="Our Team"title="Our Team"src={team_members_img7} /> 
                                        </div>  
                                    </div>
                                    <div> 
                                        <div className="image-wrapper"> 
                                            <img alt="Our Team"title="Our Team"src={team_members_img8} /> 
                                        </div>  
                                    </div>
                                </Slider>
                                <Grid component="div" className="contentStyleInnerWrap AlignCenterContent">
                                    <Typography><strong>Employee of the Month - A title that keeps the employees motivated to give their best and creates a healthy competition among them.</strong></Typography>
                                </Grid>
                            </Grid>
                            <Grid component="div" className="twenty-five-right-wrap">
                                <Typography variant="h3" data-aos="fade-right" data-aos-duration="400">{this.getExperienceyear()}+ Years Experience</Typography>
                                <Typography variant="h2" data-aos="fade-left" data-aos-duration="600">
                                Before you proceed, learn about our team.
                                </Typography>
                                <ul data-aos="fade-up" data-aos-duration="800">
                                <li>
                                Working next to each other with our customers, we draw out our aggregate best through legit correspondence, regard and an energy for taking care of issues together.
                                </li>
                                <li>
                                We accept that so as to affect the associations and networks that we serve, we have to advocate a comprehensive and impartial culture. We tune in, learn, and follow up for the benefit of our group to guarantee that everybody gets an opportunity to carry their thoughts and commitments to our work, projects, and occasions. Having a various workplace where everybody can be their genuine self is at the core of Visions.
                                </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default OurTeamPromise;
