import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Get Images Start
// import Deking_large from "../../assets/images/HomePage/Deking.png";
// import Jaw_large from "../../assets/images/HomePage/jaw.png";
import Nathan_large from "../../assets/images/HomePage/nathan.jpg";
// import Deking_small from "../../assets/images/HomePage/Deking-mobile.png";
// import Jaw_small from "../../assets/images/HomePage/jaw-mob.png";
import Nathan_small from "../../assets/images/HomePage/nathan-mob.jpg";
// import gladev_large from "../../assets/images/HomePage/gladev_large.jpg";
// import galdev_small from "../../assets/images/HomePage/galdev_small.png";
import sport_ohio_large from "../../assets/images/HomePage/sport_ohio_large.jpg";
import sports_ohio_small from "../../assets/images/HomePage/sports_ohio_small.jpg";
// import craftycanineclub_large from "../../assets/images/HomePage/craftycanineclub_large.png";
// import craftycanineclub_small from "../../assets/images/HomePage/craftycanineclub_small.png";
import FindingUtah_large from "../../assets/images/HomePage/FindingUtah_large.jpg";
import FindingUtah_small from "../../assets/images/HomePage/FindingUtah_small.jpg";
// import LeanIPD_large from "../../assets/images/HomePage/LeanIPD_large.png";
// import LeanIPD_small from "../../assets/images/HomePage/LeanIPD_small.png";
// import artelia_large from "../../assets/images/HomePage/artelia_large.png";
// import artelia_small from "../../assets/images/HomePage/artelia_small.png";
// import flagship_large from "../../assets/images/HomePage/flagship_large.jpg";
// import flagship_small from "../../assets/images/HomePage/flagship_small.png";
import papelstore_large from "../../assets/images/HomePage/papelstore_large.jpg";
import papelstore_small from "../../assets/images/HomePage/papelstore_small.jpg";
import BBI_Flags_large from "../../assets/images/HomePage/BBI_Flags_large.jpg";
import BBI_Flags_small from "../../assets/images/HomePage/BBI_Flags_small.jpg";
import StoreMore_large from "../../assets/images/HomePage/StoreMore_large.jpg";
import StoreMore_small from "../../assets/images/HomePage/StoreMore_small.jpg";
import MarbelTops_large from "../../assets/images/HomePage/MarbelTops_large.jpg";
import MarbelTops_small from "../../assets/images/HomePage/MarbelTops_small.jpg";
// import assignQ_large from "../../assets/images/HomePage/assignQ_large.png";
// import assignQ_small from "../../assets/images/HomePage/assignQ_small.png";
// import god_connection_large from "../../assets/images/HomePage/god_connection_large.png";
// import god_connection_small from "../../assets/images/HomePage/god_connection_small.png";
// import naudje_large from "../../assets/images/HomePage/naudje_large.png";
// import naudje_small from "../../assets/images/HomePage/naudje_small.png";
// Get Images End

// Get Files for Slider Start
import Slider from "../../slider";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End

class HomePortfolioSlider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <Grid item xs={12}>
        <Grid className="homePortfolioSlider sec_padding">
          <Grid component="div" className="finest-project" data-aos="fade-up" data-aos-duration="1000">
            <Typography
              variant="h3"
              className="head_title"
            >
              See Why Clients Love Us
              </Typography>
            <Typography
              className="head_paragraph"
            >
              Our Work Speaks Volumes as selling is what we do for our clients.
              </Typography>
            {/* <Link to="/portfolios" className="all-category">
                View More Categories
              </Link> */}

            <div className="slider-position-wrapper">
              <div className="desktop_big_wrapper">
                <Slider
                  asNavFor={this.state.nav2}
                  ref={slider => (this.slider1 = slider)}
                  slidesToShow={1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  centerMode={true}
                  arrows={true}
                  dots={false}
                  autoplay={true}
                  speed={1000}
                  infinite={true}
                >
                  {/* <div className="finest-image-wrapper">
                    <img alt="NaudJe" title="NaudJe" src={naudje_large} width="920" height="493" />
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                      <img alt="AssignQ" title="AssignQ" src={assignQ_large} />
                    </div> */}
                  {/* <div className="finest-image-wrapper">
                    <img alt="God Connection" title="God Connection" src={god_connection_large} width="920" height="493" />
                  </div> */}
                  <div className="finest-image-wrapper">
                    <LazyLoad once offset={500}><img alt="Marbel Tops" title="Marbel Tops" src={MarbelTops_large} width="920" height="493" /></LazyLoad>
                  </div>
                  {/* <div className="finest-image-wrapper">
                    <LazyLoad once offset={500}><img alt="Store More" title="Store More" src={StoreMore_large} width="920" height="493" /></LazyLoad>
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                    <LazyLoad once offset={500}><img alt="BBI Flags" title="BBI Flags" src={BBI_Flags_large} width="920" height="493" /></LazyLoad>
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                    <LazyLoad once offset={500}><img alt="Papelstore" title="Papelstore" src={papelstore_large} width="920" height="493" /></LazyLoad>
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                    <img alt="Flagship" title="Flagship" src={flagship_large} width="920" height="493" />
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                    <img alt="Artelia" title="Artelia" src={artelia_large} width="920" height="493" />
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                    <img alt="Gladev" title="Gladev" src={gladev_large} width="920" height="493" />
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                    <LazyLoad once offset={500}><img alt="Sports Ohio" title="Sports Ohio" src={sport_ohio_large} width="920" height="493" /></LazyLoad>
                  </div> */}
                  <div className="finest-image-wrapper">
                    <LazyLoad once offset={500}><img alt="Bayside" title="Bayside" src={Nathan_large} width="920" height="493" /></LazyLoad>
                  </div>
                  {/* <div className="finest-image-wrapper">
                    <img alt="Deking Decks" title="Deking Decks" src={Deking_large} width="920" height="493" />
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                    <img alt="Jawcon" title="Jawcon" src={Jaw_large} width="920" height="493" />
                  </div> */}
                  {/* <div className="finest-image-wrapper">
                    <img alt="Crafty Canine Club" title="Crafty Canine Club" src={craftycanineclub_large} width="920" height="493" />
                  </div> */}
                  <div className="finest-image-wrapper">
                    <LazyLoad once offset={500}><img alt="Finding Utah" title="Finding Utah" src={FindingUtah_large} width="920" height="493" /></LazyLoad>
                  </div>
                  {/* <div className="finest-image-wrapper">
                    <img alt="Lean IPD" title="Lean IPD" src={LeanIPD_large} width="920" height="493" />
                  </div> */}
                </Slider>
              </div>
              <div className="mobile-small-outer-wrapper">
                <div className="mobile-small-wrapper">
                  <Slider
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    arrows={false}
                  >
                    {/* <div className="finest-image-wrapper">
                      <img alt="NaudJe" title="NaudJe" src={naudje_small} width="208" height="422" />
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                        <img alt="AssignQ" title="AssignQ" src={assignQ_small} />
                      </div> */}
                    {/* <div className="finest-image-wrapper">
                      <img alt="God Connection" title="God Connection" src={god_connection_small} width="208" height="422" />
                    </div> */}
                    <div className="finest-image-wrapper">
                      <LazyLoad once offset={500}><img alt="Marbel Tops" title="Marbel Tops" src={MarbelTops_small} width="208" height="422" /></LazyLoad>
                    </div>
                    {/* <div className="finest-image-wrapper">
                      <LazyLoad once offset={500}><img alt="Store More" title="Store More" src={StoreMore_small} width="208" height="422" /></LazyLoad>
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                      <LazyLoad once offset={500}><img alt="BBI Flags" title="BBI Flags" src={BBI_Flags_small} width="208" height="422" /></LazyLoad>
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                      <LazyLoad once offset={500}><img alt="Papelstore" title="Papelstore" src={papelstore_small} width="208" height="422" /></LazyLoad>
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                      <img alt="Flagship" title="Flagship" src={flagship_small} width="208" height="422" />
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                      <img alt="Artelia" title="Artelia" src={artelia_small} width="208" height="422" />
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                      <img alt="Gladev" title="Gladev" src={galdev_small} width="208" height="422" />
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                      <LazyLoad once offset={500}><img alt="Sports Ohio" title="Sports Ohio" src={sports_ohio_small} width="208" height="422" /></LazyLoad>
                    </div> */}
                    <div className="finest-image-wrapper">
                      <LazyLoad once offset={500}><img alt="Bayside" title="Bayside" src={Nathan_small} width="208" height="422" /></LazyLoad>
                    </div>
                    {/* <div className="finest-image-wrapper">
                      <img alt="Deking Decks" title="Deking Decks" src={Deking_small} width="208" height="422" />
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                      <img alt="Jawcon" title="Jawcon" src={Jaw_small} width="208" height="422" />
                    </div> */}
                    {/* <div className="finest-image-wrapper">
                      <img alt="Crafty Canine Club" title="Crafty Canine Club" src={craftycanineclub_small} width="208" height="422" />
                    </div> */}
                    <div className="finest-image-wrapper">
                      <LazyLoad once offset={500}><img alt="Finding Utah" title="Finding Utah" src={FindingUtah_small} width="208" height="422" /></LazyLoad>
                    </div>
                    {/* <div className="finest-image-wrapper">
                      <img alt="Lean IPD" title="Lean IPD" src={LeanIPD_small} width="208" height="422" />
                    </div> */}
                  </Slider>
                </div>
              </div>
            </div>
          </Grid>
          <div component="div" className="btnWrap">
            <Link to="/portfolios" className="dark-btn-effect all-category-mobile" title="View Our Portfolio">
              View Our Portfolio
            </Link>
            <Link className="hover-btn-animation-out hire_us_btn"to="/hire-developers/hire-android-app-developers"title="Hire Us">
              Hire Us
            </Link>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default HomePortfolioSlider;
