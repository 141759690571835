import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import android_ui_designs from '../../assets/images/HireDevelopers/HireAndroid/android_ui_designs.svg';
import Backend_Coding from '../../assets/images/HireDevelopers/HireAndroid/Backend_Coding.svg';
import business_expertise from '../../assets/images/HireDevelopers/HireAndroid/business_expertise.svg';
import Excellent_programming_Skills from '../../assets/images/HireDevelopers/HireAndroid/Excellent_programming_Skills.svg';
import fast_development from '../../assets/images/HireDevelopers/HireAndroid/fast_development.svg';
import largeuserbase from '../../assets/images/HireDevelopers/HireAndroid/largeuserbase.svg';
import LowBarrierofEntry from '../../assets/images/HireDevelopers/HireAndroid/LowBarrierofEntry.svg';
import portability from '../../assets/images/HireDevelopers/HireAndroid/portability.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HireAndroid/Android-banner1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireAndroidAppDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
            pageTitle: "Hire Android App Developers",
            bannerImg: Hire_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: 'How much time it take to build an Android app?',
                    TypographyDescription: 'It is totally dependent on the app functionality and features, although the process can be speeded up for some urgent requirements where Android developer, backend developer and QA cam work simultaneously to provide a quick delivery.'
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: 'Why I should have an Android app for my business?',
                    TypographyDescription: 'Firstly, as 80% of the visitors use site through mobile devices. Its important to have a mobile app and Android being having the largest user base will give more exposure to your business through an Android App.'
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: 'How Visions can help you launch the app you are looking for?',
                    TypographyDescription: 'We will start with analyzing phase to go through your business idea, finalize the app scope and flow. After that we will create the mobile app wireframes for your approval and once approved, the designs will be done. Based on the designs, complete functionality of the app will be developed and tested thoroughly on all devices and screen sizes. Once, everything is complete and approved by you. The app will be submitted to Google play store for approval and once live, we will provide you support and maintenance for the same.'
                }
            ]
      };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire Android App Developer | Hire Android Programmers";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="Android App Developers" />
          {/* Start - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12} className="hirDeveloperAppSize hireDeveloperStyleSize">
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        At <strong> Visions</strong> , our reputed <strong>Android App</strong> development team can assist you to develop android apps with solid presence and features to connect with a huge user base. Having diverse experience with Android technologies and having worked with a large industry clientele is a plus for us to work on any kind of requirements.
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <strong>
                          Android platform is having the most number of users, allowing you to connect with a huge user base across the globe.
                        </strong>
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={largeuserbase} alt="Large User-base" title="Large User-base" />
                        <Typography variant="h5" className="mb-1">
                          Large User-base
                            </Typography>
                        <Typography className="head_paragraph">
                          Being one of the most used platform, <strong>Android apps</strong> can provide a great exposure to your business.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={LowBarrierofEntry} alt="Low Barrier of Entry" title="Low Barrier of Entry" />
                        <Typography variant="h5" className="mb-1">
                          Low Barrier of Entry
                            </Typography>
                        <Typography className="head_paragraph">
                          Google play store only charges, one time fee of $25 to buy Google developer account to live your app.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={fast_development} alt="Faster development" title="Faster development" />
                        <Typography variant="h5" className="mb-1">
                          Faster development
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong> Android app</strong>  have rapid development process and have huge community support. Also, Android apps are easy to get approved on Google play store and go to market.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={portability} alt="Portability" title="Portability" />
                        <Typography variant="h5" className="mb-1">
                          Portability
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong> Android apps</strong>  being built in Java, makes it easy to port your android application to other operating systems like Symbian, Ubuntu and Blackberry as well.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire Android app Developers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      Here at <strong> Visions</strong> , we are capable of delivering full range of <strong> Android app development</strong>  services to perfectly match your specific business needs and delivery a product with outstanding performance, stability and outlook.

                              </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="Business Expertise" title="Business Expertise" src={business_expertise} />
                    <Typography variant="h4" className="head_title">
                      Business Expertise
                              </Typography>
                    <Typography className="head_paragraph">
                      Your <strong> Android app </strong>  idea will be analyzed thoroughly to find our loopholes, if any, before actually working on the app development.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="Android UI Designs" title="Android UI Designs" src={android_ui_designs} />
                    <Typography variant="h4" className="head_title">
                      Android UI Designs
                              </Typography>
                    <Typography className="head_paragraph">
                      Our expert designer will create the UI Of your app, which will be implemented by the developer adhering to <strong> Material UI</strong>  guidelines.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="Excellent Programming Skills" title="Excellent Programming Skills" src={Excellent_programming_Skills} />
                    <Typography variant="h4" className="head_title">
                      Excellent Programming Skills
                              </Typography>
                    <Typography className="head_paragraph">
                      Having strong hands on latest technology of <strong> Java and Android Studio</strong> , third party <strong> APIs</strong> , <strong> SDKs</strong> , <strong> IAP</strong> , the code will be if highest standards and well documented.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="Backend Coding" title="Backend Coding" src={Backend_Coding} />
                    <Typography variant="h4" className="head_title">
                      Backend Coding
                              </Typography>
                    <Typography className="head_paragraph">
                      A Backend and <strong> API expert</strong>  will handle the backend algorithms and integration of your Android app with is backend panel.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We are capable of converting our client’s vision or idea into a tangible and high-end app with our rich <strong>Android app</strong> development services.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li>
                        We deliver high quality Android apps tailored to your specific needs. <img alt="Tick" title="Tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Up to date with the latest trends and technologies. <img alt="Tick" title="Tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Proven track record of numerous successful apps. <img alt="Tick" title="Tick" className="tick_done" src={tick_done} />
                      </li>

                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li>
                        <img alt="Tick" title="Tick" className="tick_done" src={tick_done} /> Focused on delivering our projects always on time.
                                  </li>
                      <li>
                        <img alt="Tick" title="Tick" className="tick_done" src={tick_done} /> Dedicated availability to the project and regular progress report.
                                  </li>
                      <li>
                        <img alt="Tick" title="Tick" className="tick_done" src={tick_done} /> A team of experts to handle any type of your requirements.
                                  </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Have some questions in mind your <strong> Android app development</strong>? Let us help you to sort out. </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Start - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HireAndroidAppDev;
