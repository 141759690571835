import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Bladetemplateengine from '../../assets/images/HireDevelopers/HireLaravel/Bladetemplateengine.svg';
import enhancesecurity from '../../assets/images/HireDevelopers/HireLaravel/enhancesecurity.svg';
import Fastprocessing_FamiliarWithMVCFramework from '../../assets/images/HireDevelopers/HireLaravel/Fastprocessing_FamiliarWithMVCFramework.svg';
import LaravelbasedWebsiteDevelopment from '../../assets/images/HireDevelopers/HireLaravel/LaravelbasedWebsiteDevelopment.svg';
import MaintenanceandSupport from '../../assets/images/HireDevelopers/HireLaravel/MaintenanceandSupport.svg';
import MigrationtoLaravel from '../../assets/images/HireDevelopers/HireLaravel/MigrationtoLaravel.svg';
import Object_Oriented_Concepts_Are_Well_Brushed from '../../assets/images/HireDevelopers/HireLaravel/Object_Oriented_Concepts_Are_Well_Brushed.svg';
import SaaSApplicationDevelopment from '../../assets/images/HireDevelopers/HireLaravel/SaaSApplicationDevelopment.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HireLaravel/HIRE-LARAVEL-DEVELOPERS1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireLaravelDev extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Hire Laravel Developers",
            bannerImg: Hire_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: 'What is the latest Laravel version, What is new in it?',
                    TypographyDescription: "The latest version of the Laravel is 6.0 LTS version released on date 11th September 2019. It will have big fixes till Sept 2021 and security fixes till Sept 2022. From this time only it will follow the semantic versioning in Laravel from 6.0 to onwards. The major changes in this version are the improvement of the Auth Functionality, Job Middleware, Lazy Collection, Subquery and many more things."
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: 'Why You Should Hire Laravel Developers?',
                    TypographyDescription: "Now, Laravel is a PHP-based framework that has grown over the years. It has been regarded as one of the best frameworks for developing web applications. If you are looking for speed and high processing in your application, Laravel is the answer to your question."
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: 'Is Laravel having any support for the tool for API creation?',
                    TypographyDescription: "GraphQL is the perfect answer to this requirement. It’s the query language and also supporting great GUI for the query creation. It can be easily installable using Laravel command line."
                },
                {
                    expanded: 'panel4',
                    ariaControls: 'panel4d-content',
                    panelId: 'panel4d-header',
                    typographyText: 'What is the eCommerce solution for Laravel based site?',
                    TypographyDescription: "No website functionality is 100% completed without a great buying and selling experience, so obvious question strike in your mind: in Laravel website application, if anyone wants to integrate the eCommerce feature, do we need to the coding from scratch? The answer is ‘No’, Laravel supports the platform called ‘Bagisto E-commerce’, which is exclusively built for the Ecommerce solution. It has a very nice GUI, apart from this, it supports exclusive functionality like RTL, channels, locals, currencies, custom product attributes, multi-store, API support, and many more things. You can play with its demo to get more insight."
                }
            ]
        };
    }
    componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
    }
    render() {
        document.title = "Hire Dedicated Laravel Developers | Laravel Programmers India";
        const {
            pageTitle, bannerImg
        } = this.state;
        return (
            <div className="main_hireEcommerce main_hireDevelopers">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - HireUsForm Import From Root Of Components Folder */}
                    <HireUsForm technology="Laravel Developers" />
                    {/* End - HireUsForm Import From Root Of Components Folder */}

                    {/* Start - Section Design "Advantages of Hiring Us" */}
                    <Grid item xs={12} className="main_application_dev">
                        <Grid
                            component="div"
                            className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
                            id="ios-app-development"
                        >
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <div className="text_wrap title_row">
                                        <aside>
                                            <Typography
                                                variant="h2"
                                                className="head_title"
                                                data-aos="fade-up"
                                                data-aos-duration="800"
                                            >
                                                Advantages of Hiring Us</Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1200"
                                            >
                                                We are having a team of expert <strong>LARAVEL developers</strong> to deliver you result oriented <strong> LARAVEL</strong>  applications. We offer advanced and custom coded solution for your diverse business requirements. We are capable of handling <strong> LARAVEL</strong>  applications form simple websites to advance domain specific solutions. </Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1400"
                                            >
                                                <strong>
                                                    LARAVEL is the free and open source technology in nature and can render dynamic pages and applications. </strong>
                                            </Typography>
                                        </aside>
                                    </div>
                                    <Grid item container xs={12} className="major_networks_wrap">
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={enhancesecurity} alt="Enhanced Security" title="Enhanced Security" />
                                                <Typography variant="h5" className="mb-1">
                                                    Enhanced Security
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    It avoids the scam users from accessing the paid resources or anything important to your business. <strong> Laravel</strong>  features allow you to use everything securely.  <strong>Laravel</strong> adds a pre-defined CSRF filter which protects from CSRF attack.
                            </Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={Fastprocessing_FamiliarWithMVCFramework} alt="Fast Processing & Familiar With MVC Framework" title="Fast Processing & Familiar With MVC Framework" />
                                                <Typography variant="h5" className="mb-1">
                                                    Fast Processing & Familiar With MVC Framework
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    Our <strong> Laravel</strong>  Web App Developers know about dealing with the MVC framework, and that’s why you will get amazing features as well as functionalities.

                            </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                                                <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={Object_Oriented_Concepts_Are_Well_Brushed} alt="Object-Oriented Concepts Are Well-Brushed" title="Object-Oriented Concepts Are Well-Brushed" />
                                                <Typography variant="h5" className="mb-1">
                                                    Object-Oriented Concepts Are Well-Brushed </Typography>
                                                <Typography className="head_paragraph">
                                                    For making robust and secure web applications, you need to have an idea of Object-Oriented Programming.</Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={Bladetemplateengine} alt="Blade Template Engine" title="Blade Template Engine" />
                                                <Typography variant="h5" className="mb-1">
                                                    Blade Template Engine </Typography>
                                                <Typography className="head_paragraph">
                                                    <strong> Laravel</strong>  accompanies Blade template engine which assists engineers with showing information effectively and even broaden web designs without hindering application speed.</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Advantages of Hiring Us" */}

                    {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Hire our Laravel Developers</Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            We help yours configuration, manufacture, and oversee custom <strong>Laravel</strong> applications that help take care of your most testing consistently into your present procedures and rehearses and create quantifiable results for your endeavor. Contract <strong>Laravel</strong> Developers on a full-time, hourly, or fixed cost venture premise and oversee them as your all-encompassing group.
                                            </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                                    <div className="list_key_boxes">
                                        <img alt="Laravel Based Website Development" title="Laravel Based Website Development" src={LaravelbasedWebsiteDevelopment} />
                                        <Typography variant="h4" className="head_title">
                                            Laravel based Website Development
                              </Typography>
                                        <Typography className="head_paragraph">
                                            We can incorporate custom highlights with your site utilizing <strong>Laravel</strong> or help make a custom site utilizing <strong>Laravel</strong> based CMS, for example, Statamic and October.
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                                    <div className="list_key_boxes">
                                        <img alt="Migration to Laravel" title="Migration to Laravel" src={MigrationtoLaravel} />
                                        <Typography variant="h4" className="head_title">
                                            Migration to Laravel
                              </Typography>
                                        <Typography className="head_paragraph">
                                            We can assist you with relocating your application to <strong>Laravel</strong> from <strong>ASP.NET, Java, Python</strong> or other PHP structures without loss of any usefulness or information.
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="list_key_boxes">
                                        <img alt="SaaS Application Development" title="SaaS Application Development" src={SaaSApplicationDevelopment} />
                                        <Typography variant="h4" className="head_title">
                                            SaaS Application Development
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Utilizing <strong>Laravel</strong> Cashier, Spark, Passport and Socialite other such <strong>Laravel</strong> bundles we can assist you with building up a custom SaaS application.
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="list_key_boxes">
                                        <img alt="Maintenance and Support" title="Maintenance and Support" src={MaintenanceandSupport} />
                                        <Typography variant="h4" className="head_title">
                                            Maintenance and Support
                              </Typography>
                                        <Typography className="head_paragraph">
                                            We'll keep your <strong>Laravel applications</strong> running mistake free with the goal that you can concentrate on your business. We will fix issues, administer security, server refreshes, screen execution, take intermittent reinforcements, and assume liability for ordinary bug fixes and changes.

                              </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Hire Our Developers KeyBenefits" */}

                    {/* Start - Section Design "Why Hire Us" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="why_hire_us sec_padding">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200"><strong>"Visions"</strong> is one stage ahead and fit for utilizing given highlights and usefulness of <strong>Laravel</strong> system top of the business when <strong>Laravel PHP web improvement</strong> accompanies an inquiry.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                                        <ul className="left_list lists_hire_us">
                                            <li>
                                                Laravel Enterprise solution. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                Laravel Template Design and Development. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                Laravel RESTful development. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                Laravel Maintenance and Support. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>

                                        </ul>
                                    </Grid>
                                    <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                                        <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                                    </Grid>
                                    <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                                        <ul className="right_list lists_hire_us">

                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> Laravel Web application Development.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> Laravel Custom Express solution.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> Laravel October CRM development.
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Why Hire Us" */}

                    {/* Start - Section Design "We Are Here to Help You" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">There might me some questions popping out in your head, so you may find your answer below.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                                        <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                                        <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "We Are Here to Help You" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <NumberCount />
                    {/* End - NumberCount Import From Common Components */}

                </Grid>
            </div>
        );
    }
}

export default HireLaravelDev;
