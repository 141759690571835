import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//import LazyLoad from "react-lazyload";

// Get Files for Slider Start
import Slider from "../../slider";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End


class SliderServiceCatPagePortfolio2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
           sliderData: this.props.data
        };
    }

    render() {
        const {sliderData} = this.state;

        const settingsSlider = {
            dots: false,
            arrows: true,
            autoplay: true,
            infinite: true,
            slidesToShow: 3,
            centerMode: true,
            centerPadding: '0px',
            slidesToScroll: 1,
            adaptiveHeight: false,
            responsive: [
                {
                    breakpoint: 767.98,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '50px'
                    }
                }
            ]
        };
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Grid className="serviceCatPortfolioSlider2 sec_padding pt-0 pb-0" data-aos="fade-up" data-aos-duration="800">
                        <Grid item container className="">
                            <Grid item xs={12}> 
                            <Slider {...settingsSlider}>
                                {sliderData.map((data, key) =>
                                    <div key={key}>
                                        <aside>
                                            <div className="slide_item testimonial_list">
                                                <div className="website_img">
                                                    <img width="auto" height="auto" src={data.image} alt={data.title} title={data.title} />
                                                </div>
                                                <div className="content_wrap">
                                                    <Typography className="title">{data.title}</Typography>
                                                    {data.tech_images.length > 0 &&
                                                        <div className="tech_icons_wrapper">
                                                        { data.tech_images.map((technologies, index) => 
                                                            <img width="30" height="auto" key={index} src={technologies.icon} alt={technologies.title} title={technologies.title} className="tech_icons"/>
                                                        )}
                                                        </div>
                                                    }
                                                    { data.link && 
                                                    <a href={data.link} title={data.link} target="_blank" rel="noopener noreferrer">
                                                        <Typography className="link btn">Go To Website</Typography>
                                                    </a>
                                                    }
                                                </div> 
                                            </div>
                                        </aside>
                                    </div>
                                )}
                            </Slider>
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>            
            </React.Fragment>
        )
    }
}

export default SliderServiceCatPagePortfolio2;