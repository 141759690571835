import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

// Get Images Start
import our_approach_group from "../../assets/images/ourApproach/our-approach-group.jpg";
import our_approach_banner from "../../assets/images/ourApproach/our_approach_banner.png";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from '../common/NumberCount';
import DiscussYourProject from "../common/DiscussYourProject";
import HomePortfolioSlider from '../common/HomePortfolioSlider';
import CompanyPhilosophy from '../common/CompanyPhilosophy';
import InnerPagesBelowBannerTwoColumnLayout3 from '../common/InnerPagesBelowBannerTwoColumnLayout3';
// Get Common Components End

class OurApproach extends Component { 
  constructor(props) { 
    super(props); 
    this.state = { 
      pageTitle: "Our Approach", 
      bannerImg: our_approach_banner, 
      content : [
        {
          title:"We offers creative templates for Brochures",
  
          description: '<strong>Visions</strong> is a group of expert website specialists, engineers, and advanced promoting specialists that absolutely sees each customer has its own business point of view.',
  
          listingLoop: [
            "Before we actualize our thoughts into live, from beginning to end, we intently work with our customers.",
            "We give key direction and lucidity all through the task to ensure our customers get a mistake-free item.",
            "Being a famous website architecture and development organization in India we have manufactured our very own work approach that we pursue to convey the best web arrangements."
          ],
  
          image: our_approach_group,
  
          blueBoxText: "Our range of services includes Customized Solution Development Application Support and Maintenance.",
  
          orangeBoxText: this.getExperienceyear() + "+ Years of Experience",
  
          leftAlignText: "extensively in quality"
        }
      ]
    }; 
  } 
  componentWillMount() { 
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    } 
  }

  getExperienceyear() {
    var today = new Date();
    var start_date = new Date("june 02, 1993");
    var years_now = today.getFullYear() - start_date.getFullYear();
    var m = today.getMonth() - start_date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) 
    {
      years_now--;
    }
    return years_now;
  }

  render() { 
    document.title = "Our Approach";
    const { pageTitle, bannerImg, content } = this.state; 

    return (
    <div className="main_our_approach">
      <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - InnerPagesBelowBannerTwoColumnLayout3 Import From Common Components */}
          <InnerPagesBelowBannerTwoColumnLayout3 data={content} />
          {/* End - InnerPagesBelowBannerTwoColumnLayout3 Import From Common Components */}

          {/* Start - DiscussYourProject Import From Common Components */}
          <DiscussYourProject />
          {/* End - DiscussYourProject Import From Common Components */}

          {/* Start - CompanyPhilosophy Import From Common Components */}
          <CompanyPhilosophy />
          {/* End - CompanyPhilosophy Import From Common Components */}
  
          {/* Start - HomePortfolioSlider Import From Common Components */}
          <HomePortfolioSlider />
          {/* End - HomePortfolioSlider Import From Common Components */} 

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}
  
      </Grid>
    </div>
) } }

export default OurApproach;