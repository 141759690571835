import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { FormControl } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import SimpleReactValidator from "simple-react-validator";
import Dropzone from "react-dropzone";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import CircularProgress from "@material-ui/core/CircularProgress";
// Get Common Components Start
import InnerPagesBanner from "../components/common/InnerPagesBanner";
import helper from "../helper";
// Get Common Components End
import "react-notifications/lib/notifications.css";
// Custom Stylesheets Start
import "../assets/css/apply-form.css";
// Custom Stylesheets End

// Get Images Start
import applyJob_banner from "../assets/images/applyJob/applyJob_banner.png";
require("es6-promise").polyfill();
require("isomorphic-fetch");
// Get Images End


class applyform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Apply For Job",
      bannerImg: applyJob_banner,
      fname: "",
      lname: "",
      phone: "",
      email: "",
      address: "",
      experienceYear: "",
      experienceMonth: "",
      currentCTC: "",
      expectedCTC: "",
      //applyPosition: "",
      currentEmployer: "",
      currentLocation: "",
      joiningTime: "",
      relocate: "",
      CV: "",
      CV1: "",
      fileFormatError: "",
      gCaptcha: "",
      jobAppliedFor: "",
      loader: false
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }

    let jobAppliedFor = localStorage.getItem("jobAppliedFor");
    if (jobAppliedFor) {
      this.setState({ jobAppliedFor: jobAppliedFor });
      localStorage.removeItem('jobAppliedFor');
    } else {
      this.props.history.push("/careers");
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  // handleFileChange = e => {
  //   if (e.target.files.length > 0) {
  //     if (
  //       e.target.files[0].type === "application/pdf" ||
  //       e.target.files[0].type === "application/msword" ||
  //       e.target.files[0].type ===
  //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //     ) {
  //       this.setState({
  //         CV1: e.target.value,
  //         CV: e.target.files[0],
  //         fileFormatError: ""
  //       });
  //       if (e.target.files[0].size >= 3000000) {
  //         this.setState({
  //           fileFormatError: "File size should be less then 3 MB",
  //           CV1: ""
  //         });
  //       }
  //     } else {
  //       this.setState({
  //         fileFormatError: "Please upload a valid file",
  //         CV1: ""
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       CV1: ""
  //     });
  //   }
  // }

  handleFileAccepted = e => {
    if (
      e[0].type === "application/pdf" || 
      e[0].type === "application/msword" || 
      e[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      this.setState({
        CV: e[0],
        CV1: e[0].name,
        fileFormatError: ""
      });
      if (e[0].size > 3000000) {
        this.setState({
          fileFormatError: "File size should be less than or equal to 3 MB",
          CV1: "",
          CV: ''
        });
      }
    } else {
      this.setState({
        fileFormatError: "Please upload a valid file",
        CV1: "",
        CV: ""
      });
    }
  }

  handleFileRejected = e => {
    if (
      e[0].type === "application/pdf" ||
      e[0].type === "application/msword" ||
      e[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      if (e[0].size > 3000000) {
        this.setState({
          fileFormatError: "File size should be less than or equal to 3 MB",
          CV1: "",
          CV: ''
        });
      }
    } else {
      this.setState({
        fileFormatError: "Please upload a valid file",
        CV1: "",
        CV: ""
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ loader: true });
      const data = new FormData();
      data.append("fname", this.state.fname);
      data.append("lname", this.state.lname);
      data.append("phone", this.state.phone);
      data.append("email", this.state.email);
      data.append("address", this.state.address);
      data.append("experienceYear", this.state.experienceYear);
      data.append("experienceMonth", this.state.experienceMonth);
      data.append("currentCTC", this.state.currentCTC);
      data.append("expectedCTC", this.state.expectedCTC);
      data.append("applyPosition", this.state.jobAppliedFor);
      data.append("currentEmployer", this.state.currentEmployer);
      data.append("currentLocation", this.state.currentLocation);
      data.append("joiningTime", this.state.joiningTime);
      data.append("relocate", this.state.relocate);
      data.append("CV", this.state.CV);
      data.append("gCaptcha", this.state.gCaptcha);
      fetch(helper.API_URL + "/career/addCareer", {
        method: "POST",
        body: data
      })
        .then(response => {
          if (response.status === 200) {
            NotificationManager.success(
              "Your Information Submitted Successfully. We will get back to you as soon as possible."
            );
            this.setState({ loader: false });
          } else {
            NotificationManager.error(
              "Something went wrong. Please try again."
            );
            this.setState({ loader: false });
          }
          return response.json();
        })
        .catch(error => {
          NotificationManager.error("Something went wrong. Please try again.");
          this.setState({ loader: false });
        });
      this.setState({
        fname: "",
        lname: "",
        phone: "",
        email: "",
        address: "",
        experienceYear: "",
        experienceMonth: "",
        currentCTC: "",
        expectedCTC: "",
        //applyPosition: "",
        currentEmployer: "",
        currentLocation: "",
        joiningTime: "",
        relocate: "",
        CV1: "",
        gCaptcha: ""
      });
      this.captcha.reset();
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
    }
  }

  handleCaptcha = value => {
    this.setState({ gCaptcha: value });
  }

  render() {
    document.title = "Website Design & Web Development Company India";
    const {
      pageTitle,
      bannerImg,
      fname,
      lname,
      phone,
      email,
      address,
      experienceYear,
      experienceMonth,
      currentCTC,
      expectedCTC,
      //applyPosition,
      currentEmployer,
      currentLocation,
      joiningTime,
      relocate,
      CV1,
      gCaptcha,
      jobAppliedFor
    } = this.state;
    return (
      <Grid component="div" className="apply_form_wrapper">
        <Grid container>

          <NotificationContainer />

          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - Section Design "Job Application Form" */}
          <Grid item xs={12} className="contact-pg">
            <Grid item container className="container_custom ">
              <div className="inner-wrapper">
                <Grid item xs={12} component="div"className="inner-get-free-quote sec_padding">
                  <Typography variant="h2" className="head_title">
                    Apply For {jobAppliedFor} Job
                  </Typography>
                  <Typography className="head_paragraph"> 
                        Please submit the form below and we will get back to you as soon as possible.
                      </Typography>
                  <form
                    noValidate
                    autoComplete="off"
                    encType="multipart/form-data"
                    onSubmit={this.handleSubmit}
                    name="apply-form"
                    id="apply-from"
                  > 
                    <Grid container component="div" className="apply-form-field">
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="First Name"
                          variant="outlined"
                          className="inner-fields"
                          name="fname"
                          value={fname}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 100
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "first_name",
                            fname,
                            "required|max:100|alpha_space"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Last Name"
                          variant="outlined"
                          className="inner-fields"
                          name="lname"
                          value={lname}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 100
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "last_name",
                            lname,
                            "required|max:100|alpha_space"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Phone"
                          variant="outlined"
                          className="inner-fields"
                          name="phone"
                          value={phone}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 20
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "phone",
                            phone,
                            "required|max:20|phone"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Email"
                          variant="outlined"
                          className="inner-fields"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "email",
                            email,
                            "required|email"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Address"
                          variant="outlined"
                          fullWidth
                          className="inner-fields"
                          multiline
                          rows="5"
                          name="address"
                          value={address}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 1000
                          }}
                        />
                        <span
                          className={
                            address.length >= 800
                              ? "des_count red"
                              : "des_count green"
                          }
                        >
                          {1000 - address.length} characters remaining out of 1000
                        </span>
                        <span className="text-danger">
                          {this.validator.message(
                            "Address",
                            address,
                            "required|max:1000"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Total Experience in years"
                          variant="outlined"
                          className="inner-fields"
                          name="experienceYear"
                          value={experienceYear}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 2
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "Experience_in_year",
                            experienceYear,
                            "required|integer|max:2"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Total Experience in month"
                          variant="outlined"
                          className="inner-fields"
                          name="experienceMonth"
                          value={experienceMonth}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 2
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "Experience_in_month",
                            experienceMonth,
                            "required|integer|max:2"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Current CTC"
                          variant="outlined"
                          className="inner-fields"
                          name="currentCTC"
                          value={currentCTC}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 10
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "current_ctc",
                            currentCTC,
                            "required|integer|max:10"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Expected CTC"
                          variant="outlined"
                          className="inner-fields"
                          name="expectedCTC"
                          value={expectedCTC}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 10
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "expected_ctc",
                            expectedCTC,
                            "required|integer|max:10"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Position Applied For"
                          variant="outlined"
                          className="inner-fields"
                          name="applyPosition"
                          value={jobAppliedFor}
                          onChange={this.handleChange}
                          disabled
                        />
                        <span className="text-danger">
                          {this.validator.message("position", jobAppliedFor, "required")}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Current Employer"
                          variant="outlined"
                          className="inner-fields"
                          name="currentEmployer"
                          value={currentEmployer}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 300
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "current_employer",
                            currentEmployer,
                            "required|max:300"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Current Location"
                          variant="outlined"
                          className="inner-fields"
                          name="currentLocation"
                          value={currentLocation}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 300
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "Current_location",
                            currentLocation,
                            "required|max:300"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Joining Time (in days)"
                          variant="outlined"
                          className="inner-fields"
                          name="joiningTime"
                          value={joiningTime}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 3
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message("joining_time", joiningTime, "required|integer|max:3")}
                        </span>
                      </Grid>                    
                      <Grid item xs={12} md={6}>
                        <Grid component="div" className="willing-reloc file">
                          <FormLabel component="legend">
                            Attach your CV <sup className="asterisk">*</sup>
                          </FormLabel>
                          {/* <div className="input_file_custom">
                            <input
                              type="file"
                              id="CV"
                              value={CV1}
                              name="CV"
                              accept=".doc,.docx,.pdf"
                              required
                              onChange={this.handleFileChange}
                            />
                          </div>
                          <p>
                            Note : File type should be .doc, .docx or .pdf only.
                          </p>
                          <span className="text-danger">
                            {this.validator.message("cv", CV1, "required")}
                            <div className="srv-validation-message">
                              {this.state.fileFormatError}
                            </div>
                          </span> */}
                          <div className="inner-fields drag_drop_files">
                            <Dropzone
                              accept={[
                                "application/pdf", //pdf
                                "application/msword", //doc
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
                                ".doc",
                                ".docx",
                                ".pdf"
                              ]}
                              maxSize={3000000}
                              multiple={false}
                              //onDrop={this.handleFileDrag}
                              onDropAccepted={this.handleFileAccepted}
                              onDropRejected={this.handleFileRejected}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                  <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    {CV1 ? <p>{CV1}</p> : <p>Drag file here, or click to select file</p>}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            <p className="note_condition"><strong>Note :</strong> <span className="mainContentNote"><span>File type should be .doc, .docx or .pdf only.</span><span>Maximum file size should be 3MB.</span></span></p>
                            <span className="text-danger">
                              {this.validator.message("cv", CV1, "required")}
                              <div className="srv-validation-message">
                                {this.state.fileFormatError}
                              </div>
                            </span>
                          </div>
                        </Grid>                      
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid component="div" className="willing-reloc">
                          <FormLabel component="legend">
                            Willing to relocate to chandigarh
                            <sup className="asterisk">*</sup>
                          </FormLabel>
                          <RadioGroup
                            aria-label=""
                            name="relocate"
                            value={relocate}
                            onChange={this.handleChange}
                            className="flex-direction-row"
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                          <span className="text-danger">
                            {this.validator.message("relocate", relocate, "required")}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ReCAPTCHA
                          ref={r => (this.captcha = r)}
                          sitekey="6Lcg-cUUAAAAAPReOutbrk0KYH1wZj_bocA4iZZb"
                          onChange={this.handleCaptcha}
                        />
                        <span className="text-danger captcha">
                          {this.validator.message("captcha", gCaptcha, "required")}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid component="div"className="get-quote-inner-field full button">
                          <FormControl className="form-fields" fullWidth>
                          <input type="submit" value="Submit" title="Submit" />
                          <div className="loader-style-form">
                            {this.state.loader ? <CircularProgress /> : ""}
                          </div>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* End - Section Design "Job Application Form" */}

        </Grid>
      </Grid>
    );
  }
}

export default applyform;
