import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

// Get Images Start
import Privcy_Policy_Banner from "../../assets/images/Privcy_Policy_Banner.png";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from '../common/NumberCount';
// Get Common Components End

class PrivacyPolicies extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            pageTitle: "Privacy Policy",
            bannerImg: Privcy_Policy_Banner 
    };
    }
    componentWillMount() {
        let isAuthenticated = !localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) {
            this.props.history.push("/login");
            window.location.reload();
        }
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }


    render() {
        document.title = "Website Design & Web Development Company India";
        const { pageTitle, bannerImg } = this.state;
        return (
            <div className="main_our_approach">
                <Grid container>

                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}
                    
                    {/* Start - Section Design Content "Privacy Policy Content" */}
                    <div className="sec_padding privacy-page">
                        <Grid item xs={12}>
                            <Grid item container className="container_custom" data-aos="fade-up" data-aos-duration="800">
                                <Typography variant="h3" className="privacy_title">Privacy Policy</Typography>
                                <Typography className="privacy_text">Our privacy policy governs the way we use, maintain and store all relevant information that is collected from the visitors of our website or from the clients. This privacy policy will govern our products &amp; services listed and offered on our website. We make sure, in every possible way, that any data, concerning the visitors or clients, which they willingly share with us, while filling in query forms or other forms on our  webpages, is not shared with third parties or counter business partners. We are committed to protect the personal information or any business information shared by the visitor or client at the time of filling various forms on our webpages.</Typography>

                                <Typography variant="h3" className="privacy_title">What information do we collect?</Typography>
                                <Typography className="privacy_text">We might collect differing information in the name of personal information as the user’s/ Visitor’s email id, name, contact information, address or the description of services you are looking for. We use this information to provide you with solutions tailored to your need or to reply back to your query.</Typography>

                                <Typography variant="h3" className="privacy_title">Personal Information</Typography>
                                <Typography className="privacy_text">We may collect personal Identification information of our visitors and clients, in a number of ways, including but not limited to, starting from the point of visiting the website, filling forms and providing initial information to get back regarding their queries for the services we provide.</Typography>

                                <Typography variant="h3" className="privacy_title">Cookie Policy</Typography>
                                <Typography className="privacy_text">Our site may use cookies for enriching the user’s experience or for keeping record to be able to trace the information about our visitors. These cookies can be blocked by users, if they do not wish to allow cookies to run but might result in improper functioning of certain features in our website. Cookies help the website to recognize repeated visitors and saves their information that their preferences are saved with us. Cookies are also important for the performance of certain features of our website. </Typography>

                                <Typography variant="h3" className="privacy_title">Social Media tags</Typography>
                                <Typography className="privacy_text">We have enabled various social media tags on our website for the users/ Visitors to reach our specific social media pages for more relevant information. While clicking on these tags takes you to the specific page, your IP address might be saved with us for record purposes.</Typography>

                                <Typography variant="h3" className="privacy_title">Changes in Privacy Policy </Typography>
                                <Typography className="privacy_text">We reserve the exclusive right to either change, modify or totally exclude any part of this privacy policy with or without any prior notice.</Typography>

                                <Typography variant="h3" className="privacy_title">Security measures</Typography>
                                <Typography className="privacy_text">We, by all means, have tried to keep the information safe from any losses, electronically or otherwise. We have taken proper measures to curb the misuse or misinterpretation of the visitors/ client’s information saved with us.</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    {/* End - Section Design Content "Privacy Policy Content" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <NumberCount />
                    {/* End - NumberCount Import From Common Components */}

                </Grid>
            </div>
        )
    }
}

export default PrivacyPolicies;