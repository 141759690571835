import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import easy_to_understand from '../../assets/images/HireDevelopers/HireMagento/easy_to_understand.svg';
import easy_to_edit from '../../assets/images/HireDevelopers/HireMagento/easy_to_edit.svg';
import framework from '../../assets/images/HireDevelopers/HireMagento/framework.svg';
import customizable from '../../assets/images/HireDevelopers/HireMagento/customizable.svg';
import expert_consulting from '../../assets/images/HireDevelopers/HireMagento/expert_consulting.svg';
import Maintenance_Support from '../../assets/images/HireDevelopers/HireMagento/Maintenance_Support.svg';
import search_engion_friendly from '../../assets/images/HireDevelopers/HireMagento/search_engion_friendly.svg';
import ecommerce_web_design from '../../assets/images/HireDevelopers/HireMagento/ecommerce_web_design.svg';
import Hire_Magento_banner from '../../assets/images/HireDevelopers/HireMagento/Hire_Magento_banner.jpg';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireMagentoDev extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Hire Magento Developers",
            bannerImg: Hire_Magento_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: "What is Magento?",
                    TypographyDescription: "Magento is an open source eCommerce platform providing merchants with the most flexible and controlled online store possible. Magento features powerful tools for marketing, search engine optimization and catalog-management, which are tailored to meet the unique business needs of any site. Magento is generally the best eCommerce platform option for larger companies who want more features and a more completely customized site."
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: "Is Magento considered a better platform than Shopify?",
                    TypographyDescription: "Both Magento and Shopify are powerful tools for building an e-commerce platform. While Shopify is a paid software, Magento is an open-source software accessible to anyone. However, Magento does require prior background knowledge in coding, while Shopify is easily available for users who are looking to build their platform easily."
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: "What is the benefit of Open Source?",
                    TypographyDescription: 'Open Source platforms allow for much more security, innovation, agility, quality and customization –all for a better price! It allows people to share and have access to the platform, making changes easier and more efficient.'
                },
                {
                    expanded: 'panel4',
                    ariaControls: 'panel4d-content',
                    panelId: 'panel4d-header',
                    typographyText: 'What does Magento cost?',
                    TypographyDescription: "For pricing information, please visit Magento’s website: https://magento.com/products"
                },
                {
                    expanded: 'panel5',
                    ariaControls: 'panel5d-content',
                    panelId: 'panel5d-header',
                    typographyText: "How can I get support?",
                    TypographyDescription: "The most ideal approach to get support for your Magento site would be through a Magento master. Visions has given various organizations full Magento site assembles. Visions can assist you with help for your Magento site. There are additionally various gatherings that are on Magento's online network."
                }
            ]
        };
    }
    componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
    }
    render() {
        document.title = "Hire Magento Developer |  Hire Magento 2 Developers";
        const {
            pageTitle, bannerImg
        } = this.state;
        return (
            <div className="main_hireEcommerce main_hireDevelopers">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - HireUsForm Import From Root Of Components Folder */}
                    <HireUsForm technology="Magento Developers" />
                    {/* End - HireUsForm Import From Root Of Components Folder */}

                    {/* Start - Section Design "Advantages of Hiring Us" */}
                    <Grid item xs={12} className="main_application_dev">
                        <Grid
                            component="div"
                            className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
                            id="ios-app-development"
                        >
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <div className="text_wrap title_row">
                                        <aside>
                                            <Typography
                                                variant="h2"
                                                className="head_title"
                                                data-aos="fade-up"
                                                data-aos-duration="800">
                                                Advantages of Hiring Us
                                            </Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1200">
                                                What Sets <strong>"Visions"</strong> Apart in eCommerce trade - apprehend & rent Magento Developer for Ecommerce Development. <br />
                                                Hiring a right <strong>Magento development</strong> company has become clouded as a result of the market is presently full of an outsized variety of teams claiming to produce grade eCommerce <strong>Magento development</strong> agency in USA, UK, North American country & Australia.
                                            </Typography>
                                        </aside>
                                    </div>
                                    <Grid item container xs={12} className="major_networks_wrap">
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={easy_to_understand} alt="Easy to Understand" title="Easy to Understand" />
                                                <Typography variant="h5" className="mb-1">
                                                    Easy to Understand
                                                </Typography>
                                                <Typography className="head_paragraph">
                                                    Grow your online business with Magento’s powerful options, together with product management, order fulfillment, and advanced integrations for your eCommerce web site.
                                                </Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={easy_to_edit} alt="Easy to Edit" title="Easy to Edit" />
                                                <Typography variant="h5" className="mb-1">
                                                    Easy to Edit
                                                </Typography>
                                                <Typography className="head_paragraph">
                                                    It has a truthful content control interface that allows you to look at how pages are performing so that you can edit them accordingly.
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                                                <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={framework} alt="Framework" title="Framework" />
                                                <Typography variant="h5" className="mb-1">
                                                    Framework
                                                </Typography>
                                                <Typography className="head_paragraph">
                                                    With Magento’s enterprise-class options and suppleness, our team can produce an unambiguously tailored eCommerce answer that permits you to drive a lot of traffic to your store, and boost online revenue.
                                                </Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={customizable} alt="Customizable" title="Customizable" />
                                                <Typography variant="h5" className="mb-1">
                                                    Customizable
                                                </Typography>
                                                <Typography className="head_paragraph">
                                                    From the appearance of your site to how you design your item classifications and highlights, everything made with Magento is custom.
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Advantages of Hiring Us" */}

                    {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Hire Magento Developers
                                        </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            With us, you find out a crew of <strong> 20+ Magento</strong> builders who constantly push their efforts to furnish pleasant <strong>Magento 2 </strong> guides to generate an enterprise-grade web portal within the cost nice budget.
                                            No matter what you are selling, we create an attractive personal experience and make each venture come out with a splendid straight forward which is quality for the e-commerce development industry.
                                        </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                                    <div className="list_key_boxes">
                                        <img alt="Expert Consultation" title="Expert Consultation" src={expert_consulting} />
                                        <Typography variant="h4" className="head_title">
                                            Expert Consultation
                                        </Typography>
                                        <Typography className="head_paragraph">
                                            We make charming, astounding and exceptional <strong>Magento</strong> web composition which is gadget responsive, completely operational and guarantees you of the most astonishing outcomes.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                                    <div className="list_key_boxes">
                                        <img alt="Maintenance & Support" title="Maintenance & Support" src={Maintenance_Support} />
                                        <Typography variant="h4" className="head_title">
                                            Maintenance & Support
                                        </Typography>
                                        <Typography className="head_paragraph">
                                            We have a very well-experienced group and help the customer to incorporate arrangements including operational frameworks like request the executives, shipping the board, satisfaction focuses, CRM, ERP, or bookkeeping.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="list_key_boxes">
                                        <img alt="Search Engine Friendly" title="Search Engine Friendly" src={search_engion_friendly} />
                                        <Typography variant="h4" className="head_title">
                                            Search Engine Friendly
                                        </Typography>
                                        <Typography className="head_paragraph">
                                            The Enterprise Edition of <strong>Magento</strong> was structured in light of SEO, so improving item pages and depictions is basic. It additionally produces SEO-accommodating URLs (instead of the disorders of letters and numbers found on numerous internet business locales) and meta depictions, implying that you don't need to stress over making them yourself.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="list_key_boxes">
                                        <img alt="E-Commerce Website Design" title="E-Commerce Website Design" src={ecommerce_web_design} />
                                        <Typography variant="h4" className="head_title">
                                            E-Commerce Website Design
                                        </Typography>
                                        <Typography className="head_paragraph">
                                            <strong>Magento</strong> is a genuine contender in a market of world-class internet business stages. The objective is to give a smooth route, increment client commitment, and develop the general income age for store managers.
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Hire Our Developers KeyBenefits" */}

                    {/* Start - Section Design "Why Hire Us" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="why_hire_us sec_padding">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">"Visions" is the incomparable and first <strong>Magento</strong> Development firm in India with a solid tendency towards outsider reconciliations. We have the group of generally gifted and proficient individuals in "Visions", who pass on the world-class <strong>Magento</strong> sites as per the client's needs and their desires.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                                        <ul className="left_list lists_hire_us">
                                            <li>
                                                <strong>Magento</strong> Theme Integration. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                <strong>Magento</strong> Customization. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                <strong>Magento</strong> Maintenance. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                Migrations and upgrades. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                        </ul>
                                    </Grid>
                                    <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                                        <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                                    </Grid>
                                    <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                                        <ul className="right_list lists_hire_us">
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> <strong>Magento</strong> teeny-bopper fixes.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> Accurate estimates & billing.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> <strong>Magento</strong> Extensions.
                                            </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> Application & Server Security.
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Why Hire Us" */}

                    {/* Start - Section Design "We Are Here to Help You" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Browse through the most frequently asked questions</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                                        <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                                        <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "We Are Here to Help You" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <NumberCount />
                    {/* End - NumberCount Import From Common Components */}

                </Grid>
            </div>
        );
    }
}

export default HireMagentoDev;
