import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import ECommerceWebsiteDesign from '../../assets/images/HireDevelopers/HireDrupal/E-CommerceWebsiteDesign.svg';
import EASYTOUNDERSTAND from '../../assets/images/HireDevelopers/HireDrupal/EASYTOUNDERSTAND.svg';
import EFFICIENCYOFMONGODB from '../../assets/images/HireDevelopers/HireDrupal/EFFICIENCYOFMONGODB.svg';
import ExpertConsultation from '../../assets/images/HireDevelopers/HireDrupal/ExpertConsultation.svg';
import FRAMEWORK from '../../assets/images/HireDevelopers/HireDrupal/FRAMEWORK.svg';
import GREATDEVELOPERTOOLS from '../../assets/images/HireDevelopers/HireDrupal/GREATDEVELOPERTOOLS.svg';
import MaintenanceSupport from '../../assets/images/HireDevelopers/HireDrupal/MaintenanceSupport.svg';
import SearchEngineFriendly from '../../assets/images/HireDevelopers/HireDrupal/SearchEngineFriendly.svg';
import HireDrupalBanner from '../../assets/images/HireDevelopers/HireDrupal/HireDrupalBanner.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireDrupalDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Hire Drupal Developers",
      bannerImg: HireDrupalBanner,
      HereToHelpYou: [
        {
          expanded: 'panel1',
          ariaControls: 'panel1d-content',
          panelId: 'panel1d-header',
          typographyText: "Why Drupal is so popular and called a powerful framework?",
          TypographyDescription: "Drupal is very easy to learn and handle. A user with nil programming skill can also handle the content management and customization of a Drupal's website. That's why Drupal is getting popular day by day. "
        },
        {
          expanded: 'panel2',
          ariaControls: 'panel2d-content',
          panelId: 'panel2d-header',
          typographyText: "What is a sub theme in Drupal?",
          TypographyDescription: "Sub themes are just like other themes of Drupal but they have a little difference. Sub themes inherit the parent's theme. There are many sub themes that can be connected to their parents. A sub-theme can be a child of another sub-theme, and it can be branched and organized however you see fit. This is what gives sub-themes great potential."
        },
        {
          expanded: 'panel3',
          ariaControls: 'panel3d-content',
          panelId: 'panel3d-header',
          typographyText: "What are patches in Drupal?",
          TypographyDescription: "Patches are the files that contain a list of differences between one set of files, and another. You can make changes like addition, deletion in codes through patches. Patches can also be used to make changes to another copy of the original or main file. "
        },
        {
          expanded: 'panel4',
          ariaControls: 'panel4d-content',
          panelId: 'panel4d-header',
          typographyText: "What is DRUSH in Drupal?",
          TypographyDescription: "DRUSH is a command line shell and UNIX scripting interface for Drupal. "
        },
        {
          expanded: 'panel5',
          ariaControls: 'panel5d-content',
          panelId: 'panel5d-header',
          typographyText: "What is a module in Drupal?",
          TypographyDescription: "A module is a set of codes that extend Drupal features and functionality. Drupal modules: Core modules, Contributed modules. You can also create your own module 'CustomModules' using PHP programming, and Drupal's module API."
        }
      ]
      };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire Drupal Web Developer | Drupal Programmers in India";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="Drupal Developers" />
          {/* End - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <strong>Visions </strong> have unrivaled involvement by giving Drupal methodology AND making proficient, inventive and exceptionally modified Drupal-based arrangements. Since our establishing in 1993, Drupal stays a foundation of our advancement, plan and counseling endeavors today. <strong>Drupal </strong> advancement arrangements made by <strong>Visions </strong> are secure, versatile, measured and, most eminently, include pressed and worked for superior.
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={EASYTOUNDERSTAND} alt="Easy to Understand" title="Easy to Understand" />
                        <Typography variant="h5" className="mb-1">
                          Easy to Understand
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong> Drupal</strong> empowers an adaptable structure stage to make content-rich sites for a wide range of business sectors like media or trade.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={GREATDEVELOPERTOOLS} alt="Great Developer Tools" title="Great Developer Tools" />
                        <Typography variant="h5" className="mb-1">
                          Great Developer Tools
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Drupal</strong> is an open source CMS. It is actually a culture and a way to deal with innovation that rotates around the free trade of thoughts and advancement.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={FRAMEWORK} alt="Framework" title="Framework" />
                        <Typography variant="h5" className="mb-1">
                          Framework
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong> Drupal</strong> isn't (generally) a CMS; it is a system that does "CMSey" stuff.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={EFFICIENCYOFMONGODB} alt="Efficiency of MongoDB" title="Efficiency of MongoDB" />
                        <Typography variant="h5" className="mb-1">
                          Efficiency of MongoDB
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong> Drupal 7 </strong> saw the arrival of the MongoDB module. The <strong>MongoDB Drupal</strong> module introduces like any drupal module.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire Drupal Developers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      We have skilled <strong>Drupal</strong> developers with unparalleled skill in building the absolute most complex drupal web applications/destinations. Our guaranteed group of drupal advisors are capable enough to deal with your case, and give an ideal arrangement, regardless of the multifaceted nature of your prerequisites.
                              </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="Expert Consultation" title="Expert Consultation" src={ExpertConsultation} />
                    <Typography variant="h4" className="head_title">
                      Expert Consultation
                              </Typography>
                    <Typography className="head_paragraph">
                      As a proficient <strong>Drupal web improvement</strong> organization, we have a balanced group with ability in engineering configuration, Front end usage and Drupal advancement.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="Maintenance & Support" title="Maintenance & Support" src={MaintenanceSupport} />
                    <Typography variant="h4" className="head_title">
                      Maintenance & Support
                              </Typography>
                    <Typography className="head_paragraph">
                      Our Drupal backing and upkeep administrations include access to a devoted master who might be accessible to react to your help ticket or help you with new element improvement to develop your site.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="Search Engine Friendly" title="Search Engine Friendly" src={SearchEngineFriendly} />
                    <Typography variant="h4" className="head_title">
                      Search Engine Friendly
                              </Typography>
                    <Typography className="head_paragraph">
                      The process includes : Site Audit, Technique & usefulness and Usage. We optimize the website to load faster. Add proper meta keywords and description. Multiple On-site SEO Optimization is done to make sure website has good ranking score.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="E-Commerce Website Design" title="E-Commerce Website Design" src={ECommerceWebsiteDesign} />
                    <Typography variant="h4" className="head_title">
                      E-Commerce Website Design
                              </Typography>
                    <Typography className="head_paragraph">

                      <strong>Ubercart</strong> is the most popular Drupal E-Commerce platform for your website. It implements everything you need to start selling products online.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200"><strong>Visions</strong> gives a full supplement of <strong>Drupal</strong> counseling and advancement benefits that include: arranging and disclosure, business examination, wireframe creation, imaginative structure, site theming, custom design and improvement, site coordination, quality affirmation, counseling, backing and upkeep administrations.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li>
                        Build an application/ website with Drupal.<img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Maintain an existing Drupal site that is core to your business.<img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Upgrade to the latest version of Drupal.<img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Augment your existing Drupal team by collaborating with an expert.<img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        All your project data, code, and business data is handled in line with the highest security standards.<img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> We attend daily meetings, submit weekly reports, and respond in 15 minutes during working hours.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Our weekly reports and daily meetings will keep you up to date.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> We've developed processes that are guaranteed to save your time, money and hassle.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} />Client Satisfaction is our first priority.
                                  </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200"><strong>Visions</strong> is a standout amongst other <strong>Drupal Development</strong> Company that offers the Drupal improvement Services to meet your business objectives. Diverse sort of sites that can be manufactured utilizing Drupal CMS stage incorporates News media sites, distributing organization site, E-Commerce sites, Corporate site, Content administration framework, Intranet applications, Newsletters, Educational sites and some more. All things considered, you will locate a long haul relationship after you get in out with our group and embrace our <strong>Drupal Development </strong>administrations.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HireDrupalDev;
