import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Hire_banner from '../../assets/images/HireDevelopers/HireReactNative/Reactnativebanner1.png';
import crossplatformcompatibility from '../../assets/images/HireDevelopers/HireReactNative/crossplatformcompatibility.svg';
import Easyupdates from '../../assets/images/HireDevelopers/HireReactNative/Easyupdates.svg';
import Gotomarketassistance from '../../assets/images/HireDevelopers/HireReactNative/Gotomarketassistance.svg';
import ReactNativeDevelopment from '../../assets/images/HireDevelopers/HireReactNative/ReactNativeDevelopment.svg';
import Reusablecode from '../../assets/images/HireDevelopers/HireReactNative/Reusablecode.svg';
import richuicomponents from '../../assets/images/HireDevelopers/HireReactNative/richuicomponents.svg';
import Supportandmaintenance from '../../assets/images/HireDevelopers/HireReactNative/Supportandmaintenance.svg';
import UIUXArchitecture from '../../assets/images/HireDevelopers/HireReactNative/UIUXArchitecture.svg';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireReactNativeDev extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Hire React Native App Developers",
            bannerImg: Hire_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: 'What is a React native technology?',
                    TypographyDescription: 'It is the cross platform technology developed by Facebook to build apps rapidly and with less investment. Businesses Like Walmart, Facebook, Instagram have their apps build on React Native.'
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: 'What do you mean by cross-platform?',
                    TypographyDescription: 'Cross platform technology allows you to build a single app which will be compatible to run on both iOS and Android platforms eliminating the need to build two separate apps for them.'
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: 'Are React Native apps less costly than Native Apps?',
                    TypographyDescription: 'Yes, React Native apps are most likely to cost you less that what a Native app will take. In React native, we need to build only one app which is then modified to make it compatible with both platforms.'
                },
                {
                    expanded: 'panel4',
                    ariaControls: 'panel4d-content',
                    panelId: 'panel4d-header',
                    typographyText: 'Are React Native apps reliable?',
                    TypographyDescription: 'Yes, definitely. React native apps are as reliable and good in performance as a Native app will be.'
                }
            ]
        };
    }
    componentWillMount() {
        let isAuthenticated = !localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) {
            this.props.history.push("/login");
            window.location.reload();
        }
    }
    render() {
        document.title = "Hire React Native Developer | React Native App Developers";
        const {
            pageTitle, bannerImg
        } = this.state;
        return (
            <div className="main_hireEcommerce main_hireDevelopers">
                <Grid container>
                    {/* Start - InnerPagesBanner Import From Common Components */}
                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
                    {/* End - InnerPagesBanner Import From Common Components */}

                    {/* Start - HireUsForm Import From Root Of Components Folder */}
                    <HireUsForm technology="React Native App Developers" />
                    {/* End - HireUsForm Import From Root Of Components Folder */}

                    {/* Start - Section Design "Advantages of Hiring Us" */}
                    <Grid item xs={12} className="main_application_dev">
                        <Grid
                            component="div"
                            className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
                            id="ios-app-development"
                        >
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <div className="text_wrap title_row">
                                        <aside>
                                            <Typography
                                                variant="h2"
                                                className="head_title"
                                                data-aos="fade-up"
                                                data-aos-duration="800"
                                            >
                                                Advantages of Hiring Us
                      </Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1200"
                                            >
                                                At <strong>Visions</strong>, we have an expert team of <strong>React Native</strong> constituting Senior developers, designers and project managers, who can help you to achieve an end to end <strong>React Native</strong> app right from design, development, testing, deployment as well. We are experts in developing dynamic and bespoke <strong>React Native</strong> apps.
                      </Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1400"
                                            >
                                                <strong>
                                                    React Native is the new era of mobile apps with speedy development and competitive costs.
                        </strong>
                                            </Typography>
                                        </aside>
                                    </div>
                                    <Grid item container xs={12} className="major_networks_wrap">
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={Reusablecode} alt="Reusable Code" title="Reusable Code" />
                                                <Typography variant="h5" className="mb-1">
                                                    Reusable Code
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    <strong>React Native</strong> modules/classes are reusable and hence offer rapid development and less investment.
                            </Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={crossplatformcompatibility} alt="Cross Platform Compatibility" title="Cross Platform Compatibility" />
                                                <Typography variant="h5" className="mb-1">
                                                    Cross Platform Compatibility
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    <strong>React Native</strong> is compatible to both iOS and Android platforms and hence saves time rather than developing two different apps for both platforms.
                            </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                                                <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={richuicomponents} alt="Rich UI Components" title="Rich UI Components" />
                                                <Typography variant="h5" className="mb-1">
                                                    Rich UI components
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    <strong>React Native</strong> also offer native use of the UI components and allows your app to behave as a native application to a higher extent.
                            </Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={Easyupdates} alt="Easy Updates" title="Easy Updates" />
                                                <Typography variant="h5" className="mb-1">
                                                    Easy updates
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    <strong>React Native</strong> offers easy updates, where we can update our <strong>React Native</strong> app without going through the app store cycle.
                            </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Advantages of Hiring Us" */}

                    {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Hire React Native App Developer
                              </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            Partnering with Visions, for your upcoming <strong>React Native</strong> app will help you to achieve the absolute edge in the market. Visions, will keep you update during the entire process of design, development, testing and deploying your app as well.
                              </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                                    <div className="list_key_boxes">
                                        <img alt="UI/UX Architecture" title="UI/UX Architecture" src={UIUXArchitecture} />
                                        <Typography variant="h4" className="head_title">
                                            UI/UX Architecture
                              </Typography>
                                        <Typography className="head_paragraph">
                                            We do not settle for anything less that perfect for the UI designs and UX of the app as it the part of our app, which will deal with your customers.
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                                    <div className="list_key_boxes">
                                        <img alt="React Native Development" title="React Native Development" src={ReactNativeDevelopment} />
                                        <Typography variant="h4" className="head_title">
                                            React Native Development
                              </Typography>
                                        <Typography className="head_paragraph">
                                            While coding the app, we make sure the every line of code is quality assured and clean to meet the standards of a native application.
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="list_key_boxes">
                                        <img alt="Go-to-market Assistance" title="Go-to-market Assistance" src={Gotomarketassistance} />
                                        <Typography variant="h4" className="head_title">
                                            Go-to-market Assistance
                              </Typography>
                                        <Typography className="head_paragraph">
                                            We will completely assist you to develop and deploy your app on the respective App stores, we make sure your app passes the approval process of Google play and Apple store.
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="list_key_boxes">
                                        <img alt="Support and Maintenance" title="Support and Maintenance" src={Supportandmaintenance} />
                                        <Typography variant="h4" className="head_title">
                                            Support and Maintenance
                              </Typography>
                                        <Typography className="head_paragraph">
                                            We offer free of cost support and maintenance of 30 days for your apps to launch successfully in the market.
                              </Typography>
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Hire Our Developers KeyBenefits" */}

                    {/* Start - Section Design "Why Hire Us" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="why_hire_us sec_padding">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Being the best <strong>React Native</strong> company, we deliver tailored and reliable mobile apps for your business needs.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                                        <ul className="left_list lists_hire_us">
                                            <li>
                                                We offer competitive prices to our clients. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                Best consultancy for your requirements. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                Exceptional customer satisfaction. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                        </ul>
                                    </Grid>
                                    <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                                        <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                                    </Grid>
                                    <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                                        <ul className="right_list lists_hire_us">
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> Top notch developers.
                                  </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> Transparent work process.
                                  </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> Precise timelines and accurate estimates.
                                  </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "Why Hire Us" */}

                    {/* Start - Section Design "We Are Here to Help You" */}
                    <Grid item xs={12}>
                        <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Browse through some of the questions you need to answers before proceeding with your <strong>React Native</strong> app project.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                                        <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                                        <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* End - Section Design "We Are Here to Help You" */}

                    {/* Start - NumberCount Import From Common Components */}
                    <NumberCount />
                    {/* End - NumberCount Import From Common Components */}

                </Grid>
            </div>
        );
    }
}

export default HireReactNativeDev;
