import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.hundleSubmit = this.hundleSubmit.bind(this);
    this.state = {
      userEmail: "",
      userPassword: ""
    };
  }

  renderRedirect() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (isAuthenticated) {
      return <Redirect to="/" />;
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  hundleSubmit(event) {
    event.preventDefault();
    if (
      this.state.userEmail === "nitish@visions.net.in" &&
      this.state.userPassword === "visions@2019"
    ) {
      localStorage.setItem("isAuthenticated", true);
      this.props.history.push("/");
      window.location.reload();
    }
  }
  render() {
    document.title = "Website Design & Web Development Company India";
    return (
      <div className="secure_login">
        {this.renderRedirect()}
        <div className="email">
          <label>Email</label>
          <input
            type="text"
            name="userEmail"
            value={this.state.userEmail}
            onChange={this.handleChange}
          />
        </div>
        <div className="password">
          <label>Password</label>
          <input
            type="password"
            name="userPassword"
            value={this.state.userPassword}
            onChange={this.handleChange}
          />
        </div>
        <div className="submit_btn">
          <button type="submit" onClick={this.hundleSubmit}>
            submit
          </button>
        </div>
      </div>
    );
  }
}
export default LoginPage;
