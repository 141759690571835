import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Nearness_of_Third_Party_Modules from '../../assets/images/HireDevelopers/HirePython/Nearness_of_Third_Party_Modules.svg';
import Broad_Support_Libraries from '../../assets/images/HireDevelopers/HirePython/Broad_Support_Libraries.svg';
import Easy_to_understand_Data_Structures from '../../assets/images/HireDevelopers/HirePython/Easy_to_understand_Data_Structures.svg';
import Efficiency_and_Speed from '../../assets/images/HireDevelopers/HirePython/Efficiency_and_Speed.svg';
import Service_Quality_Excellence from '../../assets/images/HireDevelopers/HirePython/Service_Quality_Excellence.svg';
import No_micro_management from '../../assets/images/HireDevelopers/HirePython/No_micro_management.svg';
import Ease_of_use from '../../assets/images/HireDevelopers/HirePython/Ease_of_use.svg';
import Extraordinary_Code from '../../assets/images/HireDevelopers/HirePython/Extraordinary_Code.svg';
import HirePythonBanner from '../../assets/images/HireDevelopers/HirePython/HirePythonBanner.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HirePythonDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Hire Python Developers",
      bannerImg: HirePythonBanner,
      HereToHelpYou: [
        {
          expanded: 'panel1',
          ariaControls: 'panel1d-content',
          panelId: 'panel1d-header',
          typographyText: 'How is Python programming language not quite the same as others? ',
          TypographyDescription: "As an issue of first significance, the linguistic structure of Python is as clear as it can get when stood out from various tongues. Another highlight of Python is that it's expansive library backing and closeness of an enormous number of untouchable modules."
        },
        {
          expanded: 'panel2',
          ariaControls: 'panel2d-content',
          panelId: 'panel2d-header',
          typographyText: 'What are the pluses of the site created utilizing Python? ',
          TypographyDescription: 'Because of the improvement of online business locales versatility, ease in using the site and will help in making an incredible website inside a restricted capacity to center time.'
        },
        {
          expanded: 'panel3',
          ariaControls: 'panel3d-content',
          panelId: 'panel3d-header',
          typographyText: 'Why is Machine learning interrelated to python improvement administrations? ',
          TypographyDescription: "Python's relentless punctuation and how it imitates English tongue make it a favored lingo to produce AI estimations among our specialists."
        },
        {
          expanded: 'panel4',
          ariaControls: 'panel4d-content',
          panelId: 'panel4d-header',
          typographyText: 'Do python leads useful for Search Engine Optimization?',
          TypographyDescription: "Frameworks of Python help in making fathomable URL's which can use most situated watchwords with the objective that regular interests in web search instruments will bolster your website more. "
        },
        {
          expanded: 'panel5',
          ariaControls: 'panel5d-content',
          panelId: 'panel5d-header',
          typographyText: 'How to Hire your Python Development?',
          TypographyDescription: "We give three sorts of duty models for securing resources. Clients have the decision to peruse the going with models: • Dedicated Developer (Monthly reason) – 160 Hours/Month. • 8 hours out of each day, 5 days per week. • Hourly Basis – Hours/Month. After the asset enlisting, every one of the stages is taken consideration with fitting accuracy."
        }
      ]
    };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire Python Developers | Python Programmers in India";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="Python Developers" />
          {/* End - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <strong>Visions</strong> convey a wide scope of custom <strong>Python</strong> arrangements, spreading over advanced web-based interfaces and cutting edge applications, regardless of whether they are basic to your business or are planned for improving experience and nearness.
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Nearness_of_Third_Party_Modules} alt="Nearness of Third Party Modules" title="Nearness of Third Party Modules" />
                        <Typography variant="h5" className="mb-1">
                          The nearness of Third Party Modules
                            </Typography>
                        <Typography className="head_paragraph">
                          The <strong>Python Package Index (PyPI)</strong> contains various third-party modules that make Python equipped for interfacing with a large portion of different programming languages.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Broad_Support_Libraries} alt="Broad Support Libraries" title="Broad Support Libraries" />
                        <Typography variant="h5" className="mb-1">
                          Broad Support Libraries
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Python</strong> gives an enormous standard library that incorporates regions like web conventions, string tasks, web services devices, and OS interfaces.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Easy_to_understand_Data_Structures} alt="Easy to understand Data Structures" title="Easy to understand Data Structures" />
                        <Typography variant="h5" className="mb-1">
                          Easy to understand Data Structures
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Python</strong> has worked in rundown and lexicon information structures which can be utilized to build quick runtime data structures.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Efficiency_and_Speed} alt="Efficiency and Speed" title="Efficiency and Speed" />
                        <Typography variant="h5" className="mb-1">
                          Efficiency and Speed
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Python</strong> has a clean object-oriented plan, gives upgraded process control capacities, and has solid incorporation and content preparing abilities.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire Python Developers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      <strong>Visions</strong> convey halfway and propelled web and portable Python applications that appreciate high adaptability with Google App Engine. We use this leader of Google's Cloud figuring administrations to have your <strong>Python application</strong> inside situations that scale naturally with your traffic, in this way, limiting forthright expenses and representing surprising increments in loads.
                              </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="Service Quality Excellence" title="Service Quality Excellence" src={Service_Quality_Excellence} />
                    <Typography variant="h4" className="head_title">
                      Service Quality Excellence
                              </Typography>
                    <Typography className="head_paragraph">
                      At <strong>Visions</strong> we grasp administration quality consistently; we mean to respond to your inquiries before you've even posed to them. Crystal clear estimates, accomplished deadlines, frequent yet concise reports, and much much more.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="No Micro-Management" title="No Micro-Management" src={No_micro_management} />
                    <Typography variant="h4" className="head_title">
                      No Micro-Management
                              </Typography>
                    <Typography className="head_paragraph">
                      We will drive your venture forward, center around your business objectives and attempt to spare you however much time as could reasonably be expected. We step up to the plate and actualize savvy highlights. We don't pose superfluous inquiries.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="Ease of use" title="Ease of use" src={Ease_of_use} />
                    <Typography variant="h4" className="head_title">
                      Ease of use
                              </Typography>
                    <Typography className="head_paragraph">
                      Expectations satisfy intense norms on code quality and design. We convey a well-refactored code that brings about quick, protected and versatile arrangements.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="Extraordinary Code" title="Extraordinary Code" src={Extraordinary_Code} />
                    <Typography variant="h4" className="head_title">
                      Extraordinary Code
                              </Typography>
                    <Typography className="head_paragraph">
                      Our engineers are persistently being prepared and instructed on the most proficient method to convey more easy to use more brilliant and progressively important highlights.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Our committed <strong>Python</strong> group administrations accommodate speedy sending of a huge IT anticipate and game plan of productive and solid help for continuous tasks. <strong>Visions</strong> have an enormous ability pool to sort out an exceptionally devoted group for an all-encompassing timeframe which can without much of a stretch be coordinated with your in-house group or work as a different augmentation to your business.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li>
                        Custom Development. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Prepared to use IT infrastructure. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Legacy Rescue. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Fast group scaling/involvement of specific topic specialists on an ad hoc basis. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Customization & Upgrade.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Prototyping.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Quick MVP Delivery.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Complete control over the group structure (inclusion/ substitution / withdrawal).
                                  </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">The broadness of information and understanding that <strong>Visions</strong> have inside its dividers enables us to use that mastery to make prevalent expectations for our clients. At the point when you work with us, you are working with the top 1% of the bent and designing greatness of the entire nation.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HirePythonDev;
