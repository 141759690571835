import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
//import LazyLoad from "react-lazyload";

// Get Images Start
import Web_Development from "../../assets/images/services/Web-Development.svg";
import UI_UX_DESIGNING from "../../assets/images/services/UI-UX-DESIGNING.svg";
import App_Development from "../../assets/images/services/App-Development.svg";
import E_commerce_Solutions from "../../assets/images/services/E-commerce-Solutions.svg";
import Digital_Marketing from "../../assets/images/services/Digital-Marketing.svg";
import Server_Network_Admin_Support from "../../assets/images/services/Server-Network-Admin-Support.svg";
import Web_Development_White from "../../assets/images/services/Web-Development-white.svg";
import UI_UX_DESIGNING_White from "../../assets/images/services/UI-UX-DESIGNING-white.svg";
import App_Development_White from "../../assets/images/services/App-Development-white.svg";
import E_commerce_Solutions_White from "../../assets/images/services/E-commerce-Solutions-white.svg";
import Digital_Marketing_White from "../../assets/images/services/Digital-Marketing-white.svg";
import Server_Network_Admin_Support_White from "../../assets/images/services/Server-Network-Admin-Support-white.svg";
import Web_Development_Big from "../../assets/images/services/web_development.svg";
import UI_UX_DESIGNING_Big from "../../assets/images/services/UI_UX.svg";
import App_Development_Big from "../../assets/images/services/application_development.svg";
import E_commerce_Solutions_Big from "../../assets/images/services/ecommerce_development.svg";
import Digital_Marketing_Big from "../../assets/images/services/digital_marketing.svg";
import Server_Network_Admin_Support_Big from "../../assets/images/services/Network_Admin_Support.svg";
import mean_logo from "../../assets/images/HomePage/mean-logo.svg";
import mern_logo from "../../assets/images/HomePage/mern-logo.svg";
import mevn_logo from "../../assets/images/HomePage/mevn-logo.svg";
import python_logo from "../../assets/images/HomePage/python-logo.svg";
import dontNetIcon from "../../assets/images/HomePage/dontNetIcon.svg";
import php_logo from "../../assets/images/HomePage/php-logo.svg";
import Reactjs_logo from "../../assets/images/HomePage/React.js-logo.svg";
import apple_logo from "../../assets/images/HomePage/apple_logo.svg";
import android_logo from "../../assets/images/HomePage/android-logo.svg";
import seo_colored from "../../assets/images/colored/seo.svg";
import seo2_colored from "../../assets/images/colored/seo2.svg";
import socialMediaSeo_colored from "../../assets/images/colored/social-media-seo.svg";
import online_reputation_icon_colored from "../../assets/images/colored/online_reputation_icon.svg";
import landingpage_colored from "../../assets/images/colored/wesite-design.svg";
import responsive_colored from "../../assets/images/colored/responsive.svg";
import logoDesign_colored from "../../assets/images/colored/logo_design.svg";
import bannerDesign_colored from "../../assets/images/colored/banner_design.svg";
import brochureDesign_colored from "../../assets/images/colored/brochure_design.svg";
// import printmedia_colored from "../../assets/images/colored/printmedia.svg";
import MobileAppIcon_colored from "../../assets/images/colored/MobileAppIcon.svg";
import magento_colored from "../../assets/images/colored/magento.svg";
import woocommerce_colored from "../../assets/images/colored/woo.svg";
import shopify_colored from "../../assets/images/colored/shopify.svg";
import opencart_colored from "../../assets/images/colored/opencart.svg";
import headerbigcommerceiconcolored from "../../assets/images/colored/header-bigcommerce-icon.svg";
import header_awsCloud_icon_colored from "../../assets/images/colored/header-awsCloud-icon.svg";
import server_admin_support_colored from "../../assets/images/colored/server_admin_support.svg";
import header_linux_icon_colored from "../../assets/images/colored/header-linux-icon.svg";
import header_emailmigration_icon_colored from "../../assets/images/colored/header_emailmigration_icon.svg";
import services_banner from "../../assets/images/services/SERVICE-BANNER.jpg";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
// Get Common Components End

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Services We Offer",
      bannerImg: services_banner,
      activeTab: "Web-Development",
      activeTab1: "tab-1",
      tabToggle: false,
      isClickedEventFired: 0
    };
    smoothscroll.polyfill();
    this.baseState = this.state;
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent, false);
  }
  
  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent, false);
  }

  listenScrollEvent = e => {
    if (this.state.isClickedEventFired > 0) {
      var abc = setTimeout(() => {
        this.setState({ isClickedEventFired: 0 });
      }, 5000);
      if (this.state.isClickedEventFired > 1) {
        clearTimeout(abc);
      }
    } else {
      var section1 = this.checkElementInViewport(
        document.getElementById("Web-Development")
      );
      var section2 = this.checkElementInViewport(
        document.getElementById("UI-UX-Design")
      );
      var section3 = this.checkElementInViewport(
        document.getElementById("App-Development")
      );
      var section4 = this.checkElementInViewport(
        document.getElementById("E-commerce-Solution")
      );
      var section5 = this.checkElementInViewport(
        document.getElementById("Digital-marketing")
      );
      var section6 = this.checkElementInViewport(
        document.getElementById("Admin-Support")
      );

      if (section1 && section2) {
        this.setState({ activeTab: "Web-Development", activeTab1: "tab-1" });
      }
      if ((section2 && !section1) || (section2 && section3)) {
        this.setState({ activeTab: "UI-UX-Design", activeTab1: "tab-2" });
      }
      if ((section3 && !section2) || (section3 && section4)) {
        this.setState({ activeTab: "App-Development", activeTab1: "tab-3" });
      }
      if ((section4 && !section3) || (section4 && section5)) {
        this.setState({ activeTab: "E-commerce-Solution", activeTab1: "tab-4" });
      }
      if ((section5 && !section4) || (section5 && section6)) {
        this.setState({ activeTab: "Digital-marketing", activeTab1: "tab-5" });
      }
      if (section6 && !section5) {
        this.setState({ activeTab: "Admin-Support", activeTab1: "tab-6" });
      }
    }
  };

  componentWillUpdate() {
    var backToTop = localStorage.getItem("backToTop");
    if (backToTop && backToTop === "true") {
      this.setState({
        activeTab: this.baseState.activeTab,
        activeTab1: this.baseState.activeTab1
      });
      localStorage.removeItem("backToTop");
    }
  }

  checkElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    const vertInView = 
      rect.top <= windowHeight && rect.top-74 + rect.height >= 0;
    const horInView = 
      rect.left <= windowWidth && rect.left-74 + rect.width >= 0;

    return vertInView && horInView;
  }

  handleOnClickScroll = (divId, tab) => {
    this.setState({
      activeTab: divId,
      activeTab1: tab,
      isClickedEventFired: this.state.isClickedEventFired + 1
    });
    var ref = document.getElementById(divId);
    //ref.scrollIntoView({ behavior: "smooth", block: "start" });
    window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
  };

  handleTabToggle = () => {
    this.setState(state => ({ tabToggle: !state.tabToggle }));
  };

  render() {
    document.title = "Web Design, Apps & Ecommerce Web Development Services";
    const { pageTitle, bannerImg } = this.state;

    return (
      <div className="main_services">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* Start - InnerPagesBanner Import From Common Components */}

          {/* Start - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}
          <Grid item xs={12}>
            <Grid component="div">
              <Grid className="serivceTabsSide">
                <Grid
                  className={`services-listing ${
                    this.state.tabToggle ? "showTab" : ""
                  }`}
                >
                  <div className="toggle-btn" onClick={this.handleTabToggle}>
                    <i className="material-icons">arrow_forward</i>
                  </div>
                  <Grid item container className="container_custom">
                    <Grid item xs={12}>
                      <div className={`row-flex ${this.state.activeTab1}`}>
                        <div
                          className={`col-flex tab-1 ${
                            this.state.activeTab === "Web-Development"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll("Web-Development", "tab-1")
                          }
                          title="Web Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={Web_Development}
                                className="withoutActive"
                                alt="Web Development"
                                title="Web Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={Web_Development_White}
                                className="withActive"
                                alt="Web Development"
                                title="Web Development"
                              />
                            </div>
                            <Typography>
                              Web <br /> Development{" "}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-2 ${
                            this.state.activeTab === "UI-UX-Design"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll("UI-UX-Design", "tab-2")
                          }
                          title="UI/UX Designing"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={UI_UX_DESIGNING}
                                className="withoutActive"
                                alt="UI/UX Designing"
                                title="UI/UX Designing"
                              />
                              <img
                                width="30"
                                height="30"
                                src={UI_UX_DESIGNING_White}
                                className="withActive"
                                alt="UI/UX Designing"
                                title="UI/UX Designing"
                              />
                            </div>
                            <Typography>
                              UI/UX <br /> DESIGNING
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-3 ${
                            this.state.activeTab === "App-Development"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll("App-Development", "tab-3")
                          }
                          title="App Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={App_Development}
                                className="withoutActive"
                                alt="App Development"
                                title="App Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={App_Development_White}
                                className="withActive"
                                alt="App Development"
                                title="App Development"
                              />
                            </div>
                            <Typography>
                              App <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-4 ${
                            this.state.activeTab === "E-commerce-Solution"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "E-commerce-Solution",
                              "tab-4"
                            )
                          }
                          title="E-commerce Solutions"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={E_commerce_Solutions}
                                className="withoutActive"
                                alt="E-commerce Solutions"
                                title="E-commerce Solutions"
                              />
                              <img
                                width="30"
                                height="30"
                                src={E_commerce_Solutions_White}
                                className="withActive"
                                alt="E-commerce Solutions"
                                title="E-commerce Solutions"
                              />
                            </div>
                            <Typography>
                              E-commerce <br /> Solutions
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-5 ${
                            this.state.activeTab === "Digital-marketing"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "Digital-marketing",
                              "tab-5"
                            )
                          }
                          title="Digital Marketing"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={Digital_Marketing}
                                className="withoutActive"
                                alt="Digital Marketing"
                                title="Digital Marketing"
                              />
                              <img
                                width="30"
                                height="30"
                                src={Digital_Marketing_White}
                                className="withActive"
                                alt="Digital Marketing"
                                title="Digital Marketing"
                              />
                            </div>
                            <Typography>
                              Digital <br /> Marketing
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-6 ${
                            this.state.activeTab === "Admin-Support"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll("Admin-Support", "tab-6")
                          }
                          title="Server/Network Admin Support"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={Server_Network_Admin_Support}
                                className="withoutActive"
                                alt="Server/Network Admin Support"
                                title="Server/Network Admin Support"
                              />
                              <img
                                width="30"
                                height="30"
                                src={Server_Network_Admin_Support_White}
                                className="withActive"
                                alt="Server/Network Admin Support"
                                title="Server/Network Admin Support"
                              />
                            </div>
                            <Typography>
                              Server/Network <br /> Admin support
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}
          
          <Grid item xs={12}>
            <Grid item xs={12} className="serviceListWrapper">

              {/* Start - Section Design "Web Development Services" */}
              <Grid
                component="div"
                className="services-detail-content sec_padding"
                id="Web-Development"
              >
                <Grid item container className="container_custom">
                  <Grid item md={12}>
                    <div className="custom-row">
                      <div
                        className="custom-col-7"
                        data-aos="fade-right"
                        data-aos-duration="400"
                      >
                        <div className="content-wrapper title_row">
                          <Typography
                            variant="h2"
                            className="head_title text-left"
                          >
                            Web Development Services
                          </Typography>
                          <Typography>
                          We take great pride in producing exceptional websites that effectively combine technology and design. Most of the websites that we build are created with a modern, professional style in mind. Every site is built from the ground up (we do not use templates), making each project unique, allowing you the opportunity to attract the attention it deserves. 
                          </Typography>
                          <Typography>
                          Our unique blend of expert technical minds, functional analysts, and creative web designers ensure that your site will not only look good but will be easy to use, fast and highly functional. Our portfolio is filled with sites that successfully combine the best of both worlds.
                          </Typography>
                          <div className="tech_icons_wrapper">
                            <Link to="/services/web-development-services/php-development"><img width="40" height="40"  src={php_logo} alt="PHP Development" title="PHP Development" /></Link>                          
                            <Link to="/services/web-development-services/mern-stack-development"><img width="40" height="40"  src={mern_logo} alt="Mern Development" title="Mern Development" /></Link>
                            <Link to="/services/web-development-services/mean-stack-development"><img width="40" height="40"  src={mean_logo} alt="Mean Development" title="Mean Development" /></Link>
                            <Link to="/services/web-development-services/mevn-stack-development"><img width="40" height="40"  src={mevn_logo} alt="Mevn Development" title="Mevn Development" /></Link>                         
                            {/* <Link to="/services/web-development-services/asp-net-development"><img width="40" height="40"  src={dontNetIcon} alt="ASP.Net Development" title="ASP.Net Development" /></Link>   */}
                            <Link to="/services/web-development-services/python-development"><img width="40" height="40"  src={python_logo} alt="Python Development" title="Python Development" /></Link>                        
                          </div>
                          <Grid component="div" className="btnWrap">
                            <Link
                              className="view_work btn-service hover-btn-animation-in"
                              to="/services/web-development-services"
                            >
                              Read More
                            </Link>
                          </Grid>
                        </div>
                      </div>
                      <div
                        className="custom-col-5"
                        data-aos="fade-left"
                        data-aos-duration="800"
                      >
                        <div className="image-wrap">
                          <img
                            width="100%"
                            height="auto"
                            src={Web_Development_Big}
                            alt="Web Development Services"
                            title="Web Development Services"
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* End - Section Design "Web Development Services" */}
              
              {/* Start - Section Design "UI/UX Design" */}
              <Grid
                component="div"
                className="services-detail-content grey-color sec_padding section_title_space"
                id="UI-UX-Design"
              >
                <Grid item container className="container_custom">
                  <Grid item md={12}>
                    <div className="custom-row">
                      <div
                        className="custom-col-5"
                        data-aos="fade-right"
                        data-aos-duration="800"
                      >
                        <div className="image-wrap">
                          <img
                            width="100%"
                            height="auto"
                            src={UI_UX_DESIGNING_Big}
                            alt="UI/UX Design"
                            title="UI/UX Design"
                          />
                        </div>
                      </div>
                      <div
                        className="custom-col-7"
                        data-aos="fade-left"
                        data-aos-duration="400"
                      >
                        <div className="content-wrapper title_row">
                          <Typography
                            variant="h2"
                            className="head_title text-left"
                          >
                            UI/UX Design
                          </Typography>
                          <Typography>
                          We have the skills and experience to turn your ideas into design, detail by detail. Our <strong>UI/UX design department</strong> will create a professional piece of art for any type of job you need. Our designs are always creative and eye catching. 
                          </Typography>
                          <Typography>
                          You can see a lot of new innovations and combination of elements which overall makes a completely new sense of spreading your message.
                          </Typography>
                          <div className="tech_icons_wrapper">
                            <Link to="/services/uiux-design-services/website-redesign"><img width="40" height="40"  src={landingpage_colored} alt="Website Redesign" title="Website Redesign" /></Link>
                            <Link to="/services/uiux-design-services/responsive-web-design"><img width="40" height="40"  src={responsive_colored} alt="Responsive Web Design" title="Responsive Web Design" /></Link>
                            <Link to="/services/uiux-design-services/logo-designing"><img width="40" height="40"  src={logoDesign_colored} alt="Logo Designing" title="Logo Designing" /></Link>
                            <Link to="/services/uiux-design-services/banner-designing"><img width="40" height="40"  src={bannerDesign_colored} alt="Banner Designing" title="Banner Designing" /></Link>
                            <Link to="/services/uiux-design-services/brochure-designing"><img width="40" height="40"  src={brochureDesign_colored} alt="Broucher Designing" title="Broucher Designing" /></Link>
                            {/* <Link to="/services/uiux-design-services/print-media-design"><img width="40" height="40"  src={printmedia_colored} alt="Print Media Design" title="Print Media Design" /></Link> */}
                            <Link to="/services/uiux-design-services/mobile-app-design"><img width="40" height="40"  src={MobileAppIcon_colored} alt="Mobile App Development" title="Mobile App Development" /></Link>
                          </div>
                          <Grid component="div" className="btnWrap">
                            <Link
                              className="view_work btn-service hover-btn-animation-in"
                              to="/services/uiux-design-services"
                            >
                              Read More
                            </Link>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* End - Section Design "UI/UX Design" */}
              
              {/* Start - Section Design "App Development Services" */}
              <Grid
                component="div"
                className="services-detail-content blue-color only-center sec_padding section_title_space"
                id="App-Development"
              >
                <Grid item container className="container_custom">
                  <Grid item md={12}>
                    <div
                      className="content-wrapper text-center title_row"
                      data-aos="fade-up"
                      data-aos-duration="400"
                    >
                      <Typography variant="h2" className="head_title">
                        App Development Services
                      </Typography>
                      <Typography>
                      <strong>Visions </strong>comprises of a team of experienced software professionals dedicated to provide a reliable and cost-effective product. Our <strong>Application Development</strong> model is a result of various innovations and enhancements, and is engineered to deliver a product that not only fulfils client's requirements, but also meets the global standards of software engineering.
                      </Typography>
                      <div className="tech_icons_wrapper">
                        <Link to="/services/app-development-services/android-app-development"><img width="40" height="40"  src={android_logo} alt="Android Development" title="Android Development" /></Link>
                        <Link to="/services/app-development-services/ios-app-development"><img width="40" height="40"  src={apple_logo} alt="IOS Development" title="IOS Development" /></Link>
                        <Link to="/services/app-development-services/react-native-development"><img width="40" height="40"  src={Reactjs_logo} alt="React Native Development" title="React Native Development" /></Link>
                      </div>

                      <Grid component="div" className="btnWrap">
                        <Link
                          className="view_work btn-service hover-btn-animation-in"
                          to="/services/app-development-services"
                        >
                          Read More
                        </Link>
                      </Grid>
                    </div>
                    <div
                      className="image-wrap text-center"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <img
                        width="100%"
                        height="auto"
                        src={App_Development_Big}
                        alt="Application Development Services"
                        title="Application Development Services"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* End - Section Design "App Development Services" */}
              
              {/* Start - Section Design "E-Commerce Development Services" */}
              <Grid
                component="div"
                className="services-detail-content grey-color sec_padding section_title_space"
                id="E-commerce-Solution"
              >
                <Grid item container className="container_custom">
                  <Grid item md={12}>
                    <div className="custom-row">
                      <div
                        className="custom-col-7"
                        data-aos="fade-right"
                        data-aos-duration="800"
                      >
                        <div className="content-wrapper title_row">
                          <Typography
                            variant="h2"
                            className="head_title text-left"
                          >
                            E-Commerce Development Services
                          </Typography>
                          <Typography>
                          To escalate the performance of your online business, we offers attractive <strong>ecommerce websites</strong> for various platforms.
                          </Typography>
                          <Typography>
                          We enhance your online presence to enable your business to rank in the most ideal position, guaranteeing your clients can undoubtedly discover your business on the top. 
                          </Typography>
                          <div className="tech_icons_wrapper">
                          {/* <Link to="/services/ecommerce-development-services/magento-development"><img width="40" height="40"  src={magento_colored} alt="Magento Development" title="Magento Development" /></Link> */}
                          <Link to="/services/ecommerce-development-services/woocommerce-development"><img width="40" height="40"  src={woocommerce_colored} alt="Woocommerce Development" title="Woocommerce Development" /></Link>
                          <Link to="/services/ecommerce-development-services/shopify-development"><img width="40" height="40"  src={shopify_colored} alt="Shopify Development" title="Shopify Development" /></Link>
                          {/* <Link to="/services/ecommerce-development-services/openCart-development"><img width="40" height="40"  src={opencart_colored} alt="OpenCart Development" title="OpenCart Development" /></Link>  */}
                          <Link to="/services/ecommerce-development-services/bigcommerce-development"><img width="40" height="40"  src={headerbigcommerceiconcolored} alt="BigCommerce Development" title="BigCommerce Development" /></Link>  
                        </div>
                          <Grid component="div" className="btnWrap">
                            <Link
                              className="view_work btn-service hover-btn-animation-in"
                              to="/services/ecommerce-development-services"
                            >
                              Read More
                            </Link>
                          </Grid>
                        </div>
                      </div>
                      <div
                        className="custom-col-5"
                        data-aos="fade-left"
                        data-aos-duration="400"
                      >
                        <div className="image-wrap">
                          <img
                            width="100%"
                            height="auto"
                            src={E_commerce_Solutions_Big}
                            alt="E-Commerce Development Services"
                            title="E-Commerce Development Services"
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* End - Section Design "E-Commerce Development Services" */}
              
              {/* Start - Section Design "Digital Marketing" */}
              <Grid
                component="div"
                className="services-detail-content sec_padding section_title_space"
                id="Digital-marketing"
              >
                <Grid item container className="container_custom">
                  <Grid item md={12}>
                    <div className="custom-row">
                      <div
                        className="custom-col-5"
                        data-aos="fade-right"
                        data-aos-duration="400"
                      >
                        <div className="image-wrap">
                          <img
                            width="100%"
                            height="auto"
                            src={Digital_Marketing_Big}
                            alt="Digital Marketing"
                            title="Digital Marketing"
                          />
                        </div>
                      </div>
                      <div
                        className="custom-col-7"
                        data-aos="fade-left"
                        data-aos-duration="800"
                      >
                        <div className="content-wrapper title_row">
                          <Typography
                            variant="h2"
                            className="head_title text-left"
                          >
                            Digital Marketing
                          </Typography>
                          <Typography>
                         <strong> Digital Marketing</strong> is the best technique by using you can probably reach to your potential customers. With the optimization of your website search engines and your customers both can find your business online. 
                          </Typography>
                          <Typography>At <strong>Visions</strong>, our Professional <strong>Digital Marketing</strong> Experts enable every business to rank above the competitors by completing every business to rank above the competitors by completing their tailored requirements and help businesses to gain higher visibility on the web.
                          </Typography>
                          <div className="tech_icons_wrapper">
                            <Link to="/services/digital-marketing-services/seo-services-india"><img width="40" height="40"  src={seo_colored} alt="Search Engine Optimization" title="Search Engine Optimization" /></Link>
                            <Link to="/services/digital-marketing-services/local-seo"><img width="40" height="40"  src={seo2_colored} alt="Local SEO" title="Local SEO" /></Link>
                            <Link to="/services/digital-marketing-services/social-media-optimization"><img width="40" height="40"  src={socialMediaSeo_colored} alt="Social Media Optimization" title="Social Media Optimization" /></Link>
                            <Link to="/services/digital-marketing-services/online-reputation-management"><img width="40" height="40"  src={online_reputation_icon_colored} alt="Online Reputation Management" title="Online Reputation Management" /></Link>
                          </div>
                        <Grid component="div" className="btnWrap">
                            <Link
                              className="view_work btn-service hover-btn-animation-in"
                              to="/services/digital-marketing-services"
                            >
                              Read More
                            </Link>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* End - Section Design "Digital Marketing" */}
              
              {/* Start - Section Design "Server/Network Admin Support" */}
              <Grid
                component="div"
                className="services-detail-content grey-color sec_padding section_title_space"
                id="Admin-Support"
              >
                <Grid item container className="container_custom">
                  <Grid item md={12}>
                    <div className="custom-row">
                      <div
                        className="custom-col-7"
                        data-aos="fade-right"
                        data-aos-duration="800"
                      >
                        <div className="content-wrapper title_row">
                          <Typography
                            variant="h2"
                            className="head_title text-left"  
                          >
                            Server/Network Admin Support
                          </Typography>
                          <Typography>
                          IT Strategy and Planning services help you to link your business plans and metrics to your IT architecture and operations in ways that create lasting value through our Business Strategy and Network Planning. 
                          </Typography>
                          <Typography>
                          We can identify what needs to be done to match your IT initiatives with your business goals, helping you eliminate unnecessary activities and maximize return.
                          </Typography>
                          <div className="tech_icons_wrapper">                      
                            <Link to="/services/server-network-admin-support-services/server-network-administration"><img width="40" height="40"  src={server_admin_support_colored} alt="Server Network Administration" title="Server Network Administration" /></Link>
                            <Link to="/services/server-network-admin-support-services/aws-cloud-hosting"><img width="40" height="40"  src={header_awsCloud_icon_colored} alt="AWS Cloud Hosting" title="AWS Cloud Hosting" /></Link> 
                            <Link to="/services/server-network-admin-support-services/linux"><img width="40" height="40"  src={header_linux_icon_colored} alt="Linux" title="Linux" /></Link>
                            <Link to="/services/server-network-admin-support-services/email-migration"><img width="40" height="40"  src={header_emailmigration_icon_colored} alt="Email Migration" title="Email Migration" /></Link>
                          </div>
                          <Grid component="div" className="btnWrap">
                            <Link
                              className="view_work btn-service hover-btn-animation-in"
                              to="/services/server-network-admin-support-services"
                            >
                              Read More
                            </Link>
                          </Grid>
                        </div>
                      </div>
                      <div
                        className="custom-col-5"
                        data-aos="fade-left"
                        data-aos-duration="400"
                      >
                        <div className="image-wrap">
                          <img
                            width="100%"
                            height="auto"
                            src={Server_Network_Admin_Support_Big}
                            alt="Server/Network Admin Support"
                            title="Server/Network Admin Support"
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* End - Section Design "Server/Network Admin Support" */}

            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Services;
