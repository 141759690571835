import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Get Images Start
import analyze_icon_new from '../../assets/images/AboutusImages/Analyze.svg';
import design_icon_new from '../../assets/images/AboutusImages/Design.svg';
import develop_icon_new from '../../assets/images/AboutusImages/Develop.svg';
import plan_icon_new from '../../assets/images/AboutusImages/Plan.svg';
import test_and_refine_icon from '../../assets/images/AboutusImages/Test & Refine.svg';
import company_philosophy_banner from '../../assets/images/AboutusImages/company_philosophy_banner.png';
// Get Images End

// Get Files for Slider Start
import Slider from "../../slider";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Get Files for Slider End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
// Custom Stylesheets End

class CompanyPhilosophy extends Component {
  render() {

    const settingsCompanyListing = {
        dots: true,
        arrows: false,
        infinite: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 959.98,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 520.98,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <Grid item xs={12}>
            <Grid component="div" className="company_philosopgy sec_padding">
                <Grid item container className="container_custom">
                    <Grid item xs={12}>
                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="400">Company Philosophy</Typography>
                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="800">Our Company philosophy has evolved from our internal experiences that "people make the difference". We realize that our employees are a major reason for our success and that they will play a large part in our future growth and competitiveness.</Typography>
                        {/* <img alt="Company Philosophy" title="Company Philosophy" src={company_philosophy_banner} /> */}
                        {/* Start Desktop View Company Philosophy Section */}
                        <Grid item xs={12} className="mainSpaceAdd mobileVeiwHideSec" data-aos="fade-up" data-aos-duration="1000">
                            <Grid item xs={12}>
                                <ul className="topBarSideList listStyleWrapMain d_flex">
                                    <li className="yellowContent">
                                        <div className="hoverOverlayLayout"></div>
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Plan" title="Plan" src={plan_icon_new} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Plan</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Develop a plan
                                                </li>
                                                    <li>
                                                        Assemble project team
                                                </li>
                                                    <li>
                                                        Hold kick-off meeting
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="blueContent">
                                        <div className="hoverOverlayLayout"></div>
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Design" title="Design" src={design_icon_new} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Design</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Determine site requirements
                                                </li>
                                                    <li>
                                                        Conduct a content inventory
                                                </li>
                                                    <li>
                                                        Perform card sorting
                                                </li>
                                                    <li>
                                                        Define information architecture
                                                </li>
                                                    <li>
                                                        Write for the web
                                                </li>
                                                    <li>
                                                        User parallel design
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="redContent">
                                        <div className="hoverOverlayLayout"></div>
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Test & Refine" title="Test & Refine" src={test_and_refine_icon} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Test & Refine</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Types of evaluations
                                                </li>
                                                    <li>
                                                        Usability testing
                                                </li>
                                                    <li>
                                                        Heuristic evaluations implement and retest
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="middleImageWrap">
                                    <img alt="Company Philosophy" title="Company Philosophy" src={company_philosophy_banner} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <ul className="bottomBarSideList listStyleWrapMain d_flex">
                                    <li className="greenContent">
                                        <div className="hoverOverlayLayout"></div>
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Analyze" title="Analyze" src={analyze_icon_new} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Analyze</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Learn about site users
                                                </li>
                                                    <li>
                                                        Conduct task analysis
                                                </li>
                                                    <li>
                                                        Develop personas
                                                </li>
                                                    <li>
                                                        Write scenarios
                                                </li>
                                                    <li>
                                                        Set measurable goals
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="blackContent">
                                        <div className="hoverOverlayLayout"></div>
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Develop" title="Develop" src={develop_icon_new} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Develop</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Develop a prototype
                                                </li>
                                                    <li>
                                                        Launch site
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>

                        {/* End Desktop View Company Philosophy Section */}

                        {/* Start Mobile View Company Philosophy Section */}
                        <Grid item xs={12} className="mainSpaceAdd desktopVeiwHideSec" data-aos="fade-up" data-aos-duration="1000">
                            <Grid item xs={12}>
                                <Slider className="listStyleWrapMain mobileVeiwListWrap" {...settingsCompanyListing}>
                                    <div className="yellowContent contentStyleWrap">
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Plan" title="Plan" src={plan_icon_new} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Plan</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Develop a plan
                                                </li>
                                                    <li>
                                                        Assemble project team
                                                </li>
                                                    <li>
                                                        Hold kick-off meeting
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="greenContent contentStyleWrap">
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Analyze" title="Analyze" src={analyze_icon_new} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Analyze</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Learn about site users
                                                </li>
                                                    <li>
                                                        Conduct task analysis
                                                </li>
                                                    <li>
                                                        Develop personas
                                                </li>
                                                    <li>
                                                        Write scenarios
                                                </li>
                                                    <li>
                                                        Set measurable goals
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blueContent contentStyleWrap">
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Design" title="Design" src={design_icon_new} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Design</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Determine site requirements
                                                </li>
                                                    <li>
                                                        Conduct a content inventory
                                                </li>
                                                    <li>
                                                        Perform card sorting
                                                </li>
                                                    <li>
                                                        Define information architecture
                                                </li>
                                                    <li>
                                                        Write for the web
                                                </li>
                                                    <li>
                                                        User parallel design
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="redContent contentStyleWrap">
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Test & Refine" title="Test & Refine" src={test_and_refine_icon} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Test & Refine</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Types of evaluations
                                                </li>
                                                    <li>
                                                        Usability testing
                                                </li>
                                                    <li>
                                                        Heuristic evaluations implement and retest
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blackContent contentStyleWrap">
                                        <div className="mainCardWrapper">
                                            <div className="icon_list_style_card">
                                                <img alt="Develop" title="Develop" src={develop_icon_new} />
                                            </div>
                                            <div className="content_list_style_card">
                                                <Typography variant="h3" className="company_title_main">Develop</Typography>
                                                <ul className="listing_Style_inner">
                                                    <li>
                                                        Develop a prototype
                                                </li>
                                                    <li>
                                                        Launch site
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </Grid>
                        </Grid>
                        {/* End Mobile View Company Philosophy Section */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
  }
}

export default CompanyPhilosophy;
