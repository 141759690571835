import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import meanStackAPIDevlp from "../../assets/images/MeanStackDevelopment/meanStackAPIDevlp.png";
import all_major_ads_network from "../../assets/images/bannerDesign/all_major_ads_network.jpg";
import ecommerce_shopify from "../../assets/images/bannerDesign/ecommerce_shopify.jpg";
import google_ads_manager from "../../assets/images/bannerDesign/google_ads_manager.jpg";
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import social_networks from "../../assets/images/bannerDesign/social_networks.jpg";
import why_hire_us from '../../assets/images/HireDevelopers/why_hire_us.png';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import HireDevelopersBanner from '../../assets/images/HireDevelopers/HireDevelopersBanner.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireDevelopers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Hire Developers",
            bannerImg: HireDevelopersBanner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: 'Hire Developers - How to hire cost effective dedicated development teams from our company?',
                    TypographyDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: 'Hire Developers - How to hire cost effective dedicated development teams from our company?',
                    TypographyDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: 'Hire Developers - How to hire cost effective dedicated development teams from our company?',
                    TypographyDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    expanded: 'panel4',
                    ariaControls: 'panel4d-content',
                    panelId: 'panel4d-header',
                    typographyText: 'Hire Developers - How to hire cost effective dedicated development teams from our company?',
                    TypographyDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    expanded: 'panel5',
                    ariaControls: 'panel5d-content',
                    panelId: 'panel5d-header',
                    typographyText: 'Hire Developers - How to hire cost effective dedicated development teams from our company?',
                    TypographyDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                }
            ]
        };
    }
    componentWillMount() {
        let isAuthenticated = !localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) {
            this.props.history.push("/login");
            window.location.reload();
        }
    }
    render() {
        document.title = "Hire Web Developer | Hire Dedicated Web Developers India";
        const {
            pageTitle, bannerImg
        } = this.state;
        return (
            <div className="main_hireEcommerce main_hireDevelopers">
                <Grid container>

                    <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />

                    <Grid item xs={12} className="sec_padding pb-0" data-aos="fade-up" data-aos-duration="800">
                        <Grid item container className="container_custom">
                            <Grid item xs={12} className="title_row">
                                <aside>
                                    <Typography variant="h2" className="head_title">
                                        We are Excited That You Want To Work With Us
                  </Typography>
                                    <Typography
                                        className="head_paragraph"
                                        data-aos="fade-up"
                                        data-aos-duration="1200"
                                    >
                                        We would love to discuss your project requirement in detail. <br />Just submit the details below with a little more info and we"ll contact you back as soon as possible.
                  </Typography>
                                </aside>
                            </Grid>
                        </Grid>
                    </Grid>

                    <HireUsForm />

                    <Grid item xs={12} className="main_application_dev">
                        <Grid
                            component="div"
                            className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
                            id="ios-app-development"
                        >
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <div className="text_wrap title_row">
                                        <aside>
                                            <Typography
                                                variant="h2"
                                                className="head_title"
                                                data-aos="fade-up"
                                                data-aos-duration="800"
                                            >
                                                Advantages of Hiring Us
                      </Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1200"
                                            >
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever sinceLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                      </Typography>
                                            <Typography
                                                className="head_paragraph"
                                                data-aos="fade-up"
                                                data-aos-duration="1400"
                                            >
                                                <strong>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting.
                        </strong>
                                            </Typography>
                                        </aside>
                                    </div>
                                    <Grid item container xs={12} className="major_networks_wrap">
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={all_major_ads_network} alt="Easy to Understand" title="Easy to Understand" />
                                                <Typography variant="h5" className="mb-1">
                                                    Easy to Understand
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    As it extracts code-packed components without you having to trace the program flow, it becomes quite easy to work with Mean Stack.
                            </Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={ecommerce_shopify} alt="Great Developer Tools" title="Great Developer Tools" />
                                                <Typography variant="h5" className="mb-1">
                                                    Great Developer Tools
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    MEAN Stack comes with tools that allow a MEAN Stack developer to establish and track hierarchies of components besides allowing to use resourceful design and debugging options.
                            </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                                                <img src={major_networks_middle} alt="Works with all major networks" title="Works with all major networks" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="aside">
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                                                <img className="icon" src={google_ads_manager} alt="Framework" title="Framework" />
                                                <Typography variant="h5" className="mb-1">
                                                    Framework
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    MEAN Stack has the efficiency of MongoDB, Express.js, AngularJS, and Node.js that lessen the unnecessary
                            </Typography>
                                            </div>
                                            <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                                                <img className="icon" src={social_networks} alt="Efficiency of MongoDB" title="Efficiency of MongoDB" />
                                                <Typography variant="h5" className="mb-1">
                                                    Efficiency of MongoDB
                            </Typography>
                                                <Typography className="head_paragraph">
                                                    We are a leading MEAN Stack Development company having a team of extremely talented MEAN Stack Developers
                            </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
                            <Grid item container className="container_custom boxes_listing">
                                <Grid item xs={12} className="title_row">
                                    <aside>
                                        <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                                            Hire Mobile & Web App Developer
                              </Typography>
                                        <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                                            VISIONS is a skilled IT company based in India, having a team of skilled IT professionals that have successfully delivered satisfactory Magento web design and development services all over the world. Hence, you should Hire Magento Developer from a leading Magento Development Company like Visions in India to be victorious among your business competitors.
                              </Typography>
                                    </aside>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                                    <div className="list_key_boxes">
                                        <img alt="Magento Website Development" title="Magento Website Development" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            Expert Consultation
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                                    <div className="list_key_boxes">
                                        <img alt="Magento Theme Development" title="Magento Theme Development" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            Maintence & Support
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="list_key_boxes">
                                        <img alt="Custom Magento Web Development" title="Custom Magento Web Development" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            Search Engine Friendly
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="list_key_boxes">
                                        <img alt="Responsive Magento Website" title="Responsive Magento Website" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            E-Commerce Website Design
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1400">
                                    <div className="list_key_boxes">
                                        <img alt="Integrating Secure Payment" title="Integrating Secure Payment" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            E-Commerce Website Development
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1600">
                                    <div className="list_key_boxes">
                                        <img alt="Magento Data Migration" title="Magento Data Migration" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            E-Commerce Applications
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="list_key_boxes">
                                        <img alt="Magento Data Migration" title="Magento Data Migration" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            Secured Payment Gateway
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="2200">
                                    <div className="list_key_boxes">
                                        <img alt="Magento Data Migration" title="Magento Data Migration" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            Shopping Cart Solutions
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="2400">
                                    <div className="list_key_boxes">
                                        <img alt="Integrating Secure Payment" title="Integrating Secure Payment" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            E-Commerce Website Development
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="2600">
                                    <div className="list_key_boxes">
                                        <img alt="Magento Data Migration" title="Magento Data Migration" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            E-Commerce Applications
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="2800">
                                    <div className="list_key_boxes">
                                        <img alt="Magento Data Migration" title="Magento Data Migration" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            Secured Payment Gateway
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="3000">
                                    <div className="list_key_boxes">
                                        <img alt="Magento Data Migration" title="Magento Data Migration" src={meanStackAPIDevlp} />
                                        <Typography variant="h4" className="head_title">
                                            Secured Payment Gateway
                              </Typography>
                                        <Typography className="head_paragraph">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since theLorem Ipsum is simply dummy text
                              </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid component="div" className="why_hire_us sec_padding">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We create outstanding UI/UX, branding web design & development solutions for startups & companies.</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                                        <ul className="left_list lists_hire_us">
                                            <li>
                                                We specialize in web development outsourcing services. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                We have a long history of satisfied customers across the globe. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                We have top-notch dedicated, enthusiastic and talented professionals. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                            <li>
                                                Flexible and simple hiring process. <img alt="tick" className="tick_done" src={tick_done} />
                                            </li>
                                        </ul>
                                    </Grid>
                                    <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                                        <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                                    </Grid>
                                    <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                                        <ul className="right_list lists_hire_us">
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We communicate effectively.
                                  </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We Offer seamless customer & technical support.
                                  </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We follow the industry standards.
                                  </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We’ll always be there to help when you need us.
                                  </li>
                                            <li>
                                                <img alt="tick" className="tick_done" src={tick_done} /> We only charge you when we do work for you.
                                  </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
                            <Grid item container className="container_custom">
                                <Grid item xs={12}>
                                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Browse through the most frequently asked questions</Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                                        <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                                        <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <NumberCount />

                </Grid>
            </div>
        );
    }
}

export default HireDevelopers;
