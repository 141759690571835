import React, { Component, Suspense, lazy } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import smoothscroll from "smoothscroll-polyfill";
import { Link } from "react-router-dom";
// import LazyLoad from "react-lazyload";

// Get Images Start
import Php_Development from "../../assets/images/Header/php.svg";
import header_mean_icon from "../../assets/images/Header/header-mean-icon.svg";
import header_mern_icon from "../../assets/images/Header/header-mern-icon.svg";
import header_mevn_icon from "../../assets/images/Header/header-mevn-icon.svg";
import net from "../../assets/images/Header/net.svg";
import header_python_icon from "../../assets/images/Header/header-python-icon.svg";
import Php_Development_White from "../../assets/images/white-icons/php.svg";
import header_mean_icon_White from "../../assets/images/white-icons/header-mean-icon.svg";
import header_mern_icon_White from "../../assets/images/white-icons/header-mern-icon.svg";
import header_mevn_icon_White from "../../assets/images/white-icons/header-mevn-icon.svg";
import net_White from "../../assets/images/white-icons/net.svg";
import header_python_icon_White from "../../assets/images/white-icons/header-python-icon.svg";
// import major_networks_middle from "../../assets/images/bannerDesign/major_networks_middle.jpg";
import aspdotnet_technologies_circle from "../../assets/images/WebDevelopment/aspdotnet_technologies_circle.png";
// import aspdotnet_tech_bg_we_work from "../../assets/images/dotNetDevelopment/aspdotnet_tech_bg_we_work.png";
import Smart_Ehealth from "../../assets/images/WebDevelopment/Smart-ehealth.jpg";
import deking_decks from "../../assets/images/WebDevelopment/deking-decks.jpg";
import TurfGreen from "../../assets/images/WebDevelopment/TurfGreen.jpg";
import hapkidoqld from "../../assets/images/WebDevelopment/hapkidoqld.jpg";
import gladev from "../../assets/images/WebDevelopment/gladev.jpg";
import lulo_admin_panel from "../../assets/images/WebDevelopment/lulo-admin-panel.jpg";
import mens_hair_fitness from "../../assets/images/WebDevelopment/mens-hair-fitness.jpg";
import truWorkForce from "../../assets/images/WebDevelopment/truWorkForce.jpg";
import Niah from "../../assets/images/WebDevelopment/Naudje.jpg";
import AssignQ from "../../assets/images/WebDevelopment/AssignQ.jpg";
import carinfo from "../../assets/images/WebDevelopment/carinfo.jpg";
import crm_web_application from "../../assets/images/WebDevelopment/crm-web-application.jpg";
import wli_web_application from "../../assets/images/WebDevelopment/wli_web_application.png";
import Botonique from "../../assets/images/WebDevelopment/Botonique.jpg";
import godConnection from "../../assets/images/WebDevelopment/godConnection.jpg";
import malena from "../../assets/images/WebDevelopment/malena.jpg";
import reach_fast from "../../assets/images/WebDevelopment/reach-fast.jpg";
import Express_logistics from "../../assets/images/WebDevelopment/Express-logistics.jpg";
import CommunityBase from "../../assets/images/WebDevelopment/CommunityBase.svg";
import major_networks_middle from "../../assets/images/WebDevelopment/major_networks_middle.png";
import CustomPHPDevelopment from "../../assets/images/WebDevelopment/CustomPHPDevelopment.svg";
import DjangoWebDevelopment from "../../assets/images/WebDevelopment/DJ.svg";
import EasytolearnJS from "../../assets/images/WebDevelopment/EasytolearnJS.svg";
import EfficientSecure from "../../assets/images/WebDevelopment/EfficientSecure.svg";
import espressjscolored from "../../assets/images/WebDevelopment/espressjs-colored.svg";
import espressjs from "../../assets/images/WebDevelopment/espressjs.svg";
import file from "../../assets/images/WebDevelopment/file.svg";
import HighAdaptability from "../../assets/images/WebDevelopment/HighAdaptability.svg";
import HighlyFlexible from "../../assets/images/WebDevelopment/HighlyFlexible.svg";
import MigrationtoPython from "../../assets/images/WebDevelopment/MigrationtoPython.svg";
import Mongocolored from "../../assets/images/WebDevelopment/Mongo-colored.svg";
import mongo from "../../assets/images/WebDevelopment/mongo.svg";
import MySQLdevelopment from "../../assets/images/WebDevelopment/MySQLdevelopment.svg";
import NODEColored from "../../assets/images/WebDevelopment/NODE.svg";
import NodeJs from "../../assets/images/WebDevelopment/NodeJs.svg";
import PHPbasedCMSdevelopment from "../../assets/images/WebDevelopment/PHPbasedCMSdevelopment.svg";
import PHPWebDevelopment from "../../assets/images/WebDevelopment/PHPWebDevelopment.svg";
import QualityAssurance from "../../assets/images/WebDevelopment/QualityAssurance.svg";
import reactjs from "../../assets/images/WebDevelopment/reactjs.svg";
import vuejs from "../../assets/images/WebDevelopment/vuejs.svg";
import WebServicesAPI from "../../assets/images/WebDevelopment/WebServicesAPI.svg";
import Web_Development from "../../assets/images/WebDevelopment/Web_Development.png";
import php_icon from "../../assets/images/Portfolios/Technologies/php_icon.svg";
import wordpress_icon from "../../assets/images/Portfolios/Technologies/wordpress_icon.svg";
import bootstrap_icon from "../../assets/images/Portfolios/Technologies/bootstrap_icon.jpg";
import wooCommerce_icon from "../../assets/images/Portfolios/Technologies/wooCommerce_icon.svg";
import mean_icon from "../../assets/images/Portfolios/Technologies/mean_icon.svg";
import angular_icon from "../../assets/images/Portfolios/Technologies/angular_icon.svg";
import dotNetCode_icon from "../../assets/images/Portfolios/Technologies/dotNetCode_icon.png";
import mern_icon from "../../assets/images/Portfolios/Technologies/mern_icon.svg";
import mevn_icon from "../../assets/images/Portfolios/Technologies/mevn_icon.svg";
import aspDotNet_icon from "../../assets/images/Portfolios/Technologies/aspDotNet_icon.svg";
import python_icon from "../../assets/images/Portfolios/Technologies/python_icon.svg";

import machineLearningWhite from "../../assets/images/artificial-inteligence/machine-learning-white.png";
import deepLearningWhite from "../../assets/images/artificial-inteligence/deep-learning-white.png";
import naturalLanguageProcessingWhite from "../../assets/images/artificial-inteligence/natural-language-processing-white.png";
import computerVisionWhite from "../../assets/images/artificial-inteligence/computer-vision-white.png";
import dataAnalystWhite from "../../assets/images/artificial-inteligence/data-analyst-white.png";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
// import NumberCount from "../common/NumberCount";
import SliderServiceCatPagePortfolio2 from "../common/SliderServiceCatPagePortfolio2";
const NumberCount = lazy(() => import("../common/NumberCount"));
// Get Common Components End

class WebDevelopment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Web Development Services",
      bannerImg: Web_Development,
      activeTab: "php-development",
      activeTab1: "tab-1",
      tabToggle: false,
      isClickedEventFired: 0,
      phpSliderData: [],
      meanStackSliderData: [],
      mernStackSliderData: [],
      mevnStackSliderData: [],
      aspDotNetSliderData: [],
      pythonSliderData: [],
    };
    smoothscroll.polyfill();
    this.baseState = this.state;
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
    const phpData = [
      {
        image: gladev,
        title: "GLADEV",
        link: "https://www.gladev.com/onepage",
        tech_images: [{ icon: php_icon, title: "PHP" }, { icon: wordpress_icon, title: "Wordpress" }]
      },
      {
        image: Smart_Ehealth,
        title: "Smart-eHealth",
        link: "https://www.smart-ehealth.com.au/",
        tech_images: [{ icon: php_icon, title: "PHP" }, { icon: wordpress_icon, title: "Wordpress" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      },
      {
        image: TurfGreen,
        title: "TurfGreen",
        link: "https://www.turfgreen.com.au/",
        tech_images: [{ icon: php_icon, title: "PHP" }, { icon: wordpress_icon, title: "Wordpress" }, { icon: wooCommerce_icon, title: "WooCommerce" }]
      },
      {
        image: hapkidoqld,
        title: "Hapkidoqld",
        link: "https://www.hapkidoqld.com.au/",
        tech_images: [{ icon: php_icon, title: "PHP" }, { icon: wordpress_icon, title: "Wordpress" }, { icon: wooCommerce_icon, title: "WooCommerce" }]
      }
    ];

    const meanStackData = [      
      // {
      //   image: truWorkForce,
      //   title: "TruWorkForce",
      //   link: "http://truworkforce-dev.c8gpaj8jge.us-west-1.elasticbeanstalk.com/",
      //   tech_images: [{ icon: mean_icon, title: "MEAN" }]
      // },
      // {
      //   image: AssignQ,
      //   title: "AssignQ",
      //   link: "http://assignq.com/",
      //   tech_images: [{ icon: angular_icon, title: "Angular" }, { icon: dotNetCode_icon, title: ".NetCore" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      // },
      // {
      //   image: Niah,
      //   title: "Naudje",
      //   link: "https://www.naudje.nl/",
      //   tech_images: [{ icon: mean_icon, title: "MEAN" }, { icon: bootstrap_icon, title: "Bootstrap" }]
      // }
    ];

    const mernStackData = [
      {
        image: deking_decks,
        title: "Deking Decks",
        link: "https://www.dekingdecks.com.au/",
        tech_images: [{ icon: mern_icon, title: "Mern" }]
      },
      // {
      //   image: mens_hair_fitness,
      //   title: "Men's Hair Fitness",
      //   link: "https://www.menshairfitness.co.nz/",
      //   tech_images: [{ icon: mern_icon, title: "Mern" }]
      // },
      {
        image: lulo_admin_panel,
        title: "Admin Panel",
        //link: "",
        tech_images: [{ icon: mern_icon, title: "Mern" }]
      },
      // {
      //   image: godConnection,
      //   title: "God Connection",
      //   link: "http://www.godconnection.org/",
      //   tech_images: [{ icon: mern_icon, title: "Mern" }]
      // }
    ];

    const mevnStackData = [
      // {
      //   image: malena,
      //   title: "Malena",
      //   link: "",
      //   tech_images: [{ icon: mevn_icon, title: "Mevn" }]
      // }
    ];

    const aspDotNetStackData = [
      // {
      //   image: Express_logistics,
      //   title: "Express Logistics",
      //   //link: "",
      //   tech_images: [{ icon: aspDotNet_icon, title: "ASP.Net" }]
      // },
      // {
      //   image: reach_fast,
      //   title: "Reach Fast",
      //   link: "http://www.reachfastmd.com/",
      //   tech_images: [{ icon: aspDotNet_icon, title: "ASP.Net" }]
      // }
    ];

    const pythonStackData = [
      {
        image: crm_web_application,
        title: "CRM Web App",
        //link: "",
        tech_images: [{ icon: python_icon, title: "Python" }]
      },
      {
        image: wli_web_application,
        title: "WLI Web App",
        //link: "",
        tech_images: [{ icon: python_icon, title: "Python" }]
      },
      // {
      //   image: Botonique,
      //   title: "Botonique",
      //   link: "http://botonique.visionsdemo.com/",
      //   tech_images: [{ icon: python_icon, title: "Python" }]
      // },
      {
        image: carinfo,
        title: "Car Info",
        link: "https://www.carinfo.uk",
        tech_images: [{icon: python_icon, title: "Python"}]
      }
    ];

    this.setState({ phpSliderData: phpData, meanStackSliderData: meanStackData, mernStackSliderData: mernStackData, mevnStackSliderData: mevnStackData, aspDotNetSliderData: aspDotNetStackData, pythonSliderData: pythonStackData });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent, false);
  }

  listenScrollEvent = e => {
    if (this.state.isClickedEventFired > 0) {
      var abc = setTimeout(() => {
        this.setState({ isClickedEventFired: 0 });
      }, 5000);
      if (this.state.isClickedEventFired > 1) {
        clearTimeout(abc);
      }
    } else {
      var section1 = this.checkElementInViewport(
        document.getElementById("php-development")
      );
      var section2 = this.checkElementInViewport(
        document.getElementById("mean-stack-development")
      );
      var section3 = this.checkElementInViewport(
        document.getElementById("mern-stack-development")
      );
      var section4 = this.checkElementInViewport(
        document.getElementById("mevn-stack-development")
      );
      var section5 = this.checkElementInViewport(
        document.getElementById("asp-net-development")
      );
      var section6 = this.checkElementInViewport(
        document.getElementById("python-development")
      );

      if (section1 && section2) {
        this.setState({ activeTab: "php-development", activeTab1: "tab-1" });
      }
      if ((section2 && !section1) || (section2 && section3)) {
        this.setState({
          activeTab: "mean-stack-development",
          activeTab1: "tab-2"
        });
      }
      if ((section3 && !section2) || (section3 && section4)) {
        this.setState({
          activeTab: "mern-stack-development",
          activeTab1: "tab-3"
        });
      }
      if ((section4 && !section3) || (section4 && section5)) {
        this.setState({
          activeTab: "mevn-stack-development",
          activeTab1: "tab-4"
        });
      }
      if ((section5 && !section4) || (section5 && section6)) {
        this.setState({
          activeTab: "asp-net-development",
          activeTab1: "tab-5"
        });
      }
      if (section6 && !section5) {
        this.setState({ activeTab: "python-development", activeTab1: "tab-6" });
      }
    }
  };

  checkElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    const vertInView =
      rect.top <= windowHeight && rect.top-74 + rect.height >= 0;
    const horInView =
      rect.left <= windowWidth && rect.left-74 + rect.width >= 0;

    return vertInView && horInView;
  }

  handleOnClickScroll = (divId, tab) => {
    this.setState({
      activeTab: divId,
      activeTab1: tab,
      isClickedEventFired: this.state.isClickedEventFired + 1
    });
    var ref = document.getElementById(divId);
    window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
  };

  handleTabToggle = () => {
    this.setState(state => ({ tabToggle: !state.tabToggle }));
  };

  componentWillReceiveProps(nextProps) {
    var backToTop = localStorage.getItem("backToTop");
    if (nextProps.match.params.id) {
      if (nextProps.match.params.id !== this.props.match.params.id) {
        var ref = document.getElementById(nextProps.match.params.id);
        window.scrollTo({ top: ref.offsetTop-72, left:0, behavior: "smooth" });
      }
    }
    if (backToTop && backToTop === "true") {
      this.setState({
        activeTab: this.baseState.activeTab,
        activeTab1: this.baseState.activeTab1
      });
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent, false);
    if (this.props.match.params.id) {
      var ref = document.getElementById(this.props.match.params.id);
      if (ref)
      setTimeout(() => {
        window.scrollTo({ top: ref.offsetTop-72, behavior: "smooth" });
      }, 500);
      else
        this.props.history.push("/services/web-development-services");
    }
  }

  getExperienceyear() {
    var today = new Date();
    var start_date = new Date("june 02, 1993");
    var years_now = today.getFullYear() - start_date.getFullYear();
    var m = today.getMonth() - start_date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) {
      years_now--;
    }
    return years_now;
  }

  render() {
    if (this.props.match.params.id) {
      if (this.props.match.params.id === 'php-development') {
        document.title = "PHP Development Company India | PHP Web Developers";
      } else if (this.props.match.params.id === 'mean-stack-development') {
        document.title = "Mean Stack Development Company India | Mean Stack Developers";
      } else if (this.props.match.params.id === 'mern-stack-development') {
        document.title = "Mern Stack Development Company India | Mern Stack Developers";
      } else if (this.props.match.params.id === 'mevn-stack-development') {
        document.title = "Mevn Stack Development Company India | Mevn Stack Developers";
      } else if (this.props.match.params.id === 'asp-net-development') {
        document.title = "ASP.NET Application Development Company India  | Dot Net Developers";
      } else if (this.props.match.params.id === 'python-development') {
        document.title = "Python Development Company India | Python  Developers";
      } else {
        document.title = "Web Design & Development Company in India | Web Developers";
      }
    } else {
      document.title = "Web Design & Development Company in India | Web Developers";
    }
    const {
      pageTitle,
      bannerImg,
      phpSliderData,
      meanStackSliderData,
      mernStackSliderData, 
      mevnStackSliderData, 
      aspDotNetSliderData, 
      pythonSliderData 
    } = this.state;
    return (
      <div className="main_web_dev service_category main_services">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}
          <Grid item xs={12}>
            <Grid component="div">
              <Grid className="serivceTabsSide">
                <Grid
                  className={`services-listing ${
                    this.state.tabToggle ? "showTab" : ""
                  }`}
                >
                  <div className="toggle-btn" onClick={this.handleTabToggle}>
                    <i className="material-icons">arrow_forward</i>
                  </div>
                  <Grid item container className="container_custom">
                    <Grid item xs={12}>
                      <div className={`row-flex ${this.state.activeTab1}`}>
                        <div
                          className={`col-flex tab-1 ${
                            this.state.activeTab === "php-development"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll("php-development", "tab-1")
                          }
                          title="PHP Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={Php_Development}
                                className="withoutActive"
                                alt="PHP Development"
                                title="PHP Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={Php_Development_White}
                                className="withActive"
                                alt="PHP Development"
                                title="PHP Development"
                              />
                            </div>
                            <Typography>
                              PHP <br /> Development{" "}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-2 ${
                            this.state.activeTab === "mean-stack-development"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "mean-stack-development",
                              "tab-2"
                            )
                          }
                          title="Mean Stack Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={header_mean_icon}
                                className="withoutActive"
                                alt="Mean Stack Development"
                                title="Mean Stack Development"
                              />
                              <img
                                src={header_mean_icon_White}
                                className="withActive"
                                alt="Mean Stack Development"
                                title="Mean Stack Development"
                              />
                            </div>
                            <Typography>
                              Mean Stack <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-3 ${
                            this.state.activeTab === "mern-stack-development"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "mern-stack-development",
                              "tab-3"
                            )
                          }
                          title="Mern Stack Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={header_mern_icon}
                                className="withoutActive"
                                alt="Mean Stack Development"
                                title="Mern Stack Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={header_mern_icon_White}
                                className="withActive"
                                alt="Mern Stack Development"
                                title="Mern Stack Development"
                              />
                            </div>
                            <Typography>
                              Mern Stack <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-4 ${
                            this.state.activeTab === "mevn-stack-development"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "mevn-stack-development",
                              "tab-4"
                            )
                          }
                          title="Mevn Stack Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={header_mevn_icon}
                                className="withoutActive"
                                alt="Mevn Stack Development"
                                title="Mevn Stack Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={header_mevn_icon_White}
                                className="withActive"
                                alt="Mevn Stack Development"
                                title="Mevn Stack Development"
                              />
                            </div>
                            <Typography>
                              Mevn Stack <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-5 ${
                            this.state.activeTab === "asp-net-development"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "asp-net-development",
                              "tab-5"
                            )
                          }
                          title="ASP.Net Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={net}
                                className="withoutActive"
                                alt="ASP.Net Development"
                                title="ASP.Net Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={net_White}
                                className="withActive"
                                alt="ASP.Net Development"
                                title="ASP.Net Development"
                              />
                            </div>
                            <Typography>
                              ASP.Net <br /> Development
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`col-flex tab-6 ${
                            this.state.activeTab === "python-development"
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            this.handleOnClickScroll(
                              "python-development",
                              "tab-6"
                            )
                          }
                          title="Python Development"
                        >
                          <div className="img-txt-wrap">
                            <div className="img-wrap">
                              <img
                                width="30"
                                height="30"
                                src={header_python_icon}
                                className="withoutActive"
                                alt="Python Development"
                                title="Python Development"
                              />
                              <img
                                width="30"
                                height="30"
                                src={header_python_icon_White}
                                className="withActive"
                                alt="Python Development"
                                title="Python Development"
                              />
                            </div>
                            <Typography>
                              Python
                              <br /> Development
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Fixed Right SideBar Tabs - This is show on every 6 Services" */}

          {/* Start - Section Design "First Section Of Service Category Page" */}
          <Grid item xs={12}>
            <Grid component="div" className="sec_padding sec_one">
              <Grid item container className="container_custom">
                <Grid item xs={12} className="title_row">
                  <aside className="">
                    <Typography
                      variant="h3"
                      className="head_title mb-3"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="1000"
                    >
                      Client-focused, customer-centric, creating website
                      solutions <br />
                      that deliver tangible business results.
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="1500"
                    >
                      We are a <strong>Web Development Services Company</strong>{" "}
                      with a skilled team of <strong>web developers</strong>.
                      Our 
                      <strong>full-stack web designers and developers</strong>
                       enjoy the decade long expertise. We are capable of
                      composing <strong>high-end web solutions</strong>,{" "}
                      <strong>web applications</strong>, and{" "}
                      <strong>web portal development</strong>.
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-easing="ease"
                      data-aos-duration="2000"
                    >
                      All applications are tailored-made with exquisitely robust
                      user experiences to meet your specific industry needs. Our
                      dedicated <strong>web developers</strong> have
                      successfully completed <strong>1000+ web projects</strong>{" "}
                      for industries with diverse business domains, including
                      eCommerce, Job Board, e-Learning, Banking, and Content &
                      Document Management software among others. We create
                      best-in-class compelling functionality and deliver the
                      best of both worlds of startup to enterprise fragment.
                    </Typography>
                  </aside>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "First Section Of Service Category Page" */}

          {/* Start - Section Design "PHP Development Services" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits phpSec bg-lightgrey section_title_space"
              id="php-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      PHP Development Services
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <strong>PHP Development Services</strong> With{" "}
                      <strong>{this.getExperienceyear()}+ yrs </strong>of
                      hands-on experience in <strong>PHP</strong>, we offer{" "}
                      <strong>best PHP development services</strong> of
                      outstanding quality. From dynamic websites to interactive
                      business applications, customized software to{" "}
                      <strong>e-commerce solutions</strong>, open-source
                      implementation to rapid application{" "}
                      <strong>framework development</strong>, we have a pool of
                      specialized <strong>PHP development</strong> team that can
                      develop applications of every nature, size and complexity.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="PHP Website Development"
                      title="PHP Website Development"
                      src={PHPWebDevelopment}
                    />
                    <Typography variant="h4" className="head_title">
                      PHP Website Development
                    </Typography>
                    <Typography className="head_paragraph">
                      We cater to your unique business requirements with the{" "}
                      <strong>best PHP web development</strong> in the industry.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="PHP based CMS Development"
                      title="PHP Based CMS Development"
                      src={PHPbasedCMSdevelopment}
                    />
                    <Typography variant="h4" className="head_title">
                      PHP Based CMS Development
                    </Typography>
                    <Typography className="head_paragraph">
                      We serve you with an unmatched PHP-based{" "}
                      <strong>CMS development</strong> which aligns with your
                      business strategy.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="PHP/MySQL Development"
                      title="PHP/MySQL Development"
                      src={MySQLdevelopment}
                    />
                    <Typography variant="h4" className="head_title">
                      PHP/MySQL Development
                    </Typography>
                    <Typography className="head_paragraph">
                      We offer an incredibly efficient{" "}
                      <strong>PHP/MySQL development</strong> for your database
                      website with high performance.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="MVC Framework"
                      title="MVC Framework"
                      src={CustomPHPDevelopment}
                    />
                    <Typography variant="h4" className="head_title">
                      MVC Framework
                    </Typography>
                    <Typography className="head_paragraph">
                      We offer expert services for creating powerful
                      applications using frameworks like{" "}
                      <strong>
                        Laravel, CodeIgniter, CakePHP, Yii, Zend, and Symphony.
                      </strong>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <SliderServiceCatPagePortfolio2 data={phpSliderData} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="btn-wrap"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <Link
                    to="/hire-developers/hire-php-developers"
                    className="dark-btn-effect all-category-mobile"
                    title="Hire PHP Developers"
                  >
                    Hire PHP Developers
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "PHP Development Services" */}

          {/* Start - Section Design "MEAN Stack Development" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="major_social_networks sec_padding section_title_space"
              id="mean-stack-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title mb-3"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      MEAN Stack Development
                    </Typography>
                    <Typography
                      className="head_paragraph "
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <strong>MEAN Stack’s</strong> popularity is largely based
                      on its easy to develop coding features that allows
                      developers to create efficient and lively content for
                      websites within a short period of time. Being one of the
                      reliable and reputed{" "}
                      <strong>MEAN Stack Development Company</strong> in India
                      with a large customer base, we can ensure you that our
                      development will be beneficial for your website and
                      application.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item container xs={12} className="major_networks_wrap">
                  <Grid item xs={12} lg={4} className="aside">
                    <div
                      className="content_box"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="200"
                    >
                      <img
                        className="icon"
                        src={HighAdaptability}
                        alt="High Adaptability"
                        title="High Adaptability"
                      />
                      <Typography variant="h5" className="mb-1">
                        High Adaptability
                      </Typography>
                      <Typography className="head_paragraph">
                        It offers high adaptability due to its capability to
                        blend <strong>JavaScript and CSS3</strong> under a
                        single roof.
                      </Typography>
                    </div>
                    <div
                      className="content_box"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="300"
                    >
                      <img
                        className="icon"
                        src={HighlyFlexible}
                        alt="Highly Flexible"
                        title="Highly Flexible"
                      />
                      <Typography variant="h5" className="mb-1">
                        Highly Flexible
                      </Typography>
                      <Typography className="head_paragraph">
                        It is easy to develop, test and introduced in the cloud
                        after completion of a development process.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div
                      className="content_box"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="100"
                    >
                      <img
                        className="icon"
                        src={EasytolearnJS}
                        alt="Easy to Learn JS"
                        title="Easy to Learn JS"
                      />
                      <Typography variant="h5" className="mb-1">
                        Easy to Learn JS
                      </Typography>
                      <Typography className="head_paragraph">
                        <strong> MEAN</strong> is based on{" "}
                        <strong>JavaScript</strong> which is straightforward and
                        easy to learn for developers.
                      </Typography>
                    </div>
                    <div
                      className="content_box middle_img"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      <img
                        src={major_networks_middle}
                        alt="MEAN Stack Development"
                        title="MEAN Stack Development"
                      />
                    </div>
                    <div
                      className="content_box"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="400"
                    >
                      <img
                        className="icon"
                        src={CommunityBase}
                        alt="Community Base"
                        title="Community Base"
                      />
                      <Typography variant="h5" className="mb-1">
                        Community Base
                      </Typography>
                      <Typography className="head_paragraph">
                        Due to its popularity and extended community base,
                        support is easily available on the internet.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4} className="aside">
                    <div
                      className="content_box"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="200"
                    >
                      <img
                        className="icon"
                        src={EfficientSecure}
                        alt="Efficient, Secure"
                        title="Efficient, Secure"
                      />
                      <Typography variant="h5" className="mb-1">
                        Efficient, Secure
                      </Typography>
                      <Typography className="head_paragraph">
                        <strong>Express and Node.js</strong> are used together
                        to develop web apps that are efficient, secure, and
                        created in a rich ecosystem.
                      </Typography>
                    </div>
                    <div
                      className="content_box"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-easing="linear"
                      data-aos-delay="300"
                    >
                      <img
                        className="icon"
                        src={file}
                        alt="Web Applications Rapidly"
                        title="Web Applications Rapidly"
                      />
                      <Typography variant="h5" className="mb-1">
                        Web Applications Rapidly
                      </Typography>
                      <Typography className="head_paragraph">
                        <strong>AngularJS</strong> serves as the front end
                        default in a JavaScript environment. It was built by
                        Google to help the development of web applications
                        rapidly.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                {/* <SliderServiceCatPagePortfolio2 data={meanStackSliderData} /> */}
                <React.Fragment>
                  <Grid item xs={12}>
                    <Grid
                      className="serviceCatPortfolioSlider2 sec_padding pt-0 pb-0"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <Grid item container className="">
                        <Grid item xs={12}>
                          <div className="custom_slider_wrap">
                            {meanStackSliderData.map((item, key) => (
                              <div key={key}>
                                <aside>
                                  <div className="slide_item testimonial_list">
                                    <div className="website_img">
                                      <img
                                        src={item.image}
                                        alt={item.title}
                                        title={item.title}
                                      />
                                    </div>
                                    <div className="content_wrap">
                                      {item.tech_images.length > 0 && (
                                        <div className="tech_icons_wrapper">
                                          {item.tech_images.map(
                                            (technologies, index) => (
                                              <img
                                                height="30"
                                                key={index}
                                                src={technologies.icon}
                                                alt={technologies.title}
                                                title={technologies.title}
                                                className="tech_icons"
                                              />
                                            )
                                          )}
                                        </div>
                                      )}
                                      {item.link && (
                                        <a
                                          href={item.link}
                                          title={item.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Typography className="link btn">
                                            Go To Website
                                          </Typography>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </aside>
                              </div>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
                <Grid
                  item
                  xs={12}
                  className="btn-wrap"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <Link
                    to="/hire-developers/hire-angular-developers"
                    className="dark-btn-effect all-category-mobile"
                    title="Hire Mean Stack Developers"
                  >
                    Hire MEAN Stack Developers
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "MEAN Stack Development" */}

          {/* Start - Section Design "MERN stack development" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="FourColumnLayout3 four-mern-section sec_padding owl_curve_before_img section_title_space bg-lightgrey"
              id="mern-stack-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title "
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      MERN stack development
                    </Typography>
                    <Typography
                      className="head_paragraph "
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      A stack is the combination of technologies used to create
                      a web application. Every <strong>web application</strong>{" "}
                      is made using multiple technologies (frameworks,
                      libraries, databases etc).
                    </Typography>
                  </aside>
                </Grid>
                <Grid item container className="boxes_wrapper">
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_green"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="icon_wrapper">
                        <img alt="MongoDB" title="MongoDB" src={mongo} />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        MongoDB
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                        While conventional relational databases have a typical
                        schema design based on columns and tables, MongoDB is
                        schema-less. Data is stored in flexible documents with a
                        JSON (JavaScript Object Notation)-based query language.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_pink"
                      data-aos="fade-up"
                      data-aos-duration="1400"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Express.js"
                          title="Express.js"
                          src={espressjs}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Express
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                        Express.JS is a flexible and minimal Node.JS web
                        application framework that provides us a robust set of
                        features to build dynamic portal applications and APIs
                        using Express.Js. We have hands-on experience in using
                        Express.Js as a server-side framework.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_yellow"
                      data-aos="fade-up"
                      data-aos-duration="1800"
                    >
                      <div className="icon_wrapper">
                        <img alt="React.Js" title="React.Js" src={reactjs} />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        React.Js
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                        React.js a modern and extensive JavaScript framework,
                        widely used to build ultra-fast JavaScript based
                        isomorphic web applications. With proven skills in React
                        Development Services, we are pioneers in building
                        high-octane web applications.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_red"
                      data-aos="fade-up"
                      data-aos-duration="2200"
                    >
                      <div className="icon_wrapper">
                        <img alt="Node.js" title="Node.js" src={NodeJs} />
                      </div>
                      <Typography
                        variant="h4"
                        className="head_title  leftalign"
                      >
                        Node.js
                      </Typography>
                      <Typography className="head_paragraph  leftalign">
                        Node JS, the open-source, cross-platform which is used
                        for building fast and scalable server-side applications.
                        Node.JS applications are built on Google Chrome’s V8
                        JavaScript Engine which has a rich library of various
                        JavaScript modules. Node.JS is our core area of
                        expertise.
                      </Typography>
                    </div>
                  </Grid>

                  <SliderServiceCatPagePortfolio2 data={mernStackSliderData} />

                  <Grid
                    item
                    xs={12}
                    className="btn-wrap"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <Link
                      to="/hire-developers/hire-reactjs-developers"
                      className="dark-btn-effect all-category-mobile"
                      title="Hire Mern Stack Developers"
                    >
                      Hire MERN Stack Developers
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "MERN stack development" */}

          {/* Start - Section Design "MEVN stack development" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="FourColumnLayout1 sec_padding section_title_space"
              id="mevn-stack-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside className="">
                    <Typography
                      variant="h2"
                      className="head_title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      MEVN Stack Development
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <strong>MEVN Stack</strong> is the open-source{" "}
                      <strong>JavaScript</strong> software stack that  has
                      emerged as the evolving trend for building powerful and{" "}
                      <strong>dynamic web applications</strong>. A combination
                      of  
                      <strong>MongoDB, Express.Js, VueJS, and NodeJS,</strong>{" "}
                      the <strong>MEVN Stack technology</strong> is the complete
                      technology solution to develop innovative full-stack
                      projects for diverse business requirements.
                    </Typography>
                    {/* <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1400"
                    >
                      <strong>
                        Our skilled and proficient developers offer specialized
                        Mevn Stack Development Services to create advanced web
                        applications for diverse industry verticals.
                      </strong>
                    </Typography> */}
                  </aside>
                </Grid>
                <Grid
                  item
                  container
                  className="boxes_wrapper margin-bottom-change"
                >
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_green"
                      data-aos="fade-up"
                      data-aos-duration="400"
                    >
                      <div className="icon_wrapper">
                        <img alt="Vue JS " title="Vue JS " src={vuejs} />
                      </div>
                      <Typography variant="h4" className="head_title">
                        Vue JS
                      </Typography>
                      <Typography className="head_paragraph">
                        <strong>VueJS</strong> is referred as a client-side
                        framework and specially used in front-end web
                        development with great efficiency.
                      </Typography>
                      <Typography className="head_paragraph">
                        It has two-way data binding that allows seamless
                        front-end development along with MVC capability and
                        interactive server-side applications.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_pink"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <div className="icon_wrapper">
                        <img alt="Node Js" title="Node Js" src={NODEColored} />
                      </div>
                      <Typography variant="h4" className="head_title">
                        Node Js
                      </Typography>
                      <Typography className="head_paragraph">
                        <strong>NodeJS</strong> is an open-source web
                        development framework used for building highly scalable
                        web applications. The application includes the web
                        server and thus saves a considerable development time.
                      </Typography>
                      <Typography className="head_paragraph">
                        It is a server platform that compiles JavaScript coding
                        with native machine coding.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_yellow"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="icon_wrapper">
                        <img alt="MongoDB" title="MongoDB" src={Mongocolored} />
                      </div>
                      <Typography variant="h4" className="head_title">
                        MongoDB
                      </Typography>
                      <Typography className="head_paragraph">
                        <strong>MongoDB</strong> is an open-source,
                        document-oriented database program, used to create a
                        scalable base with flexible components.{" "}
                      </Typography>
                      <Typography className="head_paragraph">
                        Being a professional MEVN stack development company, we
                        can use the dynamic schema of MongoDB for building
                        products faster by saving the data in a binary JSON
                        format.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={6} lg={3} className="boxes_key">
                    <div
                      className="list_key_boxes border_color_red"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div className="icon_wrapper">
                        <img
                          alt="Express JS"
                          title="Express JS"
                          src={espressjscolored}
                        />
                      </div>
                      <Typography variant="h4" className="head_title">
                        Express JS
                      </Typography>
                      <Typography className="head_paragraph">
                        This web server framework of Node.js is very  flexible,
                        lightweight, and minimal. It provides variety of  robust
                        features for building a wide range of{" "}
                        <strong>web and mobile applications</strong>.
                      </Typography>
                      <Typography className="head_paragraph">
                        We can create interactive and impressive single, multi
                        page, and hybrid apps using <strong>Express.Js</strong>.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                {/* <SliderServiceCatPagePortfolio2 data={mevnStackSliderData} /> */}
                <React.Fragment>
                  <Grid item xs={12}>
                    <Grid
                      className="serviceCatPortfolioSlider2 sec_padding pt-0 pb-0"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <Grid item container className="">
                        <Grid item xs={12}>
                          <div className="custom_slider_wrap">
                            {mevnStackSliderData.map((item, key) => (
                              <div key={key}>
                                <aside>
                                  <div className="slide_item testimonial_list">
                                    <div className="website_img">
                                      <img
                                        src={item.image}
                                        alt={item.title}
                                        title={item.title}
                                      />
                                    </div>
                                    <div className="content_wrap">
                                      <Typography className="title">
                                        {item.title}
                                      </Typography>
                                      {item.tech_images.length > 0 && (
                                        <div className="tech_icons_wrapper">
                                          {item.tech_images.map(
                                            (technologies, index) => (
                                              <img
                                                height="30"
                                                key={index}
                                                src={technologies.icon}
                                                alt={technologies.title}
                                                title={technologies.title}
                                                className="tech_icons"
                                              />
                                            )
                                          )}
                                        </div>
                                      )}
                                      {item.link && (
                                        <a
                                          href={item.link}
                                          title={item.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Typography className="link btn">
                                            Go To Website
                                          </Typography>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </aside>
                              </div>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
                <Grid
                  item
                  xs={12}
                  className="btn-wrap"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <Link
                    to="/hire-developers/hire-vuejs-developers"
                    className="dark-btn-effect all-category-mobile"
                    title="Hire Mevn Stack Developers"
                  >
                    Hire MEVN Stack Developers
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "MEVN stack development" */}

          {/* Start - Section Design "ASP.NET Development Services" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="technologies_multi_services_circle sec_padding section_title_space bg-lightgrey"
              id="asp-net-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        ASP.NET Development Services
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        Microsoft’s <strong>ASP.NET</strong> is an open-source
                        server-side web application framework that is used by{" "}
                        <strong>Dot NET developers</strong> to make dynamic
                        websites and web applications. Having dedicated
                        resources of expert <strong>ASP.NET developers</strong>,
                        we provide cutting edge{" "}
                        <strong>ASP.NET development</strong> services to meet
                        your desired goals expeditiously, with a competitive
                        price. We are a leading{" "}
                        <strong>ASP.NET Web Development</strong> Company in
                        India that creates modern, secure, scalable and
                        feature-rich applications.
                      </Typography>
                    </aside>
                  </div>
                  <div
                    className="img_wrap text-center"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <img
                      alt=".Net Technologies"
                      title="Asp.Net Technologies"
                      src={aspdotnet_technologies_circle}
                    />
                  </div>
                </Grid>
                <React.Fragment>
                  <Grid item xs={12}>
                    <Grid
                      className="serviceCatPortfolioSlider2 sec_padding pt-0 pb-0"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <Grid item container className="">
                        <Grid item xs={12}>
                          <div className="custom_slider_wrap">
                            {aspDotNetSliderData.map((item, key) => (
                              <div key={key}>
                                <aside>
                                  <div className="slide_item testimonial_list">
                                    <div className="website_img">
                                      <img
                                        src={item.image}
                                        alt={item.title}
                                        title={item.title}
                                      />
                                    </div>
                                    <div className="content_wrap">
                                      <Typography className="title">
                                        {item.title}
                                      </Typography>
                                      {item.tech_images.length > 0 && (
                                        <div className="tech_icons_wrapper">
                                          {item.tech_images.map(
                                            (technologies, index) => (
                                              <img
                                                height="30"
                                                key={index}
                                                src={technologies.icon}
                                                alt={technologies.title}
                                                title={technologies.title}
                                                className="tech_icons"
                                              />
                                            )
                                          )}
                                        </div>
                                      )}
                                      {item.link && (
                                        <a
                                          href={item.link}
                                          title={item.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Typography className="link btn">
                                            Go To Website
                                          </Typography>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </aside>
                              </div>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
                <Grid
                  item
                  xs={12}
                  className="btn-wrap"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <Link
                    to="/hire-developers/hire-asp-net-developers"
                    className="dark-btn-effect all-category-mobile"
                    title="Hire ASP.NET Developers"
                  >
                    Hire ASP.NET Developers
                  </Link>
                </Grid>
                {/* <SliderServiceCatPagePortfolio2 data={aspDotNetSliderData} /> */}
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "ASP.NET Development Services" */}

          {/* Start - Section Design "Python Development Services" */}
          <Grid item xs={12}>
            <Grid
              component="div"
              className="key_benfits_and_why_choose sec_padding bottomkeybenefits pythonSec section_title_space"
              id="python-development"
            >
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography
                      variant="h2"
                      className="head_title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Python Development Services
                    </Typography>
                    <Typography
                      className="head_paragraph"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <strong>Visions</strong> is one of the top{" "}
                      <strong>Python web development</strong> companies in India
                      that has delivered several websites and web applications
                      for our worldwide clientele. Our expert{" "}
                      <strong>Python developers</strong> use this powerful
                      technology for{" "}
                      <strong>rapid application development</strong>.
                    </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      alt="Django Web Development"
                      title="Django Web Development"
                      src={DjangoWebDevelopment}
                    />
                    <Typography variant="h4" className="head_title">
                      Django Web Development
                    </Typography>
                    <Typography className="head_paragraph">
                      We have an experienced team of{" "}
                      <strong>Django developers</strong> that convert your ideas
                      into reality and offer extensive <strong>Django</strong>{" "}
                      development services.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img
                      alt="Migration to Python"
                      title="Migration to Python"
                      src={MigrationtoPython}
                    />
                    <Typography variant="h4" className="head_title">
                      Migration to Python
                    </Typography>
                    <Typography className="head_paragraph">
                      <strong>Visions</strong> dedicated{" "}
                      <strong>Python developers</strong> team can easily migrate
                      your existing web applications to <strong>Python</strong>{" "}
                      without any trouble.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="1800"
                  >
                    <img
                      alt="Web Services & API"
                      title="Web Services & API"
                      src={WebServicesAPI}
                    />
                    <Typography variant="h4" className="head_title">
                      Web Services & API
                    </Typography>
                    <Typography className="head_paragraph">
                      With safe and well organised{" "}
                      <strong>web services and APIs</strong>, we provide access
                      to your platform data to empower your applications.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key">
                  <div
                    className="list_key_boxes"
                    data-aos="fade-up"
                    data-aos-duration="2200"
                  >
                    <img
                      alt="Web Automation Scripts"
                      title="Web Automation Scripts"
                      src={QualityAssurance}
                    />
                    <Typography variant="h4" className="head_title">
                      Web Automation Scripts
                    </Typography>
                    <Typography className="head_paragraph">
                      We offer scripts for any of your requirements to automate
                      websites or other applications, using{" "}
                      <strong>Python Selenium APIs</strong>.
                    </Typography>
                  </div>
                </Grid>
                {/* <Grid item xs={12}>
                  <SliderServiceCatPagePortfolio2 data={pythonSliderData} />
                </Grid> */}
                {/* <React.Fragment>
                  <Grid item xs={12}>
                    <Grid
                      className="serviceCatPortfolioSlider2 sec_padding pt-0 pb-0"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <Grid item container className="">
                        <Grid item xs={12}>
                          <div className="custom_slider_wrap">
                            {pythonSliderData.map((item, key) => (
                              <div key={key}>
                                <aside>
                                  <div className="slide_item testimonial_list">
                                    <div className="website_img">
                                        <img
                                          src={item.image}
                                          alt={item.title}
                                          title={item.title}
                                        />
                                    </div>
                                    <div className="content_wrap">
                                      <Typography className="title">
                                        {item.title}
                                      </Typography>
                                      {item.tech_images.length > 0 && (
                                        <div className="tech_icons_wrapper">
                                          {item.tech_images.map(
                                            (technologies, index) => (
                                                <img
                                                  height="30"
                                                  key={index}
                                                  src={technologies.icon}
                                                  alt={technologies.title}
                                                  title={technologies.title}
                                                  className="tech_icons"
                                                />
                                            )
                                          )}
                                        </div>
                                      )}
                                      {item.link && (
                                        <a
                                          href={item.link}
                                          title={item.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Typography className="link btn">
                                            Go To Website
                                          </Typography>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </aside>
                              </div>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment> */}
                <Grid item container className="container_custom">
                  <Grid item xs={12}>
                    <SliderServiceCatPagePortfolio2 data={pythonSliderData} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="btn-wrap"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <Link
                      to="/hire-developers/hire-python-developers"
                      className="dark-btn-effect all-category-mobile"
                      title="Hire Python Developers"
                    >
                      Hire Python Developers
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Python Development Services" */}

          {/* Start - Section Design "Artificial Intelligence Services" */}
          <Grid item xs={12}>
            <Grid component="div"className="sec_padding pythonSec section_title_space bg-lightgrey" id="artificial-intelligence">
                <Grid item container className="container_custom">
                  <Grid item xs={12} className="title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Artificial Intelligence Services
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <strong>Artificial Intelligence</strong> <strong>(AI)</strong> is the branch of computer sciences that emphasizes the development of intelligent machines, thinking and working like humans. For example, speech recognition, problem-solving, learning and planning.
                      </Typography>
                    </aside>
                  </Grid>
                  <Grid item xs={12} className="ai-list-wrap">
                    <ul data-aos="fade-up" data-aos-duration="1000">
                      <li className="yellow">
                        <div className="media">
                          <div className="icon-box-wrap">
                            <div className="icon-box-wrap-step1">
                              <div className="icon-box-wrap-step2">
                                <div className="icon-wrapper">
                                  <img src={machineLearningWhite} alt="Machine Learning" title="Machine Learning" />
                                </div>
                              </div>
                            </div>
                            <div className="small-btm-circle"><span className="dot"></span></div>
                          </div>
                          <div className="content-box-wrap">
                            <Typography variant="h4" className="head_title">
                              Machine Learning
                            </Typography>
                            <Typography className="head_paragraph">
                            Cras sagittis rutrum diam, vitae tempor sem aliquam sit amet. Nulla bibendum nibh vitae congue elementum, purus.
                            </Typography>
                          </div>
                        </div>
                      </li>
                      <li className="redpink">
                        <div className="media">
                          <div className="icon-box-wrap">
                            <div className="icon-box-wrap-step1">
                              <div className="icon-box-wrap-step2">
                                <div className="icon-wrapper">
                                  <img src={deepLearningWhite} alt="Deep Learning" title="Deep Learning" />
                                </div>
                              </div>
                            </div>
                            <div className="small-btm-circle"><span className="dot"></span></div>
                          </div>
                          <div className="content-box-wrap">
                            <Typography variant="h4" className="head_title">
                            Deep Learning
                            </Typography>
                            <Typography className="head_paragraph">
                            Cras sagittis rutrum diam, vitae tempor sem aliquam sit amet. Nulla bibendum nibh vitae congue elementum, purus.
                            </Typography>
                          </div>
                        </div>
                      </li>
                      <li className="navyblue">
                        <div className="media">
                          <div className="icon-box-wrap">
                            <div className="icon-box-wrap-step1">
                              <div className="icon-box-wrap-step2">
                                <div className="icon-wrapper">
                                  <img src={naturalLanguageProcessingWhite} alt="Natural Language Processing" title="Natural Language Processing" />
                                </div>
                              </div>
                            </div>
                            <div className="small-btm-circle"><span className="dot"></span></div>
                          </div>
                          <div className="content-box-wrap">
                            <Typography variant="h4" className="head_title">
                            Natural Language Processing
                            </Typography>
                            <Typography className="head_paragraph">
                            Cras sagittis rutrum diam, vitae tempor sem aliquam sit amet. Nulla bibendum nibh vitae congue elementum, purus.
                            </Typography>
                          </div>
                        </div>
                      </li>
                      <li className="green">
                        <div className="media">
                          <div className="icon-box-wrap">
                            <div className="icon-box-wrap-step1">
                              <div className="icon-box-wrap-step2">
                                <div className="icon-wrapper">
                                  <img src={computerVisionWhite} alt="Computer Vision" title="Computer Vision" />
                                </div>
                              </div>
                            </div>
                            <div className="small-btm-circle"><span className="dot"></span></div>
                          </div>
                          <div className="content-box-wrap">
                            <Typography variant="h4" className="head_title">
                            Computer Vision
                            </Typography>
                            <Typography className="head_paragraph">
                            Cras sagittis rutrum diam, vitae tempor sem aliquam sit amet. Nulla bibendum nibh vitae congue elementum, purus.
                            </Typography>
                          </div>
                        </div>
                      </li>
                      <li className="skyblue">
                        <div className="media">
                          <div className="icon-box-wrap">
                            <div className="icon-box-wrap-step1">
                              <div className="icon-box-wrap-step2">
                                <div className="icon-wrapper">
                                  <img src={dataAnalystWhite} alt="Data Analytics" title="Data Analytics" />
                                </div>
                              </div>
                            </div>
                            <div className="small-btm-circle"><span className="dot"></span></div>
                          </div>
                          <div className="content-box-wrap">
                            <Typography variant="h4" className="head_title">
                            Data Analytics
                            </Typography>
                            <Typography className="head_paragraph">
                            Cras sagittis rutrum diam, vitae tempor sem aliquam sit amet. Nulla bibendum nibh vitae congue elementum, purus.
                            </Typography>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Grid>
                </Grid> 
            </Grid>
          </Grid>    
          {/* End - Section Design "Artificial Intelligence Services" */}

          {/* Start - NumberCount Import From Common Components */}
          <Suspense fallback={<div></div>}>
            <NumberCount />
          </Suspense>
          {/* End - NumberCount Import From Common Components */}
        </Grid>
      </div>
    );
  }
}

export default WebDevelopment;
