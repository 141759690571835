import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = withStyles({
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    content: {},
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
}))(MuiExpansionPanelDetails);

export default function CustomizedExpansionPanels(props) {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <React.Fragment>
            <div className="hereToHelpYou">
                {props.HereToHelpYou.map(ele =>
                    <ExpansionPanel key={ele.expanded} className="expMain" square expanded={expanded === ele.expanded} onChange={handleChange(ele.expanded)}>
                        <ExpansionPanelSummary className="expMainHead" aria-controls={ele.ariaControls} id={ele.panelId} expandIcon={<ExpandMoreIcon />}>
                            <Typography><strong>{ele.typographyText}</strong></Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="expMainContent">
                            <Typography>
                                {ele.TypographyDescription}
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            </div>
        </React.Fragment>
    );
}