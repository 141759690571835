import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Easy_to_Manage from '../../assets/images/HireDevelopers/HireWordpress/Easy_to_Manage.svg';
import Cost_effective from '../../assets/images/HireDevelopers/HireWordpress/Cost_effective.svg';
import Outstanding_Scalability from '../../assets/images/HireDevelopers/HireWordpress/Outstanding_Scalability.svg';
import SEO_friendly from '../../assets/images/HireDevelopers/HireWordpress/SEO_friendly.svg';
import Expert_consultation from '../../assets/images/HireDevelopers/HireWordpress/Expert_consultation.svg';
import Wordpress_Development from '../../assets/images/HireDevelopers/HireWordpress/Wordpress_Development.svg';
import Testing_Deployment from '../../assets/images/HireDevelopers/HireWordpress/Testing_Deployment.svg';
import Maintenance_and_Support from '../../assets/images/HireDevelopers/HireWordpress/Maintenance_and_Support.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HireWordpress/HIRE-WORDPRESS-DEVELOPERS1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireWordpressDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Hire Wordpress Developers",
      bannerImg: Hire_banner,
      HereToHelpYou: [
        {
          expanded: 'panel1',
          ariaControls: 'panel1d-content',
          panelId: 'panel1d-header',
          typographyText: ' Is WordPress secure for businesses that need money transactions?',
          TypographyDescription: 'Yes, WordPress has dependable security and by saying this, it means you can make it as secure as you need for your specific business. WordPress offers powerful tools and plugin to extra secure your websites from any kind of hacking or data leaks.'
        },
        {
          expanded: 'panel2',
          ariaControls: 'panel2d-content',
          panelId: 'panel2d-header',
          typographyText: 'Are there any limitations to a WordPress website?',
          TypographyDescription: 'WordPress offers outstanding scalability and can be extended from a simple blog to an informational website, business websites, e-commerce platforms and many more. With the availability of thousands of premade theme and plugin and being open source in nature, it has no limits for any kind of development.'
        },
        {
          expanded: 'panel3',
          ariaControls: 'panel3d-content',
          panelId: 'panel3d-header',
          typographyText: 'Can we customize WordPress?',
          TypographyDescription: 'We can customize the prebuilt WordPress themes and plugins to alter the look and output of those to match exactly as per your requirements and eliminate any extra code or functionality not required by you.'
        },
        {
          expanded: 'panel4',
          ariaControls: 'panel4d-content',
          panelId: 'panel4d-header',
          typographyText: ' What is a Child Theme?',
          TypographyDescription: 'A child theme is needed to create when we need to make some customizations to the original theme for our website requirements. As any update in the original theme will undo all the customization we have done, so in order to prevent that an extension to the original theme is created which is called child theme.'
        }
      ]
    };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire WordPress Developer | Dedicated WordPress Programmers India";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="Wordpress Developers" />
          {/* End - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <strong>Visions</strong> will be your one-stop solution for any of your requirements related to <strong>Wordpress</strong>. We are capable of handling anything related to <strong>Wordpress</strong>. Custom theme development, plugin development, customizing existing themes and plugins, developing the website from scratch, security, maintenance among others.
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <strong>
                          WordPress is the most SEO friendly, easy to use CMS solution with outstanding scalability
                        </strong>
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Easy_to_Manage} alt="Easy to Manage" title="Easy to Manage" />
                        <Typography variant="h5" className="mb-1">
                          Easy to Manage
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong>Wordpress</strong> offers an easy to manage admin panel, hence allowing a layman person to easily update and maintain his <strong>Wordpress</strong> website.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Cost_effective} alt="Cost-effective" title="Cost-effective" />
                        <Typography variant="h5" className="mb-1">
                          Cost-effective
                            </Typography>
                        <Typography className="head_paragraph">
                          Being an open-source platform and having an array of prebuilt tools for development, it is very cost-friendly and being easy to manage, eliminates the maintenance cost as well.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Outstanding_Scalability} alt="Outstanding Scalability" title="Outstanding Scalability" />
                        <Typography variant="h5" className="mb-1">
                          Outstanding Scalability
                            </Typography>
                        <Typography className="head_paragraph">
                          Having a pool of ready-made themes and plugin to further extend the functionality of <strong>Wordpress CMS </strong>, it can be expanded to a huge platform of any level. Moreover, it has extensive community support and hence is a reliable solution.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={SEO_friendly} alt="SEO friendly" title="SEO friendly" />
                        <Typography variant="h5" className="mb-1">
                          SEO friendly
                            </Typography>
                        <Typography className="head_paragraph">
                          Being written in simple and clean code, <strong>Wordpress</strong> is the most <strong>SEO friendly</strong> platform and further offers many free and paid plugin to optimize your website and rank it well on search engines.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire Our WordPress Developers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      We have proven experience in developing a variety of projects based on <strong>WordPress</strong>, regardless of their size and complexity. We offer solutions based on your requirements and the level of security required for your project.
                              </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="Expert Consultation" title="Expert Consultation" src={Expert_consultation} />
                    <Typography variant="h4" className="head_title">
                      Expert Consultation
                              </Typography>
                    <Typography className="head_paragraph">
                      We will gather information about your project and will share our inputs to use <strong>WordPress</strong> as a platform. We will share our complete approach, theme, plugins or any third parties we may have to use for the fulfillment of your specific needs.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="Wordpress Development" title="Wordpress Development" src={Wordpress_Development} />
                    <Typography variant="h4" className="head_title">
                      Wordpress Development
                              </Typography>
                    <Typography className="head_paragraph">
                      We will help you to create truly impressive websites for your business and will make sure to install all the necessary plugins for its effective working and security. Along with that, we will provide you a document to self-manage your website for content updates.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="Testing & Deployment" title="Testing & Deployment" src={Testing_Deployment} />
                    <Typography variant="h4" className="head_title">
                      Testing & Deployment
                              </Typography>
                    <Typography className="head_paragraph">
                      We will test the website across all devices and screens sizes to make sure, its responsive and ready to deploy. We will also assist you to deploy the website on your domain and hosting.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="Maintenance & Support" title="Maintenance & Support" src={Maintenance_and_Support} />
                    <Typography variant="h4" className="head_title">
                      Maintenance & Support
                              </Typography>
                    <Typography className="head_paragraph">
                      We provide dedicated support and maintenance to our client to make sure your website launches successfully in the real market. We will be quick to fix any bugs or issues you may face in the real-time execution of the website.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We are always on the front foot to go beyond the call of duty to deliver your requirements.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li>
                        An exceptional track record of creating amazing <strong>Wordpress</strong> websites. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Adhere to the coding standards and deliver quality work.  <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Can deliver requirements right for any of your <strong>Wordpress</strong> needs. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Available to work on milestones, hourly basis, full time and part-time as well.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Always around the corner for communication purposes.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Deliver what is needed and not only what the client has asked for.
                                  </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Browse through some concerns you have may before choosing WordPress as your website platform.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HireWordpressDev;
