import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import SimpleReactValidator from "simple-react-validator";
import {NotificationContainer, NotificationManager } from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import Dropzone from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";

// Get Images Start
import helpclients from "../assets/images/ourTeam/help-clients.png";
import Get_free_quote from "../assets/images/getFreeQuote/Get_free_quote.png";
// Get Images End

// Custom Stylesheets Start
import "react-notifications/lib/notifications.css";
import "../assets/css/get-free-quote.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../components/common/InnerPagesBanner";
import OurTeamPromise from "./common/OurTeamPromise";
import helper from "../helper";
// Get Common Components End

require("es6-promise").polyfill();
require("isomorphic-fetch");

class GetfreeQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Get a free quote",
      bannerImg: Get_free_quote,
      name: "",
      email: "",
      phone: "",
      service_category: "",
      service_category_dropdown: [],
      enquiry: "",
      attachment: "",
      attachmentName: "",
      gCaptcha: "",
      loader: false
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
    
     const ServiceOptions = ['Web Development', 'UI/UX Designing', 'E-Commerce Solutions', 'Mobile App Development', 'Server/Network Admin Support', 'Digital Marketing'];

     this.setState({service_category_dropdown: ServiceOptions});
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFileAccepted = e => {
    if (
      e[0].type === "image/jpeg" || 
      e[0].type === "image/png" || 
      e[0].type === "application/pdf" || 
      e[0].type === "application/msword" || 
      e[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || 
      e[0].type === "application/vnd.ms-powerpoint" ||
      e[0].type === "application/vnd.openxmlformats-officedocument.presentationml.slideshow" ||
      e[0].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      e[0].type === "application/zip"
    ) {
      this.setState({
        attachment: e[0],
        attachmentName: e[0].name,
        fileFormatError: ""
      });
      if (e[0].size > 20000000) {
        this.setState({
          fileFormatError: "File size should be less than or equal to 20 MB",
          attachmentName: "",
          attachment: ''
        });
      }
    } else {
      this.setState({
        fileFormatError: "Please upload a valid file",
        attachmentName: "",
        attachment: ""
      });
    }
  }

  handleFileRejected = e => {
    if (
      e[0].type === "image/jpeg" ||
      e[0].type === "image/png" ||
      e[0].type === "application/pdf" ||
      e[0].type === "application/msword" ||
      e[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      e[0].type === "application/vnd.ms-powerpoint" ||
      e[0].type === "application/vnd.openxmlformats-officedocument.presentationml.slideshow" ||
      e[0].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      e[0].type === "application/zip"
    ) {
      if (e[0].size > 20000000) {
        this.setState({
          fileFormatError: "File size should be less than or equal to 20 MB",
          attachmentName: "",
          attachment: ''
        });
      }
    } else {
      this.setState({
        fileFormatError: "Please upload a valid file",
        attachmentName: "",
        attachment: ""
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ loader: true });
      const data = new FormData();
      data.append("name", this.state.name);
      data.append("email", this.state.email);
      data.append("phone", this.state.phone);
      data.append("service_category", this.state.service_category);
      data.append("enquiry", this.state.enquiry);
      data.append("gCaptcha", this.state.gCaptcha);
      data.append("attachment", this.state.attachment);

      fetch(helper.API_URL + "/quotation/addQuote", {
        method: "POST",
        body: data
      })
        .then(response => {
          if (response.status === 200) {
            NotificationManager.success(
              "Your Information Submitted Successfully. We will get back to you as soon as possible."
            );
            this.setState({ loader: false });
          } else {
            NotificationManager.error(
              "Something went wrong. Please try again."
            );
            this.setState({ loader: false });
          }
          return response.json();
        })
        .catch(error => {
          NotificationManager.error("Something went wrong. Please try again.");
            this.setState({ loader: false });
        });
      this.setState({
        name: "",
        email: "",
        phone: "",
        service_category: "",
        enquiry: "",
        gCaptcha: "",
        attachmentName: "",
        attachment: ""
      });
      this.captcha.reset();
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
    }
  };

  handleCaptcha = value => {
    this.setState({ gCaptcha: value });
  };

  getExperienceyear() {
    var today = new Date();
    var start_date = new Date("june 02, 1993");
    var years_now = today.getFullYear() - start_date.getFullYear();
    var m = today.getMonth() - start_date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < start_date.getDate())) {
      years_now--;
    }
    return years_now;
  }

  render() {
    document.title = "Get free quote for Application Development, Web development, Graphics Design and Mobile Development. | Visions";
    const {
      pageTitle,
      bannerImg,
      name,
      email,
      phone,
      service_category,
      enquiry,
      gCaptcha,
      service_category_dropdown
    } = this.state;
    return (
      <Grid component="div" className="get-free-quote-wrapper">
        <Grid container>

          <NotificationContainer />

          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - Section Design "Quote Form" */}
          <Grid item xs={12} className="contact-pg">
            <Grid item container className="container_custom">
              <div className="inner-wrapper">
                <Grid item xs={12} component="div"className="inner-get-free-quote sec_padding mx-auto">
                  <Typography variant="h2" className="head_title" data-aos="fade-up"data-aos-duration="400">
                    We are Excited That You Want To Work With Us
                  </Typography>
                  <Typography className="head_paragraph" data-aos="fade-up"data-aos-duration="800"> 
                    <strong>For a free assessment and quote for your project, please provide us with a few details below.</strong><br />
                    Once you submit this form we will perform some preliminary analysis and assessment, and get back to you with an assessment of your project requirements.
                  </Typography>
                  <form
                    noValidate
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <Grid container component="div" className="apply-form-field" data-aos="fade-up"data-aos-duration="1200">
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Name"
                          variant="outlined"
                          className="inner-fields"
                          name="name"
                          value={name}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 100
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "Name",
                            name,
                            "required|max:100|alpha_space"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Email"
                          variant="outlined"
                          className="inner-fields"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "Email",
                            email,
                            "required|email"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Phone"
                          variant="outlined"
                          className="inner-fields"
                          name="phone"
                          value={phone}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 20
                          }}
                        />
                        <span className="text-danger">
                          {this.validator.message(
                            "Phone",
                            phone,
                            "required|max:20|phone"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {/* <TextField
                          required
                          id="outlined-name"
                          label="Subject"
                          variant="outlined"
                          className="inner-fields"
                          name="subject"
                          fullWidth
                          value={subject}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 150
                          }}
                        /> */}
                        {/* <span className="text-danger">
                          {this.validator.message(
                            "Subject",
                            subject,
                            "required|max:150|min:10"
                          )}
                        </span> */}
                        <FormControl variant="outlined" fullWidth required>
                          <TextField
                            className="custom_select_tag inner-fields"
                            select
                            variant="outlined"
                            value={service_category}
                            input={<Input />}
                            onChange={this.handleChange}
                            inputProps={{
                              name: "service_category",
                              id: "service_category"
                            }}
                            //required
                            label="Service Category"
                            required
                          >
                            {service_category_dropdown.map(name => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                        <span className="text-danger">
                          {this.validator.message(
                            "Service_Category",
                            service_category,
                            "required"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="outlined-name"
                          label="Enquiry"
                          variant="outlined"
                          fullWidth
                          className="inner-fields"
                          multiline
                          rows="5"
                          name="enquiry"
                          value={enquiry}
                          onChange={this.handleChange}
                          inputProps={{
                            maxLength: 1000
                          }}
                        />
                        <span className={enquiry.length >= 800 ? "des_count red" : "des_count green"}>{1000 - enquiry.length} characters remaining out of 1000</span>
                        <span className="text-danger">
                          {this.validator.message(
                            "Enquiry",
                            enquiry,
                            "required|max:1000"
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="inner-fields drag_drop_files">
                          <Dropzone
                            accept={[
                              "image/jpeg", //jpeg image
                              "image/png", //png image
                              "application/pdf", //pdf
                              "application/msword", //doc
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
                              "application/vnd.ms-powerpoint", //ppt
                              "application/vnd.openxmlformats-officedocument.presentationml.slideshow", //pptx
                              "application/vnd.openxmlformats-officedocument.presentationml.presentation", //pptx
                              "application/zip", //zip
                              ".doc",
                              ".docx",
                              ".pdf",
                              ".jpg",
                              ".jpeg",
                              ".png",
                              ".ppt",
                              ".pptx",
                              ".zip"
                            ]}
                            maxSize={20000000}
                            multiple={false}
                            //onDrop={this.handleFileDrag}
                            onDropAccepted={this.handleFileAccepted}
                            onDropRejected={this.handleFileRejected}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section className="container">
                                <div {...getRootProps({ className: "dropzone" })}>
                                  <input {...getInputProps()} />
                                  {this.state.attachmentName ? <p>{this.state.attachmentName}</p> : <p>Drag file here, or click to select file</p>}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          <p className="note_condition"><strong>Note :</strong><span className="mainContentNote"><span> File type should be .jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt, .pptx, .zip only.</span><span>Maximum file size should be 20MB.</span></span></p>
                          <span className="text-danger">
                            <div className="srv-validation-message">
                              {this.state.fileFormatError}
                            </div>
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ReCAPTCHA
                          ref={(r) => this.captcha = r}
                          sitekey="6Lcg-cUUAAAAAPReOutbrk0KYH1wZj_bocA4iZZb"
                          onChange={this.handleCaptcha}
                        />
                        <span className="text-danger captcha">
                          {this.validator.message("captcha", gCaptcha, "required")}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="get-quote-inner-field full button">
                          <FormControl className="form-fields" fullWidth>
                          <input type="submit" value="Submit" title="Submit" />
                          <div className="loader-style-form">
                            {this.state.loader ? <CircularProgress /> : ""}
                          </div>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* End - Section Design "Quote Form" */}
          
          {/* Start - Section Design "Help Our Clients" */}
          <Grid item xs={12}>
            <Grid component="div" className="help-our-clients sec_padding">
              <Grid item container className="container_custom">
                <Grid component="div" className="help-our-clients-inner">
                  <Grid component="div" className="help-our-clients-flex-left" data-aos="fade-down-right" data-aos-duration="800">
                    <img
                      src={helpclients}
                      alt="Help our clients"
                      title="Help our clients"
                    />
                  </Grid>
                  <Grid component="div" className="help-our-clients-flex-right" data-aos="fade-left" data-aos-duration="800">
                    <Typography variant="h2" >
                      Help our clients succeed by producing world-class web and mobile solutions
                    </Typography>
                    <Typography>
                      Visions has faith in Quality. We comprehend this idea in its more extensive significance. We have explicit techniques and methodology to guarantee Quality in each deliverable of our own. At Visions, we underline on the predominance and practicality of each arrangement that we create to guarantee enhancement of the customers business.
                    </Typography>
                    <Grid component="div" item xs={12}>
                      <div className="btn-wrapper">                         
                          <Link to="/portfolios" className="free_quote dark-btn-effect all-category-mobile">
                          View Our Work
                          </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Help Our Clients" */}
          
          {/* Start - OurTeamPromise Import From Common Components */}
          <OurTeamPromise />
          {/* End - OurTeamPromise Import From Common Components */}

        </Grid>
      </Grid>
    );
  }
}

export default GetfreeQuote;
