import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Hire_banner from '../../assets/images/HireDevelopers/HireASP/ASP-Net-Banner1.png';
import ASPNETApplicationDevelopment from '../../assets/images/HireDevelopers/HireASP/ASPNETApplicationDevelopment.svg';
import easytodeploy from '../../assets/images/HireDevelopers/HireASP/easytodeploy.svg';
import EnterpriseNETSolutions from '../../assets/images/HireDevelopers/HireASP/EnterpriseNETSolutions.svg';
import HighReliability_Security from '../../assets/images/HireDevelopers/HireASP/HighReliability_Security.svg';
import language_independant from '../../assets/images/HireDevelopers/HireASP/language_independant.svg';
import ModifiedSoftwareDevelopment from '../../assets/images/HireDevelopers/HireASP/ModifiedSoftwareDevelopment.svg';
import MVC5_Development from '../../assets/images/HireDevelopers/HireASP/MVC5_Development.svg';
import serversidetechnology from '../../assets/images/HireDevelopers/HireASP/serversidetechnology.svg';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireASPDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
            pageTitle: "Hire Asp.Net Developers",
            bannerImg: Hire_banner,
            HereToHelpYou: [
                {
                    expanded: 'panel1',
                    ariaControls: 'panel1d-content',
                    panelId: 'panel1d-header',
                    typographyText: 'What is the principle contrast among ASP and the Microsoft ASP.NET site?',
                    TypographyDescription: 'The principle distinction among ASP and ASP.Net is, ASP utilizes just VBscript or Javascript while ASP.NET utilizes any sort of language ordered.'
                },
                {
                    expanded: 'panel2',
                    ariaControls: 'panel2d-content',
                    panelId: 'panel2d-header',
                    typographyText: 'To the extent time is a worry, how ASP.NET is the best to pick?',
                    TypographyDescription: 'For making any of the task best quality with a restricted measure of time span is required and ASP.NET has the quality to perform quick venture coding with a constrained measure of time.'
                },
                {
                    expanded: 'panel3',
                    ariaControls: 'panel3d-content',
                    panelId: 'panel3d-header',
                    typographyText: 'ASP versus ASP .NET',
                    TypographyDescription: 'Key contrast between them is that ASP is deciphered as contents are utilized, while ASP.NET is incorporated as a result of these pages utilizing .NET dialects. ASP .Net pages are to be MSIL(Microsoft Intermediate Language) assembled.'
                },
                {
                    expanded: 'panel4',
                    ariaControls: 'panel4d-content',
                    panelId: 'panel4d-header',
                    typographyText: 'What is IIS? For what reason do we use it?',
                    TypographyDescription: 'Internet Information Server by Microsoft with its own Operating System. It is utilized to execute website page contents.'
                },
                {
                    expanded: 'panel5',
                    ariaControls: 'panel5d-content',
                    panelId: 'panel5d-header',
                    typographyText: 'What is the distinction between client side and server-side approvals?',
                    TypographyDescription: 'Client side approvals work at the customer end with the assistance of scripting dialects, for example, JavaScript or jQuery and VBScript. Then again, server-side approvals work at the server end with the assistance of programming dialects like C#, VB, F# and so forth. Server approvals work when you submit or send information to the server.'
                }
            ]
      };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire ASP NET Developers | Hire .NET Developers India";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="Asp.Net Developers" />
          {/* End - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        <strong> Visions</strong>  is the first class <strong> ASP.NET</strong>  Development Company in India. We have a colossal group who is exceptionally experienced and devoted to their work and gives generally solid, verified, and versatile arrangements. Our devoted engineers have picked up greatness in executing .Net ventures of little, huge and mid-scale organizations over a wide scope of industry verticals. Contract <strong> Asp.Net Developers</strong>  on a devoted month to month premise who is trusted by our customers for their specialized aptitude.
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={serversidetechnology} alt="Server-Side Technology" title="Server-Side Technology" />
                        <Typography variant="h5" className="mb-1">
                          Server-Side Technology
                            </Typography>
                        <Typography className="head_paragraph">
                          It is absolutely server-side technology so, <strong>ASP.NET</strong> code executes on the server before it is sent to the browser.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={language_independant} alt="Language Independent" title="Language Independent" />
                        <Typography variant="h5" className="mb-1">
                          Language Independent
                            </Typography>
                        <Typography className="head_paragraph">
                          It enables you to choose the language that best applied to your application or partition your application across many languages.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={HighReliability_Security} alt="High Reliability and Security" title="High Reliability and Security" />
                        <Typography variant="h5" className="mb-1">
                          High Reliability and Security
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong> ASP.NET</strong>  guaranteed high dependability and security due to implicit Windows authentication and per-application configuration.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={easytodeploy} alt="Easy to Deploy" title="Easy to Deploy" />
                        <Typography variant="h5" className="mb-1">
                          Easy to Deploy
                            </Typography>
                        <Typography className="head_paragraph">
                          <strong> ASP.NET</strong>  makes for easy deployment. No need to register components because the configuration data is embedded.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire ASP.NET Developers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      <strong> Visions</strong>  has a group of profoundly gifted <strong> ASP.Net</strong>  web engineers that watch out for most recent mechanical patterns being developed to suit customer's necessities. Vision is giving world class <strong> ASP.NET</strong>  web advancement benefits all inclusive. We are one of a kind since we offer top of the line proficient web improvement benefits over the globe. We give start to finish ASP.NET arrangements where we devoted <strong> .NET engineers</strong>  help you from the earliest starting point of your product advancement venture till the time your product lives.
                              </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="ASP.NET Application Development" title="ASP.NET Application Development" src={ASPNETApplicationDevelopment} />
                    <Typography variant="h4" className="head_title">
                      ASP.NET Application Development
                              </Typography>
                    <Typography className="head_paragraph">
                      We make the application that can assist you with getting achievements and enable you to extend your wings in the manner you need.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="Enterprise.NET Solutions" title="Enterprise.NET Solutions" src={EnterpriseNETSolutions} />
                    <Typography variant="h4" className="head_title">
                      Enterprise.NET Solutions
                              </Typography>
                    <Typography className="head_paragraph">
                      Our undertaking arrangement will assist you with enhanced your business and get the brief profit for any of your activity.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="Modified Software Development" title="Modified Software Development" src={ModifiedSoftwareDevelopment} />
                    <Typography variant="h4" className="head_title">
                      Modified Software Development
                              </Typography>
                    <Typography className="head_paragraph">
                      In the event that you might want to make your tweak programming, at that point we have the mastery assets of the colleague who will enable you to out.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="ASP.NET MVC4/MVC5 Development " title="ASP.NET MVC4/MVC5 Development " src={MVC5_Development} />
                    <Typography variant="h4" className="head_title">
                      ASP.NET MVC4/MVC5 Development
                              </Typography>
                    <Typography className="head_paragraph">
                      We can assist you with working on the refreshed variant of the <strong> ASP.NET </strong> stage which can give you the most recent type of advancement.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200"><strong> Visions</strong>  gives robust and industry-centered <strong> ASP.Net</strong>  improvement arrangements. We have a group of master ASP.NET and C# designers who have top to bottom information on the most recent advances, UI, and strategies. <strong> Visions</strong>  has splendid assets in .Net web advancement. Our capable .Net designers give sharp and exact arrangements consolidating exceptionally proficient improvement strategies with cutting edge the executives apparatuses that can get down to business your needs.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li>
                        ASP.NET Web Development. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        .NET Desktop Apps Development. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        .NET Integration Services. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        .NET Application Migration. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Enterprise .NET Application Development. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> .NET Custom Software Development.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> .NET Mobile App Development.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Custom .Net MVC Development.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> ASP .NET Web API Development.
                                  </li>

                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200"><strong> Visions</strong>  sustains customer's goals with the most ideal solution that would suit their prerequisites so as to encourage business. A shared exertion of our group and its down to earth approach causes them convey custom web arrangements with very good quality highlights.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HireASPDev;
