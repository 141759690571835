import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

// Get Images Start
import Error_Banner from "../assets/images/Error_Banner.png";
// Get Images End

// Get Common Components Start
import InnerPagesBanner from "./common/InnerPagesBanner";
// Get Common Components End

class PageNotFound extends Component { 

  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }

  constructor(props) { 
    super(props); 
    this.state = { pageTitle: "Page Not Found", bannerImg: Error_Banner }; 
  } 
  render() { 
    document.title = "Website Design & Web Development Company India";
    const { pageTitle, bannerImg } = this.state; 
    return (
    <div className="main_pageNotFound">
      <Grid container>

          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}
  
          {/* Start - Section Design "Error Page 404 Content" */}
          <Grid item xs={12}>
              <Grid component="div" className="errorPageContent">
                  <Grid item container className="container_custom">
                      <Grid item xs={12} className="">
                          <Typography variant="h3" className="head_title">
                          404
                          </Typography>
                          <Typography className="head_paragraph">
                            <Link to="/"><i className="material-icons">home</i> Go Back To Home</Link>
                          </Typography>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          {/* End - Section Design "Error Page 404 Content" */}
  
      </Grid>
    </div>
) } }

export default PageNotFound;