import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// Get Images Start
import major_networks_middle from "../../assets/images/ApplicationDevelopment/major_networks_middle.svg";
import why_hire_us from '../../assets/images/AboutusImages/why_hire_us.jpg';
import tick_done from '../../assets/images/AboutusImages/tick_done.png';
import here_to_help from '../../assets/images/HireDevelopers/here-to-help-you-img.png';
import Secure_and_Reliable from '../../assets/images/HireDevelopers/HireIOS/Secure_and_Reliable.svg';
import Better_User_Experience from '../../assets/images/HireDevelopers/HireIOS/Better_User_Experience.svg';
import Secure_Transactions from '../../assets/images/HireDevelopers/HireIOS/Secure_Transactions.svg';
import Well_informed_Audience from '../../assets/images/HireDevelopers/HireIOS/Well_informed_Audience.svg';
import Classification_and_Security from '../../assets/images/HireDevelopers/HireIOS/Classification_and_Security.svg';
import Guaranteed_Maintenance_Support from '../../assets/images/HireDevelopers/HireIOS/Guaranteed_Maintenance_Support.svg';
import Autonomous_Coding from '../../assets/images/HireDevelopers/HireIOS/Autonomous_Coding.svg';
import Moderate_Solutions from '../../assets/images/HireDevelopers/HireIOS/Moderate_Solutions.svg';
import Bother_Free_Access from '../../assets/images/HireDevelopers/HireIOS/Bother_Free_Access.svg';
import On_Time_Delivery from '../../assets/images/HireDevelopers/HireIOS/On_Time_Delivery.svg';
import Versatile_to_Latest_Technologies from '../../assets/images/HireDevelopers/HireIOS/Versatile_to_Latest_Technologies.svg';
import Hire_banner from '../../assets/images/HireDevelopers/HireIOS/HIRE-IOS-APP-DEVELOPERS1.png';
// Get Images End

// Custom Stylesheets Start
import "../../assets/css/commonStyle.css";
import "../../assets/css/HireMagentoDev.css";
import "../../assets/css/hire-ecommerce.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../common/InnerPagesBanner";
import NumberCount from "../common/NumberCount";
import HireUsForm from "../HireUsForm";
import HereToHelpYou from "../common/HereToHelpYou";
// Get Common Components End

class HireIOSAppDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Hire IOS App Developers",
      bannerImg: Hire_banner,
      HereToHelpYou: [
        {
          expanded: 'panel1',
          ariaControls: 'panel1d-content',
          panelId: 'panel1d-header',
          typographyText: 'For what reason would it be a good idea for us to utilize Visions over another organization? ',
          TypographyDescription: 'Our customers use us given our expanded capacities to effectively convey complex application development ventures. We have more than 23 years of involvement with tackling a wide range of issues, for example, working closely with the existing developers group, working with legacy frameworks to help new application improvements, constructing in fact complex highlights, and uniting frameworks, procedures, and applications with consistent integration.'
        },
        {
          expanded: 'panel2',
          ariaControls: 'panel2d-content',
          panelId: 'panel2d-header',
          typographyText: 'Would you be able to give me a fixed-value quote? ',
          TypographyDescription: 'Before we start your task, we work with you to compose a point by point venture detail and afterward give you a fixed-value statement to convey the work. This fixed cost and particular secures you: you can consider us responsible to convey precisely what has been put to paper, for that definite expense. No concealed charges, no tricky expenses, everything is clear and compact. '
        },
        {
          expanded: 'panel3',
          ariaControls: 'panel3d-content',
          panelId: 'panel3d-header',
          typographyText: 'Would you be able to give me a ballpark quote? ',
          TypographyDescription: 'Indeed, we will consistently attempt to give you a ballpark or estimated cost as right on time as could reasonably be expected; typically on your first call or email. Having an unpleasant thought of expected expenses as quickly as time permits cause you to qualify whether what you have as a main priority is financially attainable and if you might want to examine further.'
        },
        {
          expanded: 'panel4',
          ariaControls: 'panel4d-content',
          panelId: 'panel4d-header',
          typographyText: "How would you ensure you build what I need, and don't miss the point? ",
          TypographyDescription: "We don't simply aimlessly start constructing your prerequisites; we start by having a gathering to understanding the business factors that are driving the requirement for your task. We need to get to a position rapidly where we realize enough to challenge you on the suspicions you've made about the highlights you require."
        },
        {
          expanded: 'panel5',
          ariaControls: 'panel5d-content',
          panelId: 'panel5d-header',
          typographyText: 'Will I claim the source code? ',
          TypographyDescription: 'Truly, you will be doled out proper copyright or permit rights for the bespoke code that we compose for your task. We are straightforward with protected innovation rights, and you will have the chance to audit our code proprietorship terms before the venture starts.'
        }
      ]
      };
  }
  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }
  render() {
    document.title = "Hire iOS Developer | Hire iPhone App Developer India";
    const {
      pageTitle, bannerImg
    } = this.state;
    return (
      <div className="main_hireEcommerce main_hireDevelopers">
        <Grid container>
          {/* Start - InnerPagesBanner Import From Common Components */}
          <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
          {/* End - InnerPagesBanner Import From Common Components */}

          {/* Start - HireUsForm Import From Root Of Components Folder */}
          <HireUsForm technology="IOS App Developers" />
          {/* End - HireUsForm Import From Root Of Components Folder */}

          {/* Start - Section Design "Advantages of Hiring Us" */}
          <Grid item xs={12} className="main_application_dev">
            <Grid
              component="div"
              className="major_social_networks sec_padding openCart section_title_space bg-lightgrey"
              id="ios-app-development"
            >
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <div className="text_wrap title_row">
                    <aside>
                      <Typography
                        variant="h2"
                        className="head_title"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Advantages of Hiring Us
                      </Typography>
                      <Typography
                        className="head_paragraph"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                      >
                        The upside of employing an iPhone application improvement organization like <strong>"Visions"</strong> is that they are perceived among numerous enterprises as being extraordinarily skilled in addition to the cost not as much as software engineers from different nations. <strong>Visions</strong> are generally adulated for their exceptional iPhone application advancement benefits. The iPhone applications created by us are exceptionally versatile, hearty and of colossal utility to permit propelled organizations for ideal profitability.
                      </Typography>
                    </aside>
                  </div>
                  <Grid item container xs={12} className="major_networks_wrap">
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Secure_and_Reliable} alt="Secure and Reliable" title="Secure and Reliable" />
                        <Typography variant="h5" className="mb-1">
                          Secure and Reliable
                            </Typography>
                        <Typography className="head_paragraph">
                          Apple is the most secure stage as far as versatile application improvement. It gives clients a solid assurance of protection from viruses and malware.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Better_User_Experience} alt="Better User Experience" title="Better User Experience" />
                        <Typography variant="h5" className="mb-1">
                          Better User Experience
                            </Typography>
                        <Typography className="head_paragraph">
                          Apple is known to give the best client involvement with the world to its versatile clients. The app. store rules are one diverse way that pushes developers to grow top-notch iOS applications.
                            </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <div className="content_box middle_img" data-aos="zoom-in" data-aos-duration="2000">
                        <img src={major_networks_middle} alt="Advantages of Hiring Us" title="Advantages of Hiring Us" />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={4} className="aside">
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="200">
                        <img className="icon" src={Secure_Transactions} alt="Secure Transactions" title="Secure Transactions" />
                        <Typography variant="h5" className="mb-1">
                          Secure Transactions
                            </Typography>
                        <Typography className="head_paragraph">
                          Even though organizations build up their safety efforts and rules for online transactions however iOS makes the online exchanges progressively secure by giving improved security to its clients.
                            </Typography>
                      </div>
                      <div className="content_box" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay="300">
                        <img className="icon" src={Well_informed_Audience} alt="Well Informed Audience" title="Well Informed Audience" />
                        <Typography variant="h5" className="mb-1">
                          Well Informed Audience
                            </Typography>
                        <Typography className="head_paragraph">
                          Apple has been the primary decision for little to big business entrepreneurs or business visionaries and educated crowd.
                            </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Advantages of Hiring Us" */}

          {/* Start - Section Design "Hire Our Developers KeyBenefits" */}
          <Grid item xs={12}>
            <Grid component="div" className="key_benfits_and_why_choose sec_padding bottomkeybenefits bg-darkblue">
              <Grid item container className="container_custom boxes_listing">
                <Grid item xs={12} className="title_row">
                  <aside>
                    <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">
                      Hire iOS Developers
                              </Typography>
                    <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">
                      <strong>Visions</strong> are the main iPhone application improvement organization. Our attention is on <strong>iOS</strong> application advancement, launch and deployment – we are liable for your prosperity. We convey the best and remarkable application for your business at the predetermined time and inside your financial limit. Our group of specialists will place your idea into a genuine application to fulfill shopper's needs or your business destinations. Visions pursue worldwide <strong>iOS</strong> improvement procedures to create a wide scope of iPhone, iWatch, iPad based versatile applications for various business verticals.
                              </Typography>
                  </aside>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="600">
                  <div className="list_key_boxes">
                    <img alt="Classification and Security" title="Classification and Security" src={Classification_and_Security} />
                    <Typography variant="h4" className="head_title">
                      Classification and Security
                              </Typography>
                    <Typography className="head_paragraph">
                      The code that we have once created for a custom application, we don't utilize the equivalent anyplace else. We guarantee the security & privacy of the code that we have composed.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="800">
                  <div className="list_key_boxes">
                    <img alt="Guaranteed Maintenance and Support" title="Guaranteed Maintenance and Support" src={Guaranteed_Maintenance_Support} />
                    <Typography variant="h4" className="head_title">
                      Guaranteed Maintenance and Support
                              </Typography>
                    <Typography className="head_paragraph">
                      We offer help for the code that we made and recommend the most appropriate ways to deal with improve the development and proficiency of the item.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1000">
                  <div className="list_key_boxes">
                    <img alt="Autonomous Coding" title="Autonomous Coding" src={Autonomous_Coding} />
                    <Typography variant="h4" className="head_title">
                      Autonomous Coding
                              </Typography>
                    <Typography className="head_paragraph">
                      The code that we compose is reasonable and easily open by any of the engineers who put their hands in the code for further updates.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1200">
                  <div className="list_key_boxes">
                    <img alt="Moderate Solutions" title="Moderate Solutions" src={Moderate_Solutions} />
                    <Typography variant="h4" className="head_title">
                      Moderate Solutions
                              </Typography>
                    <Typography className="head_paragraph">
                      We accept to make the customer access for the total ROI, and our advancement rates are additional fit with all spending reaches.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1400">
                  <div className="list_key_boxes">
                    <img alt="Bother Free Access" title="Bother Free Access" src={Bother_Free_Access} />
                    <Typography variant="h4" className="head_title">
                      Bother Free Access
                              </Typography>
                    <Typography className="head_paragraph">
                      Clients know the delight of problem-free application access with phenomenal highlights and hi-tech usefulness.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="1600">
                  <div className="list_key_boxes">
                    <img alt="On-Time Delivery" title="On-Time Delivery" src={On_Time_Delivery} />
                    <Typography variant="h4" className="head_title">
                      On-Time Delivery
                              </Typography>
                    <Typography className="head_paragraph">
                      We are extremely reliable for the item conveyance inside the submitted cutoff time. In practice to give on-schedule, we never bargain on the nature of the outcome.
                              </Typography>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={3} className="boxes_key" data-aos="fade-up" data-aos-duration="2000">
                  <div className="list_key_boxes">
                    <img alt="Versatile to Latest Technologies" title="Versatile to Latest Technologies" src={Versatile_to_Latest_Technologies} />
                    <Typography variant="h4" className="head_title">
                      Versatile to Latest Technologies
                              </Typography>
                    <Typography className="head_paragraph">
                      The designers here are talented, capable and competent to build up an iPhone application that can associate with new forms and innovations.
                              </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Hire Our Developers KeyBenefits" */}

          {/* Start - Section Design "Why Hire Us" */}
          <Grid item xs={12}>
            <Grid component="div" className="why_hire_us sec_padding">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">Why Hire Us?</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">We create outstanding UI/UX, branding web design & development solutions for startups & companies.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item md={4} className="left_side_list" data-aos="fade-right" data-aos-duration="800">
                    <ul className="left_list lists_hire_us">
                      <li>
                        End-to-End iPhone App Development Services. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Certified Methodologies. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Easy to use iPhone App Design. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Key Technology Consulting. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                      <li>
                        Reasonable Pricing. <img alt="tick" className="tick_done" src={tick_done} />
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={4} className="center_img" data-aos="fade-up" data-aos-duration="800">
                    <img alt="Why Hire Us" title="Why Hire Us" src={why_hire_us} />
                  </Grid>
                  <Grid item md={4} className="right_side_list" data-aos="fade-left" data-aos-duration="800">
                    <ul className="right_list lists_hire_us">
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Gifted Team of Professionals.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Adaptability and Predictability.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Committed UI/UX Experts and Developers.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Solid and Scalable iPhone Applications.
                                  </li>
                      <li>
                        <img alt="tick" className="tick_done" src={tick_done} /> Application Code as per Apple Guidelines.
                                  </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "Why Hire Us" */}

          {/* Start - Section Design "We Are Here to Help You" */}
          <Grid item xs={12}>
            <Grid component="div" className="we-are-help-you sec_padding bg-lightgrey">
              <Grid item container className="container_custom">
                <Grid item xs={12}>
                  <Typography variant="h2" className="head_title" data-aos="fade-up" data-aos-duration="800">We Are Here to Help You</Typography>
                  <Typography className="head_paragraph" data-aos="fade-up" data-aos-duration="1200">Our <strong>iOS</strong> team embraces change, and as the <strong>iOS</strong> ecosystem has grown we have incorporated every new feature and technology. We are well versed with all technology stacks and can solve our clients’ most challenging problems through our technological prowess and a clear understanding of the pros and cons of each of these technologies to adapt to business needs.</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} className="left_side" data-aos="fade-right" data-aos-duration="1000">
                    <HereToHelpYou HereToHelpYou={this.state.HereToHelpYou} />
                  </Grid>
                  <Grid item xs={12} md={6} className="right_side" data-aos="fade-left" data-aos-duration="1000">
                    <img alt="We Are Here to Help You" title="We Are Here to Help You" src={here_to_help} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End - Section Design "We Are Here to Help You" */}

          {/* Start - NumberCount Import From Common Components */}
          <NumberCount />
          {/* End - NumberCount Import From Common Components */}

        </Grid>
      </div>
    );
  }
}

export default HireIOSAppDev;
